import '../styles/components/footer.css';
import { useNavigate } from 'react-router-dom';
import icon from '../assets/icons/header_logo_small.svg';

export default function Footer() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <footer className='global-footer'>
            <div className='container-content-footer'>
                <img 
                    className='icon-footer clickable' 
                    src={icon} 
                    alt="Logo du laboratoire" 
                    onClick={() => handleNavigation('/')} 
                />
                <div className='container-text-footer'>
                    <button 
                        className='text-footer clickable' 
                        onClick={() => handleNavigation('/notre-laboratoire')}
                    >
                        Notre laboratoire
                    </button>
                    <button 
                        className='text-footer clickable' 
                        onClick={() => handleNavigation('/formule-marque-blanche')}
                    >
                        Formule en marque blanche
                    </button>
                    <button 
                        className='text-footer clickable' 
                        onClick={() => handleNavigation('/formule-sur-mesure')}
                    >
                        Formule sur mesure
                    </button>
                </div>
                <div className='container-text-footer'>
                    <button 
                        className='text-footer clickable' 
                        onClick={() => handleNavigation('/services')}
                    >
                        Liposomes
                    </button>
                    <button 
                        className='text-footer clickable' 
                        onClick={() => handleNavigation('/services')}
                    >
                        Niosomes
                    </button>
                    <button 
                        className='text-footer clickable' 
                        onClick={() => handleNavigation('/services')}
                    >
                        Probiotiques & postbiotiques
                    </button>
                </div>
                <div className='container-adresse-footer'>
                    <p className='text-footer black'>145 rue de la marbrerie</p>
                    <p className='text-footer black'>Le salaison - bât.12</p>
                    <p className='text-footer black'>34740 vendargues</p>
                    <p className='text-footer black'>+33 4 99 78 08 11</p>
                </div>
            </div>
        </footer>
    );
}
