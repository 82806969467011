import React from "react";

import "../styles/components/productCardMockup.css";
import icon_gel from "../assets/icons/gel_mockup.png";

const ProductCardMockup = ({ image, name, quantity, index }) => {
  return (
    <div className="ProductCardMockup_global-container">
      <img src={image} alt={name} className="ProductCardMockup-img" />
      <h3 className="ProductCardMockup-title">{name}</h3>
      <div className="ProductCardMockup_icon_subtitle-container">
        {index < 44 && (
          <>
            <img className="ProductCardMockup_icon" src={icon_gel} alt="Gel Icon" />
            <p className="ProductCardMockup-subtitle">{quantity} gélules</p>
          </>
        )}
      </div>
      <button className="ProductCardMockup-btn">En savoir +</button>
    </div>
  );
};

export default ProductCardMockup;
