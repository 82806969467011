import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../styles/components/header.css';
import iconPhone from '../assets/icons/header_phone.svg';
import iconMail from '../assets/icons/header_mail.svg';
import Dropdown from '../components/Dropdown';

export default function Header() {
    const [isMobile, setIsMobile] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1050);
        };

        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleNavigation = (path, hash = "") => {
        navigate(path);
        closeMenu();
        
        if (hash) {
            setTimeout(() => {
                const target = document.getElementById(hash);
                if (target) {
                    target.scrollIntoView({ behavior: "smooth", block: "start" });
                }
            }, 100);
        }
    };
    

    return (
        <header className="header_content">
            {!isMobile && (
                <div className="header_main_div">
                    <div className="header_center">
                        <div className="header_content">
                            <img src={iconPhone} alt="icone téléphone" className="icon_bolder" />
                            <p className="header_text">+33 4 99 78 08 11</p>
                        </div>
                        <div className="header_content">
                            <img src={iconMail} alt="icone mail" className="header_icon" />
                            <p className="header_text">contact@lin-lab.com</p>
                        </div>
                    </div>
                </div>
            )}
            <div className={`header_box ${isScrolled ? "scrolled" : ""}`}>
                <div className="header_box_div">
                    <div
                        className="header_logo"
                        onClick={() => handleNavigation('/')}
                    ></div>
                    {!isMobile && (
                        <p
                            className="header_box_p"
                            onClick={() => handleNavigation('/notre-laboratoire')}
                        >
                            NOTRE LABORATOIRE
                        </p>
                    )}
                </div>
                {isMobile ? (
                    <>
                        <button className="menu-toggle" onClick={toggleMenu}>
                            ≡
                        </button>
                        {isMenuOpen && (
                            <div className="mobile_menu">
                                <h3>Nos ingrédients :</h3>
                                <ul>
                                    <li onClick={() => handleNavigation('/probiotiques')}>Probiotiques VPRO</li>
                                    <li onClick={() => handleNavigation('/probiotiques')}>Postbiotiques PROLIPO</li>
                                    <li onClick={() => handleNavigation('/liposomes')}>Technologie LIPOSOMALE</li>
                                    <li onClick={() => handleNavigation('/niosomes')}>Cosmétiques technologie NIOSOMALE</li>
                                </ul>
                                <h3>Nos formules :</h3>
                                <ul>
                                    <li onClick={() => handleNavigation('/formule-marque-blanche')}>Marque blanche</li>
                                    <li onClick={() => handleNavigation('/formule-sur-mesure')}>Sur-mesure</li>
                                </ul>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="header_box_div">
                        <Dropdown
                            btn={1}
                            label="NOS INGRÉDIENTS"
                            items={[
                                { label: "PROBIOTIQUES VPRO®", path: "/probiotiques", hash:"top" },
                                { label: "POSTBIOTIQUES PROLIPO®", path: "/probiotiques", hash: "postbiotique_positifPoint-container-bg" },
                                { label: "ACTIFS LIPOSOMÉS", path: "/liposomes" },
                                { label: "COSMÉTIQUES", path: "/niosomes" }
                            ]}
                            onItemClick={handleNavigation}
                        />

                        <Dropdown
                            btn={2}
                            label="NOS FORMULES"
                            items={[
                                { label: "MARQUE BLANCHE", path: '/formule-marque-blanche' },
                                { label: "SUR-MESURE", path: '/formule-sur-mesure' }
                            ]}
                            onItemClick={handleNavigation}
                        />
                    </div>
                )}
            </div>
        </header>
    );
}
