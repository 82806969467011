const produits = [
    {
        "nom": "Lactobacillus acidophilus L59",
        "type": "probiotique",
        "cfu": "200 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus reuteri HR7",
        "type": "probiotique",
        "cfu": "200 milliards",
        "categories": ["Allergies/Immunité"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus rhamnosus LR663",
        "type": "probiotique",
        "cfu": "600 milliards",
        "categories": ["Santé gastro-intestinale", "Santé mentale", "Peau"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus plantarum HP59",
        "type": "probiotique",
        "cfu": "600 milliards",
        "categories": ["Santé gastro-intestinale", "Protection du foie"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus bulgaricus HB68",
        "type": "probiotique",
        "cfu": "50 milliards",
        "categories": ["Santé gastro-intestinale", "Femmes", "Peau", "Allergies/Immunité"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus helveticus HH28",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Santé mentale", "Articulations"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus paracasei HPC69",
        "type": "probiotique",
        "cfu": "600 milliards",
        "categories": ["Allergies/Immunité"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus casei HC5",
        "type": "probiotique",
        "cfu": "600 milliards",
        "categories": ["Sommeil", "Protection du foie"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus fermentum HLF67",
        "type": "probiotique",
        "cfu": "500 milliards",
        "categories": ["Sommeil", "Métabolisme"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Streptococcus thermophilus ST67",
        "type": "probiotique",
        "cfu": "200 milliards",
        "categories": ["Bucco-dentaire"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Bifidobacterium lactis BL03",
        "type": "probiotique",
        "cfu": "600 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Bifidobacterium animalis BA05",
        "type": "probiotique",
        "cfu": "600 milliards",
        "categories": ["Santé gastro-intestinale", "Métabolisme", "Allergies/Immunité"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Bifidobacterium bifidum BB67",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Santé gastro-intestinale", "Métabolisme", "Allergies/Immunité", "Femmes"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Bifidobacterium longum HBL79",
        "type": "probiotique",
        "cfu": "200 milliards",
        "categories": ["Santé mentale"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Bifidobacterium breve HBB37",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Métabolisme"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Bifidobacterium adolescentis BA6",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Santé gastro-intestinale", "Métabolisme", "Allergies/Immunité", "Femmes"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Bifidobacterium infantis HBI27",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Allergies/Immunité"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Bifidobacterium breve HBB37",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Métabolisme"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Bifidobacterium adolescentis BA6",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Santé gastro-intestinale", "Métabolisme", "Allergies/Immunité", "Femmes"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Bifidobacterium infantis HBI27",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Allergies/Immunité"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Leuconostoc mesenteroides HLM8",
        "type": "probiotique",
        "cfu": "200 milliards",
        "categories": ["Santé gastro-intestinale", "Peau", "Allergies/Immunité"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus lactis HLL31",
        "type": "probiotique",
        "cfu": "500 milliards",
        "categories": ["Santé gastro-intestinale", "Protection du foie", "Allergies/Immunité"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus salivarius HLS89",
        "type": "probiotique",
        "cfu": "200 milliards",
        "categories": ["Bucco-dentaire"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus crispatus HLC28",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Femmes"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Bacillus coagulans HBC798",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Allergies/Immunité", "Articulations"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Saccharomyces boulardii HS5",
        "type": "probiotique",
        "cfu": "20 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Pediococcus pentosaceus HPP27",
        "type": "probiotique",
        "cfu": "500 milliards",
        "categories": ["Santé gastro-intestinale", "Allergies/Immunité"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Paediococcus acidilactici HPA9",
        "type": "probiotique",
        "cfu": "500 milliards",
        "categories": ["Santé gastro-intestinale", "Allergies/Immunité", "Femmes"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus gasseri HLG13",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Peau", "Femmes", "Métabolisme"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus johnsonii HLJ08",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Peau"],
        "souche": "Souche conventionelle",
        "action": null
    },
    {
        "nom": "Lactobacillus brevis LBR71",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Femmes"],
        "souche": "Souche conventionelle",
        "action": null
    },

    // Probiotiques VPRO® avec actions spécifiques
    {
        "nom": "Bifidobacterium longum CCFM760",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche VPRO®",
        "action": "Constipation"
    },
    {
        "nom": "Lactobacillus plantarum CCFM1143",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche VPRO®",
        "action": "Diarrhée"
    },
    {
        "nom": "Lactobacillus plantarum CCFM8610",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche VPRO®",
        "action": "Immunité · Équilibre du microbiote"
    },
    {
        "nom": "Lactobacillus rhamnosus JS-SZ-2-1 (CCFM1119)",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche VPRO®",
        "action": "Résistant à l'hélicobacter pylori"
    },
    {
        "nom": "Bifidobacterium bifidum CCFM1167",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche VPRO®",
        "action": "Constipation"
    },
    {
        "nom": "Bifidobacterium longum YS108R",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche VPRO®",
        "action": "Inflammation intestinale"
    },
    {
        "nom": "Lactobacillus plantarum CN2018",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche VPRO®",
        "action": "Résistant à l'hélicobacter pylori"
    },
    {
        "nom": "Bacillus coagulans -39",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche VPRO®",
        "action": "Digestion · Absorption des nutriments · Constipation"
    },
    {
        "nom": "Bifidobacterium animalis subsp. lactis Bb-12 (DSM 15954)",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Santé gastro-intestinale"],
        "souche": "Souche VPRO®",
        "action": "Régulation du transit · Immunité"
    },
    {
        "nom": "Bifidobacterium breve CCFM1025",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Santé mentale"],
        "souche": "Souche VPRO®",
        "action": "Équilibre psychologique"
    },
    {
        "nom": "Bifidobacterium infantis CCFM687",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Santé mentale"],
        "souche": "Souche VPRO®",
        "action": "Équilibre émotionnel"
    },
    {
        "nom": "Lactobacillus plantarum CCFM1050",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Sommeil"],
        "souche": "Souche VPRO®",
        "action": "Amélioration du sommeil"
    },
    {
        "nom": "Bifidobacterium longum CCFM1029",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Allergies/Immunité"],
        "souche": "Souche VPRO®",
        "action": "Dermatite atopique"
    },
    {
        "nom": "Streptococcus thermophilus CCFM218",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Allergies/Immunité"],
        "souche": "Souche VPRO®",
        "action": "Réactions allergiques"
    },
    {
        "nom": "Lactobacillus reuteri CCFM1040",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Allergies/Immunité"],
        "souche": "Souche VPRO®",
        "action": "Asthme allergique"
    },
    {
        "nom": "Akkermansia muciniphila AH39 (Inactivated)",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Allergies/Immunité"],
        "souche": "Souche VPRO®",
        "action": "Immunité"
    },
    {
        "nom": "Bifidobacterium longum CCFM756",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Peau"],
        "souche": "Souche VPRO®",
        "action": "Riche en zinc"
    },
    {
        "nom": "Lactobacillus crispatus CCFM1110",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Femmes"],
        "souche": "Souche VPRO®",
        "action": "Vaginite"
    },
    {
        "nom": "Lactobacillus plantarum HL2",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Femmes"],
        "souche": "Souche VPRO®",
        "action": "Syndrome des ovaires polykystiques"
    },
    {
        "nom": "Lactobacillus gasseri CCFM1201",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Femmes"],
        "souche": "Souche VPRO®",
        "action": "Vaginose bactérienne"
    },
    {
        "nom": "Lactobacillus jensenii CCFM1213",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Femmes"],
        "souche": "Souche VPRO®",
        "action": "Inflammation vaginale"
    },
    {
        "nom": "Lactobacillus plantarum CCFM8724",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Bucco-dentaire"],
        "souche": "Souche VPRO®",
        "action": "Prévention des caries dentaires"
    },
    {
        "nom": "Lactobacillus rhamnosus CCFM1138",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Bucco-dentaire"],
        "souche": "Souche VPRO®",
        "action": "Prévention de la parodontite"
    },
    {
        "nom": "Bifidobacterium adolescentis CCFM1061",
        "type": "probiotique",
        "cfu": "100 milliards",
        "categories": ["Métabolisme"],
        "souche": "Souche VPRO®",
        "action": "Lésions hépatiques non alcooliques · Diabète de type II"
    },
    {
        "nom": "Lactobacillus plantarum 30M5",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Métabolisme"],
        "souche": "Souche VPRO®",
        "action": "Équilibre des œstrogènes"
    },
    {
        "nom": "Lactobacillus rhamnosus CCFM1130",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Métabolisme"],
        "souche": "Souche VPRO®",
        "action": "Hyperuricémie"
    },
    {
        "nom": "Lactobacillus rhamnosus CCFM0528",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Métabolisme"],
        "souche": "Souche VPRO®",
        "action": "Prévention du diabète"
    },
    {
        "nom": "Lactobacillus plantarum CCFM1149",
        "type": "probiotique",
        "cfu": "200 milliards",
        "categories": ["Métabolisme"],
        "souche": "Souche VPRO®",
        "action": "Hypertension artérielle"
    },
    {
        "nom": "Lactobacillus reuteri FGSZY33L6",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Métabolisme"],
        "souche": "Souche VPRO®",
        "action": "Syndrome métabolique"
    },
    {
        "nom": "Lactobacillus plantarum ZS2058",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Métabolisme"],
        "souche": "Souche VPRO®",
        "action": "Production CLA · Stimule le métabolisme"
    },
    {
        "nom": "Lactobacillus rhamnosus CCFM1107",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Protection du foie"],
        "souche": "Souche VPRO®",
        "action": "Lésions hépatiques chroniques dues à l'alcool"
    },
    {
        "nom": "Lactobacillus plantarum CCFM8661",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Protection du foie"],
        "souche": "Souche VPRO®",
        "action": "Élimination des métaux lourds"
    },
    {
        "nom": "Lactobacillus plantarum CCFM639",
        "type": "probiotique",
        "cfu": "200 milliards",
        "categories": ["Protection du foie"],
        "souche": "Souche VPRO®",
        "action": "Élimination des métaux lourds"
    },
    {
        "nom": "Lactobacillus casei CCFM1074",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Articulations"],
        "souche": "Souche VPRO®",
        "action": "Arthrite rhumatoïde"
    },
    {
        "nom": "Lactobacillus rhamnosus GG (ATCC 53103)",
        "type": "probiotique",
        "cfu": "300 milliards",
        "categories": ["Santé gastro-intestinale", "Allergies/Immunité", "Bucco-dentaire", "Peau", "Santé mentale"],
        "souche": "Souche VPRO®",
        "action": "Diarrhée · Allergies · Coliques infantiles · Infections des voies respiratoires supérieures..."
    },
    {
        "nom": "Lactobacillus rhamnosus CCFM1219",
        "type": "postbiotique",
        "technologie": "PROLIPO®",
        "cfu": "200 milliards",
        "action": "Contrôle du poids · Santé du foie"
    },
    {
        "nom": "Bifidobacterium longum CCFM1195",
        "type": "postbiotique",
        "technologie": null,
        "cfu": "100 milliards",
        "action": "Riche en zinc · Favorise la croissance"
    },
    {
        "nom": "Lactobacillus rhamnosus CCFM1138",
        "type": "postbiotique",
        "technologie": null,
        "cfu": "300 milliards",
        "action": "Prévention de la parodontite"
    },
    {
        "nom": "Lactobacillus plantarum CCFM8724",
        "type": "postbiotique",
        "technologie": null,
        "cfu": "300 milliards",
        "action": "Prévenir les caries de la petite enfance"
    },
    {
        "nom": "Lactobacillus casei CN1566",
        "type": "postbiotique",
        "technologie": null,
        "cfu": "300 milliards",
        "action": "Antioxydant · Anti-âge"
    },
    {
        "nom": "Lactobacillus plantarum CCFM8661",
        "type": "postbiotique",
        "technologie": null,
        "cfu": "300 milliards",
        "action": "Élimination des métaux lourds"
    },
    {
        "nom": "Lactobacillus plantarum CN2018",
        "type": "postbiotique",
        "technologie": null,
        "cfu": "300 milliards",
        "action": "Résistant à l'hélicobacter pylori"
    }
];

export default produits;
