import React from "react";
import MoreInfo from "../components/MoreInfo";
import '../styles/components/formulaBox.css';

function FormulaBox({ title, icon, content, link }) {
    return (
        <div className="formulaBox-container">
            <img className="formulaBox-img" src={icon} alt="Icone Formule" />
            <h3 
                className="formulabox_title"
                dangerouslySetInnerHTML={{ __html: title }}
            ></h3>
            <div className="horizontal-bar"></div>
            <p 
                className="formulabox_content"
                dangerouslySetInnerHTML={{ __html: content }}
            ></p>
            <MoreInfo 
                link={link}
                label={"En savoir +"}
                position={true}
            />
        </div>
    );
}

export default FormulaBox;
