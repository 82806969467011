import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import style from './styles/general.css';
import Home from './views/Home';
import Fiche from './views/Fiche';
import FormuleSurMesure from './views/FormuleSurMesure';
import FormuleMarqueBlanche from './views/FormuleMarqueBlanche';
import NotreLaboratoire from './views/NotreLaboratoire';
import Niosome from './views/Niosome.js';
import Liposome from './views/Liposome.js';
import Probiotique from './views/Probiotique.js';

import Header from './components/header';
import Footer from './components/footer';
import produits from './assets/ProductContent/data/data.js'
import ScrollToTop from './components/ScrollToTop.js';

export default function App() {
  return (
    <Router>
          <Header/>
          <ScrollToTop />
          <Routes>
              <Route path='/' element={<Home/>}/>
              <Route path='/formule-sur-mesure' element={<FormuleSurMesure/>}/>
              <Route path='/formule-marque-blanche' element={<FormuleMarqueBlanche/>}/>
              <Route path='/notre-laboratoire' element={<NotreLaboratoire/>}/>
              <Route path='/liposomes' element={<Liposome/>}/>
              <Route path='/probiotiques' element={<Probiotique/>}/>
              <Route path='/niosomes' element={<Niosome/>}/>
              {produits.map(produit => (
                  <Route key={produit.nom} path={produit.url} element={<Fiche />} />
              ))}
              <Route path='*' element={<Navigate to="/"/>}/>
          </Routes>
          <Footer/>
      </Router>
  );
}
