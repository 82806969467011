import React from "react";
import "../styles/components/formulacard.css";
import MoreInfo from "../components/MoreInfo";
import icon_homepage_check from "../assets/icons/homepage_check.png";

function FormulaCard({ title, subtitle, icon, content, link }) {

    console.log(link)

    return (
        <div className="formulacard_container_content">
            {/* Image principale */}
            <img className="formulacard_img" src={icon} alt="Icone" />

            {/* Titre principal avec contenu HTML interprété */}
            <h2
                className="formulacard_title"
                dangerouslySetInnerHTML={{ __html: title }}
            ></h2>

            {/* Sous-titre */}
            <p className="formulacard_subtitle">{subtitle}</p>

            {/* Liste avec icône */}
            <ul className="formulacard_list">
                {Array.isArray(content) &&
                    content.map(([key, description], index) => (
                        <div key={index} className="formulacard_list_icon">
                            <img
                                className="formulacard_icon_check"
                                src={icon_homepage_check}
                                alt="Check Icon"
                            />
                            <li className="formulacard_list_item">
                                <strong className="formulacard_orange">{key} :</strong> {description}
                            </li>
                        </div>
                    ))}
            </ul>

            {/* Lien "En savoir +" */}
            <div className="formulaCard_info">
                <MoreInfo link={link} label={"En savoir +"} position={true}/>
            </div>
        </div>
    );
}

export default FormulaCard;
