import React from 'react';
import '../styles/components/jobsTimeline.css';

export default function JobsTimeline({ img, title, text, width, height, right, isLast }) {
    // Fonction pour insérer un retour à la ligne avant "&"
    const formatTitle = (title) => {
        return title.split('&').map((part, index, array) => (
            <React.Fragment key={index}>
                {index > 0 && <br />}
                {index > 0 && '&'}
                {part}
            </React.Fragment>
        ));
    };

    return (
        <div className="timeline_global-container">
            <div
                className={`timeline-img-line-container ${isLast ? 'isLast' : ''}`}
            >
                <img
                    className="timeline-img"
                    src={img}
                    style={{ width: width, height: height }}
                    alt={title}
                />
                {!isLast && <div className="timeline-line"></div>}
            </div>
            <div
                className={`timeline-title-text-container ${right === 1 ? 'right' : 'left'}`}
            >
                <h3 className="timeline-title">{formatTitle(title)}</h3>
                <div className="timeline-text-container">
                    {text.map((line, index) => (
                        <div key={index} className="timeline-text-line">
                            <span className="timeline-icon orange-color bold">✓</span>
                            <p className="timeline-text">{line}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
