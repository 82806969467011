import biotine from "../img/mock-up-biotine.png"
import hyluronique from "../img/mock-up-hyaluronique.png"
import minceur from "../img/mock-up-minceur.png"
import capillaire from "../img/mock-up-capillaire.png"
import multivitamines from "../img/mock-up-multivitamines.png"
import vitaminec from "../img/mock-up-vitaminec+.png"
import energie from "../img/mock-up-energie.png"
import immunite from "../img/mock-up-immunité.png"
import multivitaminesEtMineraux from "../img/mock-up-sirop-multivitamines.png"
import magnesium from "../img/mock-up-magnesium.png"
import articulation from "../img/mock-up-articulation.png"
import voies_respi from "../img/mock-up-voies-respiratoires.png"
import detox from "../img/mock-up-detox.png"
import circulation from "../img/mock-up-circulation.png"
import sommeil from "../img/mock-up-sommeil.png"
import antiStress from "../img/mock-up-anti-stress.png"
import jetLag from "../img/mock-up-jet-lag.png"
import calcium from "../img/mock-up-calcium.png"
import chrome from "../img/mock-up-chrome.png"
import b12 from "../img/mock-up-vitamineb12.png"
import vitaminek2d3 from "../img/mock-up-vitaminesk2d3.png"
import glutathion from "../img/mock-up-glutathion.png"
import cuivre from "../img/mock-up-cuivre.png"
import coenzyme from "../img/mock-up-coQ10.png"
import ferComplex from "../img/mock-up-fercomplex.png"
import vitamineD3 from "../img/mock-up-vitamined3.png"
import quercetine from "../img/mock-up-quercetine.png"
import zinc from "../img/mock-up-zinc.png"
import resveratrol from "../img/mock-up-resveratrol.png"
import vitamineBComplex from "../img/mock-up-vitaminesBcomplex.png"
import atbFlore from "../img/mock-up-atb-flore.png";
import floreEquilibre from "../img/mock-up-flore-equilibre.png"
import boulardii from "../img/mock-up-boulardii.png";
import constipation from "../img/mock-up-constipation.png";
import balonement from "../img/mock-up-anti-ballonnements.png";
import acidite from "../img/mock-up-acidite-reflux.png";
import floreEssentiel from "../img/mock-up-flore-essentiel.png";
import floreIntime from "../img/mock-up-flore-intime.png"

import cafeineEGCG from "../img/cafeine-egcg.png"
import acideHyaluroniqueB5 from "../img/acide-hyaluronique-vitamine-b5.png";
import vitamineCAcideHayluronique from "../img/vitamine-c-acide-hyaluronique.png"
import niacinamideZinc from "../img/niacinamide-zinc.png";
import acideGlycolique from "../img/acide-glycolique-bakuchiol.png"

const data = {
  categories: {
    complements_alimentaires: [
      { id: 1, name: "Les essentiels" },
      { id: 2, name: "Bien-être" },
      { id: 3, name: "Vitalité" },
      { id: 4, name: "Beauté" },
      { id: 5, name: "Probiotiques & Postbiotiques" }
    ],
    serums_cosmetiques: [
      { id: 6, name: "ANTI-CERNES & ANTI-POCHES" },
      { id: 7, name: "Anti-imperfections & exfoliant" },
      { id: 8, name: "Anti-âge & hydratant" },
      { id: 9, name: "Éclat & Éclaircissant" },
      { id: 10, name: "Anti-rougeurs & unifiant" }
    ]
  },
  products: [
    {
      id: 1,
      name: "Biotine",
      quantity: 30,
      category_id: 4,
      image: biotine
    },
    {
      id: 2,
      name: "Hyaluronique",
      quantity: 30,
      category_id: 4,
      image: hyluronique
    },
    {
      id: 3,
      name: "Minceur",
      quantity: 30,
      category_id: 4,
      image: minceur
    },
    {
      id: 4,
      name: "Capillaire",
      quantity: 105,
      category_id: 4,
      image: capillaire
    },
    {
      id: 5,
      name: "Multivitamines",
      quantity: 30,
      category_id: 3,
      image: multivitamines
    },
    {
      id: 6,
      name: "Vitamines C+",
      quantity: 60,
      category_id: 3,
      image: vitaminec
    },
    // {
    //   "id": 7,
    //   "name": "Vitamine C 1000mg",
    //   "quantity": 150,
    //   "category_id": 3,
    //   "image":
    // },
    {
      id: 8,
      name: "Energie",
      quantity: 30,
      category_id: 3,
      image: energie
    },
    {
      id: 9,
      name: "Immunité",
      quantity: 30,
      category_id: 3,
      image: immunite
    },
    {
      id: 10,
      name: "Multivitamines & minéraux",
      quantity: 30,
      category_id: 3,
      image: multivitaminesEtMineraux
    },
    // {
    //   "id": 11,
    //   "name": "Sirop Multivitamines et minéraux",
    //   "quantity": 100,
    //   "category_id": 3,
    //   "image":
    // },
    {
      id: 11,
      name: "Magnésium+",
      quantity: 60,
      category_id: 2,
      image: magnesium
    },
    // {
    //   id: 12,
    //   name: "Mélatonine",
    //   quantity: 30,
    //   category_id: 2,
    //   image: 
    // },
    // {
    //   id: 13,
    //   name: "Magnésium Mix",
    //   quantity: 180,
    //   category_id: 2,
    //   image:
    // },
    {
      id: 14,
      name: "Articulation",
      quantity: 90,
      category_id: 2,
      image: articulation
    },
    {
      id: 16,
      name: "Voies respiratoires",
      quantity: 30,
      category_id: 2,
      image: voies_respi
    },
    {
      id: 17,
      name: "Détox",
      quantity: 30,
      category_id: 2,
      image: detox
    },
    {
      id: 18,
      name: "Circulation",
      quantity: 30,
      category_id: 2,
      image: circulation
    },
    {
      id: 19,
      name: "Sommeil",
      quantity: 30,
      category_id: 2,
      image: sommeil
    },
    {
      id: 20,
      name: "Anti-stress",
      quantity: 30,
      category_id: 2,
      image: antiStress
    },
    {
      id: 21,
      name: "Jet lag",
      quantity: 30,
      category_id: 2,
      image: jetLag
    },
    {
      id: 22,
      name: "Calcium",
      quantity: 60,
      category_id: 1,
      image: calcium
    },
    {
      id: 23,
      name: "Chrome",
      quantity: 30,
      category_id: 1,
      image: chrome
    },
    {
      id: 24,
      name: "Vitamine B12",
      quantity: 30,
      category_id: 1,
      image: b12
    },
    {
      id: 25,
      name: "Vitamines K2D3",
      quantity: 30,
      category_id: 1,
      image: vitaminek2d3
    },
    {
      id: 26,
      name: "Glutathion",
      quantity: 30,
      category_id: 1,
      image: glutathion
    },
    {
      id: 28,
      name: "Cuivre",
      quantity: 30,
      category_id: 1,
      image: cuivre
    },
    {
      id: 29,
      name: "Coenzyme Q10",
      quantity: 30,
      category_id: 1,
      image: coenzyme
    },
    {
      id: 30,
      name: "Fer complex",
      quantity: 30,
      category_id: 1,
      image: ferComplex
    },
    {
      id: 31,
      name: "Vitamine D3",
      quantity: 30,
      category_id: 1,
      image: vitamineD3
    },
    {
      id: 32,
      name: "Quercétine",
      quantity: 30,
      category_id: 1,
      image: quercetine
    },
    {
      id: 33,
      name: "Zinc",
      quantity: 30,
      category_id: 1,
      image: zinc
    },
    {
      id: 41,
      name: "Resvératrol",
      quantity: 30,
      category_id: 1,
      image: resveratrol
    },
    {
      id: 34,
      name: "Vitamines B Complex",
      quantity: 30,
      category_id: 1,
      image: vitamineBComplex
    },
    {
      id: 35,
      name: "ATB Flore",
      quantity: 15,
      category_id: 5,
      image: atbFlore
      },
    {
      id: 36,
      name: "Flore Equilibre",
      quantity: 30,
      category_id: 5,
      image: floreEquilibre
      },
    {
      id: 37,
      name: "Boulardii",
      quantity: 30,
      category_id: 5,
      image: boulardii
      },
    {
      id: 38,
      name: "Constipation",
      quantity: 30,
      category_id: 5,
      image: constipation
      },
    {
      id: 39,
      name: "Anti-ballonnements",
      quantity: 30,
      category_id: 5,
      image: balonement
      },
    {
      id: 40,
      name: "Acidité & reflux",
      quantity: 30,
      category_id: 5,
      image: acidite
      },
    {
      id: 42,
      name: "Flore Essentiel",
      quantity: 30,
      category_id: 5,
      image: floreEssentiel
      },
    {
      id: 43,
      name: "Flore intime",
      quantity: 15,
      category_id: 5,
      image: floreIntime
      },
    {
      id: 44,
      name: "ANTI-CERNES & ANTI-POCHES",
      category_id: 6,
      serum: true,
      actifs : "Caffeine 10% + EGCG 2%",
      image: cafeineEGCG
    },
    {
      id: 45,
      name: "Anti-imperfections & exfoliant",
      category_id: 7,
      serum: true,
      actifs : "Acide glycolique10% + Bakuchiol 1%",
      image: acideGlycolique
    },
    {
      id: 46,
      name: "Anti-âge & hydratant",
      category_id: 8,
      serum: true,
      actifs : "Acide Hyaluronique 3% + B5 2% ",
      image: acideHyaluroniqueB5
    },
    {
      id: 47,
      name: "Eclat & Eclaircissant",
      category_id: 9,
      serum: true,
      actifs : "Vitamine C 15% + Acide Hyaluronique 1%",
      image: niacinamideZinc
    },
    {
      id: 48,
      name: "Anti-rougeurs & unifiant",
      category_id: 10,
      serum: true,
      actifs : "Niacimide 10% + Zinc PCA 1%",
      image: vitamineCAcideHayluronique
    },
  ]
};

export default data;
