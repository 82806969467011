import React, { useState, useEffect } from "react";
import "../styles/components/technoBanner.css";

import technoBanner_liposome from "../assets/ProductContent/img/technoBanner-liposome.png";
import technoBanner_niosome from "../assets/ProductContent/img/technoBanner-niosome.png";

export default function TechnoBanner({ type }) {
    const [isMobile, setIsMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setIsMobile(newWidth <= 610);
            setWidth(newWidth);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    let img;
    let title = "";
    let text = "";

    // Détermine la hauteur de la bannière en fonction du type et de la largeur de l'écran
    let bannerHeight;
    if (type === "liposome") {
        bannerHeight = "350px";
    } else if (width > 1050 && width <= 1200) {
        bannerHeight = "600px";
    } else if (width > 1200){
        bannerHeight = "390px";
    }

    if (type === "liposome") {
        img = technoBanner_liposome;
        title = "TECHNOLOGIE LIPOSOMALE®";
        text = `Nous développons nos formules grâce à la <label class="bold">nouvelle technologie exclusive</label> de liposomes 
                pour encapsuler 100% des actifs. <br><br> 
                Ce procédé innovant garantit une <label class="bold">assimilation optimale</label> des nutriments par l’organisme et <br><br>
                la stabilité des actifs pour une <label class="bold">action rapide.</label>`;
    } else if (type === "niosome" || type === "niosomeProduct") {
        img = technoBanner_niosome;
        text = `<label class="bold">Chez LIN, nous révolutionnons le monde de la cosmétique grâce à l'innovation et à l'expertise scientifique. 
                Nous utilisons la technologie du niosome pour repousser les limites de l'efficacité cosmétique. </label><br><br>
                Ce système d'encapsulation non ionique est conçu pour <label class="bold">améliorer la diffusion</label> des principes actifs dans la peau. 
                Grâce à leur structure unique, ils encapsulent efficacement les ingrédients, permettant une <label class="bold">libération contrôlée</label>
                et une meilleure pénétration dans la peau.
                <br><br>    
                Cette technologie niosomale améliore 
                de manière significative l’efficacité des 
                cosmétiques, tout en garantissant 
                une <label class="bold">protection optimale des 
                principes actifs sensibles.</label>
                <br><br>
                En collaboration avec des <label class="bold">experts en
                dermatologie</label>, nous proposons des 
                cosmétiques <label class="bold">hautement concentrés</label>
                et de qualité supérieure.`;
    } else {
        img = null;
    }

    // 🔥 Ajout dynamique de "-niosome" si `type === "niosome"`
    const extraClass = (type === "niosome" || type === "niosomeProduct") ? "-niosome" : "";

    return (
        <div
            className={`technoBanner-container${extraClass}`}
            style={{
                flexDirection: isMobile ? "column-reverse" : (type === "liposome" ? "row-reverse" : "row"),
                height: bannerHeight,
                width: width < 610 ? `${width - 80}px` : "auto",
            }}
        >
            <div className={`technoBanner-content${extraClass}`} style={{ flex: 1 }}>
                {title && <h2 className={`technoBanner-title${extraClass}`}>{title}</h2>}
                <p className={`technoBanner-text${extraClass}`} dangerouslySetInnerHTML={{ __html: text }}></p>
            </div>
            {img && (
                <img
                    src={img}
                    alt={title}
                    className={`technoBanner-img${extraClass}`}
                />
            )}
        </div>
    );
}
