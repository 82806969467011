import React, { useState } from "react";
import "../styles/components/tableauCatalogue.css";
import produits from "../assets/ProductContent/data/data_probiotique.js";

const TableLiposome = ({ data }) => {
    return (
        <div className="table-container">
            <table className="table">
                <thead>
                    <tr>
                        <th key={1} className="th left">
                            {"POSTBIOTIQUES"}
                        </th>
                        <th key={3} className="th left none">
                            {"CELLULES/G"}
                        </th>
                        <th key={4} className="th left">
                            {"ACTIONS"}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex} className="tr left">
                            <td key={1} className="td">
                                {row.nom}
                            </td>
                            <td key={3} className="td left none">
                                {row.cfu}
                            </td>
                            <td key={4} className="td left">
                                {row.action}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


function filterProductsSearch(query, what) {
    return what.filter((produit) => {

        return (
            produit.nom.toLowerCase().includes(query.toLowerCase())
        );
    });
}



function filterProducts(key, what) {
    return produits.filter((produit) => produit[key] === what);
}

export default function TabPostbiotique() {
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 10;

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };


    const filteredProducts = filterProductsSearch(searchQuery, filterProducts("type", "postbiotique"));
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const nextPage = () => {
        if (currentPage < Math.ceil(filteredProducts.length / productsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="catalogue-container">
            <div className="tc-head">
                <h1 className="title">CATALOGUE DES POSTBIOTIQUES </h1>
                <div className="tc-recherche">
                    <p className="tc-p-recherche">Rechercher : </p>
                    <input
                        type="text"
                        className="search-bar"
                        placeholder="Nom"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>

            <TableLiposome data={currentProducts} />

            <div className="pagination">
                <button
                    className="pagination-btn"
                    onClick={prevPage}
                    disabled={currentPage === 1}
                >
                    ←
                </button>
                <span className="pagination-span">
                    {currentPage} / {Math.ceil(filteredProducts.length / productsPerPage)}
                </span>
                <button
                    className="pagination-btn"
                    onClick={nextPage}
                    disabled={currentPage === Math.ceil(filteredProducts.length / productsPerPage)}
                >
                    →
                </button>
            </div>

            <div className="tc-pastrouver">
                <h3 className="tc-pastrouver-h3">Vous recherchez un ingrédient spécifique que vous ne trouvez pas ?</h3>
                <p className="tc-pastrouver-p">
                    Vous recherchez un ingrédient spécifique que vous ne trouvez pas ? Notre liste d’ingrédients est non exhaustive. Si vous avez un projet de développement de complément alimentaire, contactez-nous pour obtenir davantage d’informations.
                    Notre équipe commerciale est à votre écoute pour répondre à vos besoins en matière de formulation adaptée à des objectifs santé précis.
                </p>
            </div>
        </div>
    );
}
