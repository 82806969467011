import React, { useState, useEffect } from 'react';

import MoreInfo from "../components/MoreInfo";
import '../styles/views/formuleSurMesure.css'
import PositifPoint from "../components/PositifPoint";
import Conditionnement from "../components/Conditionnement"
import CategoryCard from "../components/CategoryCard";
import Process from '../components/Process';
import Popup from '../components/Popup';

import img_fiole from '../assets/img/formule_intro_fiole.png';
import img_packaging from '../assets/img/formule_packaging.png'

import icon_gelule from '../assets/icons/gelules.png';
import icon_capsules from '../assets/icons/capsules.svg';
import icon_piluliers from '../assets/icons/piluliers.png'
import icon_blister from '../assets/icons/blister.svg';
import icon_flacons from '../assets/icons/flacons.svg';
import icon_doypacks from '../assets/icons/doypacks.png';

//icon carousel
import icon_arrow_forward from '../assets/icons/arrow_forward.svg';
import icon_arrow_back from '../assets/icons/arrow_back.svg';

//icon process
import cahier from "../assets/icons/cahier.png";
import dev from "../assets/icons/dev.png";
import prod from "../assets/icons/prod.png";
import packaging from "../assets/icons/packaging.png";
import conditionnement from "../assets/icons/conditionnement.png";
import envoi from "../assets/icons/envoi.png"

import img_sur_mesure_mobile from "../assets/icons/img-sur-mesure-mobile.png"

export default function FormuleSurMesure() {
    const [isCarousel, setIsCarousel] = useState(false);
    const [isMobile, setIsMobile] = useState(0);
    const [forceReloadKey, setForceReloadKey] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndexProcess, setCurrentIndexProcess] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const content = [
        ["Un produit unique", "Développez une formule originale qui reflète votre vision et se distingue sur le marché."],
        ["Adaptation aux besoins spécifiques", "Ciblez des problématiques précises comme le stress, l’immunité, la performance…"],
        ["Choix des ingrédients", "Sélectionnez des actifs innovants ou des ingrédients naturels pour répondre aux attentes de vos consommateurs."],
        ["Flexibilité totale", "Vous décidez de tout : dosage, galénique (gélules, comprimés, poudre), arômes et plus encore."],
        ["Valorisation de votre marque", "Proposez des compléments alimentaires qui incarnent vos valeurs et créent un lien fort avec vos clients."]
    ]

    const process = [
        [
            cahier,
            "cahier des charges",
            "Nous travaillons étroitement avec vous pour <label class='bold'>définir les objectifs</label>, les besoins spécifiques de vos clients, et les <label class='bold'>caractéristiques de la formule souhaitée</label>. Ce cahier des charges permet de poser une base solide et précise pour chaque étape du développement."
        ],
        [
            dev,
            "développement",
            "Notre équipe de R&D se charge de la formulation, en sélectionnant des <label class='bold'>ingrédients innovants et performants</label> qui répondent aux attentes en matière <label class='bold'>d’efficacité, de stabilité</label> et de <label class='bold'>biodisponibilité.</label>"
        ],
        [
            prod,
            "production",
            "Dans notre laboratoire, nous appliquons des <label class='bold'>normes strictes</label> et des techniques de pointe pour produire des lots de tailles variées. Nous assurons un <label class='bold'>contrôle qualité continu</label> pour garantir un produit fini de haute qualité."
        ],
        [
            packaging,
            "packaging personnalisé",
            "Pour optimiser <label class='bold'>l’expérience utilisateur</label> et refléter l’identité de votre marque, nous proposons un <label class='bold'>large choix de packaging personnalisé</label>, du design au format final."
        ],
        [
            conditionnement,
            "conditionnement",
            "Chaque produit est conditionné <label class='bold'>selon vos préférences</label> : piluliers, blisters, sachets ou autres formats. Notre flexibilité de conditionnement nous permet de répondre aux <label class='bold'>exigences spécifiques de vos points de vente</label> et de votre distribution."
        ],
        [
            envoi,
            "envoi produit",
            "Une fois le produit finalisé, <label class='bold'>nous assurons son envoi</label>, que ce soit pour une distribution directe aux centres de répartition ou une livraison en points de vente."
        ]
    ]

    const content_galeniques = [
        [icon_gelule, "gélules", "Pullulan, HPMC...", true],
        [icon_capsules, "capsules", "Origine marine, végétale..."],
        [icon_piluliers, "piluliers", "En plastique, en verre ..."],
        [icon_blister, "Blister", "En PVC, PVDC <br> Etanchéité aluminium..."],
        [icon_flacons, "Flacons", "En verre"],
        [icon_doypacks, "Doypack", "Écoresponsables <br> (biodégradables, compostables)", true]
    ]

    const categories = [
        {
            title: "Liposomes",
            subtitle: "Extraits de plantes vitamines - minéraux",
            type: "liposome",
            link: "/liposomes",
        },
        {
            title: "Niosomes",
            subtitle: "cosmétiques",
            type: "niosome",
            link: "/niosomes",
        },
        {
            title: "Probiotiques & postbiotiques",
            subtitle: "Technologies VPRO® et PROLIPO®",
            type: "probiotique",
            link: "/probiotiques",
        },
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsCarousel(window.innerWidth <= 1420);
            setIsMobile(window.innerWidth <= 1050);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % categories.length);
        setForceReloadKey((prevKey) => prevKey + 1);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            (prevIndex - 1 + categories.length) % categories.length
        );
        setForceReloadKey((prevKey) => prevKey + 1);
    };

    const handlePrevProcess = () => {
        setCurrentIndexProcess((prevIndex) =>
            prevIndex === 0 ? process.length - 1 : prevIndex - 1
        );
    };

    const handleNextProcess = () => {
        setCurrentIndexProcess((prevIndex) =>
            prevIndex === process.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleClick = () => {
        openPopup();
    };

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);

    return (
        <div>
            <div className="formule_global-container">
                <section className="formule_intro-container-global">
                {isMobile ? (
                    <div className='formule_intro-container'>
                        <h1 className="formule_intro-title">
                            <label className="bold formule_font-50">Nos formules</label> <br />
                            <label className='orange-color'>Sur-mesure</label>
                        </h1>
                        <MoreInfo
                            label={"Demandez notre catalogue"}
                            link={"download"}
                        />
                        <h2 className="formule_intro-subtitle">Créez vos produits <br/><label className='orange-color'>sur-mesure avec lin</label></h2>
                        <article className="formule_intro-text">
                        Parce que vos besoins sont uniques, LIN propose une création de formules sur-mesure, adaptée aux besoins spécifiques de chaque marque. Que ce soit pour répondre aux tendances du marché ou aux exigences d’une niche particulière, nos équipes scientifiques et marketing travaillent ensemble pour élaborer des produits uniques, capables de se démarquer et d’apporter de la valeur ajoutée aux consommateurs.
                        </article>
                    </div>
                ) : (
                    <div className='formule_intro-container'>
                        <h1 className="formule_intro-title">
                            <label className="bold formule_font-50">Nos formules</label> <br />
                            <label className='orange-color'>Sur-mesure</label>
                        </h1>
                        <MoreInfo
                            label={"Demandez notre catalogue"}
                            link={"download"}
                        />
                        <h2 className="formule_intro-subtitle">Créez vos produits <br/><label className='orange-color'>sur-mesure avec lin</label></h2>
                        <article className="formule_intro-text">
                        Parce que vos besoins sont uniques, LIN propose une création de formules sur-mesure, adaptée aux besoins spécifiques de chaque marque. Que ce soit pour répondre aux tendances du marché ou aux exigences d’une niche particulière, nos équipes scientifiques et marketing travaillent ensemble pour élaborer des produits uniques, capables de se démarquer et d’apporter de la valeur ajoutée aux consommateurs.
                        </article>
                    </div>
                )}
                {!isMobile && <img className='formule_intro-container-img' src={img_sur_mesure_mobile} />}
                </section>
                <section className="formule_ourFormule-container">
                    <h3 className='formule_ourFormule-title'><label className="orange-color">Votre marque</label><br /> sur-mesure</h3>
                    <div className='formule_ourFormule-bloc-container'>
                        <div className='formule_ourFormule-text-container'>
                            <h3 className='formule_ourFormule-title-bloc'><label className="orange-color">Votre formule<br /></label> personnalisée</h3>
                            <article>Créez des <label className='bold'>compléments alimentaires et cosmétiques uniques</label> avec nos formules <label className='bold'>sur-mesure</label>. Nous adaptons chaque produit à votre cahier des charges en sélectionnant des <label className='bold'>ingrédients innovants</label> et en garantissant qualité, conformité et efficacité.</article>
                            <img className='formule_ourFormule-img' src={img_fiole} />
                        </div>
                        <p className='formule_ourFormule-plus'>+</p>
                        <div className='formule_ourFormule-text-container'>
                            <h3 className='formule_ourFormule-title-bloc'><label className="orange-color">votre packaging</label></h3>
                            <article>Nous adaptons chaque produit à <label className='bold'>votre image</label> grâce à un packaging entièrement <label className='bold'>personnalisé</label>. Qu'il s'agisse de boîtes, piluliers, ou sachets, nous intégrons vos éléments graphiques pour refléter <label className='bold'>l'identité de votre marque</label>, tout en garantissant praticité, stabilité et protection optimale des formules.</article>
                            <img className='formule_ourFormule-img' src={img_packaging} />
                        </div>
                    </div>
                </section>
                <section className="fomrule_positifPoint-container">
                    <div className='fomrule_positifPoint-container2'>
                        <div className='formule_positifPoint-text-container'>
                            <h3 className='formule_positifPoint-title'><label className="orange-color">Les plus</label> de la formule <br />sur-mesure</h3>
                            <article>Une formule sur-mesure consiste à créer un complément alimentaire ou un produit de beauté totalement adapté à vos attentes, <label className="orange-color bold">de la sélection des ingrédients jusqu’au packaging.</label> Contrairement aux marques blanches, les formules personnalisées vous donnent une <label className="orange-color bold">totale liberté</label> pour développer un <label className="orange-color bold">produit unique</label> ou répondre à des besoins spécifiques parfaitement adaptés à votre cible et à vos valeurs.</article>
                        </div>
                        <div>
                            {content.map(([title, content], index) => (
                                <PositifPoint key={index} title={title} content={content} />
                            ))}
                        </div>
                    </div>
                </section>
                <div className="vertical-line"></div>
                <section className='formule_process_global-container'>
                    <h3 className='formule_process-title'><label className='orange-color'>Notre processus</label><br />de développement produit</h3>
                    <p className='formule_process-subtitle'>Notre méthode de création sur-mesure suit un <label className='bold'>processus rigoureux</label>, en six étapes clés, qui garantit<br /> une formulation unique et une <label className='bold'>qualité irréprochable.</label></p>
                    <div className="formule_process_comp-container">
                        {isCarousel ? (
                            // Mode CAROUSEL (Mobile)
                            <div>
                                <div className="carousel_container">
                                    <Process
                                        img={process[currentIndexProcess][0]}
                                        title={process[currentIndexProcess][1]}
                                        content={process[currentIndexProcess][2]}
                                        index={currentIndexProcess + 1}  // Ajout de l'index
                                    />
                                </div>
                                <div className='formule_arrow_container--mobile'>
                                    <img className="carousel_arrow" alt="Flèche gauche" src={icon_arrow_back} onClick={handlePrevProcess} />
                                    <img className="carousel_arrow" alt="Flèche droite" src={icon_arrow_forward} onClick={handleNextProcess} />
                                </div>
                            </div>
                        ) : (
                            <div className='formule_process_comp-container'>
                                {process.map(([img, title, content], index) => (
                                    <Process
                                        key={index}
                                        img={img}
                                        index={index + 1}
                                        title={title}
                                        content={content}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </section>
                <section className='formule_galenique-container'>
                    <h3 className='formule_galenique-title'><label className="orange-color">Un large choix </label>de galéniques<br />et de conditionnements</h3>
                    <div className='formule_galenique-gridContainer'>
                        {content_galeniques.map(([img, title, content, subtitle], index) => (
                            <Conditionnement
                                key={index}
                                img={img}
                                title={title}
                                content={content}
                                subtitle={subtitle || null}
                            />
                        ))}
                    </div>
                </section>
                <section className='formule_demandeMp_global-container'>
                    <h3 className='formule_demandeMp-title'>Vos matières premières <br /> à la demande</h3>
                    <article className='formule_demandeMp-text'>Nous conditionnons nos matières premières<br /> sur-mesure en fonction de vos besoins, qu'il<br /> s'agisse de plantes, de vitamines, de<br /> minéraux, en combinaison ou séparément.</article>
                    <p className='formule_demandeMp-btn' onClick={handleClick}>Nous contacter</p>
                    {/* Pop-up */}
                    <Popup isOpen={isPopupOpen} onClose={closePopup}>
                        <h2 className='popup-title'>En savoir plus sur un produit</h2>
                        <form className='popup-container-form'>
                            <div className='popup-container-2input'>
                                <div>
                                    <p className='popup-label'>Email</p>
                                    <input className="popup-input1" type="email" required />
                                </div>
                                <div>
                                    <p className='popup-label'>Numéro de téléphone</p>
                                    <input className="popup-input2" type="tel" required />
                                </div>
                            </div>
                            <div className='popup-container-2input'>
                                <div>
                                    <p className='popup-label'>Entreprise</p>
                                    <input className="popup-input1" type="text" required />
                                </div>
                                <div>
                                    <p className='popup-label'>Pays</p>
                                    <input className="popup-input2" type="text" required />
                                </div>
                            </div>
                            <p className='popup-label'>Message</p>
                            <p className='popup-subtitle'>Un expert vous répondra dans les plus brefs délais</p>
                            <textarea className='popup-textarea'></textarea>
                            <button className='popup-btn' type="submit">Envoyer</button>
                        </form>
                    </Popup>
                    <div className='formule_demandeMp-horizontalBar'></div>
                </section>
                <section className='formule_categoryCard-container'>
                    <h3 className='formule_categoryCard-title'>Nos catégories d'ingrédients</h3>
                    {isCarousel ? (
                        <div className="carousel">
                            <div key={forceReloadKey} className="carousel_item">
                                <CategoryCard
                                    title={categories[currentIndex].title}
                                    subtitle={categories[currentIndex].subtitle}
                                    type={categories[currentIndex].type}
                                    link={categories[currentIndex].link}
                                />
                            </div>
                            <div className='carousel_container_arrow'>
                                <img className="carousel_arrow" alt="Flèche droite" src={icon_arrow_back} onClick={handlePrev} />
                                <img className="carousel_arrow" alt="Flèche gauche" src={icon_arrow_forward} onClick={handleNext} />
                            </div>
                        </div>
                    ) : (
                        <nav className="homepage_nav_categorycard">
                            {categories.map((category, index) => (
                                <CategoryCard
                                    key={index}
                                    title={category.title}
                                    subtitle={category.subtitle}
                                    type={category.type}
                                    link={category.link}
                                />
                            ))}
                        </nav>
                    )}
                    <MoreInfo 
                        label={"Nous contacter"}
                        link={"*"}
                    />
                </section>
            </div>
        </div>
    );
}