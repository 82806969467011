import React, { useState } from "react";
import "../styles/components/bienfaits.css";
import arrow_drop_down from '../assets/ProductContent/img/arrow_drop_down.png';

export default function BienfaitsAccordion({ bienfaits }) {
    const [openIndexes, setOpenIndexes] = useState([]);

    const toggleDescription = (index) => {
        setOpenIndexes(prevIndexes =>
            prevIndexes.includes(index)
                ? prevIndexes.filter(i => i !== index)
                : [...prevIndexes, index]
        );
    };

    return (
        <div className="bienfaits-container">
            {bienfaits.map((bienfait, index) => {
                const isOpen = openIndexes.includes(index);
                return (
                    <div key={index} className="bienfait-item">
                        <div 
                            className="bienfaits_title-container" 
                            onClick={() => toggleDescription(index)}
                        >
                            <h3 
                                className="bienfait-title"
                                style={{ color: isOpen ? '#FF7F50' : 'black' }}
                            >
                                {bienfait.titre}
                            </h3>
                            <img 
                                className={`bienfaits-img-dropdown ${isOpen ? 'rotate' : ''}`} 
                                src={arrow_drop_down} 
                                alt="dropdown icon"
                            />
                        </div>
                        {isOpen && (
                            <p
                                className="bienfait-description"
                                dangerouslySetInnerHTML={{ __html: bienfait.description }}
                            ></p>
                        )}
                    </div>
                );
            })}
        </div>
    );
}
