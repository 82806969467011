import React from 'react';
import "../styles/components/conditionnement.css"

export default function Conditionnement({ img, title, content, subtitle }) {


    return (
        <div className="conditionnement_global-container">
            <div className='conditionnement_img-container'>
                <img className="conditionnement-img" src={img} />
                <div>
                    <h4 className='conditionnement-title'>{title}</h4>
                    {subtitle && <p className='conditionnement-allSize'>Toutes les tailles</p>}
                </div>
            </div>
            <div className='conditionnement-content'>
                <p
                    className='conditionnement-text'
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        </div>
    );
}
