import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ColorPoint from "../components/ColorPoint.js";
import TechnoBannerFull from "../components/TechnoBannerFull.js";
import TechnoBanner from "../components/TechnoBanner.js";
import MockupBanner from "../components/MockupBanner.js";
import BienfaitsAccordion from "../components/BienFaitAccordion.js";
import OtherProduct from "../components/OtherProduct.js";
import MoreInfo from "../components/MoreInfo.js";

import "../styles/views/fiche.css";
import products from "../assets/ProductContent/data/data.js";

// Icons otherProduct
import probiotique from "../assets/icons/homepage_probiotique.png";
import niosome from "../assets/icons/homepage_niosome.png";
import liposome from "../assets/icons/homepage_liposome.png";

// Icons formes disponible
import serum from "../assets/icons/serum.png";
import creme from "../assets/icons/creme.png";
import gelule from "../assets/icons/gelule.png";

import arrow_outward from "../assets/icons/arrow_outward.png";

// 🔥 Contenu des autres catégories d'ingrédients
const contentOtherProduct = [
    [probiotique, "Probiotiques & Postbiotiques", "Technologies VPRO® et PROLIPO®", "/probiotiques"],
    [niosome, "Niosomes", "Cosmétiques", "/niosomes"],
    [liposome, "Liposomes", "Extraits de plantes Vitamines · Minéraux", "/liposomes"],
];

const ContentFormes = [
    [
        serum,
        "Sérum",
    ],
    [
        creme,
        "Crème"
    ],
    [
        gelule,
        "Gélule"
    ]
]

export default function Fiche() {
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // 🔥 Récupérer le produit correspondant à l'URL actuelle
    const produit = products.find(p => p.url === location.pathname);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900);
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Appel initial

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // 🔥 Redirection au clic sur "Retour vers la liste"
    const handleRetourClick = () => {
        if (produit?.url) {
            const segments = produit.url.split("/");
            const baseUrl = segments.length > 1 ? `/${segments[1]}` : "/";
            navigate(baseUrl);
        }
    };

    const navigateToNisome = () => {
        navigate(produit.url2)
    }

    const navigateToLiposome = () => {
        if (produit?.url3) {
            navigate(produit.url3);
        }
    };

    const scrollToBienfaits = () => {
        const bienfaitSection = document.getElementById("fiche_bienfait-container");

        if (bienfaitSection) {
            const yOffset = -150;
            const y = bienfaitSection.getBoundingClientRect().top + window.scrollY + yOffset;

            window.scrollTo({ top: y, behavior: "smooth" });
        }
    };

    const typeMapping = {
        niosomeProduct: "niosome", // Associer niosomeProduct à niosome
    };

    const excludedType = typeMapping[produit?.type] || produit?.type;
    const filteredProducts = contentOtherProduct.filter(([_, __, ___, link]) =>
        !link.includes(excludedType)
    );

    const [firstProduct, secondProduct] = filteredProducts.length === 2 ? filteredProducts : [null, null];

    return (
        <div>
            <div className="fiche_global-content">
                <p className="fiche-retour" onClick={handleRetourClick}>{'< retour vers la liste des ingrédients'}</p>

                {/* Affichage conditionnel en fonction de la taille de l'écran */}
                {isMobile ? (
                    <section className="fiche_description_global-content--mobile">
                        <div className="fiche_description-text-content--mobile">
                            <h1 className="fiche_description-title">{produit?.nom}</h1>
                            {produit?.categorie && produit?.type === "liposome" && (
                                <div className="fiche_subtitle-container">
                                    <ColorPoint size={20} label={produit.categorie} />
                                </div>
                            )}
                            {produit?.subtitle && (
                                <p className="fiche_description_formes-text">{produit.subtitle}</p>
                            )}
                            {/*Section pour afficher formes disponibles disponibles si "formes" dans les fiches produits*/}
                            {produit?.formes && (
                                <div className="fiche_description_formes-container">
                                    <p className="fiche_description_formes-title">
                                        <label className="bold">Formes disponibles</label>
                                    </p>
                                    <div className="fiche_description_formes-list">
                                        {ContentFormes.filter(([_, name]) => produit.formes.includes(name)).map(([img, name], index) => (
                                            <div key={index} className="fiche_description_forme-item">
                                                <img src={img} alt={name} className="fiche_description_forme-img" />
                                                <p className="fiche_description_forme-text">{name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {/*Section pour afficher produit fini si "formes" dans les fiches produits*/}
                            {produit?.formes2 && (
                                <div className="fiche_description_formes-container">
                                    <p className="fiche_description_formes-title">
                                        <label className="bold">Produit Fini</label>
                                    </p>
                                    <div className="fiche_description_formes-list">
                                        {ContentFormes.filter(([_, name]) => produit.formes2.includes(name)).map(([img, name], index) => (
                                            <div key={index} className="fiche_description_forme-item">
                                                <img src={img} alt={name} className="fiche_description_forme-img" />
                                                <p className="fiche_description_forme-text">{name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {produit?.url2 && (
                                <div className="fiche_disponibility-container">
                                    <div onClick={navigateToNisome} className="fiche_disponibility-item">
                                        <p className="fiche_disponibility-text">Disponible également en <label className="orange-color">niosome</label></p>
                                        <img className="fiche_disponibility-img" src={arrow_outward} />
                                    </div>
                                </div>
                            )}
                            <img className="fiche_description-img" src={produit?.img} alt={produit?.nom} />
                            <p className="fiche_description-desc">Description</p>
                            <p className="fiche_description-text" dangerouslySetInnerHTML={{ __html: produit?.description || "" }}></p>
                            <button className="fiche_description-btn" onClick={scrollToBienfaits}>Je découvre les bienfaits</button>
                        </div>
                    </section>
                ) : (
                    <section className="fiche_description_global-content">
                        <div className="fiche_description-text-content">
                            <div className="fiche-fullTitle-content">
                                <h1 className="fiche_description-title">{produit?.nom}</h1>
                                {produit?.categorie && produit?.type === "liposome" && (
                                    <div className="fiche_subtitle-container">
                                        <ColorPoint size={20} label={produit.categorie} />
                                    </div>
                                )}
                                {produit?.subtitle && (
                                    <p className="fiche_description_formes-text">{produit.subtitle}</p>
                                )}
                                {/*Section pour afficher formes disponibles si "formes" dans les fiches produits*/}
                                {produit?.formes && (
                                    <div className="fiche_description_formes-container">
                                        <p className="fiche_description_formes-title">
                                            <label className="bold">Formes disponibles</label>
                                        </p>
                                        <div className="fiche_description_formes-list">
                                            {ContentFormes.filter(([_, name]) => produit.formes.includes(name)).map(([img, name], index) => (
                                                <div key={index} className="fiche_description_forme-item">
                                                    <img src={img} alt={name} className="fiche_description_forme-img" />
                                                    <p className="fiche_description_forme-text">{name}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {/*Section pour afficher produit fini si "formes2" dans les fiches produits*/}
                                {produit?.formes2 && (
                                    <div className="fiche_description_formes-container">
                                        <p className="fiche_description_formes-title">
                                            <label className="bold">Produit Fini</label>
                                        </p>
                                        <div className="fiche_description_formes-list">
                                            {ContentFormes.filter(([_, name]) => produit.formes2.includes(name)).map(([img, name], index) => (
                                                <div key={index} className="fiche_description_forme-item">
                                                    <img src={img} alt={name} className="fiche_description_forme-img" />
                                                    <p className="fiche_description_forme-text">{name}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {/*Section pour afficher les redirections liposome qui ont une url2 dans la fiche produit*/}
                                {produit?.url2 && (
                                    <div className="fiche_disponibility-container">
                                        <div onClick={navigateToNisome} className="fiche_disponibility-item">
                                            <p className="fiche_disponibility-text">Disponible également en <label className="orange-color">niosome</label></p>
                                            <img className="fiche_disponibility-img" src={arrow_outward} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <p className="fiche_description-desc">Description</p>
                            <p className="fiche_description-text" dangerouslySetInnerHTML={{ __html: produit?.description || "" }}></p>
                        </div>
                        <div className="fiche_description-img-content">
                            <img className="fiche_description-img" src={produit?.img} alt={produit?.nom} />
                            <button className="fiche_description-btn" onClick={scrollToBienfaits}>Je découvre les bienfaits</button>
                        </div>
                    </section>
                )}

                <TechnoBannerFull type={produit?.type} />

                <section className="fiche_encarts-container">
                    <div>
                        <TechnoBanner type={produit?.type} />
                        {produit?.product !== false && <MockupBanner product={produit?.product} />}
                    </div>
                    <div id="fiche_bienfait-container" className="fiche_bienfait-container">
                        <h2 className="fiche_bienfait-title">
                            {produit?.allegation ? "Allégations santé" : "Les bienfaits"}
                        </h2>
                        <p>{produit?.allegation}</p>
                        <BienfaitsAccordion bienfaits={produit?.bienfaits} />
                    </div>
                </section>
                {/*Section pour afficher les redirections liposome qui ont une url3 dans la fiche produit*/}
                {produit?.url3 && (
                    <div onClick={navigateToLiposome} className="fiche_disponibility-item fiche_disponibility-container margin-top-70">
                        <p className="fiche_disponibility-text">Disponible également en <label className="orange-color">liposome</label></p>
                        <img className="fiche_disponibility-img" src={arrow_outward} />
                    </div>
                )}
                {/* Section Contact */}
                <div className="fiche_contact-container">
                    <p className="fiche-contact-title">Vous êtes intéressé ?</p>
                    <MoreInfo label={"Nous contacter"} link={"*"} />
                </div>

                {/* Section OtherProduct (affichage dynamique des 2 autres catégories) */}
                <section className="formule_otherProduct_global-container">
                    <h3 className="formule_otherCategory-title">
                        <label className="bold">Nos autres</label> <label className="orange-color">catégories d'ingrédients</label>
                    </h3>
                    {firstProduct && secondProduct && (
                        <OtherProduct
                            title1={firstProduct[1]}
                            subtitle1={firstProduct[2]}
                            img1={firstProduct[0]}
                            link1={firstProduct[3]}
                            title2={secondProduct[1]}
                            subtitle2={secondProduct[2]}
                            img2={secondProduct[0]}
                            link2={secondProduct[3]}
                        />
                    )}
                </section>
            </div>
        </div>
    );
}
