import React, { useState } from 'react';
import '../styles/components/dropdown.css';

function Dropdown({ label, items, btn, onItemClick }) {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (path, hash) => {
        onItemClick(path, hash);

        // Si le hash est "top", on force le scroll tout en haut
        if (hash === "top") {
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }, 100); // Petit délai pour garantir la navigation avant le scroll
        }
    };

    return (
        <div 
            className="dropdown" 
            onMouseEnter={() => setIsOpen(true)} 
            onMouseLeave={() => setIsOpen(false)}
        >
            <button className={btn === 2 ? "header_box_button2" : "header_box_button"}>
                <p className={btn === 2 ? "header_box_button_p2" : "header_box_button_p"}>{label}</p>
            </button>
            {isOpen && (
                <ul className={btn === 2 ? "dropdown_menu" : "dropdown_menu2"}>
                    {items.map((item, index) => (
                        <li 
                            key={index} 
                            className="dropdown_item"
                            onClick={() => handleClick(item.path, item.hash)}
                        >
                            {item.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Dropdown;
