import React from 'react';

export default function ColorPoint({ size, label, tab }) {
    const labels = Array.isArray(label) ? label : [label];
    const cat = {
        "acide aminé": "#FF5757",
        "coenzyme": "#DB4BCF",
        "protéine": "#8B4643",
        "végétal": "#4B8C30",
        "vitamine": "#FFA834",
        "minéral": "#4C7678",
        "antioxydant": "#9747FF",
        "acide gras": "#FFBC85",
        "substance": "#3F95FF",
        "acides": "#FF5757",
        "végétaux": "#4B8C30",
        "vitamines": "#FFA834",
        "minéraux": "#4C7678",
        "Santé gastro-intestinale": "#E761A2",
        "Allergies/Immunité": "#7BD7FF",
        "Femmes": "#860E4C",
        "Métabolisme": "#FFA334",
        "Articulations": "#FFA334",
        "Souche VPRO®": "#E80000",
        "Santé mentale": "#242752",
        "Peau": "#FF7F5057",
        "Bucco-dentaire": "#D9D9D9",
        "Protection du foie": "#242752",
        "Souche conventionnelle": "#4C7678",
        "antioxydants": "#9747FF",
        "lipides": "#E761A2",
        "peptides": "#242752"
    };

    return (
        <div style={{ display: "flex", gap: "10px", alignItems: "center", flexWrap: "wrap" }}>
            {labels.map((item, index) => (
                <div key={index} style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <div
                        className="colorPoint-container"
                        style={{
                            width: size,
                            height: size,
                            borderRadius: "50%",
                            backgroundColor: cat[item] || "#CCC",
                        }}
                    />
                    {!tab && (
                        <span className="fiche_description-subtitle">{item}</span>
                    )}
                </div>
            ))}
        </div>
    );
}
