import React, { useState, useEffect } from "react";

import "../styles/components/mockupTab.css";

import ProductCardMockup from "./ProductCardMockup";
import search from "../assets/icons/search.png";
import data from "../assets/mockupContent/data/data_mockup.js";

export default function MockupTab() {
    const categoryNameAlim = data.categories.complements_alimentaires.map(cat => cat.name);
    const categoryNameSerum = data.categories.serums_cosmetiques.map(cat => cat.name);

    const [selectedCategory, setSelectedCategory] = useState(categoryNameAlim[0]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (!selectedCategory) return;
    
        // Récupération de l'ID de la catégorie sélectionnée
        const selectedCategoryId = [...data.categories.complements_alimentaires, ...data.categories.serums_cosmetiques]
            .find(cat => cat.name === selectedCategory)?.id;
    
        // Vérifier si une catégorie valide est trouvée
        if (!selectedCategoryId) {
            setFilteredProducts([]);
            return;
        }
    
        // Filtrage des produits en fonction de la catégorie sélectionnée
        let filtered = data.products.filter(product => product.category_id === selectedCategoryId);
    
        // Appliquer le filtre de recherche si nécessaire
        if (searchTerm.trim() !== "") {
            filtered = filtered.filter(product =>
                product.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
    
        setFilteredProducts(filtered);
    }, [selectedCategory, searchTerm]);    

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    return (
        <div className="mockupTab_global-container">
            <div className="mockupTab_category-container">
                <h4 className="mockupTab_category-title">Les compléments <br />alimentaires</h4>
                <div className="orange-line"></div>
                {categoryNameAlim.map((category, index) => (
                    <div
                        key={index}
                        className={`mockupTab_category-item ${selectedCategory === category ? 'selected' : ''}`}
                        onClick={() => handleCategoryClick(category)}
                    >
                        {category}
                    </div>
                ))}
                <h4 className="mockupTab_category-title">Les sérums <br />cosmétique</h4>
                <div className="orange-line"></div>
                {categoryNameSerum.map((category, index) => (
                    <div
                        key={index}
                        className={`mockupTab_category-item ${selectedCategory === category ? 'selected' : ''}`}
                        onClick={() => handleCategoryClick(category)}
                    >
                        {category}
                    </div>
                ))}
            </div>
            <div className="mockupTab_filtre_element-container">
                <div className="mockupTab_filtre-container">
                    {selectedCategory && <p className="mockupTab_filtre-label">{selectedCategory}</p>}
                    <div className="mockupTab_search-bar">
                        <div className="mockupTab_search-wrapper">
                            <img src={search} className="mockupTab_search-icon" alt="Rechercher" />
                            <input
                                type="text"
                                placeholder="Rechercher un produit"
                                className="mockupTab_search-input"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="mockupTab_element-container">
                    {filteredProducts.length > 0 ? (
                        filteredProducts.map((product) => (
                            <ProductCardMockup
                                index={product.id}
                                name={product.name}
                                quantity={product.quantity}
                                image={product.image}
                            />
                        ))
                    ) : (
                        <p>Aucun produit trouvé pour cette catégorie.</p>
                    )}
                </div>
            </div>
        </div>
    );
}
