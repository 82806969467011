import React, { useEffect, useState } from "react";
import '../styles/components/technoBannerFull.css';

import technoBanner_liposome from "../assets/ProductContent/img/banner-liposome.png";
import technoBanner_niosome from "../assets/ProductContent/img/banner-niosome.png";

export default function TechnoBannerFull({ type }) {
    const [isMobile, setIsMobile] = useState(false);

    // Sélection de l'image en fonction du type
    let backgroundImage;
    if (type === "liposome") {
        backgroundImage = `url(${technoBanner_liposome})`;
    } else if (type === "niosome" || type === "niosomeProduct") {
        backgroundImage = `url(${technoBanner_niosome})`;
    } else {
        backgroundImage = "#EFB9A1";
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 715);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div
            className="technoBannerFull-container"
            style={{
                backgroundImage: !isMobile && backgroundImage !== "#EFB9A1" ? backgroundImage : "none",
                backgroundColor: isMobile || backgroundImage === "#EFB9A1" ? "#EFB9A1" : "transparent",
                backgroundSize: "cover",
            }}
        >
            {isMobile && (
                <div className="mobile-text-container">
                    <h3 className="technobanner-title">LA technologie liposomale,<br />
                    POUR UNE ABSORPTION OPTIMISÉE</h3>
                    <p className="technobanner-subtitle">Effets rapides</p>
                    <p className="technobanner-subtitle2">Forte concentration & assimilation <br />Action durable</p>
                    <p className="technobanner-subtitle">JUSQU’À 30 fois plus efficace</p>
                    <p className="technobanner-subtitle2">100% des actifs encapsulés</p>
                    <p className="technobanner-subtitle">0 % excipient artificiel</p>
                    <p className="technobanner-subtitle2"><label className="bold">SANS :</label> OGM · Gluten · Lactose · Caséine <br />
                    Dioxyde de titane · Nanoparticules <br />
                    Stéarate de magnésium</p>
                </div>
            )}
        </div>
    );
}
