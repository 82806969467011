import { useRef, useState, useEffect } from "react";

import "../styles/views/probiotique.css";

import MoreInfo from "../components/MoreInfo";
import OtherProduct from "../components/OtherProduct";
import PositifPoint from "../components/PositifPoint";

//icon liposome actif
import left_schema from "../assets/icons/liposome_left_schema.png";
import right_schema from "../assets/icons/liposome_right_schema.png";
import bottom_schema from "../assets/icons/liposome_botom_schema.png";

//icon otherProduct
import niosome from "../assets/icons/homepage_niosome.png";
import liposome from "../assets/icons/homepage_liposome.png";

import intro_img from "../assets/img/probiotique_intro.png";
import probio from "../assets/img/probio.png";
import postbio from "../assets/img/postbio.png"

import TabPostbiotique from "../components/TabPostbiotique"
import TabCatalogue from "../components/TabCatalogue";
import produits from "../assets/ProductContent/data/data_probiotique.js";

export default function Probiotique() {
    const [isMobile, setIsMobile] = useState(false);
    const probiotiqueSectionRef = useRef(null);
    const postbiotiqueSectionRef = useRef(null);

    const scrollToSection = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1050);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const probioPositifPoint = [
        [
            "Soutien digestif",
            "Équilibre de la flore intestinale et amélioration du confort digestif."
        ],
        [
            "Renforcement immunitaire",
            "Contribution aux défenses naturelles du corps (problèmes de peau, respiratoires…)"
        ],
        [
            "Bien-être mental",
            "Influence positive sur l’axe intestin-cerveau, amélioration du sommeil et réduction du stress."
        ],
        [
            "Equilibre féminin",
            "Prévention des inconforts gynécologiques, beauté de la peau, réduction des symptômes préménopause et des SPM."
        ],
        [
            "Santé bucco-dentaire",
            "Prévention contre les caries et les bactéries pathogènes."
        ],
        [
            "Equilibre du métabolisme",
            "Contrôle du poids, protection du foie, régulation de la glycémie, santé cardiovasculaire"
        ],
        [
            "Synthèse de vitamines",
            "Aide à la production de vitamines essentielles comme la B12."
        ]
    ]

    const postbioPositifPoint = [
        [
            "Action stable et ciblée",
            "Résistent mieux aux conditions environnementales et gastriques."
        ],
        [
            "Soutien au microbiote",
            "Optimisent l’environnement intestinal en favorisant un microbiote équilibré."
        ],
        [
            "Efficacité prouvée",
            "Contribuent à des bénéfices tels que l’amélioration du système immunitaire et la protection contre le stress oxydatif."
        ]
    ]

    return (
        <div>
            <div className="probiotique_global-container">
                {isMobile ? (
                    <section className="probiotique_intro-container">
                        <h1 className="probiotique_intro-title">
                            <label className="bold font-42">Votre fournisseur</label><br /><label className="orange-color">de probiotiques <br />& postbiotiques</label>
                        </h1>
                        <p className="probiotique_intro_legend-title">Technologie VPRO® et PROLIPO®</p>
                        <img className="probiotique_intro-img" src={intro_img} alt="Probiotique" />
                        <p className="probiotique_intro-subtitle">
                            Les probiotiques et les postbiotiques,<br />
                            <label className="orange-color">une alliance pour une santé optimale</label>
                        </p>
                        <p className="probiotique_intro-text">
                            LIN, vous propose une <label className="bold">gamme innovante</label> de probiotiques et postbiotiques<br />
                            <label className="bold">micro-encapsulés</label>, soigneusement sélectionnés pour répondre aux besoins <br />
                            des entreprises de compléments alimentaires. Grâce à notre technologie de <br />
                            micro-encapsulation exclusive <label className="bold">VPRO®</label> et <label className="bold">PROLIPO®</label> nous garantissons une <br />
                            <label className="bold">efficacité maximale</label> et une <label className="bold">stabilité accrue des souches</label> pour vos<br /> formulations.
                        </p>
                        <button
                            className="probiotique_intro_probio-btn"
                            onClick={() => scrollToSection(probiotiqueSectionRef)}
                        >
                            Probiotiques VPRO®
                        </button>
                        <button
                            className="probiotique_intro_postbio-btn"
                            onClick={() => scrollToSection(postbiotiqueSectionRef)}
                        >
                            Postbiotiques Prolipo®
                        </button>
                    </section>
                ) :
                    (
                        <section className="probiotique_intro-container">
                            <div className="probiotique_intro_content-container">
                                <h1 className="probiotique_intro-title">
                                    <label className="bold font-42">Votre fournisseur</label><br /><label className="orange-color">de probiotiques <br />& postbiotiques</label>
                                </h1>
                                <p className="probiotique_intro_legend-title">Technologie VPRO® et PROLIPO®</p>
                                <p className="probiotique_intro-subtitle">
                                    Les probiotiques et les postbiotiques,<br />
                                    <label className="orange-color">une alliance pour une santé optimale</label>
                                </p>
                                <p className="probiotique_intro-text">
                                    LIN, vous propose une <label className="bold">gamme innovante</label> de probiotiques et postbiotiques<br />
                                    <label className="bold">micro-encapsulés</label>, soigneusement sélectionnés pour répondre aux besoins <br />
                                    des entreprises de compléments alimentaires. Grâce à notre technologie de <br />
                                    micro-encapsulation exclusive <label className="bold">VPRO®</label> et <label className="bold">PROLIPO®</label> nous garantissons une <br />
                                    <label className="bold">efficacité maximale</label> et une <label className="bold">stabilité accrue des souches</label> pour vos<br /> formulations.
                                </p>
                                <button
                                    className="probiotique_intro_probio-btn"
                                    onClick={() => scrollToSection(probiotiqueSectionRef)}
                                >
                                    Probiotiques VPRO®
                                </button>
                                <button
                                    className="probiotique_intro_postbio-btn"
                                    onClick={() => scrollToSection(postbiotiqueSectionRef)}
                                >
                                    Postbiotiques Prolipo®
                                </button>
                            </div>
                            <img className="probiotique_intro-img" src={intro_img} alt="Probiotique" />
                        </section>
                    )}
                <section ref={probiotiqueSectionRef} className="probiotique_positifPoint-container-bg">
                    <div className="probiotique_positifPoint-container">
                        <div className="probiotique_positifPoint_content-container">
                            <h2 className="probiotique_positifPoint-title"><label className="orange-color"> les probiotiques :</label><br />L'équilibre intestinal<br />à la source</h2>
                            <p className="probiotique_positifPoint-text">Les probiotiques sont des <label className="bold">micro-organismes vivants</label> qui apportent des bénéfices spécifiques pour la santé lorsqu’ils sont administrés en quantité adéquate. Chez LIN, nous mettons à disposition des <label className="bold">souches reconnues</label> pour leur efficacité, telles que <label className="bold">Lactobacillus acidophilus, Bifidobacterium longum, Lactobacillus rhamnosus,</label> et bien d’autres.
                                <br /><br />
                                Grâce à notre <label className="bold">expertise en probiotiques,</label> nous vous accompagnons dans le développement de produits adaptés aux <label className="bold">tendances actuelles</label> et aux attentes des consommateurs.</p>
                        </div>
                        <div className="probiotique_positifPoint-positifPoint">
                            {probioPositifPoint.map(([title, content], index) => (
                                <PositifPoint key={index} title={title} content={content} />
                            ))}
                        </div>
                    </div>
                </section>
                <section className="probiotique_tech-container">
                    <h2 className="probiotique_tech-title"><label className="orange-color">Technologie VPRO®, </label><br />Une avancée révolutionnaire</h2>
                    <div className="probiotique_tech_content-container">
                        <p className="probiotique_tech-text">
                            La technologie VPRO® optimise la <label className="bold">stabilité</label> et <label className="bold">l'efficacité</label> des probiotiques. Grâce à la micro-encapsulation des probiotiques, <label className="bold">l’activité et la survie des souches sont optimisées</label> lors de la conservation et de l’absorption dans l’estomac.
                            <br /><br />
                            Ce procédé innovant permet une <label className="bold">protection renforcée</label> contre l’acidité gastrique et l’humidité permettant une colonisation optimale de l’intestin pour une <label className="bold">action durable.</label>
                            <br /><br />
                            <label className="bold">VPRO®</label> assure ainsi une <label className="bold">libération ciblée</label> et une <label className="bold">biodisponibilité optimale</label>, répondant aux plus hautes exigences en matière de santé intestinale et de bien-être.
                        </p>
                        <img className="probiotique_tech-img" src={probio} />
                        <div className="probiotique_tech_checkList-container">
                            <div className="probiotique_tech_checkList-item">
                                <div className="probiotique_tech_checkList-icon-container">
                                    <p className="probiotique_tech_checkList-icon">✔</p>
                                </div>
                                <p className="probiotique_tech_checkList-text">Résistant à l'acide gastrique, la bile et à la conservation  </p>
                            </div>
                            <div className="probiotique_tech_checkList-item">
                                <div className="probiotique_tech_checkList-icon-container">
                                    <p className="probiotique_tech_checkList-icon">✔</p>
                                </div>
                                <p className="probiotique_tech_checkList-text">Excellent taux de survie des souches dans le tractus gastro-intestinal</p>
                            </div>
                            <div className="probiotique_tech_checkList-item">
                                <div className="probiotique_tech_checkList-icon-container">
                                    <p className="probiotique_tech_checkList-icon">✔</p>
                                </div>
                                <p className="probiotique_tech_checkList-text">Viabilité élevée jusqu'à la fin de la durée de conservation, excellente stabilité</p>
                            </div>
                            <div className="probiotique_tech_checkList-item">
                                <div className="probiotique_tech_checkList-icon-container">
                                    <p className="probiotique_tech_checkList-icon">✔</p>
                                </div>
                                <p className="probiotique_tech_checkList-text">Capacité d'adhésion élevée, forte colonisation, libération ciblée dans l'intestin</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="probiotique_listingMp-container">
                    <TabCatalogue produits={produits} type="probiotique" />
                    <MoreInfo 
                        label={"Nous contacter"}
                        link={"*"}
                    />
                </section>
                <section ref={postbiotiqueSectionRef} id="postbiotique_positifPoint-container-bg" className="postbiotique_positifPoint-container-bg">
                    <div className="probiotique_positifPoint-container">
                        <div className="probiotique_positifPoint_content-container">
                            <h2 className="probiotique_positifPoint-title"><label className="orange-color"> les postbiotiques :</label><br />L'innovation au service<br />de la santé</h2>
                            <p className="probiotique_positifPoint-text">
                                Les postbiotiques sont des composés bioactifs <label className="bold">produits par les probiotiques</label> lors de leur fermentation. Contrairement aux probiotiques vivants, les postbiotiques ne nécessitent pas d’être vivants pour être efficaces, offrant ainsi des <label className="bold">avantages uniques.</label>
                                <br /><br />
                                En intégrant les postbiotiques à vos formulations, vous bénéficiez d’une <label className="bold">alternative stable</label> et efficace aux probiotiques, idéale pour une <label className="bold">nouvelle génération</label> de compléments alimentaires.
                            </p>
                        </div>
                        <div className="probiotique_positifPoint-positifPoint">
                            {postbioPositifPoint.map(([title, content], index) => (
                                <PositifPoint key={index} title={title} content={content} />
                            ))}
                        </div>
                    </div>
                </section>
                <section className="postbiotique_tech-container">
                    <h2 className="probiotique_tech-title"><label className="orange-color">Technologie prolipo®, </label><br />pour vos postbiotiques</h2>
                    <div className="probiotique_tech_content-container">
                        <p className="probiotique_tech-text">
                            Grâce à l'encapsulation avancée des posbiotiques, PROLIPO® assure une protection optimale des postbiotiques, renforçant leur <label className="bold">stabilité</label> et leur <label className="bold">biodisponibilité.</label>
                            <br/><br/>
                            Ce procédé innovant améliore la <label className="bold">libération ciblée</label> des actifs dans l'organisme, garantissant une <label className="bold">assimilation efficace</label> et une <label className="bold">action durable.</label>
                        </p>
                        <img className="probiotique_tech-img" src={postbio} />
                        <div className="probiotique_tech_checkList-container">
                            <div className="probiotique_tech_checkList-item">
                                <div className="probiotique_tech_checkList-icon-container">
                                    <p className="probiotique_tech_checkList-icon">✔</p>
                                </div>
                                <p className="probiotique_tech_checkList-text">Résistant à l'acide gastrique, la bile et à la conservation  </p>
                            </div>
                            <div className="probiotique_tech_checkList-item">
                                <div className="probiotique_tech_checkList-icon-container">
                                    <p className="probiotique_tech_checkList-icon">✔</p>
                                </div>
                                <p className="probiotique_tech_checkList-text">Excellent taux de survie des souches dans le tractus gastro-intestinal</p>
                            </div>
                            <div className="probiotique_tech_checkList-item">
                                <div className="probiotique_tech_checkList-icon-container">
                                    <p className="probiotique_tech_checkList-icon">✔</p>
                                </div>
                                <p className="probiotique_tech_checkList-text">Viabilité élevée jusqu'à la fin de la durée de conservation, excellente stabilité</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="postbiotique_listingMp-container">
                    <TabPostbiotique/>
                </section>
                <MoreInfo label={"Nous contacter"} link={"*"} />
                <section className="probiotique_choice_global-container">
                    <h3 className="liposome_choice-title">Pourquoi choisir nos <br /> <label className="orange-color">actifs microencapsulés</label></h3>
                    <p className="liposome_choice-text">Choisir nos actifs liposomes, c'est opter pour des <label className="bold">ingrédients performants</label> et une <label className="bold">technologie avancée</label> qui améliore<br /> significativement leur <label className="bold">efficacité</label>. Nos formulations sont adaptées aux besoins du marché des compléments<br /> alimentaires et offrent une qualité irréprochable, grâce à une <label className="bold">expertise poussée</label> et des contrôles stricts.</p>
                    <div className="liposome_choice-2bloc-container">
                        <div className="liposome_choice-leftBloc-container">
                            <h4 className="liposome_choice-leftBloc-title"><label className="orange-color">Production flexible</label> et sur-mesure</h4>
                            <p className="liposome_choice-leftBloc-text">
                                Nous proposons une solution de <label className="bold">fabrication flexible</label>, allant de petites séries à des productions de grande échelle, pour répondre aux besoins spécifiques de chaque marque.
                                <br /><br />
                                Que ce soit en <label className="bold">marque blanche</label> ou en développement sur-mesure, notre savoir-faire garantit une qualité et une efficacité optimales pour vos produits liposomes.
                            </p>
                            <img className="liposome_choice-leftBloc-img" src={left_schema} />
                        </div>
                        <div className="liposome_choice-rightBloc-contaienr">
                            <h4 className="liposome_choice-rightBloc-title">Engagement qualité <br />
                                et certifications reconnues
                            </h4>
                            <p className="liposome_choice-rightBloc-text">Nos normes permettent de garantir une <label className="bold">traçabilité complète</label>, depuis la sélection des matières premières jusqu'au produit final.
                                <br /><br />
                                Nos compléments alimentaires liposomés bénéficient de <label className="bold">certifications internationales</label> attestant de notre <label className="bold">engagement qualité</label> (HACCP, GMP Food, Synadiet).
                            </p>
                            <img className="liposome_choice-rightBloc-img" src={right_schema} />
                        </div>
                    </div>
                    <div className="liposome_choice_bottomBloc-container">
                        <div className="liposome_choice_bottomBloc-description-container">
                            <h4 className="liposome_choice_bottomBloc-description-title">Un accompagnement complet <label className="orange-color">De la<br /> formulation à la mise sur le marché</label></h4>
                            <p className="liposome_choice_bottomBloc-description-text">Notre laboratoire ne se limite pas à la production : nous accompagnons nos clients dans <label className="bold">toutes les étapes de leur projet</label>, depuis la formulation initiale jusqu'à la mise sur le marché.
                                <br /><br />
                                Notre service inclut également un<label className="bold"> suivi réglementaire</label>  et une <label className="bold">assistance en packaging</label>, garantissant ainsi la conformité et l’attrait de vos compléments alimentaires pour une <label className="bold">commercialisation rapide et efficace.</label>
                            </p>
                        </div>
                        <img className="liposome_choice_bottomBloc-description-img" src={bottom_schema} />
                    </div>
                </section>
                <section className='formule_otherProduct_global-container'>
                    <h3 className='formule_otherCategory-title'>
                        <label className='bold'>Nos autres</label>
                        <label className='orange-color'> catégories d'ingrédients</label>
                    </h3>
                    <OtherProduct
                        title1={"niosomes"}
                        subtitle1={"Cosmétiques"}
                        img1={niosome}
                        link1={"/niosomes"}
                        title2={"Liposomes"}
                        subtitle2={"Extraits de plantes Vitamines · Minéraux "}
                        img2={liposome}
                        link2={"/liposomes"}
                    />
                </section>
            </div>
        </div>
    );
}
