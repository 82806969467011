import React, { useState } from "react";
import "../styles/components/tableauCatalogue.css";
import ColorPoint from "./ColorPoint.js";
import creme from "../assets/icons/creme.png";
import gelule from "../assets/icons/gelule.png";
import serum from "../assets/icons/serum.png";

const icon = {
    Crème: creme,
    Gélule: gelule,
    Sérum: serum
};

const TableCatalogue = ({ data, type }) => {
    return (
        <div className="table-container">
            <table className="table">
                <thead>
                    <tr>
                        <th className="th left">
                            {type === "probiotique" ? "Souches" : "Nom"}
                        </th>
                        {type === "probiotique" && <>
                            <th className="th left none">
                                Types de Souches
                            </th>
                            <th className="th left none">
                                CFU/G
                            </th>
                        </>}
                        {type === "liposome" || type === "niosome" ? (
                            <>
                                <th className="th left">
                                    {type === "liposome" ? "Allégations Santé" : "Actions"}
                                </th>
                                <th className="th left">
                                    Types d'Actifs
                                </th>
                            </>
                        ) : (
                            <th className="th left">Catégories</th>
                        )}
                        {type === "niosome" && <th className="th center none">Formes Disponibles</th>}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index} className="tr left" onClick={() => window.location.href = row.url}>
                            <td className="td">
                                {row.nom}
                            </td>
                            {type === "probiotique" && <>
                                <td className="td left none">
                                    {row.souche}
                                </td>
                                <td className="td left none">
                                    {row.cfu}
                                </td>
                            </>}
                            {type === "liposome" || type === "niosome" ? (
                                <>
                                    <td className="td left">
                                        {type === "liposome" ? (row.allegation ? "✔" : "") : (row.action || row.subtitle)}
                                    </td>
                                    <td className="td flex">
                                        {Array.isArray(row.categories || row.categorie) ? 
                                            (row.categories || row.categorie).map((category, index) => (
                                                <ColorPoint key={category} size="12px" label={category} tab={true} />
                                            )) 
                                            : row.categories || row.categorie ? <ColorPoint size="12px" label={row.categories || row.categorie} tab={true} /> 
                                            : null
                                        }
                                    </td>
                                </>
                            ) : (
                                <td className="td flex">
                                    {Array.isArray(row.categories || row.categorie) ? 
                                        (row.categories || row.categorie).map((category, index) => (
                                            <ColorPoint key={index} size="12px" label={category} tab={true} />
                                        )) 
                                        : row.categories || row.categorie ? <ColorPoint size="12px" label={row.categories || row.categorie} tab={true} /> 
                                        : null
                                    }
                                </td>
                            )}
                            {type === "niosome" && <td className="td center none">
                                {row.formes?.map((forme, index) => (
                                    <img key={index} src={icon[forme]} alt={forme} className="forme-icon" />
                                ))}
                            </td>}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default function TabCatalogue({ produits, type }) {
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedForme, setSelectedForme] = useState(null);
    const productsPerPage = 10;

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const categoryFilters = {
        probiotique: ["Souche conventionnelle", "Santé gastro-intestinale", "Allergies/Immunité", "Femmes", "Métabolisme", "Articulations", "Souche VPRO®", "Santé mentale", "Peau", "Bucco-dentaire", "Protection du foie"],
        liposome: ["acide aminé", "coenzyme", "protéine", "végétal", "vitamine", "minéral", "antioxydant", "acide gras", "substance"],
        niosome: ["acides", "coenzyme", "antioxydants", "végétal", "vitamines", "minéral", "lipides", "peptides"]
    };

    const formeFilters = ["Crème", "Sérum", "Gélule"];

    const filteredProducts = produits.filter(produit => {
        if (produit.type !== type) return false;

        const hasCategory = selectedCategory ? (Array.isArray(produit.categories || produit.categorie) ? (produit.categories || produit.categorie).includes(selectedCategory) : (produit.categories || produit.categorie) === selectedCategory) : true;
        const hasForme = selectedForme ? produit.formes?.includes(selectedForme) : true;
        const matchesSearch = produit.nom.toLowerCase().includes(searchQuery.toLowerCase());

        return hasCategory && hasForme && matchesSearch;
    });

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    return (
        <div className={`catalogue-container ${type === "niosome" ? "niosome" : ""}`}>
            <div className="tc-head">
                <h1 className="title">{type === "niosome" || type === "liposome" ? "CATALOGUE DES ACTIFS" : `CATALOGUE DES ${type.toUpperCase()}`}</h1>
                <div className="tc-recherche">
                    <p className="tc-p-recherche">Rechercher :</p>
                    <input
                        type="text"
                        className="search-bar"
                        placeholder="Nom"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
            {type === "niosome" && <div className="catalogue_filtre-container">
                {formeFilters.map(forme => (
                    <div
                        key={forme}
                        className={`catalogue_filtre_forme-container ${selectedForme === forme ? 'active' : ''}`}
                        onClick={() => setSelectedForme(selectedForme === forme ? null : forme)}
                    >
                        <img className="catalogue_filtre_forme-img" src={icon[forme]} alt={forme} />
                        <p className="catalogue_filtre_forme-text">{forme}</p>
                    </div>
                ))}
            </div>}
            <div className="catalogue_filtre-container">
                {categoryFilters[type]?.map(category => (
                    <div
                        key={category}
                        className={`filter-item ${selectedCategory === category ? 'active' : ''}`}
                        onClick={() => setSelectedCategory(selectedCategory === category ? null : category)}
                    >
                        <ColorPoint label={category} size="12px" />
                    </div>
                ))}
            </div>
            {currentProducts.length > 0 ? <TableCatalogue data={currentProducts} type={type} /> : <p>Aucun produit trouvé.</p>}
            <div className="pagination">
                <button className="pagination-btn" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>←</button>
                <span className="pagination-span">{currentPage} / {Math.ceil(filteredProducts.length / productsPerPage)}</span>
                <button className="pagination-btn" onClick={() => setCurrentPage(prev => prev + 1)} disabled={currentPage >= Math.ceil(filteredProducts.length / productsPerPage)}>→</button>
            </div>
                        <div className="tc-pastrouver">
                <h3 className="tc-pastrouver-h3">Vous recherchez un ingrédient spécifique que vous ne trouvez pas ?</h3>
                <p className="tc-pastrouver-p">
                    Vous recherchez un ingrédient spécifique que vous ne trouvez pas ? Notre liste d’ingrédients est non exhaustive. Si vous avez un projet de développement de complément alimentaire, contactez-nous pour obtenir davantage d’informations.
                    Notre équipe commerciale est à votre écoute pour répondre à vos besoins en matière de formulation adaptée à des objectifs santé précis.
                </p>
            </div>
        </div>
    );
}
