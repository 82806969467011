import React, { useState, useEffect } from "react";
import MoreInfo from "./MoreInfo";
import "../styles/components/otherProduct.css";

//icon carousel
import icon_arrow_forward from '../assets/icons/arrow_forward.svg';
import icon_arrow_back from '../assets/icons/arrow_back.svg';

function OtherProduct({ title1, subtitle1, img1, link1, title2, subtitle2, img2, link2 }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
    const [isCarousel, setIsCarousel] = useState(window.innerWidth <= 715);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1050);
            setIsCarousel(window.innerWidth <= 715);
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Appel initial pour définir l'état correct

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const products = [
        { title: title1, subtitle: subtitle1, img: img1, link: link1 },
        { title: title2, subtitle: subtitle2, img: img2, link: link2 }
    ];

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + products.length) % products.length);
    };

    return (
        <div className="otherProduct_global-container-bg">
            <div className={`otherProduct_global-container ${isMobile ? "mobile" : ""}`}>
                {isCarousel ? (
                    <div className="otherProduct_content-container--mobile carousel">
                        <div className="otherProduct-item-container">
                            <h5 className="otherProduct-item-title">{products[currentIndex].title}</h5>
                            <p className="otherProduct-item-subtitle">{products[currentIndex].subtitle}</p>
                            <img className="otherProduct-item-img" src={products[currentIndex].img} alt={products[currentIndex].title} />
                            <MoreInfo label={"En savoir +"} link={products[currentIndex].link} />
                        </div>
                        <div className="carousel-navigation">
                            <img className="carousel_arrow" alt="Flèche droite" src={icon_arrow_back} onClick={prevSlide} />
                            <img className="carousel_arrow" alt="Flèche gauche" src={icon_arrow_forward} onClick={nextSlide} />
                        </div>
                        <div className="otherProduct-item-container">
                            <h5 className="otherProduct-item-title">
                                <label className="orange-color">besoin</label><label className="bold"> d'un actif ?</label>
                            </h5>
                            <p className="otherProduct-item-text">
                                Notre équipe d’experts maîtrise le <label className="bold">sourcing à l’international</label> pour vous proposer une large gamme d’actifs adaptés à vos besoins spécifiques.
                                <br /><br />
                                Toujours à l’écoute, nos collaborateurs vous guident et vous proposent des <label className="bold">actifs sur-mesure</label> pour enrichir vos formulations de compléments alimentaires.
                            </p>
                            <MoreInfo label={"Découvrez nos offres"} link={"download"} />
                        </div>
                    </div>
                ) : isMobile ? (
                    <div className="otherProduct_global-container--mobile">
                        <div className="otherProduct_content-container--mobile">
                            <div className="otherProduct-item-container">
                                <h5 className="otherProduct-item-title">{title1}</h5>
                                <p className="otherProduct-item-subtitle">{subtitle1}</p>
                                <img className="otherProduct-item-img" src={img1} alt={title1} />
                                <MoreInfo label={"En savoir +"} link={link1} />
                            </div>
                            <div className="otherProduct-item-container">
                                <h5 className="otherProduct-item-title">{title2}</h5>
                                <p className="otherProduct-item-subtitle">{subtitle2}</p>
                                <img className="otherProduct-item-img" src={img2} alt={title2} />
                                <MoreInfo label={"En savoir +"} link={link2} />
                            </div>
                        </div>
                        <div className="otherProduct-item-container">
                            <h5 className="otherProduct-item-title">
                                <label className="orange-color">besoin</label><label className="bold"> d'un actif ?</label>
                            </h5>
                            <p className="otherProduct-item-text">
                                Notre équipe d’experts maîtrise le <label className="bold">sourcing à l’international</label> pour vous proposer une large gamme d’actifs adaptés à vos besoins spécifiques.
                                <br /><br />
                                Toujours à l’écoute, nos collaborateurs vous guident et vous proposent des <label className="bold">actifs sur-mesure</label> pour enrichir vos formulations de compléments alimentaires.
                            </p>
                            <MoreInfo label={"Découvrez nos offres"} link={"download"} />
                        </div>
                    </div>
                ) : (
                    // Version Desktop (affichage horizontal)
                    <>
                        <div className="otherProduct-item-container">
                            <h5 className="otherProduct-item-title">{title1}</h5>
                            <p className="otherProduct-item-subtitle">{subtitle1}</p>
                            <img className="otherProduct-item-img" src={img1} alt={title1} />
                            <MoreInfo label={"En savoir +"} link={link1} />
                        </div>
                        <div className="otherProduct-item-container">
                            <h5 className="otherProduct-item-title">{title2}</h5>
                            <p className="otherProduct-item-subtitle">{subtitle2}</p>
                            <img className="otherProduct-item-img" src={img2} alt={title2} />
                            <MoreInfo label={"En savoir +"} link={link2} />
                        </div>
                        <div className="otherProduct-item-container">
                            <h5 className="otherProduct-item-title">
                                <label className="orange-color">besoin</label><label className="bold"> d'un actif ?</label>
                            </h5>
                            <p className="otherProduct-item-text">
                                Notre équipe d’experts maîtrise le <label className="bold">sourcing à l’international</label> pour vous proposer une large gamme d’actifs adaptés à vos besoins spécifiques.
                                <br /><br />
                                Toujours à l’écoute, nos collaborateurs vous guident et vous proposent des <label className="bold">actifs sur-mesure</label> pour enrichir vos formulations de compléments alimentaires.
                            </p>
                            <MoreInfo label={"Découvrez nos offres"} link={"/formule-sur-mesure"} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default OtherProduct;
