import { useEffect, useState } from "react"

import liposome_intro from "../assets/img/liposome_intro.png";

//icon formes
import gelule from "../assets/icons/gelule_orange.png";
import poudre from "../assets/icons/poudre_orange.png";
import liquide from "../assets/icons/liquide_orange.png";

//icon catégori
import categori1 from "../assets/icons/cate1.png";
import categori2 from "../assets/icons/cate2.png";
import categori3 from "../assets/icons/cate3.png";
import categori4 from "../assets/icons/cate4.png";

//icon liposome avantage
import corps from "../assets/icons/liposome_corps.png";
import schema from "../assets/icons/liposome_schema.png";

//icon liposome actif
import left_schema from "../assets/icons/liposome_left_schema.png";
import right_schema from "../assets/icons/liposome_right_schema.png";
import bottom_schema from "../assets/icons/liposome_botom_schema.png";

//icon otherProduct
import probiotique from "../assets/icons/homepage_probiotique.png";
import niosome from "../assets/icons/homepage_niosome.png";

//icon carousel
import icon_arrow_forward from '../assets/icons/arrow_forward.svg';
import icon_arrow_back from '../assets/icons/arrow_back.svg';

import '../styles/views/liposome.css'
import MoreInfo from "../components/MoreInfo";
import OtherProduct from '../components/OtherProduct';
import TabCatalogue from "../components/TabCatalogue";
import produits from "../assets/ProductContent/data/data.js";


export default function Liposome() {

    const [isMobile, setIsMobile] = useState(false);
    const [isCarousel, setIsCarousel] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0);


    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? categories.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === categories.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1050);
            setIsCarousel(window.innerWidth <= 1050);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const categories = [
        [categori1, "Vitamines & minéraux", "Vitamine C, multivitamines,<br/> Fer, Zinc, Chrome…"],
        [categori2, "Antioxydants", "Glutathion, Quercétine,<br/> Resvératrol, Astaxanthine…"],
        [categori3, "Acides & coenzymes", "Acide Alpha-Lipoïque, NADH,<br/> Coenzyme Q10…"],
        [categori4, "Extraits végétaux", "Curcuma, Vigne Rouge,<br/> thé vert, chardon marie…"]
    ];


    return (
        <div>
            <div className="liposome_global-container">
                {isMobile ? (
                    // 📱 Version Mobile
                    <section className="liposome_intro-container">
                        <h1 className="liposome_intro-title">
                            <label className="bold font-42">Votre fournisseur</label><br />
                            <label className="orange-color">D'actifs liposomés</label>
                        </h1>
                        <img className="liposome_intro-img" src={liposome_intro} />
                        <p className="liposome_intro-subtitle">
                            <label className="orange-color">La technologie liposomale</label> pour des compléments alimentaires à absorption optimisée
                        </p>
                        <p className="liposome_intro-text">
                            La technologie liposomale est une <label className="bold">innovation clé</label> dans le domaine des compléments alimentaires, permettant une <label className="bold">absorption accrue</label> et une <label className="bold">biodisponibilité améliorée</label> des actifs.
                            <br /><br />
                            Grâce aux liposomes, de minuscules vésicules qui <label className="bold">encapsulent</label> les ingrédients, cette technologie assure une meilleure protection et une <label className="bold">délivrance maximale</label> des nutriments dans l’organisme. Ils garantissent jusqu’à <label className="bold">95% des actifs préservés.</label>
                            <br /><br />
                            Contrairement aux formes classiques, les compléments alimentaires liposomes permettent des <label className="bold">effets renforcés et ciblés</label> et une <label className="bold">assimilation optimale</label>, même pour les actifs les plus sensibles.
                        </p>
                    </section>
                ) : (
                    // 💻 Version Desktop
                    <section className="liposome_intro-container">
                        <div className="liposome_introduction-text-container">
                            <h1 className="liposome_intro-title">
                                <label className="bold font-42">Votre fournisseur</label><br />
                                <label className="orange-color">D'actifs liposomés</label>
                            </h1>
                            <p className="liposome_intro-subtitle">
                                <label className="orange-color">La technologie liposomale</label> pour des compléments alimentaires à absorption optimisée
                            </p>
                            <p className="liposome_intro-text">
                                La technologie liposomale est une <label className="bold">innovation clé</label> dans le domaine des compléments alimentaires, permettant une <label className="bold">absorption accrue</label> et une <label className="bold">biodisponibilité améliorée</label> des actifs.
                                <br /><br />
                                Grâce aux liposomes, de minuscules vésicules qui <label className="bold">encapsulent</label> les ingrédients, cette technologie assure une meilleure protection et une <label className="bold">délivrance maximale</label> des nutriments dans l’organisme. Ils garantissent jusqu’à <label className="bold">95% des actifs préservés.</label>
                                <br /><br />
                                Contrairement aux formes classiques, les compléments alimentaires liposomes permettent des <label className="bold">effets renforcés et ciblés</label> et une <label className="bold">assimilation optimale</label>, même pour les actifs les plus sensibles.
                            </p>
                            <MoreInfo label={"Voir notre catalogue"} link={"download"} />
                        </div>
                        <img className="liposome_intro-img" src={liposome_intro} />
                    </section>
                )}
                <section className="liposome_forme-container">
                    <h2 className="liposome_forme-title">Une large<label className="orange-color"> gamme d'actifs</label></h2>
                    <p className="liposome_forme-subtitle"><label className="orange-color">3 formes diponibles :</label></p>
                    <div className="liposome_forme-icon-container">
                        <div className="liposome_forme-icon-item-container">
                            <img className="liposome_forme-icon" src={gelule} />
                            <p className="liposome_forme-text">Gélules</p>
                        </div>
                        <div className="liposome_forme-icon-container">
                            <div className="liposome_forme-icon-item-container">
                                <img className="liposome_forme-icon" src={poudre} />
                                <p className="liposome_forme-text">Poudre<br />waterdispersible</p>
                            </div>
                        </div>
                        <div className="liposome_forme-icon-container">
                            <div className="liposome_forme-icon-item-container">
                                <img className="liposome_forme-icon" src={liquide} />
                                <p className="liposome_forme-text">liquide</p>
                            </div>
                        </div>
                    </div>
                    <p className="liposome_forme-description-text">Notre laboratoire propose une <label className="bold">gamme complète</label> d’actifs liposomes spécialement formulés pour les compléments alimentaires.<br />
                        Tous nos ingrédients sont soigneusement sélectionnés pour <label className="bold">répondre aux attentes élevées</label> des professionnels et de leurs consommateurs :
                    </p>
                    {isMobile ? (
                        <div className="liposome_forme-categori-container">
                            <img className="carousel_arrow left" src={icon_arrow_back} alt="Flèche gauche" onClick={handlePrev} />
                            <div className="carousel_item">
                                <img className="liposome_forme-categori-img" src={categories[currentIndex][0]} alt={categories[currentIndex][1]} />
                                <h4 className="liposome_forme-categori-title">{categories[currentIndex][1]}</h4>
                                <p className="liposome_forme-categori-text" dangerouslySetInnerHTML={{ __html: categories[currentIndex][2] }}></p>
                            </div>

                            <img className="carousel_arrow right" src={icon_arrow_forward} alt="Flèche droite" onClick={handleNext} />
                        </div>

                    ) :
                        (
                            <div className="liposome_forme-categori-container">
                                <div className="liposome_forme-categori-container-item">
                                    <img className="liposome_forme-categori-img" src={categori1} />
                                    <h4 className="liposome_forme-categori-title">Vitamines & minéraux</h4>
                                    <p className="liposome_forme-categori-text">Vitamine C, Multivitamines, <br /> Fer, Zinc, Chrome…</p>
                                </div>
                                <div className="liposome_forme-categori-container-item">
                                    <img className="liposome_forme-categori-img" src={categori2} />
                                    <h4 className="liposome_forme-categori-title">Antioxydants</h4>
                                    <p className="liposome_forme-categori-text">Glutathion, Quercétine,<br /> Resvératrol, Astaxanthine…</p>
                                </div>
                                <div className="liposome_forme-categori-container-item">
                                    <img className="liposome_forme-categori-img" src={categori3} />
                                    <h4 className="liposome_forme-categori-title">Acides & coenzymes</h4>
                                    <p className="liposome_forme-categori-text">Acide Alpha-Lipoïque, NADH,<br /> Coenzyme Q10…</p>
                                </div>
                                <div className="liposome_forme-categori-container-item">
                                    <img className="liposome_forme-categori-img" src={categori4} />
                                    <h4 className="liposome_forme-categori-title">Extraits végétaux</h4>
                                    <p className="liposome_forme-categori-text">Curcuma, Vigne Rouge,<br /> Thé vert, Chardon marie…</p>
                                </div>
                            </div>
                        )}
                </section>
                <section className="liposome_avantage-container">
                    <img className="liposome_avantage-img" src={corps} />
                    <div className="liposome_avantage-content-container">
                        <h3 className="liposome_avantage-title">Les avantages des <label className="orange-color">actifs liposomés</label></h3>
                        <div className="liposome_avantage_rightImg-container">
                            <img className="liposome_avantage-img_right" src={schema} />
                        </div>
                        <p className="liposome_avantage-description">La technologie liposomale présente plusieurs bénéfices spécifiques pour vos compléments alimentaires :</p>
                        <div className="liposome_avantage-buletPoint-container">
                            <h4 className="liposome_avantage-buletPoint-title">✔ Biodisponibilité maximale :</h4>
                            <p className="liposome_avantage-buletPoint-text"> Grâce aux liposomes, les actifs sont protégés et libérés directement dans le système digestif, permettant une assimilation complète.</p>
                        </div>
                        <div className="liposome_avantage-buletPoint-container">
                            <h4 className="liposome_avantage-buletPoint-title">✔ Protection des actifs :</h4>
                            <p className="liposome_avantage-buletPoint-text"> Les liposomes préservent les ingrédients de la dégradation digestive, assurant ainsi leur intégrité et leur efficacité.</p>
                        </div>
                        <div className="liposome_avantage-buletPoint-container">
                            <h4 className="liposome_avantage-buletPoint-title">✔ Libération ciblée et progressive :</h4>
                            <p className="liposome_avantage-buletPoint-text"> Les liposomes permettent une diffusion contrôlée des actifs, prolongeant ainsi leurs effets bénéfiques.</p>
                        </div>
                    </div>
                </section>
                <section className="liposome_listingMp-container">
                    <TabCatalogue produits={produits} type="liposome" />
                    <MoreInfo 
                        label={"Nous contacter"}
                        link={"*"}
                    />
                </section>
                <section className="liposome_choice_global-container">
                    <h3 className="liposome_choice-title">Pourquoi choisir nos <br /> <label className="orange-color">actifs liposomés</label></h3>
                    <p className="liposome_choice-text">Choisir nos actifs liposomes, c'est opter pour des <label className="bold">ingrédients performants</label> et une <label className="bold">technologie avancée</label> qui améliore<br /> significativement leur <label className="bold">efficacité</label>. Nos formulations sont adaptées aux besoins du marché des compléments<br /> alimentaires et offrent une qualité irréprochable, grâce à une <label className="bold">expertise poussée</label> et des contrôles stricts.</p>
                    <div className="liposome_choice-2bloc-container">
                        <div className="liposome_choice-leftBloc-container">
                            <h4 className="liposome_choice-leftBloc-title"><label className="orange-color">Production flexible</label> et sur-mesure</h4>
                            <p className="liposome_choice-leftBloc-text">
                                Nous proposons une solution de <label className="bold">fabrication flexible</label>, allant de petites séries à des productions de grande échelle, pour répondre aux besoins spécifiques de chaque marque.
                                <br /><br />
                                Que ce soit en <label className="bold">marque blanche</label> ou en développement sur-mesure, notre savoir-faire garantit une qualité et une efficacité optimales pour vos produits liposomes.
                            </p>
                            <img className="liposome_choice-leftBloc-img" src={left_schema} />
                        </div>
                        <div className="liposome_choice-rightBloc-contaienr">
                            <h4 className="liposome_choice-rightBloc-title">Engagement qualité <br />
                                et certifications reconnues
                            </h4>
                            <p className="liposome_choice-rightBloc-text">Nos normes permettent de garantir une <label className="bold">traçabilité complète</label>, depuis la sélection des matières premières jusqu'au produit final.
                                <br /><br />
                                Nos compléments alimentaires liposomés bénéficient de <label className="bold">certifications internationales</label> attestant de notre <label className="bold">engagement qualité</label> (HACCP, GMP Food, Synadiet).
                            </p>
                            <img className="liposome_choice-rightBloc-img" src={right_schema} />
                        </div>
                    </div>
                    <div className="liposome_choice_bottomBloc-container">
                        <div className="liposome_choice_bottomBloc-description-container">
                            <h4 className="liposome_choice_bottomBloc-description-title">Un accompagnement complet <label className="orange-color">De la<br /> formulation à la mise sur le marché</label></h4>
                            <p className="liposome_choice_bottomBloc-description-text">Notre laboratoire ne se limite pas à la production : nous accompagnons nos clients dans <label className="bold">toutes les étapes de leur projet</label>, depuis la formulation initiale jusqu'à la mise sur le marché.
                                <br /><br />
                                Notre service inclut également un<label className="bold"> suivi réglementaire</label>  et une <label className="bold">assistance en packaging</label>, garantissant ainsi la conformité et l’attrait de vos compléments alimentaires pour une <label className="bold">commercialisation rapide et efficace.</label>
                            </p>
                        </div>
                        <img className="liposome_choice_bottomBloc-description-img" src={bottom_schema} />
                    </div>
                </section>
                <MoreInfo
                    label={"Nous contacter"}
                    link={"*"}
                />
                <section className='formule_otherProduct_global-container'>
                    <h3 className='formule_otherCategory-title'><label className='bold'>Nos autres</label><label className='orange-color'> catégories d'ingrédients</label></h3>
                    <OtherProduct
                        title1={"niosomes"}
                        subtitle1={"Cosmétiques"}
                        img1={niosome}
                        link1={"/niosomes"}
                        title2={"Probiotiques & Postbiotiques"}
                        subtitle2={"Technologies VPRO® et PROLIPO®"}
                        img2={probiotique}
                        link2={"/probiotiques"}
                    />
                </section>
            </div>
        </div>
    );

}