import React, { useState, useEffect } from 'react';

import '../styles/views/notreLaboratoire.css';
import img_intro from '../assets/img/labo_intro.png';
import JobsTimeline from '../components/JobsTimeline';
import FormulaBox from '../components/FormulaBox';
import TrumpCard from '../components/TrumpCard';

import icon_formulation from '../assets/icons/formulation.svg';
import icon_reglementation from '../assets/icons/reglementation.svg';
import icon_façonnage from '../assets/icons/sourcing.svg';
import icon_formation from '../assets/icons/formation.svg';

// icon formula
import icon_formula_mesure from '../assets/icons/homepage_formula_mesure.png';
import icon_formula_blanche from '../assets/icons/homepage_formula_blanche.png';
import ImageCard from '../components/ImageCard';

// icon image card
import img_production from '../assets/img/labo_production.png';
import img_conditionnement from '../assets/img/labo_conditionnement.png';
import img_qualite from '../assets/img/labo_qualite.png';

//icon carousel
import icon_arrow_forward from '../assets/icons/arrow_forward.svg';
import icon_arrow_back from '../assets/icons/arrow_back.svg';

export default function NotreLaboratoire() {
    const [isMobile, setIsMobile] = useState(0);
    const [isMobileAll, setIsMobileAll] = useState(0);
    const [isMobileImg, setIsMobileImg] = useState(0);
    const [currentIndexAtouts, setCurrentIndexAtouts] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1050);
            setIsMobileAll(window.innerWidth <= 860);
            setIsMobileImg(window.innerWidth <= 1150);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleNextAtouts = () => {
        setCurrentIndexAtouts((prevIndex) => (prevIndex + 1) % atouts_content.length);
    };

    const handlePrevAtouts = () => {
        setCurrentIndexAtouts((prevIndex) =>
            (prevIndex - 1 + atouts_content.length) % atouts_content.length
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageCards.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + imageCards.length) % imageCards.length);
    };

    const atouts_content = [
        [
            icon_formulation,
            "formulation & sourcing",
            [
                "Développement d’ingrédients innovants",
                "Identification des actifs performants et formulation sur mesure",
                "Sélection rigoureuse et validation clinique garantissent efficacité et sécurité."
            ],
            57,
            61,
            1
        ],
        [
            icon_reglementation,
            "réglementation & qualité",
            [
                "Gestion des allégations santé et conformité",
                "Validation des formules et packagings, en lien avec les autorités de contrôle (DGCCRF, ANSES, ANSM).",
                "Suivi réglementaire pour assurer la qualité."
            ],
            47,
            57,
            0
        ],
        [
            icon_façonnage,
            "façonnage & conditionnement",
            [
                "Production flexible, petites et grandes séries. ",
                "Gamme étendue : phytothérapie, nutraceutique, probiotiques",
                "Catalogue en marque blanche et options de distribution optimisées.",
            ],
            67,
            71,
            1
        ],
        [
            icon_formation,
            "formation & communication",
            [
                "Accompagnement dans le développement de votre marque avec des techniques de vente efficaces pour maximiser vos résultats.",
                "Création des outils marketing sur mesure.",
            ],
            67,
            55,
            0
        ],
    ];

    const imageCards = [
        { img: img_production, title: "production" },
        { img: img_conditionnement, title: "conditionnement" },
        { img: img_qualite, title: "qualité" },
    ];

    return (
        <div>
            <div className='labo_global-container'>
                <section className='labo_introduction_content'>
                    {!isMobile ? (
                        <div>
                            <h1 className="labo_introduction-title">
                                <label className="orange-color font-40">L'engagement naturel</label>
                                <br />
                                au service de vos
                                <br />
                                produits premium
                            </h1>
                            <article className="labo_introduction-text">
                                Fondé en 2010 par Benjamin Waroquier, notre laboratoire d’innovation naturelle est dédié à la{' '}
                                <label className="bold">conception</label> et la <label className="bold">fabrication</label> de
                                compléments alimentaires et produits de beauté.
                                <br />
                                <br />
                                Grâce à notre laboratoire de développement et à notre installation de production, nous assurons un{' '}
                                <label className="bold">contrôle complet à chaque étape du processus.</label> Notre système de qualité
                                garantit une traçabilité rigoureuse des matières premières, depuis leur réception jusqu’à la production
                                des produits finis.
                                <br />
                                <br />
                                Nous nous engageons ainsi à offrir des <label className="bold">produits de haute qualité</label>,
                                préservant l’intégrité et l’efficacité des ingrédients actifs naturels.
                            </article>
                        </div>
                    ) : (
                        <div className="labo_introduction_content-mobile">
                            <h1 className="labo_introduction-title">
                                L'engagement <label className="orange-color font-40"><br />naturel</label>
                            </h1>
                            <img className="labo_introduction-img-mobile" src={img_intro} alt="Introduction" />
                            <article className="labo_introduction-text">
                                Fondé en 2010 par Benjamin Waroquier, notre laboratoire d’innovation naturelle est dédié à la{' '}
                                <label className="bold">conception</label> et la <label className="bold">fabrication</label> de
                                compléments alimentaires et produits de beauté.
                                <br />
                                <br />
                                Grâce à notre laboratoire de développement et à notre installation de production, nous assurons un{' '}
                                <label className="bold">contrôle complet à chaque étape du processus.</label> Notre système de qualité
                                garantit une traçabilité rigoureuse des matières premières, depuis leur réception jusqu’à la production
                                des produits finis.
                                <br />
                                <br />
                                Nous nous engageons ainsi à offrir des <label className="bold">produits de haute qualité</label>,
                                préservant l’intégrité et l’efficacité des ingrédients actifs naturels.
                            </article>
                        </div>
                    )}
                    <img className="labo_introduction-img" src={img_intro} alt="Introduction" />
                </section>
                <h2 className='labo_jobs-title'>Nos métiers</h2>
                <section className='labo_jobs-container'>
                    {isMobileAll ? (
                        <div className="carousel_container">
                            <img className="carousel_arrow" alt="Flèche droite" src={icon_arrow_back} onClick={handlePrevAtouts} />
                            <TrumpCard
                                img={atouts_content[currentIndexAtouts][0]}
                                title={atouts_content[currentIndexAtouts][1]}
                                text={atouts_content[currentIndexAtouts][2]}
                            />
                            <img className="carousel_arrow" alt="Flèche gauche" src={icon_arrow_forward} onClick={handleNextAtouts} />
                        </div>
                    ) :
                        (
                            <div className='labo_jobs-timline-conainter'>
                                {atouts_content.map(([img, title, text, width, height, right], index) => (
                                    <JobsTimeline
                                        key={index}
                                        img={img}
                                        title={title}
                                        text={text}
                                        width={width}
                                        height={height}
                                        right={right}
                                        isLast={index === atouts_content.length - 1}
                                    />
                                ))}
                            </div>
                        )}
                </section>
                <section className='labo_formula-container'>
                    <h2 className='labo_formula-title'><label className='orange-color bold'>nos services</label> de développement et de façonnage</h2>
                    <div className='labo_formula_box-container'>
                        <FormulaBox
                            title={"Formule en <span class='formulacard_orange_title'>marque blanche</span>"}
                            content={"Nous mettons à votre disposition une <label class='bold'>large gamme de compléments alimentaires et des cosmétiques</label> en marque blanche, adaptée aux tendances du marché : bien-être, immunité, digestion, beauté, énergie, etc.<br /> Chaque produit est formulé avec des <label class='bold'>ingrédients soigneusement sélectionnés</label> pour garantir efficacité et satisfaction client."}
                            icon={icon_formula_blanche}
                            link={"/formule-marque-blanche"}
                        />
                        <FormulaBox
                            title={"Formule <span class='formulacard_orange_title'>sur mesure</span>"}
                            content={"Une formule sur-mesure consiste à créer un complément alimentaire totalement <label class='bold'>adapté à vos attentes</label>, de la sélection des ingrédients jusqu’au packaging.<br/>  Contrairement aux marques blanches, les formules personnalisées vous donnent une totale liberté pour <label class='bold'>développer un produit unique</label> ou répondre à des besoins spécifiques parfaitement <label class='bold'>adaptés à votre cible et à vos valeurs.</label>"}
                            icon={icon_formula_mesure}
                            link={"/formule-sur-mesure"}
                        />
                    </div>
                </section>
                <section className='labo_ressource-container'>
                    <h3 className='labo_ressource-title bold'>Nos moyens</h3>
                    <h4 className='labo_ressource-subtitle bold'>Une flexibilité de production pour s'adapter à vos besoin : <br /> des lots de <label className='orange-color'>500 à 50.000 unités !</label></h4>
                    {isMobileImg ? (
                        <div className='carousel-container'>
                            <div className='carousel-item'>
                                <ImageCard
                                    img={imageCards[currentIndex].img}
                                    title={imageCards[currentIndex].title}
                                    isMobile={true}
                                />
                            </div>
                            <div className='labo_ressource_carousel-img'>
                                <img
                                    src={icon_arrow_back}
                                    alt="Précédent"
                                    className='carousel-arrow'
                                    onClick={handlePrev}
                                />
                                <img
                                    src={icon_arrow_forward}
                                    alt="Suivant"
                                    className='carousel-arrow'
                                    onClick={handleNext}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='labo_ressource-imgCard-container'>
                            {imageCards.map((card, index) => (
                                <ImageCard key={index} img={card.img} title={card.title} />
                            ))}
                        </div>
                    )}
                </section>
            </div >
        </div >
    );
}