import React, { useState, useEffect } from 'react';

import '../styles/views/homepage.css';
import MoreInfo from '../components/MoreInfo';
import CategoryCard from '../components/CategoryCard';
import FormulaCard from '../components/FormulaCard';
import TrumpCard from '../components/TrumpCard';
import Popup from '../components/Popup';

// icon service
import icon_conditionnement from '../assets/icons/homepage_conditionnement.svg';
import icon_marketing from '../assets/icons/homepage_marketing.svg';
import icon_packaging from '../assets/icons/homepage_packaging.svg';
import icon_prod from '../assets/icons/homepage_prod.svg';
// icon formula
import icon_formula_mesure from '../assets/icons/homepage_formula_mesure.png';
import icon_formula_blanche from '../assets/icons/homepage_formula_blanche.png';
//icon qualite
import icon_qualite1 from '../assets/icons/homepage_qualite1.png';
import icon_qualite2 from '../assets/icons/homepage_qualite2.png';
import icon_qualite3 from '../assets/icons/homepage_qualite3.png';
import icon_qualite4 from '../assets/icons/homepage_qualite4.png';
//icon atouts
import icon_formulation from '../assets/icons/homepage_formulation.png';
import icon_reglementation from '../assets/icons/homepage_reglementation.png';
import icon_façonnage from '../assets/icons/homepage_conditionnement.png';
import icon_formation from '../assets/icons/homepage_formation.png';
//icon carousel
import icon_arrow_forward from '../assets/icons/arrow_forward.svg';
import icon_arrow_back from '../assets/icons/arrow_back.svg';

//video
import video_homepage from "../assets/video/video_homepage.mp4";
import video_homepage_mobile from "../assets/video/video_homepage_mobile.mp4"

export default function Home() {

    const [isCarousel, setIsCarousel] = useState(window.innerWidth <= 900);;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [forceReloadKey, setForceReloadKey] = useState(0);
    const [wichFormula, setWichFormula] = useState(0);
    const [activeButton, setActiveButton] = useState(0);
    const [currentIndexAtouts, setCurrentIndexAtouts] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
    const [videoKey, setVideoKey] = useState(0);
    
    useEffect(() => {
        const handleResize = () => {
            const newIsMobile = window.innerWidth <= 1050;
            if (newIsMobile !== isMobile) {
                setIsMobile(newIsMobile);
                setVideoKey(prevKey => prevKey + 1);
            }
            setIsCarousel(window.innerWidth <= 1420);
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [isMobile]);
    

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % categories.length);
        setForceReloadKey((prevKey) => prevKey + 1);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            (prevIndex - 1 + categories.length) % categories.length
        );
        setForceReloadKey((prevKey) => prevKey + 1);
    };

    const handleChangeFormula = (index) => {
        setWichFormula(index)
        setActiveButton(index);
    }

    const handleNextAtouts = () => {
        setCurrentIndexAtouts((prevIndex) => (prevIndex + 1) % atouts_content.length);
    };

    const handlePrevAtouts = () => {
        setCurrentIndexAtouts((prevIndex) =>
            (prevIndex - 1 + atouts_content.length) % atouts_content.length
        );
    };

    const icon_service = [
        [icon_prod, ["FAÇONNAGE", "PETITES & GRANDES", "SÉRIES"]], [icon_packaging, ["DESIGN", "& PACKAGING"]], [icon_conditionnement, ["CONDITIONNEMENT", "OPTIMAL"]], [icon_marketing, ["EXPERTISE R&D", "AU MARKETING"]]
    ];

    const categories = [
        {
            title: "Liposomes",
            subtitle: "Extraits de plantes vitamines - minéraux",
            type: "liposome",
            link: "/liposomes",
        },
        {
            title: "Niosomes",
            subtitle: "cosmétiques",
            type: "niosome",
            link: "/niosomes",
        },
        {
            title: "Probiotiques & postbiotiques",
            subtitle: "technologies VPRO® et prolipo®",
            type: "probiotique",
            link: "/probiotiques",
        },
    ];

    const formula_content = [
        ["gain de temps ", "Les formulations sont déjà prêtes, ce qui diminue les délais liés à la recherche et au développement."],
        ["réduction des coûts ", "Pas d’investissement dans des infrastructures de production ou de R&D"],
        ["assurance qualité ", "Notre laboratoire respecte des normes de qualité élevées (HACCP, GMP)."],
        ["flexibilité ", "La possibilité d’adapter l’emballage et le design selon votre image de marque."],
        ["rapidité de mise sur le marché ", "Avec des produits prêts à l’emploi, vous pouvez lancer rapidement votre gamme"]
    ]

    const formula_content_2 = [
        ["un produit unique ", "Développez une formule originale qui reflète votre vision et se distingue sur le marché."],
        ["Adaptation aux besoins spécifiques ", "Ciblez des problématiques précises comme le stress, l’immunité, la performance…"],
        ["Choix des ingrédients ", "Sélectionnez des actifs innovants ou des ingrédients naturels pour répondre aux attentes de vos consommateurs."],
        ["Flexibilité totale ", "Vous décidez de tout : dosage, galénique (gélules, comprimés, poudre), arômes et plus encore."],
        ["Valorisation de votre marque ", "Proposez des compléments alimentaires qui incarnent vos valeurs et créent un lien fort avec vos clients."]
    ]

    const atouts_content = [
        [
            icon_formulation,
            "formulation & sourcing",
            [
                "Le <label class='bold'>développement</label> d’ingrédients innovants, l’identification des actifs performants et la <label class='bold'>formulation sur mesure</label>.",
                "Une sélection rigoureuse et une <label class='bold'>validation clinique</label> garantissant efficacité et sécurité.",
            ],
        ],
        [
            icon_reglementation,
            "réglementation & qualité",
            [
                "Une <label class='bold'>gestion des allégations</label> santé et conformité, la validation des formules et <label class='bold'>packagings</label>, en lien avec les autorités de contrôle (DGCCRF, ANSES, ANSM).",
                "Un <label class='bold'>suivi réglementaire</label> pour assurer la qualité.",
            ],
        ],
        [
            icon_façonnage,
            "façonnage & conditionnement",
            [
                "Une <label class='bold'>production flexible</label>, petites et grandes séries.",
                "Une <label class='bold'>gamme étendue</label> : phytothérapie, nutraceutique, probiotiques.",
                "Un catalogue en marque blanche et des <label class='bold'>options de distribution</label> optimisées.",
            ],
        ],
        [
            icon_formation,
            "formation & communication",
            [
                "Un <label class='bold'>soutien commercial</label> avec des outils adaptés et des <label class='bold'>formations</label>.",
                "Des <label class='bold'>stratégies de vente</label> directes et à distance pour maximiser l’efficacité commerciale.",
            ],
        ],
    ];

    return (
        <div>
            <div className="homepage_content_intro_img">
                <video autoPlay loop muted playsInline className="homepage_video">
                    <source src={video_homepage} type="video/mp4" />
                    Votre navigateur ne supporte pas les vidéos HTML5.
                </video>
            </div>
            <div className='homepage_global-container'>
                <section className='homepage_introduction_content'>
                    <h1 className='homepage_title'><label className='orange-color font-40 padding-bottom'>Notre expertise</label><br /> façonnage de <br />compléments alimentaires<br /> & cosmétiques premium</h1>
                    <article className='homepage_intruction_text'>
                        Notre mission est de créer une vaste gamme de <label className='bold'>compléments alimentaires</label> pour répondre aux besoins spécifiques de santé et de bien-être de chacun. En mettant l’accent sur la qualité irréprochable de nos produits, nous garantissons une <label className='bold'> efficacité optimale</label> et des solutions de prévention adaptées à tous.
                        <br /><br />
                        Nous concevons nos produits autour de deux piliers essentiels :
                        <ul>
                            <li><label className='orange-color bold'>Les bienfaits naturels :</label> Nos formules valorisent les ressources offertes par la nature.</li>
                            <li><label className='orange-color bold'>Les avancées scientifiques :</label> Nous intégrons des innovations pertinentes pour maximiser l’efficacité de chaque complément.</li>
                        </ul>
                        <br />
                        LIN vous offre une <label className='bold'>grande flexibilité de production</label>, des petites aux grandes séries, en passant par des solutions de marque blanche ou des formules sur-mesure. Notre expertise couvre également un vaste choix de formes galéniques, incluant les poudres, gélules capsules et formats liquides.
                    </article>
                </section>
                <MoreInfo link="/notre-laboratoire" label={"En savoir +"} />
                <section className='homepage_container_subtitle'>
                    <h2 className='homepage_subtitle_h2'>Un service <span className='orange-color'>à 360°</span></h2>
                    <p className='homepage_description_subtitle'>De l'idée au produit fini</p>
                    <p className='homepage_subtitle_content'>
                        À l’écoute de vos besoins et des tendances du marché, nous travaillons main dans la main pour concevoir des <label className='bold'>compléments alimentaires sur-mesure</label>, parfaitement <label className='bold'>adaptés à vos objectifs commerciaux et votre succès.</label>
                    </p>
                    <div className="homepage_services">
                        {icon_service.map(([icon, lines], index) => (
                            <div key={index} className="homepage_service_item">
                                <img src={icon} alt={lines.join(' ')} className="homepage_service_icon" />
                                <p className="homepage_service_text">
                                    {lines.map((line, i) => (
                                        <React.Fragment key={i}>
                                            {line}
                                            {i < lines.length - 1 && <br />}
                                        </React.Fragment>
                                    ))}
                                </p>
                            </div>
                        ))}
                    </div>
                </section>
                <div className="homepage_category_container">
                    <h2 className="homepage_category_title">
                        Nos <span className="orange-color">catégories d'ingrédients</span>
                    </h2>
                    {isCarousel ? (
                        <div className="carousel">
                            <div key={forceReloadKey} className="carousel_item">
                                <CategoryCard
                                    title={categories[currentIndex].title}
                                    subtitle={categories[currentIndex].subtitle}
                                    type={categories[currentIndex].type}
                                    link={categories[currentIndex].link}
                                />
                            </div>
                            <div className='carousel_container_arrow'>
                                <img className="carousel_arrow" alt="Flèche droite" src={icon_arrow_back} onClick={handlePrev} />
                                <img className="carousel_arrow" alt="Flèche gauche" src={icon_arrow_forward} onClick={handleNext} />
                            </div>
                        </div>
                    ) : (
                        <nav className="homepage_nav_categorycard">
                            {categories.map((category, index) => (
                                <CategoryCard
                                    key={index}
                                    title={category.title}
                                    subtitle={category.subtitle}
                                    type={category.type}
                                    link={category.link}
                                />
                            ))}
                        </nav>
                    )}
                </div>
                <div className='homepage_formule_container'>
                    <h2 className="homepage_formule_title">
                        <span className="orange-color">Quelle formule</span> répond à vos besoins ?
                    </h2>
                    {isMobile ? (
                        <div>
                            <div className="homepage_formule_container_btn_mobile">
                                <div
                                    className={`homepage_formule_btn_mobile ${activeButton === 0 ? 'active' : ''}`}
                                    onClick={() => handleChangeFormula(0)}
                                >
                                    Marque blanche
                                </div>
                                <div
                                    className={`homepage_formule_btn_mobile ${activeButton === 1 ? 'active' : ''}`}
                                    onClick={() => handleChangeFormula(1)}
                                >
                                    Sur-mesure
                                </div>
                            </div>
                            <div className="homepage_formule_bg">
                                <div className="homepage_formula_container_card">
                                    {wichFormula === 0 ? (
                                        <FormulaCard
                                            icon={icon_formula_blanche}
                                            title={"Formule en <span class='formulacard_orange_title'>marque blanche</span>"}
                                            subtitle={"Vous accédez à des produits clé en main, prêts à être mis sur le marché."}
                                            content={formula_content}
                                            link={"/formule-marque-blanche"}
                                        />
                                    ) : (
                                        <FormulaCard
                                            icon={icon_formula_mesure}
                                            title={"Formule en <span class='formulacard_orange_title'>sur-mesure</span>"}
                                            subtitle={"Vous accédez à des produits clé en main, prêts à être mis sur le marché."}
                                            content={formula_content_2}
                                            link={"/formule-sur-mesure"}
                                        />
                                    )}

                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="homepage_formule_bg">
                            <div className="homepage_formula_container_card">
                                <FormulaCard
                                    icon={icon_formula_blanche}
                                    title={"Formule en <span class='formulacard_orange_title'>marque blanche</span>"}
                                    subtitle={"Vous accédez à des produits clé en main, prêts à être mis sur le marché."}
                                    content={formula_content}
                                    link={"/formule-marque-blanche"}
                                />
                                <FormulaCard
                                    icon={icon_formula_mesure}
                                    title={"Formule <span class='formulacard_orange_title'>sur-mesure</span>"}
                                    subtitle={"Vous développez vos formules selon votre cahier des charges."}
                                    content={formula_content_2}
                                    link={"/formule-sur-mesure"}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className='homepage_qualite_container'>
                    <h2 className='homepage_qualite_title'><span className='orange-color'>un engagement</span> qualité <br />et écoresponsable</h2>
                    <p className='homepage_qualite_text'>Chaque matière première est <label className='bold'>rigoureusement sélectionnée</label> pour répondre aux normes les plus exigeantes. Nous nous engageons à garantir une traçabilité totale et à réduire notre impact environnemental, en privilégiant des méthodes <label className='bold'>respectueuses de la planète.</label></p>
                    <div className='homepage_icon_container'>
                        <img className='' alt="Logo certification qualité" src={icon_qualite1} />
                        <img className='' alt="Logo certification qualité HACCP" src={icon_qualite2} />
                        <img className='' alt="Logo certification qualité synadiet" src={icon_qualite3} />
                        <img className='' alt="Logo certification qualité ecocert" src={icon_qualite4} />
                    </div>
                </div>
                <div className='homepage_atouts_container'>
                    <h3 className='homepage_atouts_title'>Nos atouts</h3>
                    <div className="homepage_atouts_box">
                        {isMobile ? (
                            <div className="carousel_container">
                                <img className="carousel_arrow" alt="Flèche droite" src={icon_arrow_back} onClick={handlePrevAtouts} />
                                <TrumpCard
                                    img={atouts_content[currentIndexAtouts][0]}
                                    title={atouts_content[currentIndexAtouts][1]}
                                    text={atouts_content[currentIndexAtouts][2]}
                                />
                                <img className="carousel_arrow" alt="Flèche gauche" src={icon_arrow_forward} onClick={handleNextAtouts} />
                            </div>
                        ) : (
                            atouts_content.map(([img, title, text], index) => (
                                <TrumpCard key={index} img={img} title={title} text={text} />
                            ))
                        )}
                    </div>
                    <MoreInfo link="*" label={"demandez un devis"} />
                </div>
            </div>
        </div>
    )
}