import React from 'react';

import "../styles/components/positifPoint.css"
import icon_check from '../assets/icons/homepage_check.png'

export default function PositifPoint({ title, content }) {


    return (
        <div className="positifPoint_global-container">
            <div className='positifPoint_content-container'>
                <div className='poisitifPoint_check-container'>
                    <img src={icon_check} />
                </div>
                <div>
                    <h4 className="positifPoint-title orange-color">{title}</h4>
                    <article>{content}</article>
                </div>
            </div>
        </div>
    );
}
