import { useEffect, useState } from "react"

import "../styles/views/niosome.css"

import MoreInfo from "../components/MoreInfo";
import OtherProduct from "../components/OtherProduct";

//icon liposome actif
import left_schema from "../assets/img/niosome_schema.png";
import bottom_schema from "../assets/icons/liposome_botom_schema.png";

//icon otherProduct
import probiotique from "../assets/icons/homepage_probiotique.png";
import liposome from "../assets/icons/homepage_liposome.png";

//icon gamme
import gamme1 from "../assets/icons/vitamine_gamme.png";
import gamme2 from "../assets/icons/acide_gamme.png";
import gamme3 from "../assets/icons/vegetaux_gamme.png";

//icon explication
import schema_right from "../assets/img/schema_right_niosome.png";
import schema_left from "../assets/img/schema_left_niosome.png";

//icon carousel
import icon_arrow_forward from '../assets/icons/arrow_forward.svg';
import icon_arrow_back from '../assets/icons/arrow_back.svg';

import intro_niosome from "../assets/img/intro_niosome.png";
import TabNiosomeProduct from "../components/TabNiosomeProduct"
import TabCatalogue from "../components/TabCatalogue";
import produits from "../assets/ProductContent/data/data.js";


export default function Niosome() {

    const [isMobile, setIsMobile] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? gamme.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === gamme.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1050);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const gamme = [
        [gamme1, "Vitamines & minéraux", "Biotine, Niacinamide,<br/> Vitamines A, C, E, Zinc…"],
        [gamme2, "Acides & PEPTIDES", "Acide hyaluronique,<br/> Collagène, Acide clycolique…"],
        [gamme3, "Végétaux & ANTIOXYDANTS", "Curcumine, Caféine, Cannelle,<br/> Garnica, EGCG, Glutathion…"]
    ];


    return (
        <div>
            <div className="niosome_global-container">
                {isMobile ? (
                    <section className="niosome_intro-container">
                        <h1 className="niosome_intro-title"><label className="bold font-42">Votre fournisseur</label><br /><label className="orange-color">d'actifs niosomés</label></h1>
                        <img className="niosome_intro-img" src={intro_niosome} />
                        <p className="niosome_intro-subtitle"><label className="orange-color">la technologie niosomale </label>pour des cosmétiques hautement efficaces</p>
                        <p className="niosome_intro-text"><label className="bold">La technologie niosomale</label> représente une avancée majeure en cosmétique, offrant une efficacité accrue et <label className="bold">une pénétration ciblée des actifs.</label>
                            <br /><br />
                            Grâce aux <label className="bold">niosomes</label>, de petites structures sphériques capables d'encapsuler les ingrédients, cette technologie garantit une <label className="bold">diffusion profonde</label> et <label className="bold">prolongée des actifs</label> dans les couches cutanées.
                            Elle préserve également leur <label className="bold">stabilité</label> et leur intégrité, même pour les ingrédients les plus délicats.
                            <br /><br />
                            Contrairement aux formulations classiques, les produits cosmétiques enrichis en niosomes offrent une <label className="bold">action rapide,</label>  durable et parfaitement adaptée aux besoins de la peau, tout en respectant sa sensibilité naturelle.
                            <br /><br />
                            Offrez à vos clients <label className="bold">une expérience cosmétique</label> exceptionnelle grâce à cette innovation scientifique et performante.</p>
                        <MoreInfo
                            label={"Voir notre catalogue"}
                            link={"download"}
                        />
                    </section>
                ) : (
                    <section className="niosome_intro-container">
                        <div className="niosome_intro_content-container">
                            <h1 className="niosome_intro-title"><label className="bold font-42">Votre fournisseur</label><br /><label className="orange-color">d'actifs niosomés</label></h1>
                            <p className="niosome_intro-subtitle"><label className="orange-color">la technologie niosomale </label>pour des cosmétiques hautement efficaces</p>
                            <p className="niosome_intro-text"><label className="bold">La technologie niosomale</label> représente une avancée majeure en cosmétique, offrant une efficacité accrue et <label className="bold">une pénétration ciblée des actifs.</label>
                                <br /><br />
                                Grâce aux <label className="bold">niosomes</label>, de petites structures sphériques capables d'encapsuler les ingrédients, cette technologie garantit une <label className="bold">diffusion profonde</label> et <label className="bold">prolongée des actifs</label> dans les couches cutanées.
                                Elle préserve également leur <label className="bold">stabilité</label> et leur intégrité, même pour les ingrédients les plus délicats.
                                <br /><br />
                                Contrairement aux formulations classiques, les produits cosmétiques enrichis en niosomes offrent une <label className="bold">action rapide,</label>  durable et parfaitement adaptée aux besoins de la peau, tout en respectant sa sensibilité naturelle.
                                <br /><br />
                                Offrez à vos clients <label className="bold">une expérience cosmétique</label> exceptionnelle grâce à cette innovation scientifique et performante.</p>
                            <MoreInfo
                                label={"Voir notre catalogue"}
                                link={"download"}
                            />
                        </div>
                        <img className="niosome_intro-img" src={intro_niosome} />
                    </section>
                )}
                <section className="niosome_gamme-container">
                    <h2 className="niosome_gamme-title">une large <label className="orange-color">gamme d’actifs</label></h2>
                    <p className="niosome_gamme-subtitle">Notre laboratoire propose <label className="bold">une gamme complète</label> d’actifs niosomes spécialement formulés pour les cosmétiques. Tous nos ingrédients sont <label className="bold">soigneusement sélectionnés</label> pour répondre aux attentes élevées des professionnels et de leurs consommateurs :</p>
                    <div className="niosome_gamme_icon-container">
                        {isMobile ? (
                            // 📱 Mode Carousel (Mobile)
                            <div className="carousel_container">
                                <img className="carousel_arrow left" src={icon_arrow_back} alt="Flèche gauche" onClick={handlePrev} />

                                <div className="carousel_item">
                                    <img className="niosome_gamme-img" src={gamme[currentIndex][0]} alt={gamme[currentIndex][1]} />
                                    <p className="niosome_gamme-item-title">{gamme[currentIndex][1]}</p>
                                    <p className="niosome_gamme-item-subtitle" dangerouslySetInnerHTML={{ __html: gamme[currentIndex][2] }}></p>
                                </div>

                                <img className="carousel_arrow right" src={icon_arrow_forward} alt="Flèche droite" onClick={handleNext} />
                            </div>
                        ) : (
                            <div className="niosome_gamme_icon-container">
                                <div className="niosome_gamme_icon-item">
                                    <img className="niosome_gamme-img" src={gamme1} />
                                    <p className="niosome_gamme-item-title">Vitamines & minéraux</p>
                                    <p className="niosome_gamme-item-subtitle">Biotine, Niacinamide,<br /> Vitamines A, C, E, Zinc…</p>
                                </div>
                                <div className="niosome_gamme_icon-item">
                                    <img className="niosome_gamme-img" src={gamme2} />
                                    <p className="niosome_gamme-item-title">ACIDES & PEPTIDES</p>
                                    <p className="niosome_gamme-item-subtitle">Acides hyaluronique,<br /> Collagène, Acide clycolique…</p>
                                </div>
                                <div className="niosome_gamme_icon-item">
                                    <img className="niosome_gamme-img" src={gamme3} />
                                    <p className="niosome_gamme-item-title">végétaux & ANTIOXYDANTS</p>
                                    <p className="niosome_gamme-item-subtitle">Curcumine, Caféine, Cannelle,<br /> Garnicia, EGCG, Glutathion…</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <MoreInfo
                        label={"Voir notre catalogue"}
                        link={"download"}
                    />
                </section>
                <section className="niosome_presentation-container">
                    <h2 className="niosome_presentation-title">qu’est-ce qu’un <label className="orange-color">niosome ?</label></h2>
                    <p className="niosome_presentation-text">
                        <label className="bold">Les niosomes</label> sont des systèmes d'encapsulation non ioniques conçus pour <label className="bold">améliorer la libération d'ingrédients actifs dans la peau.</label> Grâce à leur structure unique, ils encapsulent efficacement les ingrédients, permettant <label className="bold">une libération contrôlée</label> et <label className="bold">une meilleure pénétration dans la peau.</label> Il en résulte une augmentation significative de <label className="bold">l'efficacité</label> des produits cosmétiques, tout en assurant une protection optimale <label className="bold">des principes actifs sensibles.</label>
                    </p>
                    <div className="niosome_presentation_schema-container">
                        <div className="niosome_presentation_leftSchema-container">
                            <img className="niosome_presentation_leftSchema-img" src={schema_left} />
                        </div>
                        <div className="niosome_presentation_rightSchema-container">
                            <h3 className="niosome_presentation_rightSchema-title">Les avantages des <label className="orange-color">actifs niosomés</label></h3>
                            <div className="niosome_presentation_rightSchema-img">
                                <img className="niosome_presentation_rightchema-img" src={schema_right} />
                            </div>
                            <p className="niosome_presentation_rightSchema-text">La technologie niosomale offre de nombreux bénéfices pour vos formulations de compléments alimentaires :</p>
                            <p className="niosome_presentation_bulletPoint-text"><label className="bold orange-color upppercase">✓ Puissant, profond et efficace :</label> Une pénétration optimale pour des résultats visibles et durables.</p>
                            <p className="niosome_presentation_bulletPoint-text"><label className="bold orange-color upppercase">✓ Action durable : Libération contrôlée & prolongée :</label> Des actifs qui travaillent pour votre peau tout au long de la journée.</p>
                            <p className="niosome_presentation_bulletPoint-text"><label className="bold orange-color upppercase">✓ Meilleur stabilité des ingrédients actifs :</label> Une formule qui préserve la puissance des actifs pour une efficacité optimale.</p>
                            <p className="niosome_presentation_bulletPoint-text"><label className="bold orange-color upppercase">✓ Biocompatibilité & respectueux de la peau :</label> Des actifs doux et adaptés, même pour les peaux les plus sensibles.</p>
                        </div>
                    </div>
                </section>
                <section className="niosome_listingMp-container">
                    <TabCatalogue produits={produits} type="niosome" />
                </section>
                <section className="niosome_listingMp-container">
                    <TabNiosomeProduct/>
                    <MoreInfo
                        label={"Nous contacter"}
                        link={"*"}
                    />
                </section>
                <section className="liposome_choice_global-container">
                    <h3 className="liposome_choice-title">Pourquoi choisir nos <br /> <label className="orange-color">actifs niosomés</label></h3>
                    <div className="niosome_choice-container-intro">
                        <div className="niosome_choice-container-intro-item">
                            <h3 className="niosome_choice-title-intro">Pour notre <label className="orange-color">expertise</label></h3>
                            <p className="niosome_choice-text-intro">
                                Notre laboratoire est <label className="bold">à la pointe de la recherche</label> et du développement dans le domaine des niosomes.
                                Nous travaillons en étroite collaboration avec des <label className="bold">experts en dermatologie</label> et en formulation cosmétique pour créer des produits qui répondent aux besoins spécifiques de nos clients.
                                Notre processus de développement intègre les <label className="bold">dernières avancées scientifiques</label> pour garantir des résultats sans précédent.
                            </p>
                        </div>
                        <div className="niosome_choice-container-intro-item">
                            <h3 className="niosome_choice-title-intro">Pour nos <label className="orange-color">engagements</label></h3>
                            <p className="niosome_choice-text-intro">
                                <label className="orange-color uppercase bold">✓ innovation </label>: Nous nous engageons à développer des solutions de pointe qui établissent de nouvelles normes dans l’industrie cosmétique.
                                <br/><br/>
                                <label className="orange-color uppercase bold">✓ qualité </label>: Chaque produit est le résultat d'une recherche approfondie et d'un contrôle qualité rigoureux, garantissant sécurité et efficacité.
                                <br /><br />
                                <label className="orange-color uppercase bold">✓ personnalisation </label>:  Nous proposons des services sur mesure pour répondre aux exigences uniques de chaque marque, lui permettant de se démarquer, de la matière première aux produits finis
                            </p>
                        </div>
                    </div>
                    <div className="liposome_choice-2bloc-container">
                        <div className="liposome_choice-leftBloc-container">
                            <h4 className="liposome_choice-leftBloc-title"><label className="orange-color">Production flexible</label> et sur-mesure</h4>
                            <p className="liposome_choice-leftBloc-text">
                                Nous proposons une solution de <label className="bold">fabrication flexible</label>, allant de petites séries à des productions de grande échelle, pour répondre aux besoins spécifiques de chaque marque.
                                <br /><br />
                                Que ce soit en <label className="bold">marque blanche</label> ou en développement sur-mesure, notre savoir-faire garantit une qualité et une efficacité optimales pour vos produits liposomes.
                            </p>
                            <img className="liposome_choice-leftBloc-img" src={left_schema} />
                        </div>
                        <div className="liposome_choice-rightBloc-contaienr">
                            <h4 className="liposome_choice-rightBloc-title">Engagement qualité <br />
                                et certifications reconnues
                            </h4>
                            <p className="liposome_choice-rightBloc-text">Nos normes permettent de garantir une <label className="bold">traçabilité complète</label>, depuis la sélection des matières premières jusqu'au produit final.
                                <br /><br />
                                Nos compléments alimentaires liposomés bénéficient de <label className="bold">certifications internationales</label> attestant de notre <label className="bold">engagement qualité</label> (HACCP, GMP Food, Synadiet).
                            </p>
                        </div>
                    </div>
                    <div className="liposome_choice_bottomBloc-container">
                        <div className="liposome_choice_bottomBloc-description-container">
                            <h4 className="liposome_choice_bottomBloc-description-title">Un accompagnement complet <label className="orange-color">De la<br /> formulation à la mise sur le marché</label></h4>
                            <p className="liposome_choice_bottomBloc-description-text">Notre laboratoire ne se limite pas à la production : nous accompagnons nos clients dans <label className="bold">toutes les étapes de leur projet</label>, depuis la formulation initiale jusqu'à la mise sur le marché.
                                <br /><br />
                                Notre service inclut également un<label className="bold">suivi réglementaire</label>  et une <label className="bold">assistance en packaging</label>, garantissant ainsi la conformité et l’attrait de vos compléments alimentaires pour une <label className="bold">commercialisation rapide et efficace.</label>
                            </p>
                        </div>
                        <img className="liposome_choice_bottomBloc-description-img" src={bottom_schema} />
                    </div>
                </section>
                <MoreInfo
                    label={"Nous contacter"}
                    link={"*"}
                />
                <section className='formule_otherProduct_global-container'>
                    <h3 className='formule_otherCategory-title'><label className='bold'>Nos autres</label><label className='orange-color'> catégories d'ingrédients</label></h3>
                    <OtherProduct
                        title1={"probiotiques & postbiotiques"}
                        subtitle1={"Technologies VPRO® et PROLIPO®"}
                        img1={probiotique}
                        link1={"/niosomes"}
                        title2={"Liposomes"}
                        subtitle2={"Extraits de plantes Vitamines · Minéraux "}
                        img2={liposome}
                        link2={"/liposomes"}
                    />
                </section>
            </div>
        </div>
    );

}