import React from "react";
import "../styles/components/trumpCard.css";

function TrumpCard({ img, title, text }) {
    return (
        <div className="trumpCard_container">
            <img className="trumpCard_img" src={img} alt={title} />
            <h4 className="trumpCard_title">{title}</h4>
            <div className="trumpCard_text">
                {text.map((paragraph, index) => (
                    <p
                        key={index}
                        dangerouslySetInnerHTML={{ __html: paragraph }}
                    ></p>
                ))}
            </div>
        </div>
    );
}

export default TrumpCard;
