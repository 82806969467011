import React, { useState, useEffect } from "react";
import '../styles/components/imageCard.css';

function ImageCard({ img, title, isMobile }) {

    const content = [
        [
            "production",
            ["<label class='bold size-23 test'><label class='orange-color'>3</label> mélangeurs</label>", "— Capacité de 20 kilos à 1 tonne/jour —"],
            ["<label class='bold size-23'><label class='orange-color'>6</label> géluleuses</label>", "— Capacité de 270.000 gélules/heure —"],
            ["<label class='bold size-23'><label class='orange-color'>6</label> brosseuses de gélules</label>"],
            ["<label class='bold size-23'><label class='orange-color'>1</label> remplisseuse liquide</label>", "— De 10 à 150 ml —"]
        ],
        [
            "conditionnement",
            ["<label class='bold size-23'><label class='orange-color'>1</label> ligne de mise en pilulier</label>", "avec bouchage et étiquetage pour flacons en verre, plastique", "— Capacité de 10.000 unités/jour —"],
            ["<label class='bold size-23'><label class='orange-color'>2</label> ligne de blistérisation automatisée</label>", "— Capacité de 60.000 unités/jour —"],
            ["<label class='bold size-23'><label class='orange-color'>2</label> étuyeuses automatiques</label>"]
        ],
        [
            "qualité",
            ["<label class='bold size-23'>Contrôle qualité garanti</label>", "— Dispose de plusieurs certifications —"],
            ["<label class='bold size-23'>Projet norme ISO 22000</label>", "Depuis fin 2022, nous avons entrepris les démarches nécessaires pour l’obtention de la norme ISO 22000."]
        ]
    ];

    // Recherche du contenu en fonction du titre
    const matchingContent = content.find(item => item[0].toLowerCase() === title.toLowerCase());

    return (
        <div
            className="imageCard_global-container"
        >
            <div
                className="imageCard_background hovered"
                style={{
                    backgroundImage: `url(${img})`,
                }}
            ></div>
            <div className="imageCard_overlay">
                    <div className="imageCard_hover-text">
                        <h3 className="imageCard_title">{title}</h3>
                        {matchingContent && (
                            <ul className="imageCard_content">
                                {matchingContent.slice(1).map((line, index) => (
                                    <li
                                        key={index}
                                        dangerouslySetInnerHTML={{
                                            __html: Array.isArray(line)
                                                ? line.join("<br />")
                                                : line,
                                        }}
                                    ></li>
                                ))}
                            </ul>
                        )}
                    </div>
            </div>
        </div>
    );
}

export default ImageCard;
