import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/components/categoryCard.css";

// Icon categories
import iconLiposome from "../assets/icons/homepage_liposome.png";
import iconLiposomeOrange from "../assets/icons/homepage_liposome_orange.png";
import iconNiosome from "../assets/icons/homepage_niosome.png";
import iconNiosomeOrange from "../assets/icons/homepage_niosome_orange.png";
import iconProbiotique from "../assets/icons/homepage_probiotique.png";
import iconProbiotiqueOrange from "../assets/icons/homepage_probiotique_orange.png";

function CategoryCard({ title, subtitle, type, link }) {
    const navigate = useNavigate();
    const [isProbiotique, setIsProbiotique] = useState(false);
    
    // Définir si c'est un probiotique au premier rendu
    useEffect(() => {
        if (type === "probiotique") {
            setIsProbiotique(true);
        }
    }, [type]);

    // Tableau des icônes
    const tab_icon = [
        { type: "liposome", default: iconLiposome, hover: iconLiposomeOrange },
        { type: "niosome", default: iconNiosome, hover: iconNiosomeOrange },
        { type: "probiotique", default: iconProbiotique, hover: iconProbiotiqueOrange },
    ];

    // Trouver les images correspondantes au type
    const icon = tab_icon.find((item) => item.type === type);

    // Gérer dynamiquement l'image de fond de la div enfant
    const [backgroundImage, setBackgroundImage] = useState(icon.default);

    // Fonction de redirection au clic
    const handleClick = () => {
        if (link) {
            window.location.href = link;
        }
    };

    return (
        <div
            className="categorycard_container"
            onMouseEnter={() => setBackgroundImage(icon.hover)}
            onMouseLeave={() => setBackgroundImage(icon.default)}
            onClick={handleClick}
        >
            <div
                className={`categorycard_img ${isProbiotique ? "width-80" : ""}`}
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                }}
            ></div>
            <h3 className="categorycard_title">{title}</h3>
            <p className="categorycard_subtitle">{subtitle}</p>
        </div>
    );
}

export default CategoryCard;
