import React, { useState, useEffect } from "react";
import "../styles/components/mockupBanner.css";

import MoreInfo from "./MoreInfo";

export default function MockupBanner({ product }) {
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 475); // Initialisation propre

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setIsMobile(window.innerWidth < 475); // Mise à jour dynamique
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div
            className="mockupBanner_global-container"
            style={{
                width: width < 610 ? `${width - 60}px` : "auto",
            }}
        >
            {isMobile ? (
                // 📱 Version mobile
                <div
                    className="mockupBanner_mobile_global-container"
                    style={{
                        width: width < 610 ? `${width - 60}px` : "auto",
                    }}
                >
                    <div className="mockupBanner_mobile-container">
                        <img src={product[2]} className="mockupBanner-img" />
                        <div className="mockupBanner_description-container">
                            <p className="mockupBanner-subtitle">{product[0]}</p>
                            <div className="mockupBanner-horizontal-bar"></div>
                            <p className="mockupBanner-title">{product[1]}</p>
                            <p className="mockupBanner-text">Contient cet actif</p>
                        </div>
                    </div>
                    <MoreInfo label={"En savoir +"} link={"*"} />
                </div>
            ) : (
                // 🖥 Version Desktop
                <>
                    <img src={product[2]} className="mockupBanner-img" />
                    <div className="mockupBanner_description-container">
                        <p className="mockupBanner-subtitle">{product[0]}</p>
                        <div className="mockupBanner-horizontal-bar"></div>
                        <p className="mockupBanner-title">{product[1]}</p>
                        <p className="mockupBanner-text">Contient cet actif</p>
                        <MoreInfo label={"En savoir +"} link={"*"} />
                    </div>
                </>
            )}
        </div>
    );
}
