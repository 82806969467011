import acerola from "../img/acerola_icon.png";
import alphaLipoique from "../img/acide-alpha-lipoique_icon.png";
import apigenine from "../img/apigenine_icon.png";
import berberine from "../img/berberine_icon.png";
import diosmine from "../img/diosmine_icon.png";
import gaba from "../img/gaba_icon.png";
import keratine from "../img/keratine_icon.png"
import lArginine from "../img/l-arginine_icon.png"
import lLysine from "../img/l-lysine_icon.png";
import nadh from "../img/nadh_icon.png"
import pea from "../img/pea_icon.png"
import quercetine from "../img/quercetine_icon.png"
import rutine from "../img/rutine_icon.png"
import andrographis from "../img/andrographis_icon.png"
import ashwagandha from "../img/ashwagandha_icon.png"
import boswellia from "../img/boswellia_icon.png"
import cafeine from "../img/cafeine_icon.png"
import calcium from "../img/calcium_icon.png"
import chardonMarie from "../img/chardon-marie_icon.png"
import chrome from "../img/chrome_icon.png"
import complexeVitamineB from "../img/complexe-vitamines-b_icon.png"
import cuivre from "../img/cuivre_icon.png"
import curcuma from "../img/curcuma_icon.png"
import epaDha from "../img/epa-dha_icon.png"
import fer from "../img/fer_icon.png"
import vitaminesK2d3 from "../img/vitamines-k2d3_icon.png"
import lTheanine from "../img/l-theanine_icon.png"
import lycopène from "../img/l-ycopene_icon.png"
import magnesium from "../img/magnesium_icon.png"
import manganese from "../img/manganese_icon.png"
import multivitamines from "../img/multivitamines_icon.png"
import pepinsDeRaisin from "../img/opc-pepins-de-raisin_icon.png"
import preleDesChamps from "../img/prele-des-champs_icon.png"
import rhodiola from "../img/rhodiola_icon.png"
import scutellaireDuBaikal from "../img/scutellaire-du-baikal_icon.png"
import selenium from "../img/selenium_icon.png"
import vigneRouge from "../img/vigne-rouge_icon.png"
import vitamineA from "../img/vitamine-a_icon.png"
import vitamineB1 from "../img/vitamine-b1_icon.png"
import vitamineB3 from "../img/vitamine-b3_icon.png"
import vitamineB5 from "../img/vitamine-b5_icon.png"
import vitamineB6 from "../img/vitamine-b6_icon.png"
import vitamineB9 from "../img/vitamine-b9_icon.png"
import vitamineB12 from "../img/vitamine-b12_icon.png"
import vitamineD3 from "../img/vitamine-d3_icon.png"
import hyaluronique from "../img/acide-hyaluronique_icon.png"
import astaxanthine from "../img/astaxanthine_icon.png"
import coenzymeQ10 from "../img/coenzyme-q10_icon.png"
import collagene from "../img/collagene_icon.png"
import glutathion from "../img/glutathion_icon.png"
import biotine from "../img/biotine_icon.png"
import melatonine from "../img/melatonine_icon.png"
import resveratrol from "../img/resveratrol_icon.png"
import vitamineC from "../img/vitamine-c_icon.png"
import vitamineE from "../img/vitamine-e_icon.png"
import zinc from "../img/zinc_icon.png"
import azelaique from "../img/acide-azelaique_icon.png"
import ferulique from "../img/acide-ferulique_icon.png"
import glycolique from "../img/acide-glycolique_icon.png"
import salicyclique from "../img/acide-salicylique_icon.png"
import tranexalique from "../img/acide-tranexamique_icon.png"
import allantoine from "../img/allantoine_icon.png"
import alphaArbutine from "../img/alpha-arbutine_icon.png"
import bakuchiol from "../img/bakuchiol_icon.png"
import beurreDeKarite from '../img/beurre-de-karite_icon.png'
import cannelle from "../img/cannelle_icon.png"
import ceramide from "../img/ceramide_icon.png"
import curumine from "../img/curcumine_icon.png"
import egcg from "../img/egcg_icon.png"
import garciniaCambodgia from '../img/garcinia-cambogia_icon.png'
import niacinamide from '../img/niacinamide_icon.png'
import panthenol from '../img/panthenol_icon.png'
import retinol from '../img/retinol_icon.png'
import squalene from '../img/squalene_icon.png'
import vitamineAUree from '../img/vitamine-a-uree_icon.png'
import niacinamideZinc from '../img/serum-niacinamide-zinc_icon.png'
import glycoliqueBakuchiol from '../img/serum-acide-glycolique-bakuchiol_icon.png'
import hyaluroniqueVitamineB5 from '../img/serum-hyaluronique-vitamine-b5_icon.png'
import cafeineEgcg from '../img/serum-cafeine-egcg_icon.png'
import vitamineCAcideHyaluronique from '../img/serum-vitamine-c-acide-hyaluronique_icon.png'
import acideHyaluronique from '../img/acide-hyaluronique_icon.png'
import astaxanthine2 from '../img/astaxanthine_icon2.png'
import biotine2 from '../img/biotine_icon2.png';
import coenzymeQ10_2 from '../img/coenzyme-q10_icon2.png'
import collagene2 from "../img/collagene_icon.png"
import glutathion2 from "../img/glutathion_icon2.png"
import melatonine2 from "../img/melatonine_icon2.png"
import vitamineC2 from "../img/vitamine-c_icon2.png"
import vitamineE2 from "../img/vitamine-e_icon2.png"
import zinc2 from "../img/zinc_icon2.png";



import mockupZinc from "../img/mockup-zinc.png";
import mockupVitamineC from "../img/mockup-vitamine-c.png";
import mockupResveratrol from "../img/mockup-resveratrol.png";
import mockupBiotine from "../img/mockup-biotine.png";
import mockupCoenzymeQ10 from "../img/mockup-coenzyme-q10.png";
import mockupHyaluronique from "../img/mockup-hyaluronique.png";
import mockupvitamineD3 from "../img/mockup-vitamine-d3.png";
import mockupVitamineB12 from "../img/mockup-vitamine-b12.png";
import mockupImmunite from "../img/mockup-immunite.png";
import mockupMultivitamines from "../img/mockup-multivitamines.png";
import mockupMagnesium from "../img/mockup-magnesium.png"
import mockupVitamineK2d3 from "../img/mockup-vitamine-k2d3.png";
import mockupFerComplex from "../img/mockup-fer-complex.png";
import mockupArticulation from "../img/mockup-articulation.png";
import mockupCuivre from "../img/mockup-cuivre.png";
import mockupVitamineBComplex from "../img/mockup-vitamine-b-complex.png";
import mockupChrome from "../img/mockup-chrome.png";
import mockupDetox from "../img/mockup-detox.png";
import mockupCalcium from "../img/mockup-calcium.png";
import mockupEnergie from "../img/mockup-energie.png";
import mockupAntiStress from "../img/mockup-anti-stress.png";
import mockupVoiesRespiratoires from "../img/mockup-voies-respiratoires.png";
import mockupSommeil from "../img/mockup-sommeil.png";
import mockupJetLag from "../img/mockup-jetlag.png";
import mockupCapillaire from "../img/mockup-capillaire.png";
import mockupGlutathion from "../img/mockup-glutathion.png"
import mockupCirculation from "../img/mockup-circulation.png";
import mockupMinceur from "../img/mockup-minceur.png"

const produits = [
    {
        nom: "acérola",
        categorie: ["végétal"],
        description: `
            <label class='bold'>L’acérola liposomée</label> est une forme optimisée de ce superfruit naturellement 
            <label class='bold'>riche en vitamine C</label>, reconnue pour son <label class='bold'>pouvoir antioxydant</label> 
            et son rôle clé dans le soutien du système immunitaire.</br> </br>
            Grâce à la technologie liposomale, la vitamine C et les autres nutriments de l’acérola gagnent en 
            <label class='bold'>biodisponibilité</label>, permettant une absorption supérieure et une 
            <label class='bold'>meilleure efficacité</label> dans les compléments alimentaires.</br> </br>
            L’acérola liposomé est particulièrement apprécié pour contribuer à la 
            <label class='bold'>réduction de la fatigue et soutenir les défenses naturelles</label> de l’organisme. 
            Sa haute teneur en vitamine C participe également à la protection des cellules contre le stress oxydatif, 
            favorisant ainsi une vitalité optimale et un bien-être général.</br> </br>
            Reconnue pour sa richesse en micronutriments, l’acérola fait partie des actifs phares dans les formulations 
            dédiées à <label class='bold'>l’immunité</label>, à <label class='bold'>l’énergie</label> et à la 
            <label class='bold'>protection cellulaire</label>.<br/> 
            Optez pour l’acérola liposomé dans vos compléments alimentaires pour offrir à vos clients une 
            <label class='bold'>solution innovante</label>, soutenant leurs défenses immunitaires et leur vitalité au quotidien.
        `,
        img: acerola,
        type: "liposome",
        product: false,
        bienfaits: [
            { titre: "Soutien immunitaire", description: "Riche en vitamine C, idéale pour soutenir l’immunité." },
            { titre: "Action antioxydante", description: "Aide à la mémoire, à l’apprentissage et au rappel. Soutient la fonction mentale, notamment chez les personnes âgées. Facilite la récupération après des événements stressants." },
            { titre: "Production de collagène", description: "Protège la santé générale grâce à ses propriétés antioxydantes. Offre un effet rajeunissant global." },
            { titre: "Soutien énergétique", description: "Contribue à maintenir la santé de la peau." },
            { titre: "Absorption du fer", description: "Soutient la fonction sexuelle masculine et la mobilité des spermatozoïdes. Aide à maintenir la santé des organes reproducteurs féminins." }
        ],
        url: "/liposomes/acerola"
    },
    {
        nom: "acide alpha-lipoïque",
        categorie: ["coenzyme"],
        description: `
            <label class='bold'>L'acide alpha-lipoïque</label> est un <label class='bold'>antioxydant</label> multifonctionnel reconnu pour ses propriétés uniques, 
            intervenant directement dans le <label class='bold'>métabolisme énergétique cellulaire</label>.</br> </br>
            Naturellement produit par le corps en petites quantités, il est essentiel pour les processus biochimiques associés à la production d'énergie, 
            tout en favorisant l'équilibre des niveaux <label class='bold'>d'antioxydants</label>, tels que <label class='bold'>les vitamines C et E</label>.</br> </br> 
            Soluble à la fois dans l'eau et les lipides, l'acide alpha-lipoïque atteint facilement les cellules et les tissus, 
            ce qui en fait un ingrédient recherché dans les compléments alimentaires pour les formulations visant l'optimisation 
            énergétique et le soutien cellulaire global.</br> </br>
            Grâce à sa forme <label class='bold'>liposomée, l'acide alpha-lipoïque</label> est mieux absorbé par l'organisme, garantissant une efficacité accrue 
            et une <label class='bold'>biodisponibilité</label> optimale.</br> </br> 
            Ses bienfaits antioxydants en font un choix idéal pour les consommateurs soucieux de 
            leur <label class='bold'>santé</label> et de leur <label class='bold'>vitalité</label>.
        `,
        img: alphaLipoique,
        type: "liposome",
        product: false,
        bienfaits: [
            { titre: "Protection antioxydante", description: "L'acide alpha-lipoïque combat les radicaux libres, réduisant ainsi le stress oxydatif et protégeant les cellules des dommages." },
            { titre: "Soutien du métabolisme", description: " Il joue un rôle clé dans la production d'énergie cellulaire, en facilitant la conversion des nutriments en énergie." },
            { titre: "Régulation de la glycémie", description: "L'acide alpha-lipoïque aide à améliorer la sensibilité à l'insuline et à réguler les niveaux de sucre dans le sang." },
            { titre: "Soutien du système nerveux", description: "Il protège les cellules nerveuses contre les dommages et peut aider à réduire les symptômes liés aux neuropathies." },
            { titre: "Amélioration de la santé du cœur", description: "En réduisant l'inflammation et le stress oxydatif, il soutient la santé cardiovasculaire." },
            { titre: "Effet anti-âge", description: "Grâce à sa capacité à régénérer d'autres antioxydants comme la vitamine C et la vitamine E, il combat les signes du vieillissement cellulaire." },
            { titre: "Soutien de la fonction hépatique", description: "Il aide à détoxifier le foie et à améliorer sa fonction." },
            { titre: "Amélioration de la peau", description: "En tant qu'antioxydant puissant, il peut contribuer à réduire les dommages causés par les rayons UV et améliorer l'apparence de la peau." },
            { titre: "Renforcement des vaisseaux sanguins", description: "Il soutient la santé des vaisseaux sanguins et améliore la circulation sanguine." }
        ],
        url: "/liposomes/alpha-lipoique"
    },
    {
        nom: "apigenine",
        categorie: ["végétal"],
        description: `
            <label class='bold'>L'apigénine liposomée</label> est un flavonoïde puissant aux multiples bienfaits. Grâce à sa <label class='bold'>technologie liposomale</label>, l'absorption de l'apigénine est optimisée, offrant une efficacité maximale dans l'organisme.</br>
            Cet ingrédient est reconnu pour ses propriétés <label class='bold'>antioxydantes</label>, aidant à protéger les cellules contre les dommages causés par <label class='bold'>le stress oxydatif</label>.</br> </br>

            <label class='bold'>L'apigénine</label> est également un allié pour soutenir <label class='bold'>la santé cardiovasculaire</label>, en favorisant une circulation sanguine optimale. </br>
            Elle contribue également à renforcer le <label class='bold'>système nerveux</label>, grâce à ses effets <label class='bold'>neuroprotecteurs</label> qui aident à préserver <label class='bold'>la santé cognitive</label> et à réduire <label class='bold'>le stress</label>.</br> </br>

            En outre, l'apigénine joue un rôle essentiel dans l'amélioration de <label class='bold'>la qualité du sommeil</label> en agissant sur les récepteurs du cerveau associés à <label class='bold'>la relaxation</label>. </br> </br>

            En choisissant l'apigénine liposomée pour vos formulations de compléments alimentaires, vous offrez à vos clients un ingrédient <label class='bold'>de haute qualité</label>, qui allie <label class='bold'>efficacité</label> et <label class='bold'>biodisponibilité supérieure</label>.
        `,
        img: apigenine,
        type: "liposome",
        product: false,
        bienfaits: [
            { titre: "Effet antioxydant", description: "Elle combat le stress oxydatif en neutralisant les radicaux libres, protégeant les cellules et tissus corporels." },
            { titre: "Soutien du système nerveux", description: " L'apigénine favorise la santé cognitive et possède des effets neuroprotecteurs qui aident à préserver la fonction du cerveau." },
            { titre: "Amélioration du sommeil", description: "Elle agit sur les récepteurs du cerveau pour favoriser la relaxation et améliorer la qualité du sommeil." },
            { titre: "Soutien cardiovasculaire", description: "L'apigénine contribue à la réduction de l'inflammation des vaisseaux sanguins, améliorant ainsi la circulation et soutenant la santé cardiaque." },
            { titre: "Soutien à la gestion du stress", description: "Ses effets calmants et relaxants aident à réduire les symptômes de stress et d'anxiété." },
        ],
        url: "/liposomes/apigenine"
    },
    {
        nom: "berbérine",
        categorie: ["végétal"],
        description: `
            <label class='bold'>La berbérine</label> est un alcaloïde naturel extrait principalement de plantes de la famille des Berberidaceae (comme l'épine-vinette). <br/>
            Elle est particulièrement appréciée pour son action bénéfique sur le métabolisme, notamment en contribuant au maintien d'une <label class='bold'>glycémie normale</label> et d'un <label class='bold'>taux de lipides équilibrés</label> dans l'organisme. <br/> <br/>
            Grâce à ses <label class='bold'>propriétés antimicrobiennes</label>, la berbérine soutient également la <label class='bold'>santé intestinale</label>, favorisant un équilibre de la flore digestive et renforçant ainsi les <label class='bold'>défenses naturelles</label>. Elle contribue au bien-être global en soutenant la <label class='bold'>santé cardiovasculaire</label> et en aidant à réguler les fonctions métaboliques. <br/> <br/>
            Grâce à sa forme liposomée, la berbérine bénéficie  d’une <label class='bold'>meilleure absorption</label> par l’organisme, garantissant ainsi une efficacité optimale.<br/><br/>
            De plus, la berbérine peut agir comme un <label class='bold'>antioxydant</label>, protégeant les cellules du stress oxydatif et participant à la lutte contre le <label class='bold'>vieillissement prématuré</label>.<br/><br/>
            Idéale pour les formulations visant à soutenir la <label class='bold'>santé métabolique</label>, à <label class='bold'>renforcer le système immunitaire</label> et à maintenir un équilibre intestinal sain, la berbérine s'impose comme un actif naturel aux nombreux bienfaits pour un corps en pleine forme.
        `,
        img: berberine,
        type: "liposome",
        product: ["gamme beauté", "minceur", mockupMinceur],
        bienfaits: [
            { titre: "Santé métabolique", description: "Régule les niveaux de glucose sanguin, en favorisant une meilleure sensibilité à l'insuline. Améliore la gestion des lipides, en aidant à maintenir les niveaux optimaux de cholestérol et de triglycérides. Soutient le métabolisme énergétique, contribuant à une meilleure utilisation des nutriments." },
            { titre: "Équilibre digestif", description: "Réduit les rides et améliore l'élasticité cutanée." },
            { titre: "Santé cardiovasculaire", description: "Lubrifie et protège les articulations." },
            { titre: "Propriétés antioxydantes", description: "Neutralise les radicaux libres pour protéger les cellules des dommages oxydatifs. Ralentit les effets du vieillissement prématuré, en soutenant la régénération cellulaire." },
            { titre: "Bien-être général", description: "Soutient l'équilibre global de l'organisme, en agissant comme un allié multifonctionnel. Aide à maintenir la vitalité et la résistance physique, en particulier chez les personnes actives." },
        ],
        url: "/liposomes/berberine"
    },
    {
        nom: "disomine",
        categorie: ["antioxydant"],
        description: `
            <label class='bold'>La diosmine liposomée</label> est une forme avancée de diosmine, un flavonoïde naturel, encapsulée dans des liposomes pour améliorer son <label class='bold'>absorption></label> et <label class='bold'>son efficacité</label>.<br/><br/>

            Utilisée dans les compléments alimentaires, elle est particulièrement bénéfique pour soutenir <label class='bold'>la santé circulatoire</label> et réduire <label class='bold'>les symptômes liés à une mauvaise circulation sanguine</label>. <br/><br/>

            En tant que matière première, la diosmine liposomée présente un grand potentiel dans la formulation de produits destinés à améliorer <label class='bold'>la santé vasculaire</label>, favoriser <label class='bold'>une circulation sanguine optimale</label> et soulager les inconforts liés aux <label class='bold'>jambes lourdes</label> ou à la sensation de pesanteur.<br/><br/>

            En raison de sa structure liposomale, la diosmine bénéficie d’une meilleure <label class='bold'>biodisponibilité</label>, permettant <label class='bold'>une libération plus efficace dans l’organisme</label>. <br/><br/>

            Elle est ainsi idéale pour des formulations ciblant les problématiques de <label class='bold'>circulation sanguine</label>, mais aussi pour des produits contribuant à <label class='bold'>la réduction des œdèmes ou des varices</label>.
        `,
        img: diosmine,
        type: "liposome",
        product: ["gamme bien-être", "circulation", mockupCirculation],
        bienfaits: [
            { titre: "Amélioration de la circulation sanguine", description: "Elle aide à renforcer les parois des vaisseaux sanguins et à réduire les symptômes de mauvaise circulation." },
            { titre: "Réduction des sensations de jambes lourdes", description: "Réduction des sensations de jambes lourdes. En favorisant la circulation, elle diminue l’inconfort lié à la pesanteur et la fatigue des jambes." },
            { titre: "Protection des capillaires", description: "Elle contribue à maintenir la santé des petits vaisseaux sanguins en réduisant leur perméabilité" },
        ],
        url: "/liposomes/disomine"
    },
    {
        nom: "gaba",
        categorie: ["acide aminé"],
        description: `
        <label class='bold'>Le GABA</label> est un neurotransmetteur naturellement présent dans le cerveau, essentiel pour réguler <label class='bold'>l'activité nerveuse</label> et maintenir <label class='bold'>un équilibre optimal</label> entre excitation et relaxation <label class='bold'>des cellules neuronales</label>.<br/><br/>

            En tant qu'actif pris dans <label class='bold'>les compléments alimentaires</label>, il est utilisé pour ses effets modulants sur les réponses <label class='bold'>du système nerveux</label>, contribuant ainsi à favoriser <label class='bold'>le calme</label> et <label class='bold'>la détente</label>.<br/><br/>

            En raison de sa structure liposomale, le <label class='bold'>GABA</label> bénéficie d’une meilleure biodisponibilité, permettant une libération plus efficace dans l’organisme. <br/><br/>

            Adapté aux formulations visant <label class='bold'>le bien-être mental</label> et <label class='bold'>la gestion du stress, le GABA</label> soutient une relaxation sans somnolence, ce qui en fait un élément apprécié pour le soutien quotidien.
        `,
        img: gaba,
        type: "liposome",
        product: ["gamme bien-être", "Anti-stress", mockupAntiStress],
        bienfaits: [
            { titre: "Réduction du stress et de l'anxiété", description: "Réduction du stress et de l'anxiété, réduction des symptômes de l'angoisse, Amélioration de la gestion du stress chronique" },
            { titre: "Amélioration du bien-être mental et émotionnel", description: "Support pour la santé mentale, amélioration de la concentration et de la mémoire, régulation de l’humeur" },
            { titre: "Amélioration du sommeil et de la relaxation", description: "Amélioration du sommeil, soutien à la relaxation musculaire, " },
            { titre: "Gestion du poids et réduction de la fatigue", description: "Aide à la gestion du poids, réduction de la fatigue" },
            { titre: "Effets sur l'équilibre hormonal", description: "Effets sur l'équilibre hormonal, Soutien à l’équilibre hormonal (régulation du cortisol) " },
        ],
        url: "/liposomes/gaba"
    },
    {
        nom: "kératine",
        categorie: ["protéine"],
        description: `
            <label class='bold'>La kératine liposomée</label> est une forme innovante de cette protéine essentielle, réputée pour ses bienfaits sur la santé et l’apparence <label class='bold'>des cheveux</label> et <label class='bold'>des ongles</label>. <br><br>

            <label class='bold'>Grâce à la technologie liposomale</label>, les actifs de la kératine sont mieux assimilés par l’organisme, offrant une efficacité renforcée pour <label class='bold'>protéger, réparer et renforcer</label> les structures capillaires.<br><br>

            Cette protéine, naturellement présente dans les cheveux et les ongles, agit comme un bouclier protecteur contre les agressions extérieures tout en <label class='bold'>favorisant leur croissance, leur brillance et leur résistance</label>. 

            La kératine liposomée est idéale pour les formulations visant à restaurer la vitalité <label class='bold'>des cheveux abîmés, réduire les cassures</label> et améliorer <label class='bold'>la santé générale des ongles</label>.

            Optez pour la kératine liposomée dans vos compléments alimentaires pour offrir à vos clients une solution innovante et efficace pour des <label class='bold'>cheveux éclatants</label> et <label class='bold'>des ongles forts</label>.
        `,
        img: keratine,
        type: "liposome",
        product: ["gamme beauté", "Capillaire", mockupCapillaire],
        bienfaits: [
            { titre: "Réduction du stress et de l'anxiété", description: "Renforce la structure des cheveux, réduisant les cassures. Favorise la brillance et la douceur des cheveux.<br/>Stimule la croissance capillaire pour des cheveux plus épais et volumineux. Protège contre les agressions extérieures, comme la pollution et les produits chimiques." },
            { titre: "Santé des ongles", description: "Améliore la résistance et réduit les cassures des ongles. Favorise une croissance saine et régulière des ongles. " },
            { titre: "Réparation et protection", description: "Restaure la vitalité des cheveux abîmés par les traitements chimiques ou la chaleur. Protège les cheveux et les ongles des agressions quotidiennes." },
            { titre: "Beauté et esthétique", description: "Contribue à une apparence saine et soignée des cheveux et des ongles. Idéal pour une routine beauté complète et naturelle." },
        ],
        url: "/liposomes/keratine"
    },
    {
        nom: "L-arginine",
        categorie: ["acide aminé"],
        description: `
            <label class='bold'>La L-Arginine</label> est <label class='bold'>un acide aminé</label> essentiel pour de nombreuses fonctions biologiques, notamment <label class='bold'>la santé cardiovasculaire</label> et le soutien des <label class='bold'>performances physiques</label>. Elle joue un rôle important dans la production de monoxyde d'azote, un composé qui aide à dilater <label class='bold'>les vaisseaux sanguins</label>, favorisant ainsi <label class='bold'>une circulation optimale</label>.
            <br><br>
            Connue pour améliorer <label class='bold'>la vitalité, la récupération musculaire</label>, et <label class='bold'>les performances sportives</label>, la L-Arginine contribue également au maintien de <label class='bold'>l’équilibre énergétique</label> et <label class='bold'>du bien-être général</label>.
            <br><br>
            En raison de sa structure liposomale, la L-Arginine bénéficie d’une meilleure biodisponibilité, permettant une libération <label class='bold'>plus efficace</label> dans l’organisme. 
            <br><br>
            Idéale pour les formulations axées sur <label class='bold'>la santé cardiovasculaire, la performance sportive</label>, ou le soutien d’une vie active, la L-Arginine est un ingrédient de choix pour des compléments alimentaires innovants et efficaces.
        `,
        img: lArginine,
        type: "liposome",
        product: false,
        bienfaits: [
            { titre: "Santé cardiovasculaire", description: "Contribue à la production de monoxyde d’azote (NO), favorisant la dilatation des vaisseaux sanguins. Améliore la circulation sanguine et soutient la santé des artères. Peut aider à réduire la pression artérielle. " },
            { titre: "Performance physiques et sportives", description: "Améliore les performances physiques en augmentant l’apport en oxygène et en nutriments aux muscles. Favorise la récupération musculaire après l’effort. Soutient la force et l’endurance pendant les activités physiques. " },
            { titre: "Santé sexuelle", description: "Favorise une meilleure circulation sanguine, bénéfique pour la fonction sexuelle chez les hommes et les femmes. Peut contribuer à améliorer la fertilité masculine en soutenant la qualité du sperme." },
            { titre: "Renforcement du système immunitaire", description: "Participe au soutien des défenses immunitaires grâce à son rôle dans la réparation des tissus et la production d’hormones." },
            { titre: "Bien-être général", description: "Soutient le métabolisme énergétique et la production de protéines. Favorise la cicatrisation et la régénération des tissus." },
            { titre: "Santé métabolique", description: "Aide à maintenir un équilibre hormonal et une fonction métabolique optimale." },
        ],
        url: "/liposomes/l-arginine"
    },
    {
        nom: "L-lysine",
        categorie: ["acide aminé"],
        description: `
            <label class='bold'>La L-Lysine</label> est un acide aminé essentiel, reconnu pour son rôle clé dans le renforcement <label class='bold'>du système immunitaire, la santé des tissus et la production de collagène</label>. Puisque le corps humain ne peut pas <label class='bold'>la synthétiser</label>, elle doit être apportée par <label class='bold'>l’alimentation</label> ou des <label class='bold'>compléments alimentaires</label>.
            <br><br>
            <label class='bold'>La L-Lysine</label> soutient la croissance et <label class='bold'>la réparation des tissus, renforce les os, favorise la santé de la peau et peut aider à prévenir les infections virales</label>, notamment les boutons de fièvre. Cet ingrédient polyvalent est particulièrement prisé dans les formulations visant à soutenir <label class='bold'>la santé globale, renforcer l’immunité, et promouvoir une récupération musculaire optimale</label>.
            <br><br>
            En raison de sa structure liposomale, la L-Lysine bénéficie d’une meilleure biodisponibilité, permettant une <label class='bold'>libération plus efficace</label> dans l’organisme. 
            <br><br>
            Intégrez la <label class='bold'>L-Lysine</label> dans vos <label class='bold'>compléments alimentaires</label> pour offrir à vos clients une solution efficace et naturelle pour soutenir leur bien-être quotidien.     
        `,
        img: lLysine,
        type: "liposome",
        product: false,
        bienfaits: [
            { titre: "Soutien immunitaire", description: "Aide à renforcer le système immunitaire. Contribue à prévenir les infections virales, notamment les boutons de fièvre." },
            { titre: "Santé des tissus et du collagène", description: "Favorise la production de collagène, essentiel pour la santé de la peau, des os, et des articulations.Aide à réparer et régénérer les tissus." },
            { titre: "Santé osseuse", description: "Soutient l’absorption et la rétention du calcium dans les os. Contribue au maintien d’une ossature solide." },
            { titre: " Performance et récupération musculaire", description: "Aide à la récupération musculaire après un effort physique intense. Soutient la croissance et la réparation musculaires." },
            { titre: "Santé de la peau", description: "Participe à la régénération de la peau et favorise une apparence saine." },
            { titre: "Énergie et métabolisme", description: "Soutient le métabolisme énergétique en facilitant la production de protéines" },
        ],
        url: "/liposomes/l-lysine"
    },
    {
        nom: "Nadh",
        categorie: ["coenzyme"],
        description: `
            <label class='bold'>Le NADH</label> (nicotinamide adénine dinucléotide hydrogéné) est un coenzyme essentiel impliqué dans la production <label class='bold'>d’énergie cellulaire</label>. Il joue un rôle clé dans <label class='bold'>le métabolisme énergétique</label>, en favorisant la conversion des nutriments en énergie utilisable par les cellules.
            <br><br>
            Reconnu pour ses effets positifs sur <label class='bold'>la vitalité</label> et <label class='bold'>la résistance à la fatigue</label>, le NADH soutient également <label class='bold'>les fonctions cognitives</label>, telles que <label class='bold'>la concentration, la mémoire et la clarté mentale</label>. 
            <br><br>
            En tant <label class='bold'>qu’antioxydant</label> puissant, il protège <label class='bold'>les cellules</label> contre les dommages oxydatifs, contribuant ainsi au bien-être général et au ralentissement des effets du vieillissement.
            <br><br>
            Grâce à  sa forme liposomée, le NADH bénéficient d’une meilleure absorption par l’organisme, garantissant ainsi une <label class='bold'>efficacité optimale</label>.
            <br><br>
            Idéal pour les formulations visant à améliorer <label class='bold'>l’énergie naturelle</label>, optimiser <label class='bold'>les performances mentales</label>, et renforcer <label class='bold'>la résilience physique, le NADH</label> est un ingrédient innovant pour répondre aux besoins des personnes actives et exigeantes.
        `,
        img: nadh,
        type: "liposome",
        product: ["gamme bien-être", "Jet lag", mockupJetLag],
        bienfaits: [
            { titre: "Énergie et métabolisme", description: "Stimule la production d’énergie cellulaire en soutenant le métabolisme énergétique. Aide à réduire la fatigue et améliore la vitalité quotidienne. Favorise une endurance accrue, particulièrement chez les personnes actives ou en période de stress physique." },
            { titre: "Fonctions cognitives", description: "Améliore la mémoire, la concentration, et la clarté mentale. Soutient les performances intellectuelles en période de stress ou de forte sollicitation cognitive." },
            { titre: "Propriétés antioxydantes", description: "Protège les cellules contre les dommages oxydatifs, ralentissant le processus de vieillissement. Renforce les défenses naturelles contre les effets des radicaux libres." },
            { titre: "Santé globale", description: "Contribue à améliorer la résilience physique et mentale. Favorise une récupération plus rapide après des efforts physiques ou mentaux." },
            { titre: "Soutien au vieillissement", description: "Aide à préserver les fonctions cellulaires optimales en ralentissant les effets du vieillissement prématuré. Participe à la régénération des cellules pour un bien-être prolongé." },
        ],
        url: "/liposomes/nadh"
    },
    {
        nom: "Pea",
        categorie: ["végétal"],
        description: `
            <label class='bold'>Le PEA liposomé</label> (palmitoyléthanolamide) est un lipide endogène naturellement présent dans le corps, reconnu pour ses propriétés <label class='bold'>neuroprotectrices</label>. Il joue un rôle clé dans le soutien des réponses naturelles de l’organisme <label class='bold'>au stress</label>, favorisant un équilibre cellulaire optimal.
            <br><br>
            En régulant les mécanismes associés à <label class='bold'>la douleur</label>, le PEA liposomé est particulièrement prisé dans les formulations de compléments alimentaires destinées au soutien du <label class='bold'>système nerveux</label>, à la gestion des <label class='bold'>douleurs chroniques</label>, et à l’amélioration du bien-être général.
            <br><br>
            Grâce à son origine naturelle et à sa tolérance élevée, le PEA constitue un ingrédient polyvalent, idéal pour des produits ciblant <label class='bold'>les articulations, le confort musculaire, et le bien-être global</label>. 
            <br><br>
            En raison de sa structure liposomale, le PEA bénéficie d’une <label class='bold'>meilleure biodisponibilité</label>, permettant une libération plus efficace dans l’organisme. 
        `,
        img: pea,
        type: "liposome",
        product: ["gamme bien-être", "Sommeil", mockupSommeil],
        bienfaits: [
            { titre: "Gestion de la douleur", description: "Aide à soulager les douleurs chroniques, notamment celles associées aux articulations et aux muscles. Soutient les réponses naturelles de l’organisme face aux douleurs inflammatoires." },
            { titre: "Soutien du système nerveu", description: "Protège les neurones et soutient la neuroprotection en cas de stress oxydatif. Aide à réduire les douleurs neuropathiques et les inconforts nerveux." },
            { titre: "Bien-être général", description: "Contribue à une amélioration du confort global, notamment en cas de douleurs musculaires ou articulaires. Favorise un bien-être quotidien sans effets secondaires indésirables." },
            { titre: "Soutien immunitaire", description: "Renforce les réponses immunitaires naturelles, aidant à maintenir l’organisme en bonne santé." },
            { titre: "Tolérance et sécurité", description: "Bénéficie d’une excellente tolérance, même en cas d’utilisation prolongée." },
        ],
        url: "/liposomes/pea"
    },
    {
        nom: "Quercétine",
        categorie: ["antioxydant"],
        description: `
            Les avantages de <label class='bold'>la quercétine liposomée</label> sont multiples. Elle soutient <label class='bold'>la santé cardiovasculaire</label> en <label class='bold'>protégeant les vaisseaux sanguins</label>. Elle aide également à renforcer <label class='bold'>le système immunitaire</label>, en réduisant les symptômes liés aux <label class='bold'>allergies saisonnières</label> et en offrant une protection contre <label class='bold'>les infections</label>.
            <br><br>
            De plus, <label class='bold'>la quercétine liposomée</label> est efficace dans la lutte contre <label class='bold'>le stress oxydatif</label>, ce qui en fait un allié précieux pour prévenir <label class='bold'>le vieillissement cellulaire</label> prématuré.
            <br><br>
            En incorporant de <label class='bold'>la quercétine liposomée</label> dans vos formulations <label class='bold'>de compléments alimentaires</label>, vous offrez à vos clients une solution naturelle et puissante pour soutenir leur bien-être au quotidien.
            <br><br>
            Optimisez vos formules en utilisant cette matière première de <label class='bold'>haute qualité</label>, qui garantit à la fois efficacité et absorption optimale.
        `,
        img: quercetine,
        type: "liposome",
        product: ["gamme bien-être", "Voies Respiratoires", mockupVoiesRespiratoires],
        bienfaits: [
            { titre: "Propriétés antioxydantes", description: "Combat les radicaux libres et protège les cellules contre les dommages oxydatifs." },
            { titre: "Soutien immunitaire", description: "Renforce le système immunitaire en réduisant l’inflammation et en protégeant contre les infections." },
            { titre: "Amélioration de la circulation sanguine", description: "Soutient la santé cardiovasculaire en réduisant l’inflammation des vaisseaux sanguins et en améliorant la circulation." },
            { titre: "Soutien respiratoire", description: "Réduit l’inflammation des muqueuses, ce qui aide à soulager les symptômes des allergies et des affections respiratoires." },
            { titre: "Amélioration de la biodisponibilité", description: "La technologie liposomale améliore l’absorption de la quercétine, permettant une action plus rapide et plus efficace dans l’organisme." },
            { titre: "Effet anti-âge", description: "Grâce à ses propriétés antioxydantes, la quercétine contribue à ralentir le vieillissement cellulaire." },
        ],
        url: "/liposomes/quercetine"
    },
    {
        nom: "Rutine",
        categorie: ["végétal"],
        description: `
            <label class='bold'>La rutine liposomée</label> est une forme avancée de ce <label class='bold'>flavonoïde naturel</label>, reconnu pour ses bienfaits sur <label class='bold'>la santé vasculaire</label> et <label class='bold'>ses propriétés antioxydantes</label>. 
            <br><br>
            Grâce à la <label class='bold'>technologie liposomale</label>, les actifs de la rutine sont mieux absorbés par l’organisme, permettant une efficacité renforcée pour protéger les cellules et soutenir <label class='bold'>le système circulatoire</label>.
            <br><br>
            La rutine contribue à renforcer <label class='bold'>les vaisseaux</label>, à améliorer <label class='bold'>la circulation sanguine</label> et à protéger les tissus contre les dommages causés par <label class='bold'>le stress oxydatif</label>. Elle aide également à soutenir la flexibilité <label class='bold'>des vaisseaux sanguins</label>.
            <br><br>
            Idéale pour les formulations visant à promouvoir <label class='bold'>la santé cardiovasculaire</label>, protéger les tissus et améliorer <label class='bold'>la circulation</label>, la rutine liposomée est un atout clé pour des <label class='bold'>compléments alimentaires innovants</label>. 
            <br><br>
            Offrez à vos clients une solution naturelle et performante grâce à <label class='bold'>la rutine liposomée</label>.

        `,
        img: rutine,
        type: "liposome",
        product: ["gamme bien-être", "Circulation", mockupCirculation],
        bienfaits: [
            { titre: "Santé Vasculaire", description: "Renforce les capillaires et améliore leur résistance. Soutient la flexibilité et la santé des vaisseaux sanguins. Favorise une circulation sanguine optimale." },
            { titre: "Propriétés Antioxydantes", description: "Protège les cellules contre les dommages causés par les radicaux libres. Réduit le stress oxydatif pour préserver les tissus et les organes." },
            { titre: "Soutien Circulatoire", description: "Améliore la microcirculation et la santé générale du système circulatoire. Peut contribuer à réduire la sensation de jambes lourdes." },
            { titre: "Bien-être Général", description: "Protège les tissus et renforce la vitalité globale." },
        ],
        url: "/liposomes/rutine"
    },
    {
        nom: "Andrographis",
        categorie: ["végétal"],
        description: `
            <label class='bold'>L'andrographis liposomé</label> est un extrait de <label class='bold'>andrographis paniculata</label> à haute biodisponibilité, grâce à <label class='bold'>la technologie liposomée</label>.
            <br><br>
            <label class='bold'>L'andrographis</label>, source végétale puissante, combine des <label class='bold'>antioxydants</label> et un soutien <label class='bold'>immunitaire</label> pour renforcer <label class='bold'>les défenses naturelles</label>. Il soutient également <label class='bold'>la détoxification du foie</label> et améliore <label class='bold'>la santé de la peau</label>.
            <br><br>
            Idéal pour les formulations ciblant <label class='bold'>la flexibilité articulaire</label> et la fluidité sanguine, il contribue également à soulager <label class='bold'>les inconforts respiratoires</label> et aide à maintenir <label class='bold'>des niveaux de glucose équilibrés</label>.
            <br><br>
            Utilisé dans les compléments alimentaires, <label class='bold'>l'andrographis liposomé</label> offre <label class='bold'>une absorption optimale</label> pour des effets rapides et durables.
        `,
        img: andrographis,
        type: "liposome",
        product: false,
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de l’andrographis sont les suivants :",
        bienfaits: [
            { titre: "Antioxydant", description: "Bonne source d'antioxydants naturels. Les antioxydants peuvent protéger les cellules et tissus contre les dommages oxydatifs causés par les radicaux libres. Contribue à l'activité antioxydante globale du corps." },
            { titre: "Articulation", description: "Aide à maintenir la mobilité et la flexibilité des articulations." },
            { titre: "Digestif et détox", description: "Aide à maintenir une fonction hépatique saine, facilitant la digestion et la purification du corps. Utile pour soutenir les fonctions de purification physiologique. Contribuer à l'élimination des substances nocives du corps et favoriser un esprit vif." },
            { titre: "Fonction cognitive", description: "Favoriser la clarté mentale en éliminant les substances nocives du corps." },
            { titre: "Glycémie", description: "Aide à maintenir un niveau normal de glucose dans le sang. Contribue à la digestion normale de l'insuline." },
            { titre: "Ménopause et Syndrome prémenstruel", description: "Contribue à la flexibilité et à la mobilité des articulations et soutient la résistance pendant le cycle prémenstruel." },
            { titre: "Système immunitaire", description: "Augmente la résistance physiologique de l'organisme dans des conditions environnementales difficiles Aide à soutenir un système immunitaire sain et l'immunité naturelle, en particulier au niveau des voies respiratoires supérieures. Soutient les mécanismes de défense naturelle du corps." },
            { titre: "Système sanguin", description: "Favorise la fluidité physiologique du sang." },
            { titre: "Voies aériennes supérieures", description: "Aide au confort respiratoire et atténue naturellement les troubles respiratoires, comme les toux et les maux de gorge. Soutient la défense immunitaire au niveau des voies respiratoires supérieures." },
        ],
        url: "/liposomes/andrographis"
    },
    {
        nom: "Ashwagandha",
        categorie: ["végétal"],
        description: `
            <label class='bold'>L’ashwagandha liposomée</label> ou ginseng indien, est une version avancée de cette <label class='bold'>plante adaptogène</label> renommée, reconnue pour ses propriétés exceptionnelles <label class='bold'>dans la gestion du stress</label> et le soutien de <label class='bold'>l’équilibre émotionnel</label>. Grâce à <label class='bold'>la technologie liposomale</label>, ses actifs, notamment <label class='bold'>les withanolides</label>, sont mieux absorbés, offrant ainsi une efficacité optimale dans les compléments alimentaires.
            <br><br>
            L’ashwagandha liposomée est idéal pour réduire <label class='bold'>le stress</label>, améliorer <label class='bold'>la résistance au surmenage</label> et favoriser <label class='bold'>un sommeil réparateur</label>. Il contribue également à équilibrer les hormones et soutenir <label class='bold'>les fonctions cognitives</label>, tout en apportant un regain d’énergie durable.
            <br><br>
            Cette plante adaptogène est particulièrement prisée dans les formulations dédiées à <label class='bold'>la relaxation</label>, à <label class='bold'>la gestion du stress</label> et <label class='bold'>au bien-être global</label>. 
            <br><br>
            Ses propriétés <label class='bold'>antioxydantes</label> aident également à protéger l’organisme des effets du <label class='bold'>stress oxydatif</label>, soutenant ainsi <label class='bold'>la vitalité</label> mentale et physique.
            <br><br>
            Optez pour <label class='bold'>l’ashwagandha liposomée</label> dans vos compléments alimentaires pour offrir à vos clients une solution innovante et efficace pour gérer les défis du quotidien et maintenir <label class='bold'>un équilibre durable</label>.
        `,
        img: ashwagandha,
        type: "liposome",
        product: ["gamme bien-être", "Anti-stress", mockupAntiStress],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de l’ashwagandha sont les suivants :",
        bienfaits: [
            { titre: "Gestion du stress et bien-être", description: "Aide à la relaxation, à l’équilibre émotionnel et au bien-être général. Contribue à une détente optimale et au bien-être mental et physique. Aide à faire face au stress et améliore la résistance de l’organisme. Favorise la stabilité émotionnelle et un sommeil réparateur." },
            { titre: "Soutien cognitif", description: "Aide à la mémoire, à l’apprentissage et au rappel. Soutient la fonction mentale, notamment chez les personnes âgées. Facilite la récupération après des événements stressants." },
            { titre: "Santé globale et anti-âge", description: "Protège la santé générale grâce à ses propriétés antioxydantes. Offre un effet rajeunissant global." },
            { titre: "Santé de la peau", description: "Favoriser la clarté mentale en éliminant les substances nocives du corps." },
            { titre: "Santé sexuelle", description: "Soutient la fonction sexuelle masculine et la mobilité des spermatozoïdes. Aide à maintenir la santé des organes reproducteurs féminins." },
            { titre: "Soutien cardiovasculaire", description: "Bénéfique pour le cœur et le système cardiovasculaire." },
            { titre: "Santé respiratoire", description: "Aide à maintenir la santé des voies respiratoires supérieures." },
        ],
        url: "/liposomes/ashwagandha"
    },
    {
        nom: "Boswellia",
        categorie: ["végétal"],
        description: `
           <label class='bold'>La boswellia liposomée</label> est une version avancée de cet <label class='bold'>extrait végétal</label>, réputé pour ses propriétés bénéfiques sur <label class='bold'>les articulations, le système digestif</label>, et <label class='bold'>le bien-être global.</label> 
            <br><br>
            Grâce <label class='bold'>à la technologie liposomale</label>, ses actifs sont mieux absorbés, garantissant <label class='bold'>une efficacité optimale</label> pour apaiser <label class='bold'>les articulations</label>, protéger la muqueuse digestive et soutenir la <label class='bold'>santé musculo-squelettique.</label>
            <br><br>
            Idéal pour réduire <label class='bold'>l’inconfort articulaire</label> et préserver <label class='bold'>la souplesse</label>, la boswellia agit également comme un protecteur pour le <label class='bold'>système digestif</label> et contribue à maintenir la <label class='bold'>santé des os, des muscles,</label> et <label class='bold'>des voies respiratoires.</label> 
            <br><br>
            Ce puissant extrait est un choix de premier plan pour les formulations dédiées au <label class='bold'>bien-être physique</label> et au soutien des systèmes clés du corps.
            <br><br>
            Optez pour la boswellia liposomée pour offrir à vos clients une <label class='bold'>solution innovante</label> et performante, adaptée aux besoins du quotidien.
        `,
        img: boswellia,
        type: "liposome",
        product: false,
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du boswellia sont les suivants :",
        bienfaits: [
            { titre: "Articulations", description: "Aide à maintenir la santé des articulations et soutient leur souplesse. Contribue à garder les articulations confortables. Favorise la santé du système musculo-squelettique, y compris les os, les articulations et les muscles." },
            { titre: "Santé osseuse", description: "Contribue à la santé des os et des articulations." },
            { titre: "Système digestif", description: "Protège le tractus digestif et aide à maintenir la muqueuse digestive. Soutient la santé globale du tractus gastro-intestinal." },
            { titre: "Fonction cognitive", description: "Soutient la fonction mentale et affine les capacités cognitives." },
            { titre: "Santé sexuelle", description: "Soutient la santé menstruelle et la santé sexuelle masculine." },
            { titre: "Système cardiovasculaire", description: "Aide à maintenir un taux de cholestérol sain. Soutient la fonction cardiaque." },
            { titre: "Voies aériennes supérieures", description: "Favorise la santé des poumons." },
        ],
        url: "/liposomes/boswellia"
    },
    {
        nom: "cafeine",
        categorie: ["végétal"],
        description: `
           <label class='bold'>La caféine</label> est un ingrédient bien connu pour ses <label class='bold'>propriétés stimulantes</label>, offrant un coup de fouet naturel pour le corps et l’esprit. Elle agit en augmentant <label class='bold'>la vigilance</label>, en réduisant la <label class='bold'>sensation de fatigue</label>, et en améliorant la concentration.<br><br>

            En plus de ses <label class='bold'>bienfaits cognitifs</label>, la caféine est également prisée pour son rôle dans la <label class='bold'>performance physique</label>, en favorisant <label class='bold'>l’endurance</label> et en <label class='bold'>stimulant la lipolyse</label>, ce qui contribue à la mobilisation des graisses en tant que source d’énergie.<br><br>

            Idéale pour les formulations axées sur <label class='bold'>la vitalité, la concentration</label>, ou encore <label class='bold'>les performances sportives</label>, la caféine est un ingrédient clé pour aider à rester actif, concentré, et performant tout au long de la journée. <br><br>

            Grâce à sa forme liposomée, la caféine est mieux absorbée par l'organisme, garantissant une <label class='bold'>efficacité accrue</label> et une biodisponibilité optimale.<br><br>

            Offrez à vos clients <label class='bold'>une solution naturelle</label> et efficace avec cet actif incontournable.
        `,
        img: cafeine,
        type: "liposome",
        product: ["gamme vitalité", "énergie", mockupEnergie],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la caféine sont les suivants :",
        bienfaits: [
            { titre: "Fonction cognitive", description: "Contribue à la performance mentale et aide à maintenir la concentration. Aide à maintenir et améliorer la vigilance, la concentration et la rapidité de réaction. Aide à se sentir plus énergique, améliore l'humeur et soutient la vitalité." },
            { titre: "Performances sportives", description: "Aide à prolonger le temps d'exercice et à améliorer les performances d'endurance. Contribue à une augmentation de la capacité de performance physique et à la réduction de la perception de l'effort pendant l'exercice." },
        ],
        url: "/liposomes/cafeine"
    },
    {
        nom: "calcium",
        categorie: ["minéral"],
        description: `
            <label class='bold'>Le calcium</label> est le minéral le plus abondant dans le corps humain, reconnu pour son rôle fondamental dans le maintien de <label class='bold'>la santé des os et des dents</label>. Il contribue également au fonctionnement <label class='bold'>musculaire normal</label>, à <label class='bold'>la transmission nerveuse</label>, et à <label class='bold'>une coagulation sanguine normale</label>.<br><br>

            Essentiel à toutes les étapes de la vie, <label class='bold'>le calcium</label> aide à préserver <label class='bold'>une densité osseuse</label> optimale, particulièrement chez les enfants en croissance, les adultes actifs, et les personnes âgées.<br><br> 
            
            Il joue également un rôle dans <label class='bold'>le métabolisme énergétique</label>, soutenant <label class='bold'>le bien-être général et l’équilibre corporel</label>.<br><br>
            
            Grâce à sa forme liposomée, le calcium est mieux absorbé par l'organisme, garantissant une <label class='bold'>efficacité accrue</label> et une biodisponibilité optimale.<br><br>
            
            <label class='bold'>Le calcium</label> est un ingrédient clé dans les formulations de compléments alimentaires visant à <label class='bold'>prévenir les carences</label> et à soutenir une santé globale durable. 
        `,
        img: calcium,
        type: "liposome",
        product: ["gamme les essentiels", "calcium", mockupCalcium],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du calcium sont les suivants :",
        bienfaits: [
            { titre: "Densité osseuse", description: "Contribue à réduire la perte de densité minérale osseuse chez les femmes ménopausées, un facteur de risque de fractures ostéoporotiques. Le calcium et la vitamine D contribuent à réduire la perte de densité osseuse chez les femmes de 50 ans et plus." },
            { titre: "Digestif", description: "Contribue au fonctionnement normal des enzymes digestives." },
            { titre: "Enfants", description: "Nécessaire à une croissance et un développement osseux normaux chez les enfants." },
            { titre: "Fonction des tissus", description: "Contribue à une fonction musculaire normale. Joue un rôle dans les processus de division et de spécialisation cellulaires." },
            { titre: "Métabolisme énergétique", description: "Contribue à un métabolisme énergétique normal." },
            { titre: "Santé bucco-dentaire", description: "Nécessaire au maintien d'une dentition normale." },
            { titre: "Santé osseuse", description: "Nécessaire au maintien d'une ossature normale." },
            { titre: "Système nerveux", description: "Contribue à une neurotransmission normale." },
            { titre: "Système sanguin", description: "Contribue à une coagulation sanguine normale." },
        ],
        url: "/liposomes/calcium"
    },
    {
        nom: "chardon marie",
        categorie: ["végétal"],
        description: `
        <label class='bold'>Le chardon-marie liposomé</label>, riche en <label class='bold'>silymarine</label>, est une plante reconnue pour ses <label class='bold'>propriétés protectrices</label> et <label class='bold'>régénérantes sur le foie</label>. Il aide à soutenir <label class='bold'>la détoxification naturelle</label> de l’organisme, favorisant <label class='bold'>l’élimination des toxine</label>s et <label class='bold'>le bon fonctionnement hépatique</label>.<br><br>

        En plus de ses bienfaits pour le foie, le chardon-marie agit comme un puissant <label class='bold'>antioxydant</label>, protégeant les cellules contre les dommages oxydatifs et contribuant au bien-être général.<br><br>
        
        Il est particulièrement utile pour les personnes exposées à des facteurs de <label class='bold'>stress environnementaux</label> ou <label class='bold'>alimentaires</label>.<br><br>
        
        Parfait pour les formulations visant à améliorer <label class='bold'>la santé du foie</label>, soutenir <label class='bold'>une digestion optimale</label>, et renforcer <label class='bold'>la protection cellulaire</label>, le chardon-marie est un actif naturel de choix pour une santé durable et une vitalité retrouvée.
        `,
        img: chardonMarie,
        type: "liposome",
        product: ["gamme bien-être", "détox", mockupDetox],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du chardon-marie sont les suivants :",
        bienfaits: [
            { titre: "Antioxydant", description: "Protection contre l'action des radicaux libres due au stress, à l'alcool, à l'exposition aux UV ou aux conditions d'ambiance polluées." },
            { titre: "Fonction hépatique et glycémie", description: "Soutient la santé du foie, contribue à la protection et au potentiel détoxifiant du foie, favorise la digestion et la purification du corps." },
            { titre: "Système immunitaire", description: "Augmente la résistance physiologique de l'organisme en cas de conditions environnementales sévères." },
            { titre: "Système cardiovasculaire", description: "Aide à maintenir la santé cardiaque." },
        ],
        url: "/liposomes/chardon-marie"
    },
    {
        nom: "chrome",
        categorie: ["minéral"],
        description: `
        <label class='bold'>Le chrome</label> est un oligo-élément essentiel reconnu pour son rôle clé dans le <label class='bold'>métabolisme</label> des glucides, des lipides, et des protéines. Il contribue au maintien <label class='bold'>d’une glycémie normale</label> en soutenant <label class='bold'>l’action de l’insuline</label>, l’hormone régulatrice du sucre dans le sang.<br><br>

        Ce minéral est également prisé pour son impact sur <label class='bold'>le contrôle du poids</label>, en aidant à réduire les envies de sucre et à stabiliser <label class='bold'>les niveaux d’énergie</label>. <br><br>

        Grâce à ses bienfaits métaboliques, le chrome joue un rôle important dans les programmes de gestion du poids et de soutien énergétique.<br><br>

        Idéal pour les formulations de compléments alimentaires dédiées au métabolisme énergétique, <label class='bold'>au contrôle du poids</label>, et au bien-être général, le chrome est un actif incontournable pour ceux qui cherchent à optimiser leur <label class='bold'>équilibre métabolique</label>.<br><br>

        Grâce à sa forme liposomée, le chrome est mieux absorbé par l'organisme, garantissant une <label class='bold'>efficacité accrue</label> et une biodisponibilité optimale.<br><br>
        `,
        img: chrome,
        type: "liposome",
        product: ["gamme les essentiels", "chrome", mockupChrome],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du chrome sont les suivants :",
        bienfaits: [
            { titre: "Glycémie", description: "Le chrome contribue au maintien d'une glycémie normale." },
            { titre: "Métabolisme énergétique", description: "Le chrome participe au métabolisme normal des macronutriments. Contribue à réguler le métabolisme des glucides." },
        ],
        url: "/liposomes/chrome"
    },
    {
        nom: "complexe vitamines b",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>Le complexe de vitamines B liposomé</label> regroupe un ensemble de vitamines essentielles <label class='bold'>(B1, B2, B3, B5, B6, B7, B9, B12)</label> qui agissent en synergie pour soutenir le métabolisme énergétique et promouvoir un <label class='bold'>bien-être global</label>. Ces vitamines jouent un rôle clé dans la <label class='bold'>conversion des nutriments en énergie</label>, renforçant ainsi la <label class='bold'>vitalité</label> et réduisant la sensation de <label class='bold'>fatigue</label>.<br><br>

        Le complexe de vitamines B contribue également au bon fonctionnement du <label class='bold'>système nerveux</label>, au maintien d’une <label class='bold'>peau saine</label>, et au soutien des <label class='bold'>fonctions cognitives</label>. <br>
        De plus, il participe à la formation des globules rouges et au renforcement du <label class='bold'>système immunitaire</label>.<br><br>
        
        Grâce à sa forme liposomée, le complexe vitamines B est mieux absorbé par l'organisme, garantissant une <label class='bold'>efficacité accrue</label> et une biodisponibilité optimale.<br><br>
        
        Ce complexe est un allié incontournable pour les formulations destinés à soutenir l’énergie, la <label class='bold'>résistance au stress</label>, et la santé globale. Offrez à vos clients une solution complète et équilibrée avec le complexe de vitamines B.
        
        `,
        img: complexeVitamineB,
        type: "liposome",
        product: ["gamme les essentiels", "vitamines B Complex", mockupVitamineBComplex],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du complexe vitamines B sont les suivants :",
        bienfaits: [
            { titre: "Métabolisme Énergétique", description: "Contribue à un métabolisme énergétique normal.<br> Contribue au métabolisme normal des protéines et du glycogène.<br> Contribue au métabolisme normal des macronutriments." },
            { titre: "Système nerveux", description: "Contribue au fonctionnement normal du système nerveux." },
            { titre: "Réduction de la fatigue", description: "Contribue à réduire la fatigue." },
            { titre: "Système Immunitaire", description: "Contribue au fonctionnement normal du système immunitaire." },
            { titre: "Système sanguin et métabolisme glucidique", description: "Contribue au maintien de globules rouges normaux.<br> Contribue à la formation normale de globules rouges. Contribue à la formation normale du sang.<br> Contribue à un métabolisme glucidique normal." },
            { titre: "Fonctions psychologiques et cognitives", description: "Contribue à des fonctions psychologiques normales.<br> Contribue à des performances intellectuelles normales." },
            { titre: "Peau, cheveux et ongles", description: "Contribue au maintien d'une peau normale.<br> Contribue au maintien de cheveux normaux. Contribue au maintien de muqueuses normales." },
            { titre: "Santé cardiovasculaire", description: "Contribue à une fonction cardiaque normale.<br> Contribue au métabolisme normal de l'homocystéine" },
            { titre: "Formation des tissus et division cellulaire", description: "Joue un rôle dans le processus de division cellulaire.<br> Contribue à la croissance des tissus maternels pendant la grossesse." },
            { titre: "Système hormonal", description: "Contribue à la synthèse normale et au métabolisme des hormones stéroïdes, de la vitamine D et de certains neurotransmetteurs." },
        ],
        url: "/liposomes/complexevitaminesB"
    },
    {
        nom: "cuivre",
        categorie: ["minéral"],
        description: `
        <label class='bold'>Le cuivre</label> est un oligo-élément indispensable, reconnu pour son rôle dans le métabolisme énergétique, la <label class='bold'>protection des cellules</label> contre le stress oxydatif, et le soutien de <label class='bold'>l’immunité</label>.<br><br>

        Il contribue à la formation normale des tissus conjonctifs, essentiels pour la santé de la peau, des os et des articulations, et joue un rôle clé dans <label class='bold'>le maintien d’une pigmentation normale des cheveux et de la peau</label>. <br><br>

        De plus, le cuivre soutient une <label class='bold'>fonction nerveuse optimale</label> et participe au transport normal du fer dans l’organisme, favorisant ainsi la formation des globules rouges.<br><br>

        Grâce à sa forme liposomée, le cuivre est mieux absorbé par l'organisme, garantissant une <label class='bold'>efficacité accrue</label> et une biodisponibilité optimale.<br><br>

        Le cuivre est un actif polyvalent, idéal pour les formulations visant à <label class='bold'>renforcer l’immunité</label>, protéger les cellules, et améliorer la santé générale. Ajoutez le cuivre à vos compléments alimentaires pour offrir un soutien essentiel au quotidien.
        
        `,
        img: cuivre,
        type: "liposome",
        product: ["gamme les essentiels", "cuivre", mockupCuivre],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du cuivre sont les suivants :",
        bienfaits: [
            { titre: "Antioxydant", description: "Le cuivre contribue à protéger les cellules contre le stress oxydatif." },
            { titre: "Formation des tissus", description: "Le cuivre contribue au maintien des tissus conjonctifs normaux." },
            { titre: "Métabolisme vitamines et minéraux", description: "Le cuivre contribue au transport normal du fer dans l'organisme." },
            { titre: "Métabolisme énergétique", description: "Le cuivre contribue à un métabolisme énergétique normal." },
            { titre: "Peau / Phanères", description: "Le cuivre contribue à la pigmentation normale de la peau.<br>Le cuivre contribue à la pigmentation normale des cheveux." },
            { titre: "Fonctions psychologiques et cognitives", description: "Contribue à des fonctions psychologiques normales.<br> Contribue à des performances intellectuelles normales." },
            { titre: "Système Immunitaire", description: " Le cuivre contribue au fonctionnement normal du système immunitaire." },
            { titre: "Système nerveux", description: "Le cuivre contribue au fonctionnement normal du système nerveux." },
        ],
        url: "/liposomes/cuivre"
    },
    {
        nom: "curcuma",
        categorie: ["végétal"],
        description: `
        <label class='bold'>Le curcuma liposomé</label> est une forme avancée de cet ingrédient traditionnel, reconnu pour ses propriétés <label class='bold'>antioxydantes</label> exceptionnelles. <br><br>

        Grâce à la <label class='bold'>technologie liposomale</label>, les actifs clés du <label class='bold'>curcuma</label>, notamment <label class='bold'>la curcumine</label>, sont mieux protégés et assimilés par l’organisme, offrant une efficacité renforcée pour soutenir <label class='bold'>la santé globale</label>.<br><br>
        
        Ce puissant actif naturel aide à maintenir <label class='bold'>la souplesse articulaire</label>, à protéger les cellules contre <label class='bold'>le stress oxydatif</label>, et à favoriser <label class='bold'>le bien-être digestif</label>. Il est également apprécié pour son rôle <label class='bold'>dans le soutien du système immunitaire</label>.<br><br>
        
        Grâce à sa forme liposomée, le curcuma est mieux absorbé par l'organisme, garantissant une <label class='bold'>efficacité accrue</label> et une biodisponibilité optimale.<br><br>
        
        Le curcuma liposomé est idéal pour les formulations visant à promouvoir <label class='bold'>la mobilité articulaire</label>, protéger les cellules contre les radicaux libres et améliorer <label class='bold'>la vitalité</label> générale. Offrez à vos clients une solution innovante et performante avec cet ingrédient essentiel.
        
        `,
        img: curcuma,
        type: "liposome",
        product: ["gamme bien-être", "articulation", mockupArticulation],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du curcuma sont les suivants :",
        bienfaits: [
            { titre: "Antioxydant", description: "Contribue à la protection des cellules contre le stress oxydatif. <br>Contient des antioxydants naturels." },
            { titre: "Articulations et mobilité", description: "Aide à maintenir la santé des articulations et des os.<br>Contribue à la flexibilité des articulations et à la protection des tendons.<br>Aide à renforcer le système locomoteur et à maintenir la mobilité." },
            { titre: "Digestif", description: "Contribue à la digestion normale et soutient la santé du tractus intestinal.<br>Soutient la production et la sécrétion des fluides digestifs, facilitant la digestion des graissesAide à stimuler l'appétit en cas de perte d'appétit." },
            { titre: "Soutien hépatique", description: "Aide à maintenir la santé du foie et à prévenir l'accumulation de graisses." },
            { titre: "Peau / Phanères", description: "Aide à maintenir la santé de la peau." },
            { titre: "Système cardiovasculaire", description: "Soutient la fonction cardiaque et la circulation sanguine." },
            { titre: "Système nerveux", description: "Soutient la fonction du système nerveux." },
            { titre: "Voies aériennes supérieures", description: "Contribue à la santé des poumons et des voies respiratoires supérieures." },
        ],
        url: "/liposomes/curcuma"
    },
    {
        nom: "epa · dha",
        categorie: ["acide gras"],
        description: `
        <label class='bold'>Les acides eicosapentaénoïque</label> (EPA) et <label class='bold'>docosahexaénoïque</label> (DHA) sont des acides gras <label class='bold'>oméga 3 essentiels</label>, reconnus pour leurs nombreux bienfaits sur la santé. Ils jouent un rôle crucial dans le maintien de la santé <label class='bold'>cardiovasculaire</label>, en contribuant à la régulation de la pression sanguine et au maintien des niveaux normaux de <label class='bold'>triglycérides</label> dans le sang.<br><br>

        De plus, <label class='bold'>l'EPA et le DHA</label> soutiennent les fonctions cognitives, favorisant un fonctionnement optimal du cerveau, incluant la <label class='bold'>concentration</label>, la <label class='bold'>mémoire</label> et la <label class='bold'>clarté mentale</label>. <br><br>
        
        En tant <label class='bold'>qu'antioxydants</label> puissants, ils protègent les cellules contre les dommages oxydatifs, contribuant ainsi au bien-être général et à la prévention des effets du vieillissement. <br><br>
        
        Grâce à la forme liposomée, l’EPA et le DHA bénéficie d’une <label class='bold'>meilleure absorption</label> par l’organisme, garantissant ainsi une efficacité optimale.<br><br>
        
        Idéals pour les formulations visant à améliorer <label class='bold'>la santé cardiovasculaire</label>, optimiser <label class='bold'>les performances mentales</label> et renforcer la résilience physique, l'EPA et le DHA constituent des ingrédients innovants répondant aux besoins des personnes actives et soucieuses de leur santé.
        
        
        `,
        img: epaDha,
        type: "liposome",
        product: false,
        allegation: "D'après l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de l'acide docosahexaénoïque (DHA) et de l'acide eicosapentaénoïque (EPA) sont les suivants : ",
        bienfaits: [
            { titre: "Système cardiovasculaire", description: "Ils contribuent au maintien d'une pression sanguine normale.<br> Ils contribuent à une fonction cardiaque normale.<br> Ils contribuent au maintien d'une concentration normale de triglycérides dans le sang." },
            { titre: "Système nerveux", description: "La consommation d'acide docosahexaénoïque (DHA) contribue au fonctionnement normal du cerveau." },
            { titre: "Vision", description: "La consommation d'acide docosahexaénoïque (DHA) contribue au maintien d'une vision normale." },
            { titre: "Enfants", description: "La consommation d'acide docosahexaénoïque (DHA) contribue au développement normal de la vue des nourrissons jusqu'à 12 mois.<br>La consommation d'acide docosahexaénoïque (DHA) par la mère contribue au développement normal du cerveau du fœtus et de l'enfant allaité. " },
        ],
        url: "/liposomes/epadha"
    },
    {
        nom: "fer",
        categorie: ["minéral"],
        description: `
        <label class='bold'>Le fer</label> est un minéral essentiel au bon fonctionnement de l'organisme. Il joue un rôle clé dans la production des <label class='bold'>globules rouges</label>, contribuant ainsi au transport de <label class='bold'>l’oxygène dans le sang</label>. Il participe également à la réduction de <label class='bold'>la fatigue</label> et soutient <label class='bold'>les fonctions cognitives</label>. <br><br>

        <label class='bold'>Le fer bisglycinate liposomé</label> est une forme de fer <label class='bold'>hautement biodisponible</label> et facilement absorbée par l’organisme, idéale pour les compléments alimentaires. <br><br>

        Contrairement aux formes traditionnelles de fer, le fer bisglycinate liposomé est <label class='bold'>mieux toléré, réduisant les risques d’effets secondaires digestifs</label> tels que la constipation ou les nausées.<br><br>

        <label class='bold'>Grâce à la technologie liposomale</label>, ce fer est encapsulé dans des liposomes, ce qui améliore son absorption et son <label class='bold'>efficacité</label>, tout en garantissant une libération prolongée et ciblée dans le corps. <br><br>

        Il est particulièrement recommandé pour les personnes ayant des besoins accrus en fer, comme les <label class='bold'>femmes enceintes</label>.<br><br>

        Optez pour le fer bisglycinate liposomé pour vos produits et offrez à vos clients une solution de qualité pour maintenir des <label class='bold'>niveaux de fer équilibrés</label> et favoriser leur vitalité au quotidien.
        
        `,
        img: fer,
        type: "liposome",
        product: ["gamme les essentiels", "fer complex", mockupFerComplex],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du fer sont les suivants : ",
        bienfaits: [
            { titre: "Fonction cognitive", description: "Il contribue à une fonction cognitive normale. <br>Il joue un rôle dans le processus de division cellulaireMétabolisme énergétique. <br>Il contribue à un métabolisme énergétique normal" },
            { titre: "Système immunitaire", description: "Il contribue au fonctionnement normal du système immunitaire" },
            { titre: "Système sanguin", description: "Il contribue à la formation normale de globules rouges et d'hémoglobine. <br>Il contribue au transport normal de l'oxygène dans l'organisme" },
            { titre: "Tonique", description: "Il contribue à réduire la fatigue" },
            { titre: "Enfants", description: "Il contribue au développement cognitif normal des enfants" },
        ],
        url: "/liposomes/fer"
    },
    {
        nom: "vitamines k2d3",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La vitamine K2D3 liposomée</label> associe deux nutriments essentiels pour une efficacité optimale : <label class='bold'>la vitamine K2</label>, connue pour son rôle dans la fixation <label class='bold'>du calcium</label>, et <label class='bold'>la vitamine D3</label>, qui favorise son absorption. <br><br>

        Grâce à <label class='bold'>la technologie liposomale</label>, ces vitamines bénéficient d’une biodisponibilité renforcée, permettant une absorption supérieure et une <label class='bold'>meilleure efficacité</label>.<br><br>

        Cette combinaison innovante soutient à la fois <label class='bold'>la santé osseuse</label> et <label class='bold'>la santé cardiovasculaire</label>, en contribuant à une bonne répartition <label class='bold'>du calcium</label> dans l’organisme. Elle aide à maintenir <label class='bold'>des os solides</label> tout en protégeant <label class='bold'>les vaisseaux sanguins des dépôts calcifiés</label>.<br><br>

        La vitamine K2D3 liposomée est idéale pour les formulations visant à améliorer la densité osseuse, réduire les risques <label class='bold'>cardiovasculaires</label> et soutenir le bien-être général. En intégrant cette technologie avancée, elle devient un atout clé pour <label class='bold'>des compléments alimentaires</label> performants et innovants.<br><br>

        Optez pour la vitamine K2D3 liposomée pour offrir à vos clients une solution complète et efficace pour leur <label class='bold'>santé osseuse</label> et <label class='bold'>cardiovasculaire</label>.
        `,
        img: vitaminesK2d3,
        type: "liposome",
        product: ["gamme les essentiels", "vitamines k2d3", mockupVitamineK2d3],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du K2D3 sont les suivants : ",
        bienfaits: [
            { titre: "Santé osseuse", description: "La vitamine K contribue au maintien d'une ossature normale. <br>La vitamine D contribue au maintien d'une ossature normale.<br>La vitamine D contribue à réduire le risque de chute associé à l'instabilité posturale et à la faiblesse musculaire. Les chutes sont un facteur de risque de fractures osseuses chez les hommes et les femmes de 60 ans et plus.<br>Le calcium et la vitamine D contribuent à réduire la perte de densité minérale osseuse chez les femmes ménopausées. Une faible densité osseuse est un facteur de risque de fractures ostéoporotiques." },
            { titre: "Santé bucco-dentaire", description: "La vitamine D contribue au maintien d'une dentition normale." },
            { titre: "Système immunitaire", description: "La vitamine D contribue au fonctionnement normal du système immunitaire. <br>Chez les enfants, la vitamine D contribue au fonctionnement normal du système immunitaire." },
            { titre: "Système sanguin", description: "La vitamine K contribue à une coagulation sanguine normale." },
            { titre: "Fonction musculaire", description: "La vitamine D contribue au maintien d'une fonction musculaire normale." },
            { titre: "Métabolisme des minéraux", description: "La vitamine D contribue à l'absorption et à l'utilisation normales du calcium et du phosphore. La vitamine D contribue à une calcémie normale." },
            { titre: "Croissance et développement des enfants", description: "La vitamine D est nécessaire à une croissance et un développement osseux normaux chez les enfants." },
            { titre: "Division cellulaire", description: "La vitamine D joue un rôle dans le processus de division cellulaire." },
        ],
        url: "/liposomes/vitaminesk2d3"
    },
    {
        nom: "l-theanine",
        categorie: ["acide aminé"],
        description: `
        <label class='bold'>La L-Théanine</label> est un acide aminé unique naturellement présent dans <label class='bold'>le thé vert</label>, réputé pour ses propriétés <label class='bold'>relaxantes</label> et <label class='bold'>stimulantes de la concentration</label>. Elle agit en favorisant un état de <label class='bold'>calme mental</label> tout en améliorant la vigilance, sans provoquer de somnolence.<br><br>

        Idéale pour réduire <label class='bold'>le stress</label> et améliorer <label class='bold'>la qualité du sommeil</label>, la L-Théanine liposomée stimule également les ondes alpha cérébrales, associées à une <label class='bold'>relaxation profonde</label> et à une meilleure <label class='bold'>clarté mentale</label>. <br><br>

        C’est un actif incontournable pour les formulations visant à soutenir <label class='bold'>la gestion du stress, l’attention et le bien-être cognitif</label>.<br><br>

        En raison de sa structure liposomale, la L-Théanine bénéficie d’une meilleure biodisponibilité, permettant une libération <label class='bold'>plus efficace</label> dans l’organisme. <br><br>

        Optez pour la L-Théanine pour offrir à vos clients une solution <label class='bold'>naturelle, efficace et équilibrée</label>, idéale pour faire face aux défis du quotidien.

        `,
        img: lTheanine,
        type: "liposome",
        product: false,
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés L-Théanine sont les suivants : ",
        bienfaits: [
            { titre: "Antioxydant", description: "Contribue à protéger les cellules contre les dommages oxydatifs.<br>Aide à protéger contre le vieillissement prématuré et le stress oxydatif.<br>Protège le corps contre les radicaux libres grâce à ses puissants antioxydants.<br>Les polyphénols du thé vert, comme les catéchines, offrent des bienfaits pour le métabolisme du glucose et des lipides.<br>Le thé vert est riche en polyphénols, qui contribuent à l’équilibre et à la santé du corps." },
            { titre: "Cholestérol et santé cardiovasculaire", description: "Contribue à maintenir un taux de cholestérol normal et soutient une fonction cardiaque saine.<br> Aide à maintenir les lipides sanguins normaux.<br> Soutient la circulation et apaise les tensions dans le système circulatoire." },
            { titre: "Digestif et flore intestinale", description: "Aide à maintenir un système digestif sain.<br>Favorise un bon équilibre de la flore intestinale et améliore son état après la prise d’antibiotiques." },
            { titre: "Sommeil et relaxation", description: "Favorise la relaxation et améliore la qualité du sommeil naturel." },
            { titre: "Système immunitaire", description: "Renforce les défenses naturelles contre les radicaux libres et les agressions extérieures." },
            { titre: "Minceur et métabolisme énergétique", description: "Aide à brûler les graisses et favorise le métabolisme des lipides.<br>Soutient la thermogenèse pour un meilleur contrôle du poids." },
            { titre: "Peau et protection UV", description: "Protège la peau des dommages oxydatifs induits par les UV et favorise un vieillissement sain." },
            { titre: "Santé bucco-dentaire", description: "Protège contre la formation de plaque dentaire et les caries." },
            { titre: "Santé osseuse", description: "Participe au renforcement et à la santé des os." },
            { titre: "Soutien général", description: "Soutient l’énergie et la vitalité physique et mentale. <br>Aide à protéger les yeux des dommages oxydatifs." },
        ],
        url: "/liposomes/l-theanine"
    },
    {
        nom: "lycopène",
        categorie: ["végétal"],
        description: `
        <label class='bold'>Le lycopène</label> est un caroténoïde naturel, principalement présent dans <label class='bold'>les tomates</label>, reconnu pour ses puissantes <label class='bold'>propriétés antioxydantes</label>. Il joue un rôle clé dans la protection des cellules contre les dommages causés par les radicaux libres, aidant ainsi à prévenir <label class='bold'>le vieillissement prématuré</label> et à maintenir une santé optimale.<br><br>

        Le lycopène liposomé est particulièrement apprécié pour son impact positif sur <label class='bold'>la santé cardiovasculaire</label>, en contribuant à <label class='bold'>la réduction du stress oxydatif</label> et au maintien de <label class='bold'>la fonction cardiaque</label> normale. Il est également associé à des bienfaits pour <label class='bold'>la peau</label>, en aidant à protéger contre les effets des UV et en favorisant un teint éclatant.<br><br>

        En raison de sa structure liposomale, le lycopène bénéficie d’une meilleure biodisponibilité, permettant une <label class='bold'>libération plus efficace</label> dans l’organisme. <br><br>

        Idéal pour les formulations visant à soutenir <label class='bold'>la santé cardiovasculaire, protéger la peau, et promouvoir une vitalité durable</label>, le lycopène est un actif essentiel pour des compléments alimentaires innovants et performants. Offrez à vos clients une solution naturelle et efficace grâce au lycopène.
        `,
        img: lycopène,
        type: "liposome",
        product: false,
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du lycopène sont les suivants :",
        bienfaits: [
            { titre: "Anti-âge", description: "Aide à améliorer le processus de vieillissement, protège la peau contre les stress environnementaux, et réduit l'érythème et les dommages causés par les radicaux libres, les UV, et autres facteurs nocifs (effet potentiel renforcé avec une utilisation topique de crèmes)." },
            { titre: "Antioxydant", description: "Mélange d'antioxydants naturels qui protègent les cellules contre les dommages oxydatifs et maintient le maintien d'un bon niveau de vitamines et d'antioxydants dans l'ensemble du corps pour ses fonctions cellulaires." },
            { titre: "Peau / Phanères", description: "Contribue à la santé et à l'apparence de la peau en soutenant la production de collagène, et renforce les défenses naturelles de la peau contre les dommages induits par les UV." },
            { titre: "Protection de la peau contre les UV", description: "Aide à améliorer la santé et l'apparence de la peau en développant le collagène et en soutenant les défenses naturelles contre les dommages UV." },
        ],
        url: "/liposomes/lycopene"
    },
    {
        nom: "magnésium",
        categorie: "minéral",
        description: `
        <label class='bold'>Le magnésium</label> est un minéral indispensable à de nombreuses fonctions biologiques. Il contribue au <label class='bold'>métabolisme énergétique</label>, au fonctionnement normal <label class='bold'>du système nerveux</label>, et à <label class='bold'>la fonction musculaire</label>. Ce minéral joue également un rôle clé dans <label class='bold'>la réduction de la fatigue</label> et le maintien de <label class='bold'>l'équilibre électrolytique</label>, un atout essentiel pour les sportifs et les personnes actives.<br><br>

        En soutenant <label class='bold'>la santé des os, des dents et le processus de division cellulaire, le magnésium liposomée</label> se révèle être un allié incontournable pour le bien-être quotidien. Les différentes formes de magnésium permettent de répondre à des besoins spécifiques, en maximisant leurs bienfaits selon leurs propriétés uniques.<br><br>

        <label class='bold'>Taurate de magnésium</label> : Recherché pour son effet apaisant sur <label class='bold'>le système nerveux</label>, c’est une excellente option pour soutenir <label class='bold'>la relaxation, la gestion du stress et la santé cardiovasculaire</label>.<br><br>

        <label class='bold'>Citrate de magnésium</label> : Apprécié pour sa <label class='bold'>biodisponibilité élevée</label>, il est idéal pour améliorer <label class='bold'>la digestion</label> et soulager <label class='bold'>les inconforts</label> liés à <label class='bold'>la constipation</label>, tout <label class='bold'>en soutenant l'énergie</label> et <label class='bold'>la récupération après l'effort</label>.<br><br>

        <label class='bold'>Bisglycinate de magnésium</label> : Doux pour l'estomac et hautement absorbable, il excelle dans <label class='bold'>la gestion du stress</label> et <label class='bold'>l'amélioration du sommeil</label>, tout en favorisant <label class='bold'>la détente musculaire et mentale</label>.<br><br>

        En raison de sa structure liposomale, le magnésium bénéficie d’une meilleure biodisponibilité, permettant une <label class='bold'>libération plus efficace</label> dans l’organisme. 
        `,
        img: magnesium,
        type: "liposome",
        product: ["gamme bien-être", "magnésium+", mockupMagnesium],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du magnésium sont les suivants : ",
        bienfaits: [
            { titre: "Fonction cognitive", description: "Le magnésium contribue à l'équilibre électrolytique.<br>Équilibre électrolytique et acido-basique.<br>Le magnésium contribue à des fonctions psychologiques normales." },
            { titre: "Fonction des tissus", description: "Le magnésium contribue à une fonction musculaire normale." },
            { titre: "Formation des tissus", description: "Le magnésium joue un rôle dans le processus de division cellulaire." },
            { titre: "Métabolisme énergétique", description: "Le magnésium contribue à une métabolisme énergétique normal.<br>Le magnésium contribue à une synthèse protéique normale." },
            { titre: "Santé bucco-dentaire", description: "Le magnésium contribue au maintien d'une dentition normale." },
            { titre: "Santé osseuse", description: "Le magnésium contribue au maintien d'une ossature normale." },
            { titre: "Système nerveux", description: "Le magnésium contribue au fonctionnement normal du système nerveux." },
            { titre: "Tonique", description: "Le magnésium contribue à réduire la fatigue." },
        ],
        url: "/liposomes/magnesium"
    },
    {
        nom: "manganèse",
        categorie: "minéral",
        description: `
        <label class='bold'>Le manganèse</label> est un oligo-élément essentiel qui intervient dans divers processus biochimiques au sein de l'organisme. Il joue un rôle clé dans <label class='bold'>le métabolisme énergétique</label> et <label class='bold'>la formation des tissus conjonctifs</label>, soutenant ainsi <label class='bold'>la structure</label> et <label class='bold'>la souplesse des os et des articulations</label>. <br><br>

        En outre, ce minéral agit au niveau cellulaire en contribuant à la défense contre le stress oxydatif, protégeant les cellules des effets des radicaux libres.<br><br>

        Le manganèse est donc pris dans les formulations visant à soutenir <label class='bold'>le bien-être</label> et <label class='bold'>la vitalité générale</label>, et il est souvent inclus dans <label class='bold'>les compléments nutritionnels</label> pour ses multiples bienfaits.<br><br>

        En raison de sa structure liposomale, le manganèse bénéficie d’une meilleure biodisponibilité, permettant une <label class='bold'>libération plus efficace</label> dans l’organisme. 
        `,
        img: manganese,
        type: "liposome",
        product: ["gamme vitalité", "multivitamines", mockupMultivitamines],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la manganèse sont les suivants :",
        bienfaits: [
            { titre: "Antioxydant", description: "Le manganèse contribue à protéger les cellules contre le stress oxydatif." },
            { titre: "Formation des tissus", description: "Le manganèse contribue à la formation normale des tissus conjonctifs." },
            { titre: "Métabolisme énergétique", description: "Le manganèse contribue à un métabolisme énergétique normal." },
            { titre: "Métabolisme glucidique", description: "Contribue à réguler le métabolisme des glucides." },
            { titre: "Santé osseuse", description: "Le manganèse contribue au maintien d'une ossature normale." },
        ],
        url: "/liposomes/manganese"
    },
    {
        nom: "multivitamines",
        categorie: "vitamine",
        description: `
        <label class='bold'>Le complexe multivitamines liposomées</label> regroupe un large éventail <label class='bold'>de vitamines essentielles</label>, conçues pour combler <label class='bold'>les carences nutritionnelles</label> et soutenir les besoins quotidiens de l’organisme. <br><br>

        Ce mélange polyvalent contribue à renforcer <label class='bold'>le métabolisme énergétique, à réduire la fatigue, et à soutenir le système immunitaire</label>.<br><br>

        En combinant les bienfaits <label class='bold'>de vitamines</label> clés, comme <label class='bold'>les vitamines A, C, D, E,</label> et celles <label class='bold'>du complexe B</label>, cette formule agit sur plusieurs fronts : <label class='bold'>peau, cheveux, ongles, santé osseuse, fonction musculaire, et performances cognitives</label>. <br><br>

        Elle est idéale pour maintenir <label class='bold'>une vitalité générale</label> et répondre aux besoins spécifiques <label class='bold'>des modes de vie actifs</label> ou <label class='bold'>des périodes de stress</label>.<br><br>

        En raison de sa structure liposomale, le complexe multivitamines bénéficie d’une meilleure biodisponibilité, permettant une libération <label class='bold'>plus efficace</label> dans l’organisme. <br><br>

        Parfait pour les formulations de compléments alimentaires visant à améliorer <label class='bold'>la santé globale</label>, à optimiser <label class='bold'>la récupération</label> et à soutenir <label class='bold'>une immunité renforcée</label>, le <label class='bold'>complexe multivitamines</label> est un choix incontournable pour une vie en pleine forme.
        `,
        img: multivitamines,
        type: "liposome",
        product: ["gamme vitalité", "multivitamines", mockupMultivitamines],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du multivitamine sont les suivants : ",
        bienfaits: [
            { titre: "Anti-âge", description: "Aide à améliorer le processus général de vieillissement, protège la peau contre le stress environnemental et contre le vieillissement prématuré." },
            { titre: "Antioxydant", description: "Contribue à protéger les cellules contre le stress oxydatif.<br>Mélange d'antioxydants naturels aidant à protéger les cellules contre les radicaux libres, maintenant un bon niveau de vitamines et d'antioxydants." },
            { titre: "Fonction thyroïdienne", description: "Contribue à une fonction thyroïdienne normale." },
            { titre: "Peau / Phanères", description: "Contribue au maintien de cheveux normaux.<br>Contribue au maintien des ongles normaux.<br>Aide à améliorer la santé et l'apparence de la peau en stimulant le développement du collagène et soutient les défenses naturelles contre les dommages induits par les UV." },
            { titre: "Santé sexuelle", description: "Contribuer à une spermatogénèse normale." },
            { titre: "Système immunitaire", description: "Contribue au fonctionnement normal du système immunitaire." },
            { titre: "Système cardiovasculaire", description: "Contribue à l'entretien du cœur et de la santé cardiovasculaire, aide à améliorer le système cardiovasculaire, le vieillissement général, les problèmes immunitaires, et protège contre les stress environnementaux." },
        ],
        url: "/liposomes/multivitamines"
    },
    {
        nom: "opc · pépins de raisin",
        categorie: ["antioxydant", "végétal"],
        description: `
        <label class='bold'>Les OPC liposomés</label>, ou proanthocyanidines oligomères, sont des <label class='bold'>antioxydants</label> puissants souvent extraits <label class='bold'>des pépins de raisin</label>. Reconnus pour leurs propriétés <label class='bold'>protectrices</label>, ils contribuent activement au soutien de <label class='bold'>la santé cardiovasculaire et vasculaire</label>, en renforçant <label class='bold'>les vaisseaux</label> et en améliorant <label class='bold'>la microcirculation</label>.<br><br>

        En piégeant efficacement les radicaux libres, les OPC permettent également de lutter contre les effets du <label class='bold'>stress physique</label> et <label class='bold'>environnemental</label>, tout en soutenant les tissus conjonctifs et en protégeant <label class='bold'>les yeux</label> des rayons UV. Ils enrichissent les formulations de compléments alimentaires pour <label class='bold'>le bien-être global</label> et <label class='bold'>la santé circulatoire</label>.<br><br>

        <label class='bold'>Les OPC</label> jouent également un rôle clé dans la protection des tissus conjonctifs et la réduction des signes de vieillissement cutané, en préservant <label class='bold'>l’élasticité</label> et <label class='bold'>la fermeté</label> de la peau.<br><br>

        Grâce à leur formulation liposomée, les OPC bénéficient d’une meilleure absorption par l’organisme, garantissant ainsi une <label class='bold'>efficacité optimale</label>.<br><br>

        Ce produit est idéal pour des formules <label class='bold'>anti-âge</label>, pour la santé <label class='bold'>cardiovasculaire</label>, ou encore pour <label class='bold'>des soins de la peau</label>.
        `,
        img: pepinsDeRaisin,
        type: "liposome",
        product: ["gamme bien-être", "circulation", mockupCirculation],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés des OPC sont les suivants :",
        bienfaits: [
            { titre: "Propriétés antioxydantes", description: "Agir en tant que piégeurs de radicaux naturels dans des situations de stress physique et mental.<br>L'extrait de pépins de raisin est une source d'OPC pour un antioxydant naturel.<br> Les proanthocyanidines protègent l'œil oligomère des rayons ultraviolets" },
            { titre: "Santé cardiovasculaire et circulatoire ", description: "Renforcer le cœur et les vaisseaux pour la santé du cœur et le système circulatoire. <br>Pour sceller les vaisseaux et améliorer la microcirculation.<br>Pour soutenir des vaisseaux sanguins sains" },
            { titre: "Soutien du tissu conjonctif ", description: "A un effet de renforcement sur le tissu conjonctif" },
        ],
        url: "/liposomes/opc"
    },
    {
        nom: "prêle des champs",
        categorie: ["végétal"],
        description: `
        <label class='bold'>La prêle des champs liposomée</label> est une forme avancée de la plante, ayant des principes actifs mieux absorbés par l’organisme, offrant ainsi une efficacité accrue.<br><br>

        Riche  <label class='bold'>en silice</label> et  <label class='bold'>en minéraux, la prêle des champs liposomée</label> soutient la  <label class='bold'>santé des os, des articulations et des cheveux</label>. Il est particulièrement apprécié pour son action dans le renforcement des tissus conjonctifs, contribuant ainsi à maintenir la fermeté et l’élasticité de la peau. Son pouvoir antioxydant permet également de lutter contre  <label class='bold'>le vieillissement cellulaire et d’améliorer la santé capillaire et des ongles</label>.<br><br>

        <label class='bold'>La prêle des champs liposomée</label> est idéal dans les formules destinées à l'entretien  <label class='bold'>des articulations</label>, à  <label class='bold'>la santé des cheveux</label> ou à  <label class='bold'>la protection de la peau</label>. Il peut également être utilisé pour renforcer l’action des autres actifs dans des formules visant à améliorer la santé générale et la vitalité.<br><br>

        Optez pour  <label class='bold'>la prêle des champs liposomée</label> dans vos produits de compléments alimentaires pour offrir à vos clients une efficacité renforcée grâce à sa formulation innovante et sa  <label class='bold'>haute biodisponibilité</label>.
        `,
        img: preleDesChamps,
        type: "liposome",
        product: ["gamme beauté", "capillaire", mockupCapillaire],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la prêle des champs sont les suivants :",
        bienfaits: [
            { titre: "Anti-stress", description: "Favorise la détente et contribue à un bon équilibre nerveux.<br>Apaisant, relaxant Utilisé dans les problèmes de nervosité." },
            { titre: "Antioxydant", description: "Combat la formation des radicaux libres. <br>Lutte contre le vieillissement cellulaire.<br>A utiliser pour se protéger des radicaux libres." },
            { titre: "Fonction hépatique", description: "Améliore la fonction hépatique grâce à son effet protecteur." },
            { titre: "Fonction rénale", description: "Facilite les fonctions d’élimination de l’organisme.<br>Traditionnellement utilisé pour contribuer / soutenir / aider à l'élimination par les reins." },
            { titre: "Minceur", description: "Facilite la perte de poids en complément de mesures diététiques." },
            { titre: "Système cardiovasculaire", description: "Maintient la résistance des parois des vaisseaux sanguins et des artères coronaires, imperméables.<br>Maintient l'élasticité des artères.<br>Aide à la relaxation des vaisseaux sanguins.<br>Fournit du sang oxygéné au myocarde." },
            { titre: "Peau", description: "Il améliore l'état de  la peau, des cheveux et des ongles, favorise la croissance des cheveux et le renforcement." },
            { titre: "Divers", description: "Pour la santé des organes respiratoires, promotion de la toux et de la résistance du corps (capacités de résistance).<br>Contient des herbes aux propriétés diurétiques, apaisantes et réductrices de spasmes.<br>Peut aider à reminéraliser le corps / la silice dans la prêle aide à maintenir les cheveux, les ongles et la peau dans un état optimal." },
        ],
        url: "/liposomes/preledeschamps"
    },
    {
        nom: "rhodiola",
        categorie: ["végétal"],
        description: `
        <label class='bold'>La rhodiola rosea liposomée</label> est une forme hautement biodisponible de cette <label class='bold'>plante adaptogène</label> reconnue pour ses propriétés exceptionnelles contre <label class='bold'>le stress</label>. <br><br>

        <label class='bold'>Grâce à la technologie liposomale</label>, ses actifs sont encapsulés pour garantir une absorption optimale et une efficacité renforcée, permettant de mieux gérer <label class='bold'>les défis physiques et émotionnels</label> du quotidien.<br><br>

        Cette plante est idéale pour soutenir <label class='bold'>le système nerveux</label>, améliorer la résistance face <label class='bold'>au stress émotionnel et physique</label>, et optimiser les performances mentales et physiques. <br><br>

        Elle aide également à protéger les cellules du stress oxydatif grâce à ses puissantes propriétés <label class='bold'>antioxydantes</label>, tout en soutenant la vitalité cardiovasculaire et le bien-être général.<br><br>

        La rhodiola liposomée est particulièrement prisée dans les formulations visant à <label class='bold'>réduire le stress</label>, améliorer <label class='bold'>l’endurance sportive</label>, renforcer <label class='bold'>les capacités cognitives</label> et préserver la santé globale. <br><br>

        Optez pour cette solution innovante et efficace pour offrir à vos clients un <label class='bold'>complément alimentaire de nouvelle génération</label>.
                `,
        img: rhodiola,
        type: "liposome",
        product: ["gamme bien-être", "anti-stress", mockupAntiStress],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la rhodiola sont les suivants :",
        bienfaits: [
            { titre: "Anti-stress", description: "Aide l’organisme à s’adapter au stress émotionnel et physique.<br>Stimule le système nerveux et améliore l’état d’esprit.<br>Réduit la fatigue et les maux de tête liés au stress.Soutient la qualité du sommeil et la performance au travail.<br>Aide à protéger le système cardiovasculaire contre les effets du stress." },
            { titre: "Antioxydant", description: "Protège les cellules et le système nerveux contre les radicaux libres.<br>Réduit l’impact du stress oxydatif et renforce la résistance physique et mentale.<br>Inhibe les processus d’oxydation nocifs pour préserver les fonctions cellulaires." },
            { titre: "Circulation", description: "Soutient le fonctionnement normal du système circulatoire en apportant oxygène et nutriments aux cellules.<br>Apaise les tensions du système circulatoire." },
            { titre: "Système Digestif", description: "Contribue à la santé du tractus gastro-intestinal et normalise ses fonctions.<br>Performance sportive.<br>Renforce l’endurance et les performances sportives face aux effets du stress physique." },
            { titre: "Système cardiovasculaire", description: "Protège le système cardiovasculaire en aidant le corps à s’adapter au stress émotionnel et physique." },
        ],
        url: "/liposomes/rhodiola"
    },
    {
        nom: "scutellaire du baïkal",
        categorie: ["végétal"],
        description: `
        <label class='bold'>La scutellaire liposomée</label> est une version avancée de cette plante reconnue pour ses propriétés <label class='bold'>antioxydantes</label> et son soutien à <label class='bold'>la santé articulaire</label>. <br><br>

        <label class='bold'>Grâce à la technologie liposomale</label>, ses actifs bénéficient d’une absorption améliorée, offrant une efficacité optimale dans la protection contre <label class='bold'>les dommages oxydatifs</label> et le maintien de <label class='bold'>la mobilité articulaire</label>.<br><br>
        
        Idéale pour préserver la flexibilité <label class='bold'>des articulations</label> et protéger <label class='bold'>les tendons, la scutellaire</label> aide également à réduire <label class='bold'>le stress oxydatif</label> qui affecte <label class='bold'>le collagène</label>, favorisant ainsi une meilleure mobilité et <label class='bold'>une récupération optimale</label> après l’effort physique. <br><br>
        
        Ce puissant <label class='bold'>antioxydant</label> est particulièrement apprécié dans les formulations visant à soutenir la santé articulaire, la flexibilité et la performance physique.<br><br>
        
        Optez pour <label class='bold'>la scutellaire liposomée</label> dans vos <label class='bold'>compléments alimentaires</label> pour offrir à vos clients une solution innovante et efficace pour protéger <label class='bold'>leurs articulations</label>.
                `,
        img: scutellaireDuBaikal,
        type: "liposome",
        product: ["gamme bien-être", "articulation", mockupArticulation],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du scutellaire sont les suivants :",
        bienfaits: [
            { titre: "Antioxydant", description: "Agit comme un antioxydant naturel.<br>Réduit le stress oxydatif causé par les dommages au collagène." },
            { titre: "Santé des articulations", description: "Soutient la santé des articulations.<br>Maintient la flexibilité des articulations et protège les tendons.<br>Améliore la mobilité articulaire." },
            { titre: "Performance musculaires et sportives", description: "Contribue au bon fonctionnement des muscles squelettiques lors d'une activité physique normale, d’un exercice physique ou d’une activité sportive." },
        ],
        url: "/liposomes/scutellairedubaikal"
    },
    {
        nom: "sélénium",
        categorie: ["minéral"],
        description: `
        <label class='bold'>Le sélénium liposomé</label> est une forme innovante de cet oligo-élément essentiel, reconnu pour ses puissantes propriétés <label class='bold'>antioxydantes</label> et son rôle clé dans le maintien de <label class='bold'>la santé globale</label>. <br><br>

        <label class='bold'>Grâce à la technologie liposomale</label>, le sélénium bénéficie d’une absorption optimale, offrant une efficacité renforcée pour protéger les cellules contre <label class='bold'>les radicaux libres</label>, soutenir <label class='bold'>les fonctions immunitaires</label> et favoriser un vieillissement sain.<br><br>

        Le sélénium est particulièrement apprécié pour son impact sur <label class='bold'>la santé de la peau, des cheveux et des ongles</label>, ainsi que pour son rôle dans <label class='bold'>la fonction thyroïdienne, la santé cardiovasculaire et la fertilité masculine</label>. <br><br>

        Intégré dans des formulations innovantes, il est idéal pour répondre aux besoins des consommateurs cherchant une solution naturelle et performante pour protéger <label class='bold'>leur santé</label> et <label class='bold'>leur vitalité</label>.<br><br>

        Optez pour <label class='bold'>le sélénium liposomé</label> dans vos compléments alimentaires pour offrir à vos clients une solution complète et <label class='bold'>innovante</label>.
                `,
        img: selenium,
        type: "liposome",
        product: ["gamme vitalité", "immunité", mockupImmunite],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du sélénium sont les suivants :",
        bienfaits: [
            { titre: "Anti-âge", description: "Protège la peau contre le stress environnemental et le vieillissement prématuré.<br>Soutient le processus général de vieillissement sain." },
            { titre: "Antioxydant", description: "Contribue à protéger les cellules contre le stress oxydatif.<br>Aide à maintenir un bon niveau d'antioxydants et protège les cellules contre les radicaux libres." },
            { titre: "Fonction thyroïdienne", description: "Contribue à une fonction thyroïdienne normale." },
            { titre: "Peau et phanères", description: "Contribue au maintien de cheveux normaux.<br>Contribue au maintien des ongles normaux.<br>Améliore la santé et l’apparence de la peau en soutenant la production de collagène.<br>Protège la peau contre les dommages induits par les UV." },
            { titre: "Santé sexuelle", description: "Contribue à une spermatogénèse normale." },
            { titre: "Système immunitaire", description: "Contribue au fonctionnement normal du système immunitaire." },
            { titre: "Système cardiovasculaire", description: "Soutient la santé du cœur et le bon fonctionnement du système cardiovasculaire.<br>Protège contre le stress environnemental et les problèmes liés au vieillissement." },
        ],
        url: "/liposomes/selenium"
    },
    {
        nom: "vigne rouge",
        categorie: ["végétal"],
        description: `
        <label class='bold'>La vigne rouge liposomée</label> est une forme hautement biodisponible de <label class='bold'>la vigne rouge</label>, connue pour ses propriétés exceptionnelles dans le soutien de la <label class='bold'>circulation sanguine</label> et <label class='bold'>la santé vasculaire</label>.<br><br>

        <label class='bold'>Grâce à la technologie liposomale</label>, ses actifs, notamment <label class='bold'>les anthocyanines</label> et <label class='bold'>les flavonoïdes</label>, sont mieux absorbés, offrant ainsi une efficacité optimale dans les compléments alimentaires.<br><br>

        La vigne rouge liposomée est idéale pour renforcer <label class='bold'>les vaisseaux sanguins</label>, améliorer <label class='bold'>la microcirculation</label> et réduire la sensation de <label class='bold'>jambes lourdes</label>. Elle contribue également à la protection des vaisseaux, ce qui peut avoir un effet bénéfique sur les personnes souffrant <label class='bold'>de troubles circulatoires</label>. <br><br>

        Ses propriétés <label class='bold'>antioxydantes</label> aident à combattre <label class='bold'>le stress oxydatif</label>, améliorant <label class='bold'>la santé cardiaque</label>.<br><br>

        Cette plante est particulièrement recherchée dans les formules destinées à améliorer <label class='bold'>la circulation sanguine</label>, à réduire les symptômes de l'insuffisance veineuse et à soutenir <label class='bold'>la santé des jambes</label>. Son efficacité renforcée en fait un ingrédient de choix pour les produits de <label class='bold'>santé vasculaire</label> et de bien-être général.<br><br>

        Optez pour <label class='bold'>la vigne rouge liposomée</label> dans <label class='bold'>vos compléments alimentaires</label> pour offrir à vos clients une solution innovante et efficace pour prendre soin de leur <label class='bold'>circulation sanguine</label> et de <label class='bold'>leur santé veineuse</label>.
                `,
        img: vigneRouge,
        type: "liposome",
        product: ["gamme bien-être", "circulation", mockupCirculation],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la vigne rouge sont les suivants :",
        bienfaits: [
            { titre: "Anti-stress", description: "Favorise la détente et l'équilibre nerveux, aidant à un bon équilibre mental et à des performances mentales optimisées. Apaisant et relaxant, il améliore la fonction mentale." },
            { titre: "Anti-âge", description: "Aide à ralentir le vieillissement prématuré en protégeant la peau contre le stress environnemental, les radicaux libres, et les rayons UV, surtout en combinaison avec des crèmes topiques." },
            { titre: "Antioxydant", description: "Source riche en antioxydants naturels, elle contribue à protéger la peau et les cellules contre les dommages des radicaux libres et à maintenir une peau saine." },
            { titre: "Circulation", description: "La vigne rouge soutient la circulation sanguine, particulièrement dans les jambes, en particulier la sensation de jambes lourdes et fatiguées. Elle favorise le retour veineux et aide à préserver la santé des veines grâce à ses flavonoïdes, qui maintiennent l'intégrité des parois capillaires et assurent l'échange de fluide normal entre la circulation et les tissus environnants." },
            { titre: "Minceur", description: "Aide à affiner la silhouette et à réduire l'apparence de la cellulite, soutenant un poids corporel stable." },
            { titre: "Fonction cognitive", description: "Favorise la détente et contribue à l'équilibre nerveux et mental, aidant à l'amélioration des performances mentales." },
            { titre: "Peau", description: "Soutient la santé et l'apparence de la peau, avec des propriétés antioxydantes aidant à prévenir les dommages liés aux radicaux libres." },
            { titre: "Protection peau contre les UV", description: "Soutient les défenses naturelles de la peau contre les dommages causés par le rayonnement UV en contribuant au développement du collagène." },
            { titre: "Système immunitaire", description: "Les antioxydants naturels du raisin contribuent à renforcer les défenses de l'organisme et à protéger les cellules contre les radicaux libres." },
            { titre: "Système cardiovasculaire", description: "Favorise une pression artérielle saine et soutient le métabolique et la fonction cardiaque." },
            { titre: "Vision", description: "Grâce aux proanthocyanidines, la vigne rouge protège les yeux contre les rayons UV et soutient la santé des vaisseaux sanguins oculaires." },
        ],
        url: "/liposomes/vignerouge"
    },
    {
        nom: "vitamine a",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La vitamine A</label> est une vitamine <label class='bold'>liposoluble</label> indispensable pour le maintien de la vision, la santé de la peau, et le fonctionnement normal du système immunitaire. <br><br>

        Elle joue également un rôle clé dans <label class='bold'>la croissance cellulaire</label> et le soutien des muqueuses, contribuant à <label class='bold'>la protection des voies respiratoires, digestives et urinaires</label>.<br><br>

        <label class='bold'>La vitamine A liposomée</label> est particulièrement appréciée pour son action sur <label class='bold'>la santé oculaire</label>, notamment dans l’adaptation à l’obscurité, et pour son rôle dans le maintien d’une <label class='bold'>peau saine et éclatante</label>. C’est un actif essentiel dans les formulations visant à promouvoir <label class='bold'>une santé globale</label>, à renforcer les défenses naturelles et à améliorer <label class='bold'>l’apparence de la peau</label>.<br><br>

        Grâce à sa forme liposomée, la vitamine A bénéficie  d’une meilleure absorption par l’organisme, garantissant ainsi une efficacité optimale.<br><br>

        Intégrez <label class='bold'>la vitamine A</label> dans <label class='bold'>vos compléments alimentaires</label> pour offrir à vos clients une solution complète et efficace pour soutenir leur bien-être quotidien.
                `,
        img: vitamineA,
        type: "liposome",
        product: ["gamme bien-être", "multivitamines", mockupMultivitamines],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la vitamine A sont les suivants :",
        bienfaits: [
            { titre: "Formation des tissus", description: "Joue un rôle dans le processus de spécialisation cellulaire.<br>Contribue au maintien des muqueuses normales." },
            { titre: "Métabolisme vitamines et minéraux", description: "Contribue au métabolisme normal du fer." },
            { titre: "Peau et phanères", description: "Contribue au maintien d'une peau normale." },
            { titre: "Système immunitaire", description: "Contribue au fonctionnement normal du système immunitaire." },
            { titre: "Vision", description: "Contribue au maintien d'une vision normale." },
        ],
        url: "/liposomes/vitaminea"
    },
    {
        nom: "vitamine b1",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La vitamine B1</label>, également appelée thiamine, est un nutriment essentiel intervenant principalement dans <label class='bold'>le métabolisme des glucides</label> et <label class='bold'>la production d'énergie</label> au sein des cellules. <br><br>

        Elle joue un rôle clé dans le fonctionnement normal du <label class='bold'>système nerveux</label>, favorisant ainsi la transmission de l'influx nerveux et le maintien de la <label class='bold'>clarté mentale</label>. Son action est également bénéfique pour le maintien d'une bonne santé cardiovasculaire et le soutien d'une <label class='bold'>vitalité</label> au quotidien.<br><br>
        
        Sous forme liposomée, la vitamine B1 bénéficie d'une <label class='bold'>biodisponibilité optimale</label>, améliorant son assimilation et son efficacité. Reconnue pour sa contribution à la <label class='bold'>résistance à la fatigue et au stress</label>, elle s'intègre parfaitement aux formules visant à soutenir l'énergie naturelle, la performance cognitive et l'équilibre nerveux.<br><br>
        
        Idéale pour accompagner les personnes actives et soucieuses de leur bien-être, la Vitamine B1 liposomée constitue un ingrédient de choix pour maintenir <label class='bold'>un métabolisme performant</label> et <label class='bold'>favoriser la vitalité</label> globale.
                `,
        img: vitamineB1,
        type: "liposome",
        product: ["gamme les essentiels", "vitamines b complex", mockupVitamineBComplex],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la vitamine B1 sont les suivants :",
        bienfaits: [
            { titre: "Métabolisme énergétique", description: "La thiamine contribue à une digestion énergétique normale.<br>La thiamine contribue à une fonction cardiaque normale." },
            { titre: "Système nerveux", description: "La thiamine contribue aux fonctions psychologiques normales.<br>La thiamine contribue au fonctionnement normal du système nerveux." },
            { titre: "Enfants", description: "La thiamine contribue au maintien d'un développement neurologique normal et d'une fonction neurologique normale.<br>La thiamine contribue à un métabolisme glucidique normale et à un métabolisme énergétique normale." },
        ],
        url: "/liposomes/vitamineb1"
    },
    {
        nom: "vitamine b3",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La Vitamine B3</label>, également appelée niacine, est un nutriment incontournable pour le métabolisme énergétique et le maintien d'une <label class='bold'>fonction nerveuse</label> optimale. <br><br>

        Elle intervient notamment dans la synthèse des coenzymes NAD et NADP, assurant ainsi la <label class='bold'>conversion des macronutriments</label> (glucides, lipides, protéines) en énergie utilisable par l'organisme. Par son action bénéfique sur la <label class='bold'>circulation sanguine</label>, elle contribue également à soutenir la <label class='bold'>santé cardiovasculaire</label> et la vitalité globale.<br><br>
        
        Sous forme liposomée, la Vitamine B3 profite d'une biodisponibilité améliorée, garantissant une assimilation optimale et des effets renforcés. Reconnue pour son rôle dans le maintien de la peau en bonne santé et la <label class='bold'>régulation de l'humeur</label>, elle s'intègre parfaitement dans des formules visant à soutenir l'énergie naturelle, l'équilibre psychique et la <label class='bold'>performance physique</label>.<br><br>
        
        Grâce à sa forme liposomée, la vitamine B3 bénéficie  d’une <label class='bold'>meilleure absorption</label> par l’organisme, garantissant ainsi une efficacité optimale.<br><br>
        
        Idéale pour les personnes actives et soucieuses de leur bien-être, la vitamine B3 liposomée s'impose ainsi comme un ingrédient de choix dans la quête d'une <label class='bold'>vitalité accumulée</label> et d'un <label class='bold'>métabolisme performant</label>.
                `,
        img: vitamineB3,
        type: "liposome",
        product: ["gamme les essentiels", "vitamines b complex", mockupVitamineBComplex],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la vitamine B3 sont les suivants :",
        bienfaits: [
            { titre: "Fonction cognitive", description: "La niacine contribue à des fonctions psychologiques normales." },
            { titre: "Métabolisme énergétique", description: "La niacine contribue à une digestion énergétique normale.<br>Fonction des membranes cellulaires.<br>La niacine contribue au maintien de muqueuses normales." },
            { titre: "Système nerveux", description: "La niacine contribue au fonctionnement normal du système nerveux." },
            { titre: "Peau et phanères", description: "La niacine contribue au maintien d'une peau normale." },
            { titre: "Tonique", description: "La niacine contribue à réduire la fatigue." },
        ],
        url: "/liposomes/vitamineb3"
    },
    {
        nom: "vitamine b5",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La vitamine B5</label>, également appelée acide pantothénique, est un élément essentiel dans le métabolisme énergétique et la <label class='bold'>synthèse des acides gras, hormones stéroïdiennes et neurotransmetteurs</label>. <br><br>

        Elle contribue à transformer les nutriments (lipides, glucides, protéines) en énergie utilisable, soutenant ainsi la vitalité quotidienne et la fonction cognitive. De plus, elle joue un rôle important dans le <label class='bold'>maintien d'une peau saine</label> et d'un <label class='bold'>équilibre émotionnel</label>.<br><br>

        Sous sa forme liposomée, la Vitamine B5 bénéficie d'une biodisponibilité supérieure, optimisant son assimilation et son efficacité. Reconnue pour son soutien au <label class='bold'>système nerveux et à la résistance au stress</label>, elle s'intègre parfaitement aux formules visant à améliorer l'énergie naturelle, la performance mentale et le bien-être global. <br><br>

        Grâce à sa forme liposomée, la vitamine B12 bénéficie  d’une <label class='bold'>meilleure absorption</label> par l’organisme, garantissant ainsi une efficacité optimale. <br><br>

        Idéale pour les personnes actives, la vitamine B5 liposomée se révèle un ingrédient de choix pour <label class='bold'>renforcer la vitalité</label> et <label class='bold'>favoriser une santé métabolique</label>.
                `,
        img: vitamineB5,
        type: "liposome",
        product: ["gamme les essentiels", "vitamines b complex", mockupVitamineBComplex],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la vitamine B5 sont les suivants :",
        bienfaits: [
            { titre: "Enfants", description: "La vitamine B5 (acide pantothénique) est nécessaire pour libérer l'énergie des aliments.<br>L'acide pantothénique (vitamine B5) contribue au métabolisme énergétique normal." },
            { titre: "Fonction cognitive", description: "L'acide pantothénique contribue à des performances intellectuelles normales." },
            { titre: "Métabolisme hormonal", description: "L'acide pantothénique contribue à la synthèse normale et au métabolisme normal des hormones stéroïdes, de la vitamine D et de certains neurotransmetteurs." },
            { titre: "Métabolisme vitamines et minéraux", description: "L'acide pantothénique contribue à la synthèse normale et au métabolisme normal des hormones stéroïdes, de la vitamine D et de certains neurotransmetteurs." },
            { titre: "Métabolisme énergétique", description: "L'acide pantothénique contribue à une digestion énergétique normale." },
        ],
        url: "/liposomes/vitamineb5"
    },
    {
        nom: "vitamine b6",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La vitamine B6</label>, ou pyridoxine, est un nutriment indispensable jouant un rôle clé dans de nombreuses réactions métaboliques de l'organisme, notamment dans la <label class='bold'>synthèse des protéines</label> et la production de globules rouges.<br><br>

        Elle contribue également à la <label class='bold'>bonne santé du système nerveux</label> en participant à la formation de <label class='bold'>neurotransmetteurs</label>, soutenant ainsi <label class='bold'>l'équilibre émotionnel</label> et aidant à réduire la fatigue. <br><br>

        De plus, son action sur la <label class='bold'>régulation hormonale</label> la rend essentielle pour maintenir un bien-être global et aider à gérer les variations du cycle menstruel.<br><br>

        En tant que cofacteur polyvalent, la vitamine B6 liposomée soutient l'organisme dans sa lutte contre le stress oxydatif et favorise un <label class='bold'>métabolisme énergétique</label> optimal.<br><br>

        Grâce à sa forme liposomée, la vitamine B6 bénéficie  d’une <label class='bold'>meilleure absorption</label> par l’organisme, garantissant ainsi une efficacité optimale. <br><br>

        Idéale pour les formulations visant à soutenir l'équilibre nerveux, la <label class='bold'>gestion du stress</label> et la <label class='bold'>vitalité</label> quotidienne, la vitamine B6 constitue un ingrédient de choix pour répondre aux besoins de ceux qui cherchent à optimiser leur santé et leur bien-être.
                `,
        img: vitamineB6,
        type: "liposome",
        product: ["gamme les essentiels", "vitamines b complex", mockupVitamineBComplex],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la vitamine B6 sont les suivants :",
        bienfaits: [
            { titre: "Fonction cognitive", description: "La vitamine B6 contribue aux fonctions psychologiques normales." },
            { titre: "Métabolisme énergétique", description: "La vitamine B6 contribue à un métabolisme énergétique normal." },
            { titre: "Métabolisme hormonal", description: "La vitamine B6 contribue à la synthèse normale de la cystéine.<br>La vitamine B6 contribue au métabolisme normal des protéines et du glycogène.<br>La vitamine B6 contribue à réguler l'activité hormonale." },
            { titre: "Système cardiovasculaire", description: "La vitamine B6 contribue au métabolisme normal de l'homocystéine." },
            { titre: "Système immunitaire", description: "La vitamine B6 contribue au fonctionnement normal du système immunitaire." },
            { titre: "Système nerveux", description: "La vitamine B6 contribue au fonctionnement normal du système nerveux." },
            { titre: "Système sanguin", description: "La vitamine B6 contribue à la formation normale de globules rouges." },
            { titre: "Tonique", description: "La vitamine B6 contribue à réduire la fatigue." },
        ],
        url: "/liposomes/vitamineb6"
    },
    {
        nom: "vitamine b9",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La vitamine B9</label>, également appelée acide folique, est un nutriment essentiel au bon déroulement de la division cellulaire et à la <label class='bold'>formation des globules rouges</label>. <br><br>

        Elle contribue ainsi à la <label class='bold'>régénération des tissus</label>, au maintien d'un <label class='bold'>système nerveux sain</label> et à la prévention des carences pouvant affecter la vitalité. Par ailleurs, la vitamine B9 joue un rôle important dans le soutien de la <label class='bold'>fonction cognitive</label> et <label class='bold'>l'équilibre émotionnel</label>.<br><br>
        
        Sous forme liposomée, la vitamine B9 bénéficie d'une assimilation optimale, renforçant son efficacité et son impact sur l'organisme.<br><br> 
        
        Reconnue pour son action favorable sur la <label class='bold'>santé cellulaire</label> et la <label class='bold'>résistance à la fatigue</label>, elle s'intègre parfaitement aux formules visant à soutenir la vitalité générale, le fonctionnement cognitif et le bien-être quotidien.<br><br>
        
        Idéale pour accompagner les personnes actives et soucieuses de leur santé, la vitamine B9 liposomée constitue un ingrédient de choix pour maintenir un métabolisme performant et contribuer à une meilleure qualité de vie.
                `,
        img: vitamineB9,
        type: "liposome",
        product: false,
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la vitamine B9 sont les suivants :",
        bienfaits: [
            { titre: "Fonction cognitive", description: "Les folates contribuent à des fonctions psychologiques normales." },
            { titre: "Fonction des membranes cellulaires", description: "Les folates jouent un rôle dans le processus de division cellulaire.<br>Les folates contribuent à la croissance des tissus maternels pendant la grossesse." },
            { titre: "Métabolisme énergétique", description: "Les folates contribuent à la synthèse normale des acides aminés." },
            { titre: "Système immunitaire", description: "Les folates contribuent au fonctionnement normal du système immunitaire." },
            { titre: "Système cardiovasculaire", description: "Les folates contribuent au métabolisme normal de l'homocystéine." },
            { titre: "Système nerveux", description: "La supplémentation en acide folique augmente le statut maternel en folates. Un faible statut en folates chez la mère augmente les risques d'apparition d'anomalies du tube neural chez le fœtus en développement." },
            { titre: "Système sanguin", description: "Les folates contribuent à la formation normale du sang." },
        ],
        url: "/liposomes/vitamineb9"
    },
    {
        nom: "vitamine b12",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La vitamine B12</label>, ou cobalamine, est un nutriment indispensable au bon fonctionnement de l'organisme. Elle participe activement à la <label class='bold'>formation des globules rouges</label> et à la <label class='bold'>synthèse de l'ADN</label>, tout en soutenant la santé du système nerveux. Grâce à son rôle dans la production d'énergie, la vitamine B12 aide à <label class='bold'>réduire la fatigue</label> et à maintenir une vitalité optimale.<br><br>

        Son action est particulièrement bénéfique pour le métabolisme, <label class='bold'>l'équilibre mental</label> et la préservation des fonctions cognitives. En favorisant la <label class='bold'>régénération cellulaire</label> et la protection des nerfs, elle contribue au bien-être global et à la prévention de divers déséquilibres.<br><br>
        
        Grâce à sa forme liposomée, la vitamine B12 bénéficie  d’une <label class='bold'>meilleure absorption</label> par l’organisme, garantissant ainsi une efficacité optimale.<br><br>
        
        Idéale pour les formulations visant à renforcer la vitalité, soutenir la <label class='bold'>santé neurologique</label> et améliorer l'équilibre énergétique, la vitamine B12 se présente comme un ingrédient de choix pour répondre aux besoins de ceux qui souhaitent optimiser leur qualité de vie au quotidien.<br><br>
                `,
        img: vitamineB12,
        type: "liposome",
        product: ["gamme les essentiels", "vitamines b12", mockupVitamineB12],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la vitamine B12 sont les suivants :",
        bienfaits: [
            { titre: "Fonction cognitive", description: "La vitamine B12 contribue à des fonctions psychologiques normales." },
            { titre: "Formation des tissus", description: "La vitamine B12 joue un rôle dans le processus de division cellulaire." },
            { titre: "Métabolisme énergétique", description: "La vitamine B12 contribue à un métabolisme énergétique normal." },
            { titre: "Système immunitaire", description: "La vitamine B12 contribue au fonctionnement normal du système immunitaire." },
            { titre: "Système cardiovasculaire", description: "La vitamine B12 contribue au métabolisme normal de l’homocystéine." },
            { titre: "Système nerveux", description: "La vitamine B12 contribue au fonctionnement normal du système nerveux." },
            { titre: "Système sanguin", description: "La vitamine B12 contribue à la formation normale de globules rouges." },
            { titre: "Tonique", description: "La vitamine B12 contribue à réduire la fatigue." },
        ],
        url: "/liposomes/vitamineb12"
    },
    {
        nom: "vitamine d3",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La vitamine D3</label> est essentielle pour le maintien de <label class='bold'>la santé des os</label> et <label class='bold'>des dents</label>, en favorisant une absorption optimale <label class='bold'>du calcium et du phosphore</label>. Elle joue également un rôle clé dans le fonctionnement normal <label class='bold'>du système immunitaire</label>, renforçant <label class='bold'>les défenses naturelles</label>.<br><br>

        <label class='bold'>Cette vitamine liposoluble</label> est particulièrement bénéfique pour soutenir <label class='bold'>la fonction musculaire</label> et contribuer à la division cellulaire normale, tout en aidant à maintenir une calcémie normale. La vitamine D3 est souvent recommandée pour compenser le manque d’exposition au soleil, notamment en hiver.<br><br>
        
        Grâce à sa forme liposomée, la vitamine D3 bénéficie  d’une meilleure absorption par l’organisme, garantissant ainsi une <label class='bold'>efficacité optimale</label>.<br><br>
        
        Parfaitement adaptée aux formulations de <label class='bold'>compléments alimentaires</label> visant à améliorer la <label class='bold'>santé osseuse</label>, renforcer <label class='bold'>l’immunité</label>, et promouvoir un bien-être global, la vitamine D3 est un ingrédient incontournable pour répondre aux besoins quotidiens en vitamine D.
                `,
        img: vitamineD3,
        type: "liposome",
        product: ["gamme les essentiels", "vitamines d3", mockupvitamineD3],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la vitamine D3 sont les suivants :",
        bienfaits: [
            { titre: "Densité osseuse", description: "Le calcium et la vitamine D contribuent à réduire la perte de densité minérale osseuse chez les femmes ménopausées, facteur de risque des fractures ostéoporotiques." },
            { titre: "Enfants", description: "La vitamine D est nécessaire à une croissance et un développement osseux normaux chez les enfants.<br>La vitamine D contribue au fonctionnement normal du système immunitaire des enfants.<br>Le calcium et la vitamine D sont essentiels pour la croissance et le développement osseux des enfants." },
            { titre: "Fonction des tissus", description: "La vitamine D contribue au maintien d'une fonction musculaire normale et à la formation des tissus.<br>La vitamine D joue un rôle dans le processus de division cellulaire." },
            { titre: "Métabolisme vitamines et minéraux", description: "La vitamine D contribue à l'absorption et à l'utilisation normales du calcium et du phosphore.<br>La vitamine D contribue à une calcémie normale." },
            { titre: "Santé bucco-dentaire", description: "La vitamine D contribue au maintien d'une dentition normale." },
            { titre: "Santé osseuse", description: "La vitamine D contribue au maintien d'une ossature normale.<br>La vitamine D réduit le risque de chute dû à l'instabilité posturale et à la faiblesse musculaire chez les plus de 60 ans, facteur de risque de fractures." },
            { titre: "Système immunitaire", description: "La vitamine D contribue au fonctionnement normal du système immunitaire." },
        ],
        url: "/liposomes/vitamined3"
    },
    {
        nom: "acide hyaluronique",
        categorie: ["végétal"],
        description: `
        <label class='bold'>L’acide hyaluronique liposomée</label> est une matière première précieuse, reconnue pour ses multiples <label class='bold'>bienfaits</label> sur la peau, les articulations et l’hydratation globale de l’organisme. Naturellement présent dans le corps, il joue un rôle clé dans le maintien de <label class='bold'>l’élasticité</label> et de la <label class='bold'>fermeté des tissus</label>, tout en favorisant la lubrification des <label class='bold'>articulations</label>.<br><br>

        <label class='bold'>L’acide hyaluronique</label> est une solution idéale pour répondre aux besoins croissants des consommateurs en quête de <label class='bold'>santé</label> et de <label class='bold'>bien-être</label>. Sous forme de poudre, il s’intègre facilement dans des formules ciblées pour <label class='bold'>la beauté</label> de <label class='bold'>la peau</label> (anti-âge, hydratation), <label class='bold'>le confort articulaire</label> ou encore le renforcement des <label class='bold'>tissus conjonctifs</label>.<br><br>
        
        En choisissant notre <label class='bold'>acide hyaluronique</label> de qualité, vous bénéficiez d’une matière première pure, liposomée et conforme aux normes de production les plus strictes. <br><br>
        
        Idéal pour développer des produits premium, notre <label class='bold'>acide hyaluronique</label> répond aux exigences des consommateurs soucieux d’efficacité et de naturalité.
                `,
        img: hyaluronique,
        type: "liposome",
        product: ["gamme beauté", "hyaluronique", mockupHyaluronique],
        bienfaits: [
            { titre: "Hydratation intense", description: "Maintient l'humidité des tissus, notamment la peau." },
            { titre: "Anti-âge", description: "Réduit les rides et améliore l'élasticité cutanée." },
            { titre: "Soutien articulaire", description: "Lubrifie et protège les articulations." },
            { titre: "Régénération cellulaire", description: "Favorise la réparation des tissus." },
            { titre: "Santé des tissus conjonctifs", description: "Renforce la structure des tendons, ligaments et cartilages." },
            { titre: "Effet repulpant", description: "Améliore la fermeté et l'apparence de la peau." },
            { titre: "Confort articulaire", description: "Réduit les douleurs articulaires liées à l’usure." },
        ],
        url: "/liposomes/acide-hyaluronique",
        url2: "/niosomes/acide-hyaluronique"
    },
    {
        nom: "astaxanthine",
        categorie: ["végétal"],
        description: `
        <label class='bold'>L’astaxanthine liposomée</label> est une forme hautement <label class='bold'>biodisponible</label> de ce <label class='bold'>caroténoïde naturel</label>, reconnu comme l’un des plus puissants antioxydants. Grâce à la technologie  liposomale, ses actifs sont encapsulés, garantissant une absorption optimale et une <label class='bold'>efficacité accrue</label> pour protéger les cellules contre les dommages oxydatifs.<br><br>

        L’astaxanthine liposomée est idéale pour soutenir <label class='bold'>la santé cellulaire</label>, réduire les effets du <label class='bold'>stress oxydatif</label> et favoriser un vieillissement en bonne santé. Elle est également bénéfique pour <label class='bold'>la peau</label>, en protégeant contre <label class='bold'>les effets des rayons UV</label>, et pour les yeux, en soutenant <label class='bold'>la vision</label> et la récupération visuelle.<br><br>
        
        Ce puissant <label class='bold'>antioxydant</label> est particulièrement prisé dans les formulations dédiées à <label class='bold'>l’anti-âge</label>, au bien-être cutané, à la santé oculaire, et au renforcement des défenses naturelles. <br><br>
        
        Le procédé liposomal amplifie ses bienfaits, en faisant un ingrédient de premier choix pour des compléments alimentaires performants.<br><br>
        
        Optez pour l’astaxanthine liposomée dans vos compléments alimentaires pour offrir à vos clients une solution innovante et efficace pour <label class='bold'>préserver leur jeunesse</label>, leur peau et leur vitalité.
                `,
        img: astaxanthine,
        type: "liposome",
        product: false,
        bienfaits: [
            { titre: "Propriétés antioxydantes", description: "Protège les cellules contre le stress oxydatif et les dommages causés par les radicaux libres.<br>Réduit les marqueurs de l'inflammation chronique.<br>Soutient la santé générale en ralentissant le vieillissement cellulaire." },
            { titre: "Santé de la peau", description: "Améliore l'élasticité et l'hydratation de la peau.<br>Réduit les rides et les tâches liées au vieillissement.<br>Protège la peau contre les effets nocifs des rayons UV et des agressions environnementales." },
            { titre: "Soutien oculaire", description: "Protège les yeux contre les effets de la lumière bleue et des rayons UV.<br>Améliore la récupération visuelle après une exposition prolongée à la lumière.<br>Réduit la fatigue oculaire et améliore la santé rétinienne." },
            { titre: "Santé cardio-vasculaire", description: "Améliore la circulation sanguine et réduit l’oxydation des lipides.<br>Soutient la santé des vaisseaux sanguins et la fonction cardiaque." },
            { titre: "Performance physique et récupération", description: "Réduit la fatigue musculaire et le stress oxydatif lié à l’exercice.<br>Accélère la récupération après un effort intense.<br>Améliore l’endurance et les performances sportives." },
            { titre: "Soutien immunitaire", description: "Renforce les défenses naturelles de l’organisme.<br>Protège le système immunitaire contre les effets néfastes du stress oxydatif." },
            { titre: "Santé cognitive", description: "Protège les neurones contre les dommages oxydatifs.<br>Contribue à maintenir une bonne mémoire et des fonctions cognitives optimales." },
            { titre: "Bien-être articulaire", description: "Réduit les douleurs et raideurs articulaires" },
        ],
        url: "/liposomes/astaxanthine",
        url2: "/niosomes/astaxanthine"
    },
    {
        nom: "coenzyme q10",
        categorie: ["coenzyme"],
        description: `
        <label class='bold'>La coenzyme Q10 liposomée</label>, souvent abrégée en Q10 ou CoQ10, est un composé naturellement présent dans les cellules du corps. Ce nutriment joue un rôle central dans le processus de <label class='bold'>production d'énergie cellulaire</label>, intervenant au cœur des mitochondries. <br><br>

        Connue pour ses propriétés biochimiques uniques, la Q10 est particulièrement abondante dans les organes à forte demande énergétique, comme le <label class='bold'>cœur, les muscles et le foie</label>.<br><br>
        
        Grâce à sa structure moléculaire spécifique, la coenzyme Q10 est également prise dans les formulations visant à <label class='bold'>renforcer la vitalité</label> et le bien-être général. <br><br>
        
        Grâce à sa forme liposomée, la coenzyme Q10 est mieux absorbée par l'organisme, garantissant une <label class='bold'>efficacité accrue</label> et une biodisponibilité optimale.<br><br>
        
        Elle est disponible sous forme de compléments alimentaires et peut être intégrée dans les produits de soins, notamment pour la peau.
        
                `,
        img: coenzymeQ10,
        type: "liposome",
        product: ["gamme les essentiels", "coenzyme q10", mockupCoenzymeQ10],
        bienfaits: [
            { titre: "Soutien au système immunitaire", description: "La CoQ10 aide à renforcer le système immunitaire en soutenant le fonctionnement optimal des cellules immunitaires et en protégeant contre les infections." },
            { titre: "Amélioration de la santé osseuse", description: "La CoQ10 peut contribuer à la prévention de l'ostéoporose en améliorant la densité osseuse et en réduisant l'oxydation des cellules osseuses." },
            { titre: "Protection contre la dégénérescence rétinienne", description: "La CoQ10 pourrait aider à prévenir la dégénérescence maculaire liée à l'âge en protégeant les cellules de la rétine contre le stress oxydatif." },
            { titre: "Amélioration de la santé des cheveux", description: "Réduction de l'inflammation.<br>En réduisant les dommages oxydatifs et en favorisant une meilleure circulation sanguine vers le cuir chevelu, la CoQ10 peut contribuer à la santé des cheveux et prévenir leur chute.<br>Lubrifie et protège les articulations." },
            { titre: "Gestion du stress et de l'anxiété", description: "Grâce à ses propriétés antioxydantes et à son rôle dans la production d'énergie, la CoQ10 pourrait aider à réduire les niveaux de stress et d'anxiété." },
        ],
        url: "/liposomes/coenzyme-q10",
        url2: "/niosomes/coenzyme-q10",
    },
    {
        nom: "collagène",
        categorie: ["protéine"],
        description: `
        <label class='bold'>Le collagène</label> est une protéine structurelle clé, naturellement présente dans la peau, les os, les tendons et les articulations. Il est essentiel pour maintenir <label class='bold'>l’élasticité</label> et <label class='bold'>la fermeté de la peau</label>, tout en soutenant la résilience des tissus conjonctifs.<br><br>

        Le collagène contribue également à la <label class='bold'>santé articulaire</label>, en favorisant la <label class='bold'>souplesse</label> et en aidant à réduire les inconforts liés au vieillissement ou à l’usure articulaire. Sa capacité à stimuler la <label class='bold'>régénération</label> des tissus en fait un ingrédient clé pour préserver la jeunesse de la peau et la mobilité articulaire.<br><br>

        Idéal pour les formulations de compléments alimentaires dédiées à la <label class='bold'>beauté de la peau</label>, à la santé des <label class='bold'>articulations</label>, et au <label class='bold'>bien-être général</label>, le collagène est un allié précieux pour répondre aux besoins des personnes actives et soucieuses de leur apparence.
                `,
        img: collagene,
        type: "liposome",
        product: false,
        bienfaits: [
            { titre: "Peau et beauté", description: "Maintient l’élasticité et la fermeté de la peau.<br>Réduit l’apparence des rides et des ridules, aidant à prévenir le vieillissement cutané.<br>Favorise une peau hydratée et éclatante.<br>Stimule la régénération des tissus cutanés, améliorant l’apparence générale de la peau." },
            { titre: "Articulations et tissus conjonctifs", description: "Renforce les cartilages pour une meilleure souplesse articulaire.<br>Réduit les douleurs articulaires et l’inconfort lié à l’usure ou à l’âge.<br>Soutient la régénération des tissus conjonctifs, améliorant la mobilité et la flexibilité." },
            { titre: "Santé des cheveux et des ongles", description: "Favorise des cheveux plus forts et plus épais.<br>Aide à prévenir les ongles cassants et stimule leur croissance.<br>Santé osseuse.<br>Contribue à la densité osseuse, renforçant la solidité des os.<br>Aide à prévenir les troubles liés à la fragilité osseuse, comme l’ostéoporose." },
            { titre: "Santé cardio-vasculaire", description: "Améliore la circulation sanguine et réduit l’oxydation des lipides.<br>Soutient la santé des vaisseaux sanguins et la fonction cardiaque." },
            { titre: "Performance physique et récupération", description: "Soutient la récupération musculaire et articulaire après l’effort.<br>Améliore la résistance des tendons et ligaments, réduisant le risque de blessures." },
            { titre: "Soutien immunitaire", description: "Renforce les défenses naturelles de l’organisme.<br>Protège le système immunitaire contre les effets néfastes du stress oxydatif." },
            { titre: "Bien-être général", description: "Contribue à la régénération cellulaire, favorisant la santé globale.<br>Aide à réduire les effets du vieillissement prématuré grâce à son rôle structurel dans l’organisme." },
        ],
        url: "/liposomes/collagene",
        url2: "/niosomes/collagene",
    },
    {
        nom: "glutathion",
        categorie: ["antioxydant"],
        description: `
        <label class='bold'>Le glutathion liposomé</label> est une forme hautement biodisponible de cet antioxydant puissant, optimisée grâce à <label class='bold'>la technologie liposomée</label>.<br><br>

        En tant qu’<label class='bold'>antioxydant</label> majeur, le glutathion joue un rôle clé dans la neutralisation des radicaux libres et la <label class='bold'>réduction du stress oxydatif</label>, protégeant ainsi les cellules et les tissus contre les dommages.<br><br>
        
        Il soutient également <label class='bold'>le système immunitaire</label>, aide à <label class='bold'>la détoxification du foie</label>, et améliore <label class='bold'>la santé de la peau</label>.<br><br>
        
        <label class='bold'>Le glutathion liposomé</label> est idéal pour des formules de compléments alimentaires, offrant une <label class='bold'>absorption rapide et une efficacité maximale</label>.<br><br>
        
        Utilisé pour ses bienfaits anti-âge et sa capacité à améliorer <label class='bold'>la vitalité</label>, il constitue un ingrédient précieux pour vos produits.
                `,
        img: glutathion,
        type: "liposome",
        product: ["gamme les essentiels", "glutathion", mockupGlutathion],
        bienfaits: [
            { titre: "Protection antioxydante", description: "Neutralise les radicaux libres et réduit le stress oxydatif." },
            { titre: "Soutien au système immunitaire", description: "Renforce les défenses naturelles du corps." },
            { titre: "Détoxification", description: "Aide à éliminer les toxines et soutient la fonction hépatique." },
            { titre: "Anti-âge", description: "Réduit les signes du vieillissement cellulaire en protégeant contre les dommages oxydatifs." },
            { titre: "Amélioration de la santé de la peau", description: "Contribue à l'éclat et à la régénération de la peau." },
            { titre: "Amélioration de la vitalité", description: "Stimule l'énergie et la vitalité cellulaire." },
            { titre: "Soutien aux fonctions cérébrales", description: "Protège les cellules nerveuses contre les dommages oxydatifs." },
            { titre: "Amélioration de l’absorption des nutriments", description: "Grâce à la technologie liposomée, le glutathion est mieux absorbé par l'organisme." },
        ],
        url: "/liposomes/glutathion",
        url2: "/niosomes/glutathion",
    },
    {
        nom: "biotine",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La biotine liposomée</label>, également connue sous le nom de <label class='bold'>vitamine B8</label>, est une vitamine clé pour <label class='bold'>la beauté et la santé</label>. Elle joue un rôle essentiel dans le métabolisme énergétique et le bon fonctionnement des cellules, soutenant ainsi la <label class='bold'>vitalité des cheveux, de la peau, et des ongles</label>.<br><br>

        <label class='bold'>La biotine</label> contribue à renforcer les cheveux, <label class='bold'>en réduisant leur chute</label> et en favorisant une croissance saine. Elle aide également à maintenir <label class='bold'>une peau éclatante</label> et des ongles résistants, en prévenant les cassures et en améliorant leur aspect global.<br><br>

        Grâce à sa forme liposomée, la biotine est mieux absorbée par l'organisme, garantissant une <label class='bold'>efficacité accrue</label> et une <label class='bold'>biodisponibilité optimale</label>.<br><br>

        Idéale pour les formulations de compléments alimentaires dédiées à la beauté et au bien-être, la biotine est un choix incontournable pour ceux qui souhaitent soutenir leur <label class='bold'>apparence naturellement</label> et <label class='bold'>efficacement</label>.
                `,
        img: biotine,
        type: "liposome",
        product: ["gamme beauté", "biotine", mockupBiotine],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la biotine sont les suivants : ",
        bienfaits: [
            { titre: "Fonction cognitive", description: "La biotine contribue à des fonctions psychologiques normales." },
            { titre: "Formation des tissus", description: "La biotine contribue au maintien de muqueuses normales." },
            { titre: "Métabolisme énergétique", description: "La biotine contribue au métabolisme normal des Macronutriments.<br>La biotine contribue à un métabolisme énergétique normal." },
            { titre: "Peau / Phanères", description: "La biotine contribue au maintien d'une peau normale.<br>La biotine contribue au maintien de cheveux normaux." },
            { titre: "Système nerveux", description: "La biotine contribue au fonctionnement normal du système nerveux." },
        ],
        url: "/liposomes/biotine",
        url2: "/niosomes/biotine",
    },
    {
        nom: "mélatonine",
        categorie: ["substance"],
        description: `
        <label class='bold'>La mélatonine</label> est une hormone naturelle essentielle dans la <label class='bold'>régulation du cycle veille-sommeil</label>. Elle contribue à atténuer les effets du <label class='bold'>décalage horaire</label> et à réduire le <label class='bold'>temps d'endormissement</label>, favorisant ainsi une transition plus douce et rapide vers un sommeil réparateur. Reconnue pour ses effets sur la chronobiologie, la mélatonine liposomée aide à <label class='bold'>rétablir les rythmes circadiens</label>, facilite l'adaptation aux changements de fuseau horaire et améliore <label class='bold'>la qualité du sommeil</label>.<br><br>

        En tant que <label class='bold'>régulateur du sommeil</label>, elle soutient également le bien-être général en favorisant un <label class='bold'>repos efficace</label> et en particulier la fatigue liée aux perturbations du sommeil. <br><br>
        
        Grâce à sa forme liposomée, la mélatonine bénéficie  d’une <label class='bold'>meilleure absorption</label> par l’organisme, garantissant ainsi une efficacité optimale. <br><br>
        
        Idéale pour les formulations visant à améliorer <label class='bold'>la qualité du sommeil</label>, à gérer <label class='bold'>le décalage horaire</label> et à favoriser un endormissement rapide, la mélatonine est un ingrédient innovant pour répondre aux besoins des personnes cherchant à optimiser leur <label class='bold'>repos</label> et leur bien-être quotidien.
        
                `,
        img: melatonine,
        type: "liposome",
        product: ["gamme bien-être", "sommeil", mockupSommeil],
        allegation: "D'après l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la mélatonine sont les suivants :",
        bienfaits: [
            { titre: "Chronobiologie", description: "La mélatonine contribue à atténuer les effets du décalage horaire." },
            { titre: "Sommeil", description: "La mélatonine contribue à réduire le temps d'endormissement." },
        ],
        url: "/liposomes/melatonine",
        url2: "/niosomes/melatonine",
    },
    {
        nom: "resvératrol",
        categorie: ["antioxydant"],
        description: `
        <label class='bold'>Le resvératrol liposomé</label> est une version hautement biodisponible de ce <label class='bold'>polyphénol</label> renommé, reconnu pour ses propriétés exceptionnelles dans la lutte contre <label class='bold'>le stress oxydatif</label> et le soutien à la santé globale. <br><br>

        Grâce à <label class='bold'>la technologie liposomale</label>, ses actifs sont encapsulés pour garantir une protection optimale et une absorption maximale par l’organisme, offrant ainsi une <label class='bold'>efficacité accrue</label>.<br><br>

        Le resvératrol liposomé est idéal pour protéger les cellules contre les dommages oxydatifs, favoriser le vieillissement en bonne santé et <label class='bold'>soutenir le bien-être cardiovasculaire</label>. Il contribue également à améliorer <label class='bold'>la vitalité, à renforcer les défenses naturelles et à stimuler les fonctions cérébrales</label>.<br><br>

        Ce puissant antioxydant est particulièrement prisé dans les formulations destinées à <label class='bold'>la protection cellulaire</label>, à <label class='bold'>l’anti-âge</label> et au maintien de <label class='bold'>la santé cardiovasculaire</label>. <br><br>

        Optez pour <label class='bold'>le resvératrol liposomé</label> dans vos <label class='bold'>compléments alimentaires</label> pour offrir à vos clients une solution naturelle et efficace pour préserver leur santé et <label class='bold'>leur vitalité</label>.
                `,
        img: resveratrol,
        type: "liposome",
        product: ["gamme les essentiels", "resvératrol", mockupResveratrol],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), l’avantage santé prouvé du resvératrol est le suivant :",
        bienfaits: [
            { titre: "Système cardiovasculaire", description: "Il soutient la santé cardiovasculaire." },
        ],
        url: "/liposomes/resveratrol",
        url2: "/niosomes/resveratrol",
    },
    {
        nom: "vitamine c",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La vitamine C</label> est un nutriment hydrosoluble essentiel pour <label class='bold'>soutenir le système immunitaire</label> et <label class='bold'>protéger les cellules</label> du stress oxydatif. <br><br>

        Elle joue également un rôle clé dans la <label class='bold'>production de collagène</label>, favorisant la santé de la peau, des os et des cartilages.<br><br>

        Indispensable pour <label class='bold'>l'absorption du fer</label> et <label class='bold'>la réduction de la fatigue</label>, la vitamine C contribue à maintenir un métabolisme énergétique normal. Elle se révèle particulièrement bénéfique en périodes de fatigue ou lors des <label class='bold'>changements de saison</label>, lorsque les défenses de l'organisme sont davantage sollicitées.<br><br>

        Grâce à sa forme liposomée, la vitamine C bénéficie  d’une <label class='bold'>meilleure absorption</label> par l’organisme, garantissant ainsi une efficacité optimale.<br><br>

        Idéale pour les formulations de compléments alimentaires visant à renforcer <label class='bold'>l'immunité</label>, soutenir la <label class='bold'>vitalité</label> et protéger les cellules, la vitamine C est un ingrédient incontournable pour répondre aux besoins quotidiens et maintenir un bien-être optimal.
                `,
        img: vitamineC,
        type: "liposome",
        product: ["gamme vitalité", "vitamine c+", mockupVitamineC],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la vitamine C sont les suivants :",
        bienfaits: [
            { titre: "Anti-âge", description: "Aide à protéger la peau contre le vieillissement prématuré en particulier l'érythème et le stress oxydatif causé par les radicaux libres et les rayons UV, avec un effet protecteur supplémentaire si combiné à des crèmes topiques." },
            { titre: "Antioxydant", description: "La vitamine C contribue à protéger les cellules contre le stress oxydatif.<br>La vitamine C contribue à la régénération de la forme réduite de la vitamine E." },
            { titre: "Enfants", description: "La vitamine C contribue au fonctionnement normal du système immunitaire et améliore l'absorption du fer." },
            { titre: "Fonction cognitive", description: "La vitamine C contribue aux fonctions psychologiques normales." },
            { titre: "Formation des tissus", description: "La vitamine C contribue à la formation normale du collagène pour le bon fonctionnement des vaisseaux sanguins, des vaisseaux sanguins, de la peau, des os, des cartilages et des dents." },
            { titre: "Métabolisme vitamines et minéraux", description: "La vitamine C augmente l'absorption du fer." },
            { titre: "Métabolisme énergétique", description: "La vitamine C contribue à une métabolisme énergétique normal." },
            { titre: "Peau et phanères", description: "Contribue à améliorer la santé et l'apparence de la peau en soutenant le développement du collagène et les défenses naturelles contre les dommages induits par les UV." },
            { titre: "Système immunitaire", description: "La vitamine C contribue au fonctionnement normal du système immunitaire, y compris pendant et après un exercice physique intense (200 mg/jour recommandés pour cet effet)." },
            { titre: "Tonique", description: "La vitamine C contribue à réduire la fatigue." },
        ],
        url: "/liposomes/vitamine-c",
        url2: "/niosomes/vitamine-c",
    },
    {
        nom: "vitamine e",
        categorie: ["vitamine"],
        description: `
        <label class='bold'>La vitamine E</label>, sous sa forme active de <label class='bold'>tocophérol</label> , est une vitamine liposoluble reconnue pour ses puissantes <label class='bold'>propriétés antioxydantes</label>, qui protège les cellules contre les dommages causés par les radicaux libres. <br><br>

        Le tocophérol agit comme un bouclier naturel, préservant <label class='bold'>la santé cellulaire</label> et contribuant à ralentir les effets du vieillissement prématuré, tout en favorisant une peau éclatante et éclipsant les signes du temps.<br><br>

        Cette vitamine joue un rôle clé dans le bon fonctionnement <label class='bold'>du système immunitaire</label>, soutenant les défenses naturelles de l'organisme. Elle est également essentielle pour protéger les membranes cellulaires, en particulier celles riches en lipides, tout en favorisant <label class='bold'>une circulation sanguine</label> optimale et un équilibre général.<br><br>

        Grâce au tocophérol, la vitamine E devient un allié de taille pour les formulations <label class='bold'>de compléments alimentaires</label> ciblant la protection cellulaire, la beauté et le renforcement immunitaire. <br><br>

        Grâce à sa forme liposomée, la vitamine E bénéficie  d’une <label class='bold'>meilleure absorption</label> par l’organisme, garantissant ainsi une efficacité optimale.
                `,
        img: vitamineE,
        type: "liposome",
        product: ["gamme vitalité", "mutlivitamines", mockupMultivitamines],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés de la vitamine E sont les suivants :",
        bienfaits: [
            { titre: "Anti-âge", description: "Aide à améliorer le processus de vieillissement, protège la peau contre le stress environnemental et le vieillissement prématuré en particulier l'érythème et les effets des radicaux libres." },
            { titre: "Antioxydant", description: "La vitamine E contribue à protéger les cellules contre le stress oxydatif.<br>Mélange scientifiquement prouvé d'antioxydants naturels aidant à protéger les cellules des dommages des radicaux libres et maintenant un bon niveau de vitamines dans le corps." },
            { titre: "Enfants", description: "La vitamine E contribue à la protection des constituants des cellules contre les dommages oxydatifs." },
            { titre: "Peau et phanères", description: " Contribue à améliorer la santé et l'apparence de la peau en favorisant le développement du collagène et en soutenant les défenses naturelles contre les dommages causés par les UV." },
            { titre: "Protection peau contre UV", description: "Aide à améliorer la santé et l'apparence de la peau en soutenant les défenses naturelles contre les dommages induits par les rayons UV." },
            { titre: "Système cardiovasculaire", description: "Aide à maintenir la santé cardiaque et cardiovasculaire.<br>Améliore le système cardiovasculaire et offre une protection contre les stress environnementaux." },
        ],
        url: "/liposomes/vitamine-e",
        url2: "/niosomes/vitamine-e",
    },
    {
        nom: "zinc",
        categorie: ["minéral"],
        description: `
        <label class='bold'>Le zinc</label> est <label class='bold'>un oligo-élément</label> indispensable à de nombreuses <label class='bold'>fonctions biologiques</label>. Il contribue au fonctionnement normal du <label class='bold'>système immunitaire</label>, soutenant les <label class='bold'>défenses naturelles</label> contre les agressions extérieures.<br><br>

        Ce minéral liposomé joue également un rôle clé dans le maintien <label class='bold'>d’une peau saine</label>, des cheveux et des ongles solides, ainsi que dans la <label class='bold'>cicatrisation</label> des tissus. En tant <label class='bold'>qu’antioxydant</label>, le zinc aide à protéger les cellules contre <label class='bold'>le stress oxydatif</label>, ralentissant ainsi les effets du vieillissement prématuré.<br><br>
        
        Le zinc est aussi crucial pour <label class='bold'>la fertilité et la reproduction</label>, ainsi que pour <label class='bold'>le métabolisme</label> des macronutriments et la synthèse des protéines.<br><br>
        
        Grâce à sa forme liposomée, le zinc bénéficie  d’une meilleure absorption par l’organisme, garantissant ainsi une <label class='bold'>efficacité optimale</label>.<br><br>
        
        Idéal pour les formulations visant à améliorer la santé de la <label class='bold'>peau</label>, renforcer <label class='bold'>l’immunité</label>, et soutenir le bien-être général, le zinc est un actif polyvalent et essentiel pour un corps en pleine santé.<br><br>
                `,
        img: zinc,
        type: "liposome",
        product: ["gamme les essentiels", "zinc", mockupZinc],
        allegation: "Selon l'Autorité Européenne de Sécurité des Aliments (EFSA), les avantages santé prouvés du Zinc sont les suivants :",
        bienfaits: [
            { titre: "Antioxydant", description: "Contribue à protéger les cellules contre le stress oxydatif." },
            { titre: "Enfants", description: "Le zinc est essentiel pour la croissance.<br>Contribue au fonctionnement normal du système immunitaire." },
            { titre: "Équilibre électrolytique et acido-basique", description: "Contribue à un métabolisme acido basique normal." },
            { titre: "Fonction cognitive", description: " Contribue à une fonction cognitive normale." },
            { titre: "Métabolisme hormonal", description: "Contribue au maintien d'un taux normal de testostérone dans le sang." },
            { titre: "Formation des tissus", description: "Contribue à la synthèse normale de l'ADN.<br>Joue un rôle dans le processus de division cellulaire." },
            { titre: "Métabolisme vitamines et minéraux", description: " Contribue au métabolisme normal de la vitamine A." },
            { titre: "Métabolisme énergétique", description: "Le zinc contribue au métabolisme normal des acides gras.<br>Le zinc contribue à une synthèse protéique normale.<br>Le zinc contribue à une métabolisme glucidique normale.<br>Le zinc contribue au métabolisme normal des macronutriments." },
            { titre: "Peau et phanères", description: "Le zinc contribue au maintien d'une peau normale.<br>Le zinc contribue au maintien de cheveux normaux.<br>Le zinc contribue au maintien des ongles normaux." },
            { titre: "Santé osseuse", description: "Le zinc contribue au maintien d'une ossature normale." },
            { titre: "Santé sexuelle", description: "Le zinc contribue à une fertilité et une reproduction normales.<br>Le zinc contribue au maintien d'un taux normal de testostérone dans le sang." },
            { titre: "Système immunitaire", description: "Le zinc contribue au fonctionnement normal du système immunitaire." },
            { titre: "Vision", description: "Le zinc contribue au maintien d'une vision normale." },
        ],
        url: "/liposomes/zinc",
        url2: "/niosomes/zinc",
    },
    {
        nom: "acide alpha-lipoïque",
        subtitle: "éclaircissement",
        categorie: ["minéral"],
        formes: ["Sérum", "Crème"],
        description: `
        <label class='bold'>L'acide alpha-lipoïque</label> (AAL), reconnu pour ses puissantes propriétés antioxydantes, agit en synergie avec d'autres antioxydants pour protéger la peau contre le stress oxydatif et les agressions extérieures et <label class='bold'>éclaircir le teint</label>. <br><br>

        Grâce à la technologie niosomale, cet actif offre une absorption optimale et <label class='bold'>une action prolongée</label> pour des résultats visibles et durables.<br><br>
        
        Ce actif améliore la texture de la peau, réduit les rides et <label class='bold'>uniformise le teint</label>, tout en régénérant les vitamines C et E pour une peau éclatante et revitalisée. <br><br>
        
        Adapté à tous les types de peau, y compris les peaux sensibles, il procure une hydratation intense et un effet anti-âge global.
                `,
        img: alphaLipoique,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Puissant antioxydant", description: "L'acide alpha-lipoïque neutralise les radicaux libres, notamment les signes visibles du vieillissement prématuré tout en protégeant la peau des agressions comme les rayons UV et la pollution." },
            { titre: "Réduction des rides et lifting visuel", description: "En stimulant la production de collagène, il améliore l'élasticité et la fermeté de la peau, estompe rides et ridules pour un effet repulpant naturel." },
            { titre: "Uniformisation du teint et éclat", description: "Il atténue les taches pigmentaires et les irrégularités, révélant un teint plus clair, uniforme et lumineux." },
            { titre: "Hydratation cutanée améliorée", description: "Malgré ses propriétés exfoliantes, l'acide glycolique peut également améliorer l'hydratation de la peau. Il contribue à renforcer la fonction barrière de la peau, réduisant ainsi la perte d'eau et aidant la peau à retenir l'humidité plus efficacement." },
            { titre: "Régénération des antioxydants naturels", description: "L'acide alpha-lipoïque régénère la vitamine C et la vitamine E, renforçant leur efficacité pour une meilleure protection et réparation de la peau." },
            { titre: "Hydratation et protection cutanée", description: "En renforçant la barrière cutanée, il améliore l'hydratation et prévient la perte en eau, laissant la peau souple, douce et parfaitement hydratée." },
        ],
        url: "/niosomes/alphalipoique",
    },
    {
        nom: "acide azélaïque",
        subtitle: "matifiant",
        categorie: ["acides"],
        formes: ["Sérum", "Crème"],
        description: `
        <label class='bold'>L'acide azélaïque</label> est un actif multifonctionnel réputé pour ses propriétés <label class='bold'>apaisantes, régulatrices et éclaircissantes</label>. <br><br>

        Il agit efficacement sur les imperfections, les rougeurs et <label class='bold'>l'hyperpigmentation</label> tout en <label class='bold'>régulant la production de sébum</label>. <br><br>
        
        Grâce à la technologie niosomale, cet ingrédient bénéficie d'une <label class='bold'>libération renforcée</label> et d'une action prolongée, maximisant ses bienfaits pour la peau.<br><br>
        
        Cet actif, adapté à <label class='bold'>tous les types de peau</label>, y compris les plus sensibles, est une solution idéale pour <label class='bold'>unifier le teint</label>, réduire les imperfections et retrouver une peau douce, lisse et éclatante.        
                `,
        img: azelaique,
        type: "niosome",
        product: false,
        bienfaits2:["L'acide azélaïque peut être utilisé dans plusieurs applications :"],
        bienfaits: [
            { titre: "Réduction des imperfections", description: "L'acide azélaïque combat les bactéries responsables de l'acné et favorise le renouvellement cellulaire pour une peau plus claire et plus lisse." },
            { titre: "Régulation de la production de sébum", description: "En équilibrant la production de sébum, il aide à limiter les brillances et à prévenir l'apparition de nouvelles imperfections." },
            { titre: "Apaisement des rougeurs et irritations", description: "Grâce à ses propriétés anti-inflammatoires, cet actif calme les irritations et réduit visiblement les rougeurs, offrant un confort durable à la peau." },
            { titre: "Uniformité et éclat du teint", description: "L'acide azélaïque atténue les taches pigmentaires et les irrégularités, révélant un teint uniforme et lumineux." },
            { titre: "Convient aux peaux sensibles", description: "Sa formulation douce et non irritante en fait un allié parfait pour les peaux réactives ou sujettes à l'inconfort." },
        ],
        url: "/niosomes/azelaique",
    },
    {
        nom: "acide férulique",
        subtitle: "antioxydant & protection",
        categorie: ["acides", "antioxydants"],
        formes: ["Sérum", "Crème"],
        description: `
        <label class='bold'>L'acide férulique</label> est un antioxydant puissant reconnu pour sa capacité à <label class='bold'>protéger la peau</label> des agressions environnementales et à renforcer l'efficacité des autres antioxydants comme les vitamines C et E. <br><br>

        Grâce à la technologie niosomale, cet actif offre une <label class='bold'>absorption optimale</label> et une <label class='bold'>action prolongée</label>, maximisant les bienfaits de l'acide férulique pour la peau.<br><br>

        Ce soin convient à tous les types de peau, offrant une protection <label class='bold'>anti-âge</label>, une <label class='bold'>réduction des tâches pigmentaires</label> et un éclat naturel pour une peau visiblement plus saine et lumineuse.        
                `,
        img: ferulique,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Protection antioxydante puissante", description: "L'acide férulique protège les cellules cutanées des radicaux libres, préservant ainsi la jeunesse de la peau face aux agressions extérieures comme les UV et la pollution." },
            { titre: "Réduction des tâches pigmentaires", description: "En inhibant la production excessive de mélanine, cet actif aide à réduire l'apparence des taches brunes pour un teint plus uniforme." },
            { titre: "Prévention du vieillissement prématuré", description: "L'acide férulique ralentit les signes visibles du vieillissement, comme les rides et les ridules, en renforçant la protection naturelle de la peau." },
            { titre: "Renforcement de l'éclat du teint", description: "Ce sérum ravive l'éclat naturel de la peau, la rendant plus lumineuse et rayonnante au quotidien." },
            { titre: "Convient à tous les types de peau", description: "Grâce à sa formulation douce et non irritante, l'acide férulique convient même aux peaux sensibles, garantissant une tolérance optimale." },
        ],
        url: "/niosomes/ferulique",
    },
    {
        nom: "acide glycolique",
        subtitle: "exfoliant",
        categorie: ["acides"],
        formes: ["Sérum", "Crème"],
        description: `
        <label class='bold'>L'acide glycolique</label>, un AHA aux puissantes propriétés exfoliantes, favorise l'élimination des cellules mortes et stimule le renouvellement cellulaire.<br><br>

        Concentré en actifs exfoliants, il <label class='bold'>combat les points noirs</label> et les <label class='bold'>pores dilatés</label> pour un <label class='bold'>teint lisse</label> et uniforme</label>.<br><br>

        Grâce à la technologie niosomale, il assure une <label class='bold'>libération contrôlée</label>, améliorant son <label class='bold'>efficacité</label> tout en <label class='bold'>réduisant les irritations</label>, pour une peau visiblement plus lisse et lumineuse.        
                `,
        img: glycolique,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Augmentation de la production de collagène", description: "En stimulant la production de collagène, l'acide glycolique contribue à raffermir la peau, réduisant ainsi l'apparence des rides et des ridules. Le collagène est essentiel au maintien de l'élasticité et de la fermeté de la peau, et sa production accrue peut conduire à une peau plus saine et plus éclatante." },
            { titre: "Texture et tonus de la peau améliorés", description: "L'utilisation régulière d'acide glycolique peut améliorer la texture et le teint de la peau, la rendant plus lisse et plus uniforme. Il est efficace pour réduire les cicatrices d'acné, la pigmentation et les tâches de vieillesse, contribuant ainsi à un teint plus raffiné et plus éclatant." },
            { titre: "Éliminer l’acné", description: "L'acide glycolique peut aider à éliminer l'acné en exfoliant la peau et en débouchant les pores. Cela empêche l'accumulation de cellules mortes de la peau et de sébum qui peuvent entraîner des poussées d'acné, ce qui en fait un ingrédient efficace pour les personnes aux prises avec une peau à tendance acnéique." },
            { titre: "Hydratation cutanée améliorée", description: "Malgré ses propriétés exfoliantes, l'acide glycolique peut également améliorer l'hydratation de la peau. Il contribue à renforcer la fonction barrière de la peau, réduisant ainsi la perte d'eau et aidant la peau à retenir l'humidité plus efficacement." },
            { titre: "Minimiser les pores", description: "L'acide glycolique peut aider à minimiser l'apparence des pores en les débarrassant des débris et en réduisant leur taille. Cela conduit à une surface de peau plus lisse et à une réduction de la visibilité des pores." },
            { titre: "Augmentation de la pénétration des actifs", description: "En éliminant la couche externe des cellules mortes de la peau, l'acide glycolique augmente la capacité de la peau à absorber d'autres actifs. Cela signifie que les sérums, les hydratants et les traitements appliqués après l'acide glycolique peuvent pénétrer plus profondément et agir plus efficacement." },
        ],
        url: "/niosomes/glycolique",
    },
    {
        nom: "acide salicylique",
        subtitle: "anti-imperfections localisées",
        categorie: ["acides"],
        formes: ["Sérum", "Crème"],
        description: `
        <label class='bold'>L'acide salicylique</label>, un actif reconnu pour ses propriétés exfoliantes et purifiantes, agit efficacement pour désobstruer les pores, <label class='bold'>réduire les imperfections</label> et lisser le grain de peau.<br><br>

        Grâce à la technologie niosomale, cet actif offre une absorption optimale et une action prolongée, permettant à l’acide salicylique de <label class='bold'>pénétrer en profondeur</label> pour des résultats visibles et durables.<br><br>
        
        Ce soin convient particulièrement <label class='bold'>aux peaux mixtes à grasses et sujettes aux imperfections</label>, tout en restant doux et respectueux des peaux sensibles.
                
                `,
        img: salicyclique,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Exfoliation douce et efficace", description: "L'acide salicylique élimine les cellules mortes à la surface de la peau, révélant un teint plus frais et plus lumineux." },
            { titre: "Réduction des imperfections et des points noirs", description: "En pénétrant les pores, il désobstrue les impuretés, réduisant ainsi l’apparence des imperfections et prévenant leur réapparition." },
            { titre: "Régulation de la production de sébum", description: "Cet actif aide à équilibrer la production de sébum, diminuant les brillances et offrant un effet matifiant durable." },
            { titre: "Amélioration de la texture de la peau", description: "En affinant le grain de peau, l'acide salicylique rend la peau plus lisse et visiblement homogène." },
            { titre: "Convient aux peaux mixtes à grasses", description: "Grâce à sa formule douce, cet actif est idéal pour les peaux sujettes aux imperfections tout en respectant leur sensibilité." },
        ],
        url: "/niosomes/salicylique",
    },
    {
        nom: "acide tranexamique",
        subtitle: "anti-tâche & hyperpigmentation",
        categorie: ["acides"],
        formes: ["Sérum", "Crème"],
        description: `
        <label class='bold'>L’acide tranexamique</label> est un actif puissant reconnu pour son efficacité dans la réduction des <label class='bold'>taches pigmentaires</label>, des <label class='bold'>irrégularités</label> du teint et des <label class='bold'>rougeurs</label>. <br><br>

        Grâce à la technologie niosomale, cet actif offre une <label class='bold'>absorption renforcée</label> et une action prolongée, permettant à l’acide tranexamique d’agir en profondeur pour des résultats visibles et durables.<br><br>
        
        Ce soin est idéal pour les <label class='bold'>peaux sujettes à l’hyperpigmentation</label>, aux rougeurs ou aux irrégularités, en offrant une solution douce mais efficace pour un <label class='bold'>teint uniforme</label> et éclatant.
                `,
        img: tranexalique,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Réduction des tâches pigmentaires", description: "L’acide tranexamique agit efficacement pour atténuer les taches brunes et autres signes d’hyperpigmentation, laissant une peau plus uniforme." },
            { titre: "Uniformité et éclat du teint", description: "En régulant la production de mélanine, ce sérum favorise un teint plus lumineux et homogène, tout en réduisant les zones ternes." },
            { titre: "Apaisement des rougeurs", description: "Grâce à ses propriétés apaisantes, l’acide tranexamique aide à calmer les rougeurs et les irritations, offrant un confort visible pour les peaux sensibles." },
            { titre: "Amélioration de la texture de la peau", description: "Cet actif lisse le grain de peau, améliorant visiblement sa texture pour un toucher doux et soyeux." },
            { titre: "Convient à tous les types de peau", description: "Sa formulation douce et respectueuse garantit une tolérance optimale, même pour les peaux sensibles ou sujettes aux irritations." },
        ],
        url: "/niosomes/tranexamique",
    },
    {
        nom: "allantoïne",
        subtitle: "apaisant & régénérateur",
        categorie: ["végétal"],
        formes: ["Sérum", "Crème"],
        description: `
        <label class='bold'>L'allantoïne</label>, réputée pour ses propriétés réparatrices et apaisantes, est l'ingrédient idéal pour <label class='bold'>calmer les peaux sensibles, irritées</label> ou sujettes aux rougeurs. <br><br>

        Grâce à la technologie niosomale, cet actif offre une absorption optimale et une efficacité prolongée, permettant à l'allantoïne <label class='bold'>d'agir en profondeur</label> pour restaurer et hydrater la peau.<br><br>
        
        Ce actif convient à tous les types de peau, offrant une <label class='bold'>action réparatrice</label>, une protection contre les agressions extérieures et une visible de la texture et de la <label class='bold'>douceur</label> de la peau.
                `,
        img: allantoine,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Réparation de la peau", description: "L'allantoïne favorise le renouvellement cellulaire et accélère la cicatrisation, aidant à réparer les peaux abîmées ou irritées." },
            { titre: "Apaisement des irritations", description: "Grâce à ses propriétés calmantes, elle réduit les rougeurs et les sensations d'inconfort, offrant un apaisement immédiat pour les peaux sensibles." },
            { titre: "Hydratation et douceur cutanée", description: "L'allantoïne améliore la capacité de la peau à retenir l'humidité, procurant une hydratation intense et une peau douce et soyeuse." },
            { titre: "Protection contre les agressions extérieures", description: "Elle agit comme une barrière protectrice contre les facteurs environnementaux, aidant la peau à mieux se défendre contre la pollution et les irritants." },
            { titre: "Convient aux peaux sensibles et réactives", description: "Douce et non irritante, l'allantoïne est idéale pour les peaux délicates, garantissant une tolérance optimale même pour les peaux les plus réactives." },
        ],
        url: "/niosomes/allantoine",
    },
    {
        nom: "alpha-arbutine",
        subtitle: "anti-tâche brunes",
        categorie: ["végétal"],
        formes: ["Sérum", "Crème"],
        description: `
        <label class='bold'>L'alpha-arbutine</label>, connu pour ses <label class='bold'>propriétés éclaircissantes</label>, est une solution efficace et douce pour réduire l'apparence des taches pigmentaires, uniformiser le teint et révéler une peau éclatante. <br><br>

        En combinant cet ingrédient puissant avec la technologie niosomale, nous offrons une absorption optimale et une efficacité prolongée, <label class='bold'>sans irriter la peau</label>.<br><br>
        
        Adapté à tous les types de peau, y compris les peaux sensibles, <label class='bold'>cet actif agit en profondeur</label> pour réduire les irrégularités pigmentaires, tout en <label class='bold'>préservant l'hydratation</label> et en offrant une protection antioxydante.
        
                `,
        img: alphaArbutine,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Réduction des tâches pigmentaires", description: "L'alpha-arbutine inhibe la production excessive de mélanine, réduisant ainsi l'apparence des tâches brunes, des tâches de vieillesse et des irrégularités pigmentaires." },
            { titre: "Uniformité du teint et éclat", description: "En éclaircissant progressivement la peau, cet actif aide à uniformiser le teint et à révéler une peau plus lumineuse et éclatante." },
            { titre: "Protection contre le stress oxydatif", description: "Grâce à ses propriétés antioxydantes, l'alpha-arbutine protège la peau des agressions environnementales et des radicaux libres, ralentissant le vieillissement cutané." },
            { titre: "Hydratation et douceur cutanée", description: "L'alpha-arbutine maintient l'hydratation de la peau en renforçant sa barrière naturelle, la laissant douce, souple et parfaitement hydratée." },
            { titre: "Convient aux peaux sensibles", description: "Grâce à sa douceur, l'acide arbutine est adapté aux peaux sensibles, offrant une efficacité sans irritations ni rougeurs." },
        ],
        url: "/niosomes/alphaarbutine",
    },
    {
        nom: "bakuchiol",
        subtitle: "anti-imperfections",
        categorie: ["végétal"],
        formes: ["Sérum", "Crème"],
        description: `
        <label class='bold'>Le bakuchiol</label>, souvent qualifié d'alternative naturelle au rétinol, est reconnu pour ses propriétés <label class='bold'>anti-âge et régénérantes</label> adapté à tous types de peau. Ce composé naturel s'avère être une option efficace pour ceux qui recherchent une <label class='bold'>peau plus jeune et plus saine</label> sans les effets irritants souvent associés au rétinol.<br><br>

        Grâce à sa douceur unique, il offre une solution efficace pour <label class='bold'>stimuler le renouvellement cellulaire</label> contribuant à une peau plus lisse et plus éclatante, à un teint uniforme et améliorer l’élasticité, l'apparence des rides et ridules, tout en préservant la sensibilité de la peau.<br><br>
        
        En <label class='bold'>éliminant les cellules mortes</label> et en favorisant une nouvelle croissance, il contribue à atténuer l'apparence des tâches de vieillesse, des cicatrices d'acné et des irrégularités cutanées.<br><br>
        
        Concentré en actifs antibactériens, il <label class='bold'>combat l'excès de sébum</label>, réduit les imperfections, <label class='bold'>affine le grain de peau</label> et unifie le teint pour une peau visiblement plus jeune et radieuse.
        
                `,
        img: bakuchiol,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Propriétés anti-âge", description: "Il a été démontré que le bakuchiol réduit de manière significative les signes de vieillissement, y compris les rides et les ridules. Il favorise la production de collagène, essentiel au maintien de l'élasticité et de la fermeté de la peau, contribuant ainsi à un teint plus lisse et plus jeune." },
            { titre: "Convient aux peaux sensibles", description: "L'une des principales caractéristiques du bakuchiol est qu'il convient aux peaux sensibles. Contrairement au rétinol, qui peut provoquer des irritations, des rougeurs, le bakuchiol est doux pour la peau, ce qui en fait une excellente option pour les personnes qui ne tolèrent pas les rétinoïdes traditionnels." },
            { titre: "Améliore l’élasticité et la texture de la peau", description: "L'utilisation régulière du bakuchiol peut améliorer la texture et le teint de la peau, la rendant plus lisse et plus uniforme. Il est efficace pour atténuer les pores, des cicatrices d'acné et de la pigmentation, ce qui permet d'obtenir un teint plus raffiné et plus éclatant." },
            { titre: "Effets anti-inflammatoires et antibactériens", description: "Le bakuchiol possède des propriétés anti-inflammatoires et antibactériennes, ce qui le rend bénéfique pour le traitement de l'acné et la réduction des rougeurs et de l'inflammation. Sa capacité à calmer la peau en fait un ingrédient précieux pour les personnes ayant une peau sujette à l'acné ou réactive." },
            { titre: "Hydratation", description: "Le bakuchiol peut également contribuer à améliorer l'hydratation de la peau en soutenant la fonction de barrière cutanée. Cela réduit la perte d'eau et permet à la peau de retenir l'humidité plus efficacement, ce qui lui donne un aspect rebondi et hydraté.<br>Réduction de l’hyperpigmentation.<br>Le bakuchiol s'est avéré efficace pour réduire l'hyperpigmentation et les taches sombres. En régulant le renouvellement des cellules de la peau, il aide à estomper les pigments indésirables, ce qui permet d'obtenir un teint plus clair et plus uniforme." },
            { titre: "Pas de photosensibilité", description: "Contrairement au rétinol, le bakuchiol n'augmente pas la sensibilité de la peau au soleil, ce qui en fait une option plus sûre pour une utilisation de jour. Toutefois, il est toujours recommandé d'utiliser un écran solaire dans le cadre d'une routine quotidienne de soins de la peau afin de se protéger contre les dommages causés par les UV." },
        ],
        url: "/niosomes/bakuchiol",
    },
    {
        nom: "beurre de karité",
        subtitle: "apaisant & nourrissant",
        categorie: ["lipides"],
        formes: ["Sérum", "Crème"],
        description: `
            <label class='bold'>Le beurre de karité</label>, riche en acides gras essentiels et en vitamines, est reconnu pour ses propriétés <label class='bold'>nourrissantes, protectrices et réparatrices</label>.<br><br>
    
            Grâce à la technologie niosomale, cet actif optimise l'absorption du beurre de karité, permettant une action <label class='bold'>en profondeur et durable</label> pour hydrater, adoucir et restaurer la peau.<br><br>
    
            Ce soin convient à tous les types de peau, particulièrement aux <label class='bold'>peaux sèches, irritées ou abîmées</label>, en offrant un <label class='bold'>confort immédiat</label> et une protection durable.
        `,
        img: beurreDeKarite,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Hydratation profonde et durable", description: "Le beurre de karité pénètre en profondeur pour hydrater intensément la peau, prévenant les sensations de tiraillement et la sécheresse." },
            { titre: "Réparation des peaux abîmées", description: "Grâce à ses propriétés régénérantes, il aide à réparer et apaiser les peaux irritées, craquelées ou exposées aux agressions extérieures." },
            { titre: "Protection contre les agressions extérieures", description: "Le beurre de karité forme une barrière protectrice sur la peau, la protégeant des agressions environnementales comme le froid ou la pollution." },
            { titre: "Amélioration de la douceur et de l'élasticité", description: "Cet actif restaure la souplesse de la peau, la rendant plus douce, lisse et confortable au toucher." },
            { titre: "Convient à tous les types de peau", description: "Sa formule riche et non irritante est idéale pour tous, y compris les peaux sensibles ou réactives." }
        ],
        url: "/niosomes/beurre-de-karite",
    },
    {
        nom: "caféine",
        subtitle: "contours des yeux & drainage",
        categorie: ["végétal"],
        formes: ["Sérum", "Crème"],
        description: `
            <label class='bold'>La caféine</label> est un actif énergisant et stimulant naturel, reconnu pour ses effets <label class='bold'>tonifiants et revitalisants</label> sur la peau.<br><br>
    
            Elle aide à réduire les signes de fatigue en améliorant la <label class='bold'>microcirculation cutanée</label> et en favorisant le drainage. Son action décongestionnante réduit les <label class='bold'>poches sous les yeux</label> et atténue les cernes, offrant un regard frais et reposé.<br><br>
    
            Sa capacité à détoxifier en fait un allié précieux contre les <label class='bold'>signes de fatigue</label>.<br><br>
    
            Grâce à ses propriétés antioxydantes, la caféine lutte efficacement contre les radicaux libres responsables du vieillissement cutané. Elle protège également la peau des agressions extérieures et lui donne une peau plus ferme et éclatante.<br><br>
    
            Enfin, son action lipolytique aide à soutenir le processus de <label class='bold'>déstockage des graisses</label>, utile pour les <label class='bold'>soins anti-cellulite</label>.
        `,
        img: cafeine,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Réduction des poches et des cernes", description: "La caféine est reconnue pour ses puissantes propriétés décongestionnantes, en faisant un ingrédient phare pour réduire les cernes et les poches sous les yeux. Elle stimule la microcirculation sanguine, favorisant l’élimination des pigments responsables des cernes sombres. Grâce à son action drainante, la caféine aide à diminuer la rétention d’eau, souvent responsable des poches. Elle agit également comme un anti-inflammatoire naturel, réduisant les gonflements et apaisant la peau fragile du contour des yeux." },
            { titre: "Amélioration de la circulation", description: "La caféine stimule la circulation sanguine, ce qui peut améliorer l'apparence générale de la peau en favorisant une peau plus saine et plus éclatante. Une meilleure circulation aide à nourrir les cellules de la peau, ce qui donne un teint plus lumineux et plus uniforme." },
            { titre: "Fermeté de la peau", description: "La caféine, réputée pour ses propriétés stimulantes, est un actif star en cosmétique pour raffermir et tonifier la peau. Grâce à son effet drainant, elle réduit visiblement la rétention d’eau et aide à lisser les zones sujettes au relâchement, comme le visage ou le corps. En boostant la microcirculation, la caféine améliore l'élasticité cutanée tout en donnant à la peau un aspect plus ferme et revitalisé. Idéale pour un coup d’éclat et une peau tonique au quotidien." },
            { titre: "Protection antioxydante", description: "En tant qu'antioxydant, la caféine aide à combattre les radicaux libres et à protéger la peau des dommages causés par les rayons UV et les polluants environnementaux. Cette protection peut prévenir le vieillissement prématuré de la peau, notamment les rides, les taches solaires et les ridules." },
            { titre: "Apaise et calme les inflammations", description: "Les propriétés anti-inflammatoires de la caféine la rendent bénéfique pour calmer la peau irritée et les rougeurs. Elle peut être particulièrement apaisante pour des affections telles que la rosacée et l'acné, en réduisant les rougeurs et en procurant un effet apaisant." },
            { titre: "Réduction des rides et des ridules", description: "En favorisant la production de collagène et en offrant une protection antioxydante, la caféine peut aider à réduire l’apparence des ridules et des rides, contribuant ainsi à un teint plus lisse et plus jeune" },
            { titre: "Réparation des dommages causés par le soleil", description: "Il a été démontré que la caféine aide à réparer les dommages cutanés causés par l'exposition aux rayons UV. Elle agit en favorisant la réparation de l'ADN endommagé par l'exposition aux UV, aidant ainsi à prévenir la formation de cellules cancéreuses de la peau et à réduire les signes de dommages causés par le soleil." },
            { titre: "Déstockage des graisses", description: "Grâce à son effet lipolytique, la caféine  intégrée dans les soins pour le corps, aide à stimuler le déstockage des graisses et à réduire visiblement l’apparence de la cellulite." }
        ],
        url: "/niosomes/cafeine",
    },
    {
        nom: "cannelle",
        subtitle: "éclat & purifiant",
        categorie: ["végétal"],
        formes: ["Sérum", "Crème"],
        description: `
            <label class='bold'>La cannelle</label>, grâce à ses vertus <label class='bold'>purifiantes et illuminatrices</label>, aide à diminuer les impuretés et à raviver l’éclat naturel de la peau. Riche en composés phénoliques, elle est également reconnue pour ses propriétés <label class='bold'>antioxydantes et éclaircissantes</label>.<br><br>
    
            Elle agit efficacement pour protéger la peau contre les <label class='bold'>radicaux libres</label>, réduire l’apparence des taches pigmentaires et favoriser un teint uniforme.<br><br>
    
            Grâce à la <label class='bold'>technologie niosomale</label>, cet actif est optimisé pour une absorption renforcée et une action prolongée.<br><br>
    
            Adaptée à tous les types de peau, cet actif offre une solution complète pour préserver la jeunesse de la <label class='bold'>peau tout en améliorant sa texture et son éclat</label>.
        `,
        img: cannelle,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Traitement de l’acné", description: "La cannelle combat les bactéries responsables de l'acné, telles que Propionibacterium acnes. Elle réduit également l'inflammation des boutons et accélère leur cicatrisation. Elle peut diminuer les marques et cicatrices laissées par l'acné" },
            { titre: "Effet purifiant et exfoliant", description: "La cannelle élimine les cellules mortes, nettoie les pores et améliore la texture de la peau. Elle réduit les points noirs et prévient l'obstruction des pores." },
            { titre: "Éclat de la peau", description: "La cannelle stimule la circulation sanguine lorsqu'elle est appliquée localement, apportant plus d'oxygène et de nutriments à la peau. Elle donne un teint éclatant et revitalisé." },
            { titre: "Anti-âge naturel", description: "La cannelle encourage la production de collagène, améliorant l'élasticité de la peau et réduisant l'apparence des rides et ridules." },
            { titre: "Lutte contre les infections cutanées", description: "Grâce à ses propriétés antimicrobiennes et antifongiques, la cannelle aide à traiter des affections comme les mycoses ou les petites infections." },
            { titre: "Réduction des inflammations", description: "Ses propriétés anti-inflammatoires apaisent les rougeurs, les irritations et les affections cutanées comme l’eczéma ou la dermatite." }
        ],
        url: "/niosomes/cannelle",
    },
    {
        nom: "céramide",
        subtitle: "hydratation & souplesse",
        categorie: ["lipides"],
        formes: ["Sérum", "Crème"],
        description: `
            Les <label class='bold'>céramides</label>, composants essentiels de la barrière cutanée, jouent un rôle clé dans la <label class='bold'>protection et l’hydratation de la peau</label>.<br><br>
    
            Enrichi en céramides encapsulées grâce à la <label class='bold'>technologie niosomale</label>, cet actif agit en profondeur pour <label class='bold'>restaurer et renforcer la barrière cutanée</label>, prévenir la perte en eau et apaiser les peaux sensibles.<br><br>
    
            Ce soin est idéal pour <label class='bold'>tous les types de peau</label>, notamment les peaux sèches, irritées ou sujettes aux rougeurs.<br><br>
    
            Il aide à maintenir une <label class='bold'>peau douce, hydratée et résiliente</label> face aux agressions extérieures.
        `,
        img: ceramide,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Renforcement de la barrière cutanée", description: "Les céramides réparent et renforcent la barrière cutanée, protègent la peau des agressions extérieures et particulièrement la perte en eau transépidermique." },
            { titre: "Hydratation intense et durable", description: "Grâce à leur capacité à retenir l'eau, les céramides offrent une hydratation prolongée, laissant la peau douce et souple." },
            { titre: "Réduction des rougeurs et des irritations", description: "Les propriétés apaisantes des céramides soulagent les peaux sensibles, atténuant les rougeurs et les sensations d'inconfort." },
            { titre: "Amélioration de la souplesse et de la texture de la peau", description: "En restaurant la structure lipidique de l'épiderme, ce sérum aide à retrouver une peau lisse, élastique et plus résistante." },
            { titre: "Convient aux peaux sèches et sensibles", description: "Sa formulation douce et respectueuse en fait un allié incontournable pour les peaux sujettes à la déshydratation ou aux irritations." }
        ],
        url: "/niosomes/ceramide",
    },
    {
        nom: "curcumine",
        subtitle: "antibactérien",
        categorie: ["végétal"],
        formes: ["Sérum", "Crème"],
        description: `
            La <label class='bold'>curcumine</label>, issue du curcuma, est un puissant actif naturel connu pour ses propriétés <label class='bold'>anti-inflammatoires, antioxydantes et antimicrobiennes</label>.<br><br>
    
            Grâce à la technologie niosomale, cette formule <label class='bold'>maximise l’absorption</label> de la curcumine, offrant une efficacité renforcée et durable.<br><br>
    
            Ce soin est conçu pour <label class='bold'>apaiser les peaux sensibles</label>, <label class='bold'>réduire les rougeurs</label> et protéger la peau des dommages causés par les radicaux libres.<br><br>
    
            Adaptée à tous les types de peau, elle aide également à <label class='bold'>uniformiser le teint</label> et à améliorer la santé globale de la peau.
        `,
        img: curumine, // Objet d'image
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Protection antioxydante puissante", description: "La curcumine neutralise les radicaux libres, protège la peau des agressions extérieures et du vieillissement prématuré." },
            { titre: "Apaisement des rougeurs et irritations", description: "Grâce à ses propriétés anti-inflammatoires, la curcumine calme les peaux réactives, notamment les rougeurs et les sensations d'inconfort comme l’eczéma, le psoriasis ou la dermatite" },
            { titre: "Réduction des signes du vieillissement", description: "En stimulant la régénération cellulaire, cet actif aide à atténuer les rides, les ridules et les autres signes visibles du vieillissement." },
            { titre: "Uniformité et éclat du teint", description: "La curcumine favorise un teint uniforme en particulier les irrégularités pigmentaires et en ravivant l'éclat naturel de la peau." },
            { titre: "Convient aux peaux sensibles", description: "Sa formulation garantit une tolérance optimale, même pour les peaux les plus délicates." },
            { titre: "Propriétés anti-inflammatoires", description: "Sa formulation garantit une tolérance optimale, même pour les peaux les plus délicates." }
        ],
        url: "/niosomes/curcumine",
    },
    {
        nom: "EGCG",
        subtitle: "éclat & anti-âge",
        categorie: ["végétal"],
        formes: ["Sérum", "Crème"],
        description: `
        <label class='bold'>L'EGCG</label> (épigallocatéchine gallate), un <label class='bold'>puissant antioxydant</label> extrait du thé vert, révolutionne les soins pour la peau. Il aide à <label class='bold'>éclaircir le teint</label> et à lisser les ridules.<br><br>

        Il agit comme un bouclier contre les agressions extérieures telles que la pollution et les radicaux libres, atténuant ainsi le vieillissement cutané. Grâce à ses propriétés <label class='bold'>anti-inflammatoires</label>, l’EGCG <label class='bold'>réduit les rougeurs</label> et les irritations, idéal pour les peaux sensibles.<br><br>

        Il stimule la <label class='bold'>régénération cellulaire</label>, favorisant une peau plus lisse et uniforme tout en éliminant l’apparence des taches pigmentaires.<br><br>

        De plus, l’EGCG aide à contrôler l’excès de sébum, laissant un fini mat et une <label class='bold'>peau fraîche</label>. Sa capacité à hydrater en profondeur renforce la barrière cutanée et améliore l’éclat naturel du teint.
        `,
        img: egcg,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Effets anti-inflammatoires", description: "Grâce à ses propriétés anti-inflammatoires, l'EGCG peut aider à réduire l'inflammation et les rougeurs de la peau, ce qui la rend bénéfique pour des affections telles que la rosacée, le psoriasis et l'acné. Il apaise la peau irritée, réduisant l'inconfort et l'apparition d'inflammation." },
            { titre: "Contrôle de l'acné et du sébum", description: "Il a été démontré que l'EGCG réduit la production de sébum et possède des propriétés antimicrobiennes contre les bactéries responsables de l'acné, ce qui en fait un ingrédient efficace pour gérer la peau sujette à l'acné. Il aide à éliminer les imperfections et à prévenir les futures éruptions cutanées, favorisant ainsi un teint plus clair." },
            { titre: "Réduction de l’hyperpigmentation", description: "L'EGCG inhibe la tyrosinase, une enzyme impliquée dans la production de mélanine, contribuant ainsi à réduire l'apparence des taches brunes et de l'hyperpigmentation. Une utilisation régulière peut conduire à un teint plus uniforme et plus lumineux." },
            { titre: "Hydratation cutanée améliorée", description: "L'EGCG peut améliorer l'hydratation de la peau en renforçant la barrière cutanée, en réduisant la perte d'eau et en maintenant les niveaux d'hydratation. Cela conduit à une peau plus rebondie et plus hydratée, diminuant l'apparence de la sécheresse et des ridules." },
            { titre: "Amélioration de l’élasticité et la fermeté", description: "En encourageant la production de collagène, l'EGCG aide à maintenir l'élasticité et la fermeté de la peau, réduisant ainsi les signes du vieillissement et donnant à la peau une apparence plus jeune et plus résistante." },
            { titre: "Cicatrisation et réparation des plaies", description: "Les propriétés antioxydantes et anti-inflammatoires de l'EGCG accélèrent les processus naturels de guérison de la peau, ce qui en fait un outil bénéfique pour la réparation des plaies mineures, des coupures et autres lésions cutanées. Il favorise une guérison plus rapide et réduit les cicatrices." },
        ],
        url: "/niosomes/egcg",
    },
    {
        nom: "garcinia cambogia",
        subtitle: "hydratant & purifiant",
        categorie: ["végétal"],
        formes: ["Sérum", "Crème"],
        description: `
        L'extrait de <label class='bold'>Garcinia Cambogia</label>, riche en composés bioactifs tels que l'acide hydroxycitrique (HCA), est reconnu pour ses propriétés <label class='bold'>purifiantes, régulatrices et hydratantes</label>.<br><br>

        Grâce à la technologie niosomale, cet actif agit en profondeur pour <label class='bold'>équilibrer la production de sébum</label>, resserrer les pores, maintenir l’hydratation et <label class='bold'>unifier le teint</label>.<br><br>

        Idéal pour les peaux mixtes à grasses, il convient également à tous les types de peau cherchant à retrouver fraîcheur, clarté et éclat.
        `,
        img: garciniaCambodgia,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Régulation de la production de sébum", description: "Grâce à ses propriétés anti-inflammatoires, l'EGCG peut aider à réduire l'inflammation et les rougeurs de la peau, ce qui la rend bénéfique pour des affections telles que la rosacée, le psoriasis et l'acné. Il apaise la peau irritée, réduisant l'inconfort et l'apparition d'inflammation." },
            { titre: "Hydratation de la peau", description: "Il a été démontré que l'EGCG réduit la production de sébum et possède des propriétés antimicrobiennes contre les bactéries responsables de l'acné, ce qui en fait un ingrédient efficace pour gérer la peau sujette à l'acné. Il aide à éliminer les imperfections et à prévenir les futures éruptions cutanées, favorisant ainsi un teint plus clair." },
            { titre: "Réduction de l’apparence des pores", description: "L'EGCG inhibe la tyrosinase, une enzyme impliquée dans la production de mélanine, contribuant ainsi à réduire l'apparence des taches brunes et de l'hyperpigmentation. Une utilisation régulière peut conduire à un teint plus uniforme et plus lumineux." },
            { titre: "Action purifiante et clarifiante", description: "L'EGCG peut améliorer l'hydratation de la peau en renforçant la barrière cutanée, en réduisant la perte d'eau et en maintenant les niveaux d'hydratation. Cela conduit à une peau plus rebondie et plus hydratée, diminuant l'apparence de la sécheresse et des ridules." },
            { titre: "Uniformité et éclat du teint", description: "En encourageant la production de collagène, l'EGCG aide à maintenir l'élasticité et la fermeté de la peau, réduisant ainsi les signes du vieillissement et donnant à la peau une apparence plus jeune et plus résistante." },
            { titre: "Convient aux peaux mixtes à grasses", description: "Les propriétés antioxydantes et anti-inflammatoires de l'EGCG accélèrent les processus naturels de guérison de la peau, ce qui en fait un outil bénéfique pour la réparation des plaies mineures, des coupures et autres lésions cutanées. Il favorise une guérison plus rapide et réduit les cicatrices." },
        ],
        url: "/niosomes/garcinia-cambogia",
    },
    {
        nom: "niacinamide",
        subtitle: "teint unifié & pores affinés",
        categorie: ["vitamines"],
        formes: ["Sérum", "Crème"],
        description: `
        La <label class='bold'>niacinamide</label>, ou vitamine B3, aide à prévenir les <label class='bold'>imperfections</label> et à <label class='bold'>apaiser les rougeurs</label> tout en lissant et en affinant la texture.<br><br>

        Ce puissant actif multifonction aide à <label class='bold'>réguler la production de sébum</label>, notamment l’apparence des pores dilatés et minimisant les imperfections.<br><br>

        Grâce à ses propriétés <label class='bold'>anti-inflammatoires</label>, la niacinamide apaise les irritations, les rougeurs et <label class='bold'>réduit les inflammations</label>, ce qui en fait un allié de choix pour les peaux sensibles ou sujettes à l’acné. En régulant la production de sébum, elle aide à limiter les imperfections tout en affinant le grain de peau.<br><br>

        En agissant également comme antioxydant, elle améliore la barrière cutanée, contribuant à l’hydratation naturelle de la peau et la protège des agressions extérieures, permettant de lisser le teint et unifier l’apparence des taches brunes, des rougeurs et les cicatrices d’acné.
        `,
        img: niacinamide,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Amélioration de la barrière cutanée", description: "La niacinamide joue un rôle crucial dans le renforcement de la barrière cutanée. En stimulant la production de céramides, elle aide à retenir l'hydratation et protège la peau contre les agressions extérieures, telles que la pollution et les agents irritants." },
            { titre: "Hydratation", description: "En plus de renforcer la barrière cutanée, la niacinamide améliore l’hydratation cutanée en réduisant la perte d’eau transépidermique. La peau est ainsi plus souple, plus lisse et visiblement plus hydratée." },
            { titre: "Réduction de l’hyperpigmentation", description: "La niacinamide est efficace pour atténuer l'apparence des tâches brunes et de l'hyperpigmentation en inhibant le transfert des mélanosomes vers les cellules de la peau. Avec une utilisation régulière, elle peut aider à éclaircir la peau et à uniformiser le teint." },
            { titre: "Effet anti-âge", description: "En stimulant la synthèse du collagène et en combattant les radicaux libres, la niacinamide contribue à réduire les signes du vieillissement. Elle peut atténuer l'apparence des rides et des ridules, rendant la peau plus ferme et plus jeune." },
            { titre: "Contrôle de la sécrétion de sébum", description: "Pour les peaux grasses et à tendance acnéique, la niacinamide peut réduire la production de sébum, minimisant ainsi l'apparence des pores et réduisant le risque de formation d'acné." },
            { titre: "Apaisement des inflammations", description: "La niacinamide possède des propriétés anti-inflammatoires qui peuvent soulager les rougeurs et les inflammations de la peau, notamment celles liées à l’acné, à l’eczéma ou à d’autres affections cutanées inflammatoires." },
            { titre: "Protection contre le stress oxydatif", description: "En tant qu’antioxydant, la niacinamide protège la peau contre les dommages causés par les radicaux libres, résultant de l’exposition aux UV, de la pollution et d’autres facteurs environnementaux, aidant ainsi à prévenir les dommages cellulaires et le vieillissement prématuré de la peau. " },
        ],
        url: "/niosomes/niacinamide",
    },
    {
        nom: "panthénol (Pro-Bitamine B5)",
        subtitle: "hydratant & apaisant",
        categorie: ["vitamines"],
        formes: ["Sérum", "Crème"],
        description: `
        Le <label class='bold'>panthénol</label>, également connu sous le nom de <label class='bold'>provitamine B5</label>, est un actif exceptionnellement reconnu pour ses propriétés <label class='bold'>hydratantes, apaisantes et réparatrices</label>.<br><br>

        Grâce à la technologie niosomale, cet actif offre une absorption renforcée et une action prolongée, permettant au panthénol d’agir en profondeur pour <label class='bold'>restaurer et protéger la peau</label>.<br><br>

        Ce soin est idéal pour les peaux sensibles, sèches ou irritées, en améliorant la <label class='bold'>douceur, l’élasticité et le confort cutané</label>, tout en aidant à réparer les dommages causés par les agressions extérieures.
        `,
        img: panthenol,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Hydratation intense et durable", description: "Le panthénol améliore la capacité de la peau à retenir l'eau, procurant une hydratation longue durée et une peau visiblement plus souple." },
            { titre: "Réparation des peaux abîmées", description: "Il aide à renforcer la barrière cutanée et à accélérer la réparation des zones fragilisées ou endommagées." },
            { titre: "Apaisement des rougeurs et irritations", description: "Grâce à ses propriétés anti-inflammatoires, le panthénol calme les irritations et réduit les rougeurs, offrant un confort immédiat." },
            { titre: "Amélioration de la douceur et de l’élasticité", description: "En régénérant la peau, cet actif laisse la peau douce, élastique et visiblement revitalisée." },
            { titre: "Convient à tous les types de peau", description: "Sa formulation douce et respectueuse est adaptée à toutes les peaux, comprenant les plus sensibles et réactives." },
        ],
        url: "/niosomes/panthenol",
    },
    {
        nom: "rétinol (Vitamine A)",
        subtitle: "lissant & anti-âge",
        categorie: ["vitamines"],
        formes: ["Sérum", "Crème"],
        description: `
        La <label class='bold'>vitamine A</label>, appelée également <label class='bold'>rétinol</label>, connue pour ses propriétés <label class='bold'>régénérantes, lissantes et anti-âge</label>, est un actif incontournable pour améliorer la texture de la peau et <label class='bold'>réduire les signes visibles du vieillissement</label>.<br><br>

        Grâce à la technologie niosomale, cet actif offre une absorption optimale et une action prolongée, permettant à la vitamine A de pénétrer en profondeur pour des résultats visibles.<br><br>

        Ce soin convient à tous les types de peau, notamment celles sujettes aux <label class='bold'>imperfections ou aux irrégularités</label>, en favorisant un teint plus uniforme et éclatant.
        `,
        img: retinol,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Stimulation du renouvellement cellulaire", description: "Le rétinol accélère la régénération des cellules cutanées, aidant à révéler une peau plus jeune, lisse et éclatante." },
            { titre: "Réduction des rides et des ridules", description: "En renforçant la structure de la peau, ce sérum aide à atténuer les signes visibles du vieillissement prématuré." },
            { titre: "Amélioration de la texture de la peau", description: "Le rétinol affine le grain de peau, notamment l'apparence des pores et des irrégularités." },
            { titre: "Uniformité et éclat du teint", description: "Elle favorise un teint homogène en atténuant les tâches pigmentaires et en améliorant l'éclat naturel de la peau." },
            { titre: "Convient à tous les types de peau", description: "Grâce à sa formulation douce, ce sérum est adapté à toutes les peaux, même les plus sensibles, tout en garantissant une efficacité optimale." },
        ],
        url: "/niosomes/retinol",
    },
    {
        nom: "squalène",
        subtitle: "nourrissant",
        categorie: ["lipides"],
        formes: ["Sérum", "Crème"],
        description: `
        Le <label class='bold'>squalène</label>, un lipide naturellement présent dans la peau, est un ingrédient clé pour <label class='bold'>maintenir l’hydratation</label>, restaurer la barrière cutanée et <label class='bold'>protéger la peau</label> des agressions extérieures.<br><br>

        Grâce à la technologie niosomale, cet actif permet une absorption optimale et une action prolongée, maximisant les bénéfices du squalène pour la peau.<br><br>

        Ce soin convient à tous les types de peau, notamment les <label class='bold'>peaux sèches, sensibles ou matures</label>, en améliorant la douceur et l’éclat de la peau.
        `,
        img: squalene,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Hydratation longue durée", description: "Le squalène aide la peau à retenir l’humidité, procurant une hydratation intense et durable qui prévient la sécheresse et les tiraillements." },
            { titre: "Restauration de la barrière cutanée", description: "Il renforce la barrière protectrice naturelle de la peau, contribuant à une meilleure défense contre les agressions extérieures." },
            { titre: "Protection contre les agressions extérieures", description: "Grâce à ses propriétés antioxydantes, le squalène protège la peau des effets néfastes des radicaux libres et du stress environnemental." },
            { titre: "Amélioration de la souplesse et de l’éclat de la peau", description: "Cet actif rend la peau plus douce, lisse et lumineuse, tout en favorisant une apparence saine et revitalisée." },
            { titre: "Convient à tous les types de peau", description: "Sa formule douce et non comédogène est idéale pour tous les types de peau, même sensibles ou sujettes aux imperfections." },
        ],
        url: "/niosomes/squalene",
    },
    {
        nom: "vitamine A + Urée",
        subtitle: "lissant & hydratant",
        categorie: ["vitamines"],
        formes: ["Sérum", "Crème"],
        description: `
        La combinaison de la <label class='bold'>vitamine A</label> et de l’<label class='bold'>urée</label> offre un duo puissant pour <label class='bold'>régénérer la peau</label>, lisser sa texture et <label class='bold'>renforcer son hydratation</label>.<br><br>

        La vitamine A stimule le <label class='bold'>renouvellement cellulaire</label> et améliore l’apparence des rides, tandis que l’urée hydrate en profondeur et renforce la barrière cutanée.<br><br>

        Grâce à la technologie niosomale, cette formule offre une absorption optimale et une action prolongée, assurant une efficacité renforcée.<br><br>

        Ce soin convient particulièrement aux <label class='bold'>peaux sèches, déshydratées ou ternes</label>, tout en étant respectueux des peaux sensibles.
        `,
        img: vitamineAUree,
        type: "niosome",
        product: false,
        bienfaits: [
            { titre: "Stimulation du renouvellement cellulaire", description: "La vitamine A accélère la régénération des cellules cutanées, aidant à lisser la peau et à améliorer son éclat naturel." },
            { titre: "Réduction des rides et ridules", description: "En favorisant la production de collagène, la vitamine A atténue les signes visibles du vieillissement prématuré." },
            { titre: "Hydratation profonde et durable", description: "L’urée pénètre en profondeur pour hydrater intensément la peau, réduisant les sensations de tiraillement et de sécheresse." },
            { titre: "Amélioration de la texture et de la souplesse de la peau", description: "La synergie entre la vitamine A et l’urée aide à adoucir et assouplir la peau, en la rendant visiblement plus lisse et confortable." },
            { titre: "Convient aux peaux sèches et sensibles", description: "Sa formule respectueuse et non irritante est idéale pour les peaux qui manquent de confort ou qui présentent des signes de déshydratation." },
        ],
        url: "/niosomes/vitamine-a-uree",
    },
    {
        nom: "niacinamide & zinc",
        subtitle: "Anti-rougeurs & unifiant",
        type: "niosomeProduct",
        subtitle: "Anti-rougeurs & unifiant",
        formes2: ["Sérum"],
        url: "/niosomes/niacinamide-zinc",
        categorie: false,
        img: niacinamideZinc,
        product: false,
        bienfaits: [
            { titre: "Régulation de la production de sébum", description: "Le zinc PCA contrôle efficacement l’excès de sébum, offrant un effet matifiant durable et prévenant les brillances." },
            { titre: "Réduction des imperfections et des rougeurs", description: "La niacinamide apaise les inflammations cutanées et réduit l’apparence des rougeurs et des imperfections, pour une peau plus uniforme." },
            { titre: "Amélioration de la texture et du grain de peau", description: "Ce sérum affine visiblement le grain de peau, la laissant douce, lisse et homogène." },
            { titre: "Renforcement de la barrière cutanée", description: "La niacinamide aide à renforcer la barrière cutanée, améliorant ainsi la rétention d’hydratation et la protection contre les agressions extérieures." },
            { titre: "Convient aux peaux mixtes à grasses", description: "Sa formule légère et non comédogène est idéale pour les peaux sujettes aux imperfections et aux excès de sébum." },
        ],
        description: `
        Akore Formulations a développé ce sérum pour les <label class='bold'>peaux sujettes aux imperfections ou aux rougeurs.</label><br><br>

        Il <label class='bold'>unifie, lisse et optimise l'éclat</label> de votre peau grâce à notre sérum concentré à 10 % de niacinamide et 1 % de zinc.<br>
        Cette innovation améliore considérablement la <label class='bold'>douceur de la peau</label> tout en renforçant la barrière cutanée au fil du temps. <br><br>

        La niacinamide est reconnue pour ses propriétés <label class='bold'>apaisantes et unifiantes</label> et le zinc PCA, un ingrédient clé pour <label class='bold'>réguler la production de sébum</label> et <label class='bold'>réduire les imperfections.</label> <br><br>

        Grâce à la technologie niosomale, ces deux actifs pénètrent efficacement en profondeur pour une <label class='bold'>action ciblée</label> et prolongée, améliorant visiblement la clarté et la texture de la peau.<br><br>

        Ce soin convient à <label class='bold'>tous les types de peau</label>, en particulier les peaux mixtes à grasses.
        `,
    },
    {
        nom: "acide glycolique & bakuchiol",
        subtitle: "Anti-imperfections & Exfoliant",
        type: "niosomeProduct",
        subtitle: "Anti-imperfections & Exfoliant",
        formes2: ["Sérum"],
        url: "/niosomes/acide-glycolique-bakuchiol",
        categorie: false,
        img: glycoliqueBakuchiol,
        product: false,
        bienfaits: [
            { titre: "Stimulation du renouvellement cellulaire", description: "La vitamine A accélère la régénération des cellules cutanées, aidant à révéler une peau plus jeune, lisse et éclatante." },
            { titre: "Réduction des rides et des ridules", description: "En renforçant la structure de la peau, ce sérum aide à atténuer les signes visibles du vieillissement prématuré." },
            { titre: "Amélioration de la texture de la peau", description: "La vitamine A affine le grain de peau, notamment l'apparence des pores et des irrégularités." },
            { titre: "Uniformité et éclat du teint", description: "Elle favorise un teint homogène en atténuant les taches pigmentaires et en améliorant l'éclat naturel de la peau." },
            { titre: "Convient à tous les types de peau", description: "Grâce à sa formulation douce, ce sérum est adapté à toutes les peaux, même les plus sensibles, tout en garantissant une efficacité optimale." },
        ],
        description: `
            Akore Formulations a développé ce sérum pour <label class="bold">lutter contre les points noirs et les pores dilatés</label>, grâce à son action purifiante en profondeur, pour un teint lisse et uniforme. 
            <br/><br/>
            Un teint inégal peut souvent résulter de l'accumulation de cellules mortes à la surface de la peau.
            Grâce à sa teneur élevée en acide glycolique, notre sérum agit en <label class="bold">exfoliant</label> les cellules mortes et en <label class="bold">affinant les pores</label> pour une peau plus lisse et plus uniforme. 
            <br/><br/>
            Cette formule associe l’acide glycolique concentré à 10%, un exfoliant réputé pour <label class="bold">affiner le grain de peau</label> et révéler son éclat, au bakuchiol 1%, une <label class="bold">alternative naturelle</label> au rétinol reconnue pour ses propriétés régénérantes et apaisantes.
            <br/><br/>
            Grâce à la technologie niosomale, ces actifs bénéficient d’une <label class="bold">absorption optimale</label> et d’une action prolongée, offrant une efficacité maximale tout en <label class="bold">respectant les peaux sensibles</label>.
        `,
    },
    {
        nom: "acide hyaluronique & vitamine B5 ",
        subtitle: "Anti-âge & hydratant",
        type: "niosomeProduct",
        subtitle: "Anti-âge & hydratant",
        formes2: ["Sérum"],
        url: "/niosomes/acide-hyaluronique-vitamine-b5",
        categorie: false,
        img: hyaluroniqueVitamineB5,
        product: false,
        bienfaits: [
            { titre: "Hydratation intense et durable", description: "L’acide hyaluronique retient l’eau dans l’épiderme, procurant une hydratation longue durée et une peau repulpée." },
            { titre: "Réparation et apaisement de la peau", description: "La vitamine B5 favorise la régénération des tissus, apaise les irritations et renforce la barrière cutanée pour une peau plus résistante." },
            { titre: "Amélioration de la souplesse et de l’élasticité", description: "Ce sérum aide à restaurer la souplesse et l’élasticité de la peau, la laissant douce et confortable." },
            { titre: "Renforcement de la barrière cutanée", description: "En réparant et en hydratant, la combinaison de la vitamine B5 et de l’acide hyaluronique protège la peau contre les agressions extérieures." },
            { titre: "Convient à tous les types de peau", description: "Sa formulation respectueuse et non irritante convient parfaitement aux peaux sensibles ou sujettes à des rougeurs." },
        ],
        description: `
            Akore Formulations a développé ce sérum pour une <label class="bold">peau hydratée, plus rebondie et plus lisse</label> en compensant la diminution naturelle de l'acide hyaluronique dans la peau.
            <br/><br/>
            La formule rétablit les niveaux d'<label class="bold">hydratation de la peau tout au long de la journée</label> et aide la peau à retenir l'humidité pour <label class="bold">repulper les peaux sèches et déshydratées.</label><br/>
            Nous utilisons <label class="bold">3 % d'acide hyaluronique végétal</label> dans un mélange de trois tailles de molécules différentes (poids moléculaire élevé, moyen et faible), ce qui permet d'<label class="bold">hydrater plusieurs couches de la surface de la peau.</label>
            <br/><br/>
            Cette formule est associé à 2% de vitamine B5, réputée pour ses <label class="bold">propriétés réparatrices et apaisantes.</label>
            <br/><br/>
            Grâce à la technologie niosomale, ces deux actifs pénètrent efficacement en profondeur pour des <label class="bold">résultats visibles et durables.</label>
            <br/><br/>
            Ce soin est idéal pour réparer, apaiser et hydrater la peau, en offrant douceur, souplesse et éclat. Convient à <label class="bold">tous les types de peau</label>, même les plus sensibles.
        `,
    },
    {
        nom: "caféine & EGCG",
        subtitle: "Anti-cernes & anti-poches",
        type: "niosomeProduct",
        subtitle: "Anti-cernes & anti-poches",
        formes2: ["Sérum"],
        url: "/niosomes/cafeine-egcg",
        categorie: false,
        img: cafeineEgcg,
        product: false,
        bienfaits: [
            { titre: "Réduction des poches et des cernes", description: "La caféine stimule la microcirculation, aidant à diminuer visiblement les poches et les cernes pour un regard reposé." },
            { titre: "Protection antioxydante puissante", description: "L’EGCG, extrait de thé vert, neutralise les radicaux libres, protégeant la peau des agressions environnementales et des signes de vieillissement prématuré." },
            { titre: "Apaisement et hydratation de la peau", description: "L’EGCG apaise les irritations et la caféine hydrate en profondeur, laissant la peau douce et confortable." },
            { titre: "Amélioration de l’éclat et de la fermeté", description: "La synergie des deux actifs revitalise la peau, améliorant son éclat naturel et renforçant sa fermeté." },
            { titre: "Convient à tous les types de peau", description: "Sa formulation douce est idéale pour les zones délicates comme le contour des yeux, même sur les peaux sensibles." },
        ],
        description: `
            Akore Formulations a développé ce sérum pour <label class="bold">réduire l'apparence des cernes bleutés et des poches sous les yeux</label> en stimulant la circulation sanguine et lymphatique. <br/>
            Les poches sous les yeux sont causées par une accumulation d'eau et de graisse et une circulation sanguine déficiente. Ce sérum, fortement enrichi en caféine, <label class="bold">stimule la microcirculation sanguine.</label>
            <br/><br/>
            Cette formule associe 5% de caféine, reconnue pour son action <label class="bold">énergisante et décongestionnante</label>, à 2% de EGCG (extrait de thé vert), un <label class="bold">puissant antioxydant</label> qui protège et apaise la peau. 
            <br/><br/>
            Grâce à la technologie niosomale, ces actifs pénètrent profondément dans la peau pour une <label class="bold">action ciblée</label>, offrant une réduction visible des poches, des cernes et une peau revitalisée.
            <br/><br/>
            Ce soin convients à tous les types de peau. Il est idéal pour le <label class="bold">contour des yeux et les zones fatiguées</label>, en apportant fraîcheur et éclat tout en hydratant la peau.
        `,
    },
    {
        nom: "vitamine C & acide hyaluronique",
        subtitle: "Eclat & Eclaircissant ",
        type: "niosomeProduct",
        subtitle: "Eclat & Eclaircissant",
        formes2: ["Sérum"],
        url: "/niosomes/vitamine-c-acide-hyaluronique",
        categorie: false,
        img: vitamineCAcideHyaluronique,
        product: false,
        bienfaits: [
            { titre: "Éclat et uniformité du teint", description: "La vitamine C réduit les tâches pigmentaires et illumine la peau, révélant un teint lumineux et homogène." },
            { titre: "Hydratation profonde et durable", description: "L’acide hyaluronique retient l’eau dans l’épiderme, procurant une hydratation intense et durable pour une peau repulpée et confortable." },
            { titre: "Réduction des rides et des ridules", description: "En stimulant la régénération cellulaire, ce sérum atténue les rides et ridules, pour une peau plus lisse et visiblement rajeunie." },
            { titre: "Amélioration de la fermeté et de la souplesse", description: "La synergie entre la vitamine C et l’acide hyaluronique renforce la fermeté de la peau tout en améliorant son élasticité et sa douceur." },
            { titre: "Convient à tous les types de peau", description: "Sa formulation douce et efficace s’adapte à toutes les peaux, même sensibles ou sujettes aux irritations." },
        ],
        description: `
            Akore Formulations a développé ce sérum pour <label class="bold">redonner de l'éclat aux peaux ternes</label> et prévenir le photovieillissement cutané.
            <br/><br/>
            Le manque de sommeil, le stress et le tabagisme sont des facteurs qui stimulent la formation de radicaux libres, entraînant l'oxydation des cellules de l'épiderme et un teint terne. Grâce à sa teneur élevée en vitamine C, ce sérum offre une <label class="bold">protection efficace</label> contre ce processus.
            <br/><br/>
            Cette formule associe 15% de vitamine C, reconnue pour ses <label class="bold">propriétés antioxydantes et éclaircissantes</label>, à 1% d’acide hyaluronique, réputé pour son <label class="bold">hydratation profonde et ses effets repulpants.</label> 
            <br/><br/>
            Grâce à la technologie niosomale , ces actifs sont encapsulés pour offrir une <label class="bold">absorption optimale</label> et une action prolongée, agissant en synergie pour révéler une <label class="bold">peau éclatante, lisse et intensément hydratée</label>.
            <br/><br/>
            Ce soin convient à <label class="bold">tous les types de peau</label>, en particulier les signes visibles du vieillissement, en <label class="bold">uniformisant le teint</label> et en améliorant la souplesse de la peau.
        `,
    },
    {
        nom: "acide hyaluronique",
        subtitle: "Anti-âge & hydratation",
        type: "niosomeProduct",
        formes: ["Sérum", "Crème","Gélule"],
        url: "/niosomes/acide-hyaluronique",
        url3: "/liposomes/acide-hyaluronique",
        categorie: false,
        img: acideHyaluronique,
        product: false,
        bienfaits: [
            { titre: "Triple poids moléculaire : hydratation intense", description: "Cette substance est réputée pour ses propriétés hydratantes, avec la capacité de retenir jusqu'à 1000 fois son poids en eau.<br/> Un apport régulier d'acide hyaluronique aide à compenser sa diminution progressive dans la peau due au vieillissement, aidant à réduire les ridules et à rendre la peau plus élastique et plus ferme.<br/>Il existe trois formes d'acide hyaluronique pour une hydratation en profondeur : l'acide hyaluronique « à haut poids moléculaire » se dépose à la surface de la peau, formant un film hydratant qui empêche l'évaporation de l'eau.<br/>\"Faible et très faible poids moléculaire\", constitué de molécules plus petites qui s'infiltrent dans les couches plus profondes de la peau pour stimuler la production naturelle d'acide hyaluronique. L'effet combiné de ces trois formes offre une hydratation immédiate et durable à la peau." },
            { titre: "Réduction des ridules et des rides", description: "En hydratant la peau, l'acide hyaluronique peut réduire l'apparence des rides et des ridules. Une peau hydratée diminue la visibilité des rides en repulpant la peau, lui donnant une apparence plus lisse et plus jeune." },
            { titre: "Élasticité de la peau", description: "L'acide hyaluronique aide à maintenir l'élasticité de la peau en la gardant hydratée. Une peau élastique est plus résistante et a une apparence plus ferme et plus tonique, ce qui est associé à une peau d'apparence plus jeune." },
            { titre: "Amélioration de la cicatrisation de la peau", description: "Grâce à ses propriétés curatives naturelles, l'acide hyaluronique est bénéfique pour réparer la peau abîmée. Il aide à apaiser les plaies, les brûlures et autres affections cutanées en régulant les niveaux d'inflammation et en signalant au corps de construire davantage de vaisseaux sanguins dans la zone endommagée." },
            { titre: "Propriétés antioxydantes", description: "Outre ses propriétés hydratantes et cicatrisantes, l’acide hyaluronique possède également des propriétés antioxydantes qui protègent la peau des agressions environnementales telles que la pollution et les rayons UV. Ce mécanisme de défense aide à prévenir les signes du vieillissement et à maintenir la peau en bonne santé." },
            { titre: "Amélioration de la texture de la peau", description: "L'utilisation régulière d'acide hyaluronique peut améliorer considérablement la texture de la peau, la rendant plus lisse et plus affinée. Il peut aider à minimiser l'apparence des pores et à créer un teint impeccable." },
            { titre: "Non irritant pour tous les types de peau", description: "L'acide hyaluronique est incroyablement doux et peut être utilisé par tous les types de peau, y compris les peaux sensibles et à tendance acnéique. Il ne décape pas la peau et ne provoque pas d'irritation, ce qui en fait un ingrédient sûr et bénéfique pour tout le monde." },
            { titre: "Renforcement de la barrière protectrice de la peau", description: "En favorisant l'hydratation de la peau, l'acide hyaluronique renforce la barrière cutanée naturelle, contribuant ainsi à la protéger des agressions extérieures et des polluants.<br/> Une barrière cutanée solide est essentielle pour une peau saine, car elle retient l'humidité et empêche l'entrée de substances nocives." },
        ],
        description: `
            <label class="bold">L'acide hyaluronique</label> joue un rôle essentiel dans le <label class="bold">maintien de l'hydratation de la peau</label> et la préservation de sa texture <label class="bold">douce</label> et <label class="bold">volumineuse.</label><br/><br/>

            La formule rétablit les niveaux d'hydratation de la peau tout au long de la journée et aide la peau à retenir l'humidité pour <label class="bold">repulper les peaux sèches et déshydratées</label>, pour une peau plus rebondie et plus lisse en compensant la diminution naturelle de l'acide hyaluronique dans la peau.<br/><br/>
            
            Pour rééquilibrer l'hydratation, nous utilisons 3 % <label class="bold">d'acide hyaluronique</label> végétal dans un mélange de <label class="bold">trois tailles de molécules différentes</label> (poids moléculaire élevé, moyen et faible), ce qui permet d'<label class="bold">hydrater plusieurs couches</label> de la surface de la peau.<br/><br/>
            
            Cet actif contribue également à <label class="bold">réduire l'apparence des ridules</label> et à améliorer la souplesse de la peau, offrant ainsi une texture douce et lisse. Idéal pour tous types de peaux, l'acide hyaluronique est un ingrédient essentiel dans les produits de soins avancés pour préserver l'élasticité et la jeunesse de la peau.<br/><br/>
        `,
    },
    {
        nom: "astaxanthine",
        subtitle: "Antioxydant & lissant",
        type: "niosomeProduct",
        formes: ["Sérum", "Crème"],
        url: "/niosomes/astaxanthine",
        url3: "/liposomes/astaxanthine",
        categorie: false,
        img: astaxanthine2,
        product: false,
        bienfaits: [
            { titre: "Protection antioxydante puissante", description: "L'astaxanthine protège les cellules de la peau des radicaux libres, notamment les dommages causés par la pollution, les UV et d'autres facteurs environnementaux." },
            { titre: "Réduction des signes du vieillissement", description: "En prévenant le stress oxydatif, cet actif diminue l'apparence des rides et ridules, offrant un effet rajeunissant visible." },
            { titre: "Amélioration de l'élasticité de la peau", description: "L'astaxanthine stimule la production de collagène, renforçant ainsi la fermeté et la souplesse de la peau." },
            { titre: "Hydratation intense et durable", description: "Grâce à ses propriétés hydratantes, elle améliore la rétention d'eau dans la peau, la laissant douce, rebondie et parfaitement hydratée." },
            { titre: "Éclat et uniformité du teint", description: "Cet antioxydant puissant aide à réduire les irrégularités pigmentaires et à uniformiser le teint, révélant une peau éclatante et lumineuse." },
        ],
        description: `
            <label class="bold">L'astaxanthine</label>, considérée comme l'un des <label class="bold">antioxydants les plus puissants</label> au monde, est un actif exceptionnel pour <label class="bold">protéger la peau</label> contre le stress oxydatif et les dommages causés par les radicaux libres.<br/><br/>

            Grâce à la technologie niosomale, cette molécule bénéficie d'une absorption maximale et d'une <label class="bold">action prolongée</label>, permettant une efficacité renforcée.<br/><br/>
            
            Ce actif agit en profondeur pour améliorer l'élasticité, <label class="bold">réduire les signes visibles du vieillissement</label>, protéger la peau des agressions extérieures et révéler un <label class="bold">teint éclatant</label>.<br/><br/>
            
            Convient à tous les types de peau, y compris les peaux sensibles.
        
        `,
    },
    {
        nom: "biotine",
        subtitle: "Anti-séborrhée",
        type: "niosomeProduct",
        formes: ["Sérum", "Crème","Gélule"],
        url: "/niosomes/biotine",
        url3: "/liposomes/biotine",
        categorie: false,
        img: biotine2,
        product: false,
        bienfaits: [
            { titre: "Renforcement des cheveux et des ongles", description: "La biotine renforce les structures kératiniques des cheveux et des ongles, leur conférant résistance, éclat et vitalité." },
            { titre: "Amélioration de l'hydratation et de l'éclat de la peau", description: "En stimulant le métabolisme cellulaire, elle aide à maintenir une peau hydratée, souple et lumineuse." },
            { titre: "Stimulation de la croissance des cheveux", description: "Grâce à son action sur la production de kératine, la biotine favorise une croissance capillaire plus rapide et des cheveux plus forts." },
            { titre: "Réduction des ongles cassants", description: "En améliorant la qualité des ongles, elle diminue les fissures, les dédoublements et la casse." },
            { titre: "Convient à tous les types de peau et de cheveux", description: "La biotine est adaptée à une utilisation quotidienne pour des résultats visibles, quelle que soit la nature de la peau ou des cheveux." },
            { titre: "Anti séborrhée", description: "La biotine régule la production de sébum, contribuant ainsi à prévenir et soulager la séborrhée." },
        ],
        description: `
            <label class="bold">La biotine</label>, également connue sous le nom de vitamine B8, est un ingrédient clé pour renforcer la santé de la peau, des cheveux et des ongles. Elle soutient la <label class="bold">barrière cutanée</label>, préserve l'<label class="bold">hydratation</label> et favorise le <label class="bold">renouvellement cellulaire</label>, offrant une peau plus saine et éclatante.<br/><br/>

            Son action <label class="bold">régulatrice du sébum</label> contribue également à lutter contre la séborrhée, maintenant un <label class="bold">équilibre optimal</label> pour la peau et le cuir chevelu.<br/><br/>
            
            Cet actif <label class="bold">agit en profondeur</label> pour revitaliser la peau, stimuler la croissance des cheveux et renforcer les ongles cassants. Adapté à <label class="bold">tous les types de peau</label>, il aide à maintenir une hydratation optimale et un éclat naturel.<br/><br/>
            
            Grâce à la technologie niosomale, cet actif est optimisé pour une absorption maximale et une action prolongée, permettant des <label class="bold">résultats visibles et durables</label>.
        
        `,
    },
    {
        nom: "coenzyme q10",
        subtitle: "Régénération cellulaire",
        type: "niosomeProduct",
        formes: ["Sérum", "Crème"],
        url: "/niosomes/coenzyme-q10",
        url3: "/liposomes/coenzyme-q10",
        categorie: false,
        img: coenzymeQ10_2,
        product: false,
        bienfaits: [
            { titre: "Protection antioxydante puissante", description: "La CoQ10 protège la peau contre les dommages des radicaux libres, ralentissant ainsi le vieillissement prématuré et renforçant la barrière cutanée." },
            { titre: "Réduction des rides et ridules", description: "En neutralisant le stress oxydatif, la CoQ10 aide à réduire l'apparence des rides et des ridules, pour une peau plus jeune et éclatante." },
            { titre: "Stimulation de la régénération cellulaire", description: "Cet actif améliore la production d'énergie au niveau cellulaire, favorisant le renouvellement des cellules pour une peau revitalisée." },
            { titre: "Amélioration de l'élasticité et de la fermeté", description: "La CoQ10 stimule la production de collagène et d'élastine, contribuant à une peau plus ferme et plus élastique." },
            { titre: "Convient à tous les types de peau", description: "Sa formulation douce et efficace garantit une tolérance optimale, même pour les peaux sensibles." },
        ],
        description: `
            <label class="bold">La coenzyme Q10</label>, ou CoQ10, est un <label class="bold">antioxydant puissant</label> naturellement présent dans la peau, essentiel pour la protection des cellules et la production d'énergie. Connue pour ses propriétés anti-âge, la CoQ10 joue un rôle crucial dans la <label class="bold">préservation de la jeunesse de la peau</label> en neutralisant les radicaux libres responsables du vieillissement prématuré.<br/><br/>

            La CoQ10 est encapsulée grâce à la technologie niosomale. Cet actif optimise l'absorption et <label class="bold">agit en profondeur</label> pour réduire les signes visibles du vieillissement et protéger la peau des agressions extérieures.<br/>
            Cette technologie avancée permet une <label class="bold">libération prolongée</label> de la CoQ10, garantissant une <label class="bold">efficacité maximale</label> tout au long de la journée.<br/><br/>
            
            Ce soin convient à <label class="bold">tous les types de peau</label>, offrant une protection antioxydante, une amélioration de l'élasticité et un éclat naturel pour une <label class="bold">peau revitalisée</label>. <br/><br/>
            
            De plus, la CoQ10 favorise la <label class="bold">régénération cellulaire</label>, aidant à renouveler les cellules cutanées et à réparer les dommages causés par l'environnement, assurant ainsi une peau plus lisse et plus saine.
        `,
    },
    {
        nom: "collagene",
        subtitle: "Fermeté",
        type: "niosomeProduct",
        formes: ["Sérum", "Crème"],
        url: "/niosomes/collagene",
        url3: "/liposomes/collagene",
        categorie: false,
        img: collagene2,
        product: false,
        bienfaits: [
            { titre: "Protection antioxydante puissante", description: "La CoQ10 protège la peau contre les dommages des radicaux libres, ralentissant ainsi le vieillissement prématuré et renforçant la barrière cutanée." },
            { titre: "Réduction des rides et ridules", description: "En neutralisant le stress oxydatif, la CoQ10 aide à réduire l'apparence des rides et des ridules, pour une peau plus jeune et éclatante." },
            { titre: "Stimulation de la régénération cellulaire", description: "Cet actif améliore la production d'énergie au niveau cellulaire, favorisant le renouvellement des cellules pour une peau revitalisée." },
            { titre: "Amélioration de l'élasticité et de la fermeté", description: "La CoQ10 stimule la production de collagène et d'élastine, contribuant à une peau plus ferme et plus élastique." },
            { titre: "Convient à tous les types de peau", description: "Sa formulation douce et efficace garantit une tolérance optimale, même pour les peaux sensibles. " },
        ],
        description: `
            <label class="bold">Le collagène</label>, composant clé de la structure cutanée, est essentiel pour préserver l'<label class="bold">élasticité</label>, la <label class="bold">fermeté</label> et l'<label class="bold">hydratation</label> de la peau. <br/><br/>

            Grâce à la technologie niosomale, cet actif maximise l'absorption du collagène pour une <label class="bold">action ciblée en profondeur</label>, offrant des résultats visibles sur la texture et l'apparence de la peau.<br/><br/>
            
            Ce soin convient à tous les types de peau, en particulier les signes visibles du vieillissement et en <label class="bold">améliorant durablement l'hydratation et la souplesse</label> cutanée.
        `,
    },
    {
        nom: "glutathion",
        subtitle: "Protection cellulaire & détox",
        type: "niosomeProduct",
        formes: ["Sérum", "Crème"],
        url: "/niosomes/glutathion",
        url3: "/liposomes/glutathion",
        categorie: false,
        img: glutathion2,
        product: false,
        bienfaits: [
            { titre: "Puissante protection antioxydante", description: "Le glutathion neutralise les radicaux libres, protège la peau des agressions environnementales et ralentit le vieillissement prématuré." },
            { titre: "Réduction des irrégularités pigmentaires", description: "En régulant la production de mélanine, le glutathion aide à atténuer les tâches sombres et à uniformiser le teint." },
            { titre: "Prévention des signes du vieillissement", description: "Cet actif prévient l'apparition des rides et des ridules tout en renforçant la régénération cellulaire pour une peau plus jeune et revitalisée." },
            { titre: "Détoxification et uniformité du teint", description: "Le glutathion favorise un teint lumineux et homogène, révélant une peau éclatante au quotidien." },
            { titre: "Convient à tous les types de peau", description: "Sa formulation douce et non irritante garantit une efficacité optimale même pour les peaux sensibles." },
        ],
        description: `
            <label class="bold">Le glutathion</label>, considéré comme le « maître des antioxydants », est un actif incontournable pour <label class="bold">protéger la peau contre le stress oxydatif</label> et pour <label class="bold">son action détoxifiante</label> visant à favoriser son éclat naturel. <br/><br/>

            Grâce à la technologie niosomale, cet actif offre une <label class="bold">absorption renforcée</label> et une action prolongée, permettant des résultats visibles et durables.<br/><br/>
            
            Ce soin convient à <label class="bold">tous les types de peau</label>, en aidant à <label class="bold">réduire les irrégularités pigmentaires</label>, à prévenir les signes du vieillissement et à renforcer la barrière cutanée pour une peau éclatante et saine.        
        `,
    },
    {
        nom: "mélatonine",
        subtitle: "Régénération & rajeunissement",
        type: "niosomeProduct",
        formes: ["Sérum", "Crème"],
        url: "/niosomes/melatonine",
        url3: "/liposomes/melatonine",
        categorie: false,
        img: melatonine2,
        product: false,
        bienfaits: [
            { titre: "Régénération nocturne de la peau", description: "La mélatonine stimule les processus naturels de réparation de la peau, aidant à restaurer son éclat et sa vitalité pendant le repos nocturne." },
            { titre: "Protection contre les radicaux libres", description: "Grâce à ses puissantes propriétés antioxydantes, elle neutralise les radicaux libres, protège la peau des dommages environnementaux et du stress oxydatif." },
            { titre: "Réduction des signes visibles du vieillissement", description: "Pour favoriser le renouvellement cellulaire, ce sérum atténue les rides, les ridules et les autres signes de vieillissement prématuré." },
            { titre: "Hydratation et de la texture cutanée", description: "La mélatonine soutient la fonction barrière de la peau, permettant une meilleure rétention d'eau et une peau plus douce, lisse et uniforme." },
            { titre: "Convient à tous les types de peau", description: "Sa formulation respectueuse et non irritante en fait un allié pour toutes les peaux, même sensibles ou réactives." },
        ],
        description: `
            <label class="bold">La mélatonine</label>, souvent appelée « hormone du sommeil », est également un puissant antioxydant capable de protéger la peau des agressions extérieures et de favoriser sa <label class="bold">régénération naturelle</label> pendant la nuit pour un <label class="bold">effet rajeunissant</label>.<br/><br/>

            Grâce à la technologie niosomale, cet actif optimise l'absorption de la mélatonine, permettant une <label class="bold">action ciblée et durable</label>.<br/><br/>
            
            Ce soin est idéal pour restaurer l'éclat et la vitalité de la peau, en améliorant sa texture et en <label class="bold">atténuant les signes visibles du vieillissement</label>, tout en garantissant une hydratation profonde.
         
        `,
    },
    {
        nom: "resvératrol",
        subtitle: "Anti-rides & anti-tâches",
        type: "niosomeProduct",
        formes: ["Sérum", "Crème","Gélule"],
        url: "/niosomes/resveratrol",
        url3: "/liposomes/resveratrol",
        categorie: false,
        img: resveratrol,
        product: false,
        bienfaits: [
            { titre: "Protection antioxydante puissante", description: "Le resvératrol protège la peau des radicaux libres et des agressions environnementales, préservant sa jeunesse et sa vitalité." },
            { titre: "Prévention du vieillissement prématuré", description: "En renforçant la barrière cutanée, cet actif aide à ralentir l'apparition des rides et ridules, pour une peau visiblement plus jeune." },
            { titre: "Éclat et uniformité du teint", description: "Le resvératrol favorise un teint lumineux et homogène, en soulignant les irrégularités pigmentaires et les zones ternes." },
            { titre: "Amélioration de la texture de la peau", description: "Cet actif aide à lisser et adoucir la peau, en affinant le grain de peau pour une apparence renouvelée et radieuse." },
            { titre: "Convient à tous les types de peau", description: "Sa formule douce et efficace garantit une tolérance optimale, même pour les peaux sensibles ou réactives." },
        ],
        description: `
            <label class="bold">Le resvératrol</label>, puissant antioxydant issu des plantes, est reconnu pour ses propriétés <label class="bold">protectrices et régénérantes</label>. Il contribue également à atténuer l'apparence des tâches pigmentaires, pour un <label class="bold">teint visiblement plus uniforme</label>.<br/><br/>

            <label class="bold">Grâce à la technologie niosomale</label>, cet actif maximise l'absorption du <label class="bold">resvératrol</label>, permettant une action en profondeur et prolongée pour protéger la peau des agressions extérieures et <label class="bold">ralentir les signes visibles du vieillissement</label>.<br/><br/>
            
            Ce soin est adapté à <label class="bold">tous les types de peau</label>, en offrant un éclat naturel, une texture améliorée et une protection contre les dommages environnementaux.
        `,
    },
    {
        nom: "vitamine C",
        subtitle: "Éclat & Eclaircissant",
        type: "niosomeProduct",
        formes: ["Sérum", "Crème"],
        url: "/niosomes/vitamine-c",
        url3: "/liposomes/vitamine-c",
        categorie: false,
        img: vitamineC2,
        product: false,
        bienfaits: [
            { titre: "Protection antioxydante", description: "La vitamine C est un puissant antioxydant qui aide à protéger la peau des effets néfastes des radicaux libres, des molécules instables qui peuvent endommager les cellules et contribuer au vieillissement. En neutralisant les radicaux libres, la vitamine C aide à protéger la peau du vieillissement prématuré causé par l'exposition aux rayons UV et à la pollution." },
            { titre: "Augmentation de la production de collagène", description: "Le collagène est une protéine essentielle qui aide à garder la peau ferme, lisse et jeune. La vitamine C est essentielle à la synthèse du collagène, aidant à maintenir l'élasticité de la peau et à prévenir la formation de ridules et de rides." },
            { titre: "Éclaircissement du teint", description: "La vitamine C est réputée pour sa capacité à éclaircir le teint et à réduire l'apparence des décolorations cutanées. Elle peut aider à atténuer la pigmentation, à réduire les rougeurs et à uniformiser le teint, donnant à la peau une apparence plus radieuse et plus jeune." },
            { titre: "Réduction des tâches sombres", description: "La vitamine C aide à prévenir la production de mélanine, réduisant ainsi l'apparition de tâches brunes et d'hyperpigmentation. Avec une utilisation régulière, elle peut aider à obtenir un teint plus clair et plus uniforme." },
            { titre: "Amélioration de la cicatrisation des plaies", description: "La vitamine C protège non seulement la peau, mais accélère également les processus naturels de réparation de la peau. Elle favorise la cicatrisation des plaies et peut réduire le temps de récupération des cicatrices, coupures et autres lésions cutanées mineures." },
            { titre: "Hydratation", description: "La vitamine C peut aider à améliorer les niveaux d'hydratation de la peau en diminuant la perte d'eau transépidermique, ce qui permet à la peau de mieux retenir l'humidité. Cela conduit à une peau plus lisse et plus rebondie." },
            { titre: "Action anti-cernes", description: "La vitamine C peut aider à améliorer l'apparence des cernes sous les yeux, grâce à son effet éclaircissant et à sa capacité à améliorer l’hydratation de la peau et à réduire les poches." },
            { titre: "Réparation des dommages causés par le soleil", description: "La vitamine C peut aider à réparer certains signes de dommages causés par le soleil, tels que la décoloration et les ridules, en favorisant le renouvellement et la régénération cellulaires." },
        ],
        description: `
            <label class="bold">La vitamine C</label> aide à réduire visiblement les signes du vieillissement en <label class="bold">éclaircissant et en équilibrant le teint</label> inégal de la peau.<br/><br/>

            La vitamine C en soin visage est un incontournable pour une peau éclatante et lumineuse.<br/><br/>
            
            Puissant antioxydant, <label class="bold">elle protège contre les radicaux libres</label> et les agressions extérieures, comme la pollution et les rayons UV.<br/>
            Ce concentré de vitamine <label class="bold">stimule la production de collagène</label>, améliorant l'élasticité et réduisant les signes visibles du vieillissement, tels que les rides et les ridules.<br/><br/>
            
            Elle aide également à <label class="bold">estomper les tâches pigmentaires</label> et uniformise le teint, pour une peau visiblement plus lumineuse.<br/><br/>
            
            Grâce à sa texture légère, l'actif pénètre rapidement, apportant une hydratation intense sans alourdir. Adapté à tous les types de peau, il redonne vitalité et éclat au teint terne.
            
        `,
    },
    {
        nom: "vitamine E",
        subtitle: "Réparation & protection",
        type: "niosomeProduct",
        formes: ["Sérum", "Crème"],
        url: "/niosomes/vitamine-e",
        url3: "/liposomes/vitamine-e",
        categorie: false,
        img: vitamineE2,
        product: false,
        bienfaits: [
            { titre: "Protection contre les radicaux libres", description: "La vitamine E neutralise les radicaux libres, protégeant la peau des agressions extérieures comme les UV et la pollution." },
            { titre: "Prévention des signes du vieillissement", description: "En préservant l’élasticité et la fermeté de la peau, elle aide à ralentir l’apparition des rides et des ridules." },
            { titre: "Hydratation intense et durable", description: "La vitamine E nourrit la peau en profondeur, réduisant la sécheresse et laissant une sensation de confort durable." },
            { titre: "Apaisement et régénération de la peau", description: "Grâce à ses propriétés apaisantes, ce sérum calme les irritations et soutient la régénération des tissus cutanés." },
            { titre: "Convient à tous les types de peau", description: "Sa formulation douce et non comédogène en fait un soin idéal pour tous les types de peau, même les plus sensibles." },
        ],
        description: `
            <label class="bold">La vitamine E</label>, réputée pour ses <label class="bold">puissantes propriétés antioxydantes, protège</label> la peau des agressions environnementales, prévient les signes visibles du vieillissement prématuré et <label class="bold">répare</label> la barrière cutanée. <br/><br/>

            Grâce à la technologie niosomale, cette formule optimise l’absorption de la vitamine E, permettant une <label class="bold">action en profondeur et prolongée</label> pour hydrater, apaiser et revitaliser la peau.<br/><br/>
            
            Ce soin convient à <label class="bold">tous les types de peau</label>, en offrant une hydratation durable, un éclat naturel et une barrière cutanée renforcée.
            
        `,
    },
    {
        nom: "zinc",
        subtitle: "Cicatrisant & apaisant",
        type: "niosomeProduct",
        formes: ["Sérum", "Crème"],
        url: "/niosomes/zinc",
        url3: "/liposomes/zinc",
        categorie: false,
        img: zinc2,
        product: false,
        bienfaitsTitre: "test",
        bienfaits: [
            { titre: "Régulation de la production de sébum", description: "Le zinc PCA est reconnu pour sa capacité à réguler la production de sébum. En contrôlant la quantité de sébum produite par la peau, il aide à réduire la brillance et l'aspect gras de la peau, ce qui est particulièrement bénéfique pour les peaux grasses ou mixtes." },
            { titre: "Propriétés anti-acné", description: "Grâce à ses propriétés antibactériennes et anti-inflammatoires, le zinc PCA est efficace dans le traitement de l'acné. Il aide à réduire la prolifération des bactéries responsables de l'acné et à apaiser l'inflammation, diminuant ainsi les rougeurs et les poussées d'acné." },
            { titre: "Amélioration de l'hydratation de la peau", description: "Le composant PCA du zinc PCA est un facteur hydratant naturel qui attire l'humidité dans la peau, contribuant ainsi à maintenir des niveaux d'hydratation optimaux. Cela peut rendre la peau plus douce et plus souple, tout en améliorant sa texture globale." },
            { titre: "Effets antioxydants", description: "Le zinc PCA possède également des propriétés antioxydantes, ce qui signifie qu'il peut aider à protéger la peau contre les dommages causés par les radicaux libres et le stress environnemental. Cela est essentiel pour prévenir le vieillissement prématuré de la peau et maintenir son apparence saine et jeune." },
            { titre: "Réduction des irritations et des rougeurs", description: "Pour les peaux sensibles et réactives, le zinc PCA peut aider à réduire les irritations et les rougeurs grâce à ses propriétés apaisantes. Il est doux pour la peau et peut être utilisé pour améliorer l'apparence des peaux sujettes à l'eczéma ou à la rosacée." },
            { titre: "Amélioration de la barrière cutanée", description: "En renforçant la fonction barrière de la peau, le zinc PCA contribue à la protéger des agressions extérieures et à réduire la perte d'hydratation. C'est essentiel pour maintenir une peau saine et résiliente." },
        ],
        description: `
            <label class="bold">Le zinc PCA</label> ​​apaise les irritations, <label class="bold">favorise la cicatrisation</label> et <label class="bold">resserre les pores</label>.<br/>
            Cet actif niosomal est un allié précieux pour une peau saine et équilibrée. <br/><br/>
            
            Reconnu pour ses <label class="bold">propriétés purifiantes</label>, il aide à réguler la production de sébum, <label class="bold">idéal pour les peaux mixtes à grasses sujettes aux imperfections</label>. Il apaise les rougeurs et favorise la cicatrisation des petites lésions cutanées, comme les boutons d'acné. <br/><br/>
            
            Grâce à son pouvoir <label class="bold">antioxydant</label>, il protège la peau des agressions extérieures et des radicaux libres, retardant les signes du vieillissement.<br/><br/>
            
            Le zinc pénètre rapidement pour agir en profondeur. Il booste également le renouvellement cellulaire, laissant <label class="bold">une peau lisse</label> et lumineuse. <br/><br/>
            
            Que vous cherchiez à <label class="bold">atténuer vos imperfections</label> ou à <label class="bold">revitaliser votre teint</label>, cette formule au zinc est l'élément incontournable de votre routine beauté.
            
        `,
    },
];

export default produits;
