import React, { useState } from 'react';
import '../styles/components/moreinfo.css';

import Popup from './Popup';

function MoreInfo({ link, label, position }) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleClick = () => {
        if (link === "download") {
            downloadFile();
        } else if (link !== "*") {
            window.location.href = link;
        } else {
            openPopup();
        }
    };

    const downloadUrl="/catalogue.pdf"

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);

    const downloadFile = () => {
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = downloadUrl.split('/').pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div
            className={`moreinfo_container ${position ? 'absolute-position' : ''}`}
            onClick={handleClick}
        >
            <p className='moreinfo_text'>{label}</p>

            {/* Pop-up */}
            <Popup isOpen={isPopupOpen} onClose={closePopup}>
                <h2 className='popup-title'>En savoir plus sur un produit</h2>
                <form className='popup-container-form'>
                    <div className='popup-container-2input'>
                        <div>
                            <p className='popup-label'>Email</p>
                            <input className="popup-input1" type="email" required />
                        </div>
                        <div>
                            <p className='popup-label'>Numéro de téléphone</p>
                            <input className="popup-input2" type="tel" required />
                        </div>
                    </div>
                    <div className='popup-container-2input'>
                        <div>
                            <p className='popup-label'>Entreprise</p>
                            <input className="popup-input1" type="text" required />
                        </div>
                        <div>
                            <p className='popup-label'>Pays</p>
                            <input className="popup-input2" type="text" required />
                        </div>
                    </div>
                    <p className='popup-label'>Message</p>
                    <p className='popup-subtitle'>Un expert vous répondra dans les plus brefs délais</p>
                    <textarea className='popup-textarea'></textarea>
                    <button className='popup-btn' type="submit">Envoyer</button>
                </form>
            </Popup>
        </div>
    );
}

export default MoreInfo;
