import React, { useState, useEffect } from 'react';

import MoreInfo from "../components/MoreInfo";
import '../styles/views/formuleSurMesure.css';
import PositifPoint from "../components/PositifPoint";
import Process from '../components/Process';
import MockupTab from '../components/MockupTab';

import img_fiole from '../assets/img/formule_intro_fiole.png';
import img_packaging from '../assets/img/formule_packaging.png';

//icon process
import formule_standard from "../assets/icons/formule_standard.png";
import prod from "../assets/icons/prod.png";
import conditionnement from "../assets/icons/conditionnement.png";
import envoi from "../assets/icons/envoi.png";
import OtherProduct from '../components/OtherProduct';

//icon otherProduct
import probiotique from "../assets/icons/homepage_probiotique.png";
import niosome from "../assets/icons/homepage_niosome.png";
import liposome from "../assets/icons/homepage_liposome.png";

//icon carousel
import icon_arrow_forward from '../assets/icons/arrow_forward.svg';
import icon_arrow_back from '../assets/icons/arrow_back.svg';

import marque_blanche_mobile from "../assets/icons/img-marque-blanche-mobile.png"

export default function FormuleMarqueBlanche() {

    const [isMobile, setIsMobile] = useState(0);
    const [isCarousel, setIsCarousel] = useState(0);
    const [currentIndexProcess, setCurrentIndexProcess] = useState(0);

    const handlePrevProcess = () => {
        setCurrentIndexProcess((prevIndex) =>
            prevIndex === 0 ? process.length - 1 : prevIndex - 1
        );
    };

    const handleNextProcess = () => {
        setCurrentIndexProcess((prevIndex) =>
            prevIndex === process.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1050);
            setIsCarousel(window.innerWidth <= 1050)
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const downloadUrl = "/catalogue.pdf"

    const downloadFile = () => {
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = downloadUrl.split('/').pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const content = [
        ["Gain de temps", "Les formulations sont déjà prêtes, ce qui diminue les délais liés à la recherche et au développement."],
        ["Réduction des coûts", "Pas d’investissement dans des infrastructures de production ou de R&D."],
        ["Assurance qualité", "Notre laboratoire respecte des normes de qualité élevées (HACCP, GMP)."],
        ["Flexibilité", "La possibilité d’adapter l’emballage et le design selon votre image de marque."],
        ["Rapidité de mise sur le marché", "Avec des produits prêts à l’emploi, vous pouvez lancer rapidement votre gamme."]
    ]

    const categories = [
        {
            title: "Liposomes",
            subtitle: "Extraits de plantes vitamines - minéraux",
            type: "liposome",
            link: "*",
        },
        {
            title: "Niosomes",
            subtitle: "cosmétiques",
            type: "niosome",
            link: "*",
        },
        {
            title: "Probiotiques & postbiotiques",
            subtitle: "",
            type: "probiotique",
            link: "*",
        },
    ];

    const process = [
        [
            formule_standard,
            "choix d’une formule standard",
            "Accédez à une gamme de <label class='bold'>formules préétablies, testées et validées</label>, prêtes pour une <label class='bold'>prêtes à être mises sur le marché rapidement</label>. Ces formules répondent aux principaux besoins en matière de santé et de bien-être du marché."
        ],
        [
            prod,
            "production",
            "Grâce à notre infrastructure <label class='bold'>certifiée</label>, nous assurons une production de haute qualité. Adaptée à vos volumes, elle garantit des produits <label class='bold'>conformes aux normes et rigoureusement contrôlés</label>."
        ],
        [
            conditionnement,
            "conditionnement personnalisé",
            "Piluliers, blisters, sachets, flacons, nous personnalisons le conditionnement pour <label class='bold'>refléter votre marque</label> et répondre aux attentes de vos clients, tout en <label class='bold'>optimisant la conservation</label> des actifs."
        ],
        [
            envoi,
            "Envoi des produits",
            "Nous assurons un <label class='bold'>acheminement rapide et sécurisé</label>, que ce soit vers des centres de répartition ou directement en point de vente, pour une <label class='bold'>distribution efficace.</label>"
        ]
    ]

    useEffect(() => {
        const handleResize = () => {
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div>
            <div className="formule_global-container">
                <section className="formule_intro-container-global">
                    {isMobile ? (
                        <div className='formule_intro-container'>
                            <h1 className="formule_intro-title">
                                <label className="bold formule_font-50">Nos formules</label> <br />
                                <label className='orange-color'>En marque blanche</label>
                            </h1>
                            <MoreInfo
                                label={"Demandez notre catalogue"}
                                link={"download"}
                            />
                            <h2 className="formule_intro-subtitle">Des produits<label className="orange-color"> clé en main</label></h2>
                            <article className="formule_intro-text">
                                Nos formules en marque blanche offrent une <label className='bold'>solution simple et rapide</label> pour intégrer des compléments alimentaires et des cosmétiques de haute qualité dans votre catalogue.
                                En optant pour cette solution, vous accédez à des produits pré-formulés et éprouvés, <label className='bold'>prêts à être commercialisés sous votre marque.</label>
                            </article>
                        </div>
                    ) : (
                        <div className='formule_intro-container'>
                            <h1 className="formule_intro-title">
                                <label className="bold formule_font-50">Nos formules</label> <br />
                                <label className='orange-color'>En marque blanche</label>
                            </h1>
                            <MoreInfo
                                label={"Demandez notre catalogue"}
                                link={"download"}
                            />
                            <h2 className="formule_intro-subtitle">Des produits<label className="orange-color"> clé en main</label></h2>
                            <article className="formule_intro-text">
                                Nos formules en marque blanche offrent une <label className='bold'>solution simple et rapide</label> pour intégrer des compléments alimentaires et des cosmétiques de haute qualité dans votre catalogue.
                                En optant pour cette solution, vous accédez à des produits pré-formulés et éprouvés, <label className='bold'>prêts à être commercialisés sous votre marque.</label>
                            </article>
                        </div>
                    )}
                    {!isMobile && <img className='formule_intro-container-img' src={marque_blanche_mobile} />}
                </section>
                <section className="formule_ourFormule-container">
                    <h3 className='formule_ourFormule-title'><label className="orange-color">Nos formules,</label> votre marque</h3>
                    <div className='formule_ourFormule-bloc-container'>
                        <div className='formule_ourFormule-text-container'>
                            <h3 className='formule_ourFormule-title-bloc'><label className="orange-color">Votre formule<br /></label> Prêtes à l'emploi</h3>
                            <article>Nous vous proposons un <label className='bold'>large catalogue de formules éprouvées</label> et prêtes à être commercialisées. Ces solutions, conçues par nos experts, répondent aux <label className='bold'>attentes actuelles du marché</label> en matière de santé et de bien-être. Chaque formule est élaborée avec des ingrédients de qualité et respecte des normes strictes pour garantir l'efficacité et la conformité.</article>
                            <img className='formule_ourFormule-img' src={img_fiole} />
                        </div>
                        <p className='formule_ourFormule-plus'>+</p>
                        <div className='formule_ourFormule-text-container'>
                            <h3 className='formule_ourFormule-title-bloc'><label className="orange-color">votre packaging</label></h3>
                            <article>Nous adaptons chaque produit à <label className='bold'>votre image</label> grâce à un packaging entièrement <label className='bold'>personnalisé.</label> Qu'il s'agisse de boîtes, piluliers, ou sachets, nous intégrons vos éléments graphiques pour refléter <label className='bold'>l'identité de votre marque,</label> tout en garantissant praticité, stabilité et protection optimale des formules.</article>
                            <img className='formule_ourFormule-img' src={img_packaging} />
                        </div>
                    </div>
                </section>
                <section className="fomrule_positifPoint-container">
                    <div className='fomrule_positifPoint-container2'>
                        <div className='formule_positifPoint-text-container'>
                            <h3 className='formule_positifPoint-title'><label className="orange-color">Les plus</label> de la formule <br />sur-mesure</h3>
                            <article>Nous mettons à votre disposition une <label className='bold orange-color'>large gamme de compléments alimentaires et de cosmétiques</label> en marque blanche, adaptée aux tendances du marché : bien-être, immunité, digestion, beauté, énergie, etc. Chaque produit est formulé avec des ingrédients soigneusement sélectionnés pour garantir efficacité et satisfaction client.</article>
                        </div>
                        <div className='formule_positifPoint-container'>
                            {content.map(([title, content], index) => (
                                <PositifPoint key={index} title={title} content={content} />
                            ))}
                        </div>
                    </div>
                </section>
                <div className="vertical-line"></div>
                <section className='formule_process_global-container'>
                    <h3 className='formule_process-title'>
                        <label className='orange-color'>Notre processus</label><br />de développement produit
                    </h3>
                    <p className='formule_process-subtitle'>
                        Notre méthode de création sur-mesure suit un <label className='bold'>processus rigoureux</label>, en six étapes clés, qui garantit<br />
                        une formulation unique et une <label className='bold'>qualité irréprochable.</label>
                    </p>
                    <div className="formule_process_comp-container">
                        {isCarousel ? (
                            // Mode CAROUSEL (Mobile)
                            <div>
                                <div className="carousel_container">
                                    <Process
                                        img={process[currentIndexProcess][0]}
                                        title={process[currentIndexProcess][1]}
                                        content={process[currentIndexProcess][2]}
                                        index={currentIndexProcess + 1}  // Ajout de l'index
                                    />
                                </div>
                                <div className='formule_arrow_container--mobile'>
                                    <img className="carousel_arrow" alt="Flèche gauche" src={icon_arrow_back} onClick={handlePrevProcess} />
                                    <img className="carousel_arrow" alt="Flèche droite" src={icon_arrow_forward} onClick={handleNextProcess} />
                                </div>
                            </div>
                        ) : (
                            <div className='formule_process_comp-container2'>
                                {process.map(([img, title, content], index) => (
                                    <Process
                                        key={index}
                                        img={img}
                                        index={index + 1}
                                        title={title}
                                        content={content}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </section>
                <div className="vertical-line"></div>
                <section className='formule_tabMockup_global-container'>
                    <h3 className='formule_tabMockup-title'><label className='orange-color'>Nos produits</label><br/>en marque blanche</h3>
                    <MockupTab />
                </section>
                <section className='formule_product_global-container'>
                    <h3 className='formule_product-title'><label className='orange-color'>Nos produits</label><br /> <label className='bold'> en marque blanche</label></h3>
                    <div className='formule_product_catalogue-container'>
                        <h3 className='formule_product_catalogue-title'><label className='bold'>Retrouvez toutes nos formules</label><br /><label className='orange-color'>dans notre catalogue</label></h3>
                        <button className='formule_product_catalogue-btn' onClick={downloadFile}>Voir notre catalogue</button>
                    </div>
                    <MoreInfo 
                        label={"Nous contacter"}
                        link={"*"}
                    />
                </section>
                <section className='formule_otherProduct_global-container'>
                    <h3 className='formule_otherCategory-title'><label className='bold'>Nos autres</label><label className='orange-color'> catégories d'ingrédients</label></h3>
                    <OtherProduct
                        title1={"niosomes"}
                        subtitle1={"Cosmétiques"}
                        img1={niosome}
                        link1={"/niosomes"}
                        title2={"Probiotiques & Postbiotiques"}
                        subtitle2={"Technologies VPRO® et PROLIPO®"}
                        img2={probiotique}
                        link2={"/probiotiques"}
                    />
                </section>
            </div>
        </div>
    );
}