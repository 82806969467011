import React, { useState, useEffect } from "react";
import "../styles/components/process.css"

export default function Process({ img, index, title, content }) {

    return (
        <div className="process_global-container">
            <img className="process-img" src={img} />
            <h4 className="process-title"><label className="orange-color bold font-size25">{index}.</label> {title}</h4>
            <p
                className='process-text'
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    );
}
